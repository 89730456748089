import React from "react";
import {
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
  Grid,
  Select,
  MenuItem,
  IconButton,
  Button,
  Switch,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SortTableColumn from "../../../components/common/SortTableColumn";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../components/common/TableCell";

import {
  headCells,
  fetchPreApprovedUserList,
  deletePreApprovedUser,
  activeDeactivePreApprovedUser,
} from "./PreApprovedUserList";
import ViewEditPreApprovedUserModal from "./ViewEditPreApprovedUserModal";
import useViewEditVendorModalState from "../../../hooks/vendor/useViewEditVendorModalState";
import usePaginationState from "../../../hooks/usePaginationState";
import useConfirmationModalState from "../../../hooks/useConfirmationModalState";
import ConfirmationModal from "../../../components/common/ConfirmationModal";
import RefreshIcon from "@mui/icons-material/Refresh";
import TablePagination from "../../../components/common/TablePagination";
import history from "../../../history";
import { PER_PAGE_ROWS_OPTIONS } from "../../../AppConfig";

export default function MuiTable() {
  const [ preApprovedUsers, setPreApprovedUsers] = React.useState([]);
  const { showCmfModal, setShowCmfModal, cmfModalDetails, setCmfModalDetails } =
    useConfirmationModalState();
  const {
    showModal,
    setShowModalState,
    modalId,
    setModalIdState,
    modalFiledDisabled,
    setModalFiledDisabledState,
  } = useViewEditVendorModalState();
  const { pagination, setPagination } = usePaginationState({
    searchFilter: "",
  });
  const [isLoading, setIsLoading] = React.useState(false);
  let timer: ReturnType<typeof setTimeout>;

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      setPreApprovedUsers([]);
      const data = await fetchPreApprovedUserList(pagination);
      if (data) {
        setPagination({
          searchFilter: data.pagination.searchFilter
            ? data.pagination.searchFilter
            : "",
          perPageRows: data.pagination.perPageRows
            ? data.pagination.perPageRows
            : 10,
          currentPage: data.pagination.currentPage
            ? data.pagination.currentPage
            : 1,
          totalPages: data.pagination.totalPages
            ? data.pagination.totalPages
            : 0,
          totalItems: data.pagination.totalItems
            ? data.pagination.totalItems
            : 0,
          orderBy: data.pagination.orderBy ? data.pagination.orderBy : "desc",
          sortBy: data.pagination.sortBy ? data.pagination.sortBy : "id",
        });
        setPreApprovedUsers(data.preApprovedUsers);
      }
    } catch (e) {}
    setIsLoading(false);
  };

  const handleRequestSort = (property: string) => {
    pagination.orderBy =
      pagination.sortBy == property
        ? pagination.orderBy == "asc"
          ? "desc"
          : "asc"
        : "asc";
    pagination.sortBy = property;
    fetchData();
  };

  const handleRowsPerPage = (rowCount: number) => {
    pagination.perPageRows = rowCount;
    pagination.currentPage = 1;
    fetchData();
  };

  const handleCloseModal = (status: boolean) => {
    setShowModalState(false);
    if (status) {
      fetchData();
    }
  };

  const handleShowModal = (id: number, disabled: boolean) => {
    setModalIdState(id);
    setModalFiledDisabledState(disabled);
    setShowModalState(true);
  };

  const handleShowDelModal = (id: number, name: string) => {
    setCmfModalDetails({
      id,
      title: "User Delete",
      message: `You are about to delete ${name}.`,
    });
    setShowCmfModal(true);
  };

  const handleDelCloseModal = async (status: boolean) => {
    setShowCmfModal(false);
    if (status) {
      const resDelete = await deletePreApprovedUser(cmfModalDetails.id);
      if (resDelete) fetchData();
    }
  };

  const handleActiveDeactive = async (id: number) => {
    const tempPreApprovedUser: any = preApprovedUsers.find(
      (merchant: any) => id === merchant.id
    );
    if (!tempPreApprovedUser) return;
    const result = await activeDeactivePreApprovedUser(
      id,
      tempPreApprovedUser.is_active === "0" ? 1 : 0
    );
    if (result) {
      const tempPreApprovedUser: any = preApprovedUsers.map((user: any) => {
        if (user.id === id) {
          return {
            ...user,
            is_active: user.is_active === "0" ? "1" : "0",
          };
        }
        return user;
      });
      setPreApprovedUsers(tempPreApprovedUser);
    }
  };

  const handleSearchChange = async (e: any) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(async () => {
      if (e.target.name === "searchFilter") {
        pagination.searchFilter = e.target.value;
        pagination.currentPage = 1;
        fetchData();
      }
    }, 500);
  };

  const handlePageChange = (page: number) => {
    pagination.currentPage = page;
    fetchData();
  };

  return (
    <React.Fragment>
      <div className="page-header">
        <span className="page-title">Pre Approved User List</span>
      </div>
      <div>
        <Grid container spacing={2} className="headElements">
          <Grid item className="leftHead">
            <p className="selectPages">Show</p>
            <Select
              value={pagination.perPageRows}
              label="Page"
              className="dropDown"
              onChange={(e) => handleRowsPerPage(Number(e.target.value))}
            >
              {PER_PAGE_ROWS_OPTIONS.map((item: number) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
            <span
              className="margin-left-16 btn-icon"
              title="Reload"
              onClick={() => fetchData()}
            >
              <RefreshIcon fontSize="large" className="color-light-blue" />
            </span>
            <Button
              className="btn-primary margin-left-16"
              onClick={() => handleShowModal(0, false)}
            >
              Add New Pre Approved User
            </Button>
          </Grid>

          <Grid item className="rightHead">
            <p className="selectPages">Search</p>
            <TextField
              size={"small"}
              className="searchField"
              id="outlined-basic"
              name="searchFilter"
              placeholder="Search User"
              onChange={(e: any) => handleSearchChange(e)}
            />
          </Grid>
        </Grid>
        <TableContainer component={Paper} className="tableContainer">
          <Table stickyHeader className="table" aria-label="simple table">
            <SortTableColumn
              order={pagination.orderBy}
              orderBy={pagination.sortBy}
              headCells={headCells}
              handleRequestSort={handleRequestSort}
            />
            <TableBody>
              {isLoading ? (
                <TableCell colSpan={6}>
                  <div className="loader-container">
                    <div className="loader"></div>
                  </div>
                </TableCell>
              ) : (
                preApprovedUsers.map((row: any, index) => (
                  <StyledTableRow hover key={index}>
                    <StyledTableCell className="tableCell table-cell-left-align">
                      {row.user_name}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell-left-align">
                      <Grid
                        container
                        className="actionGrid"
                        justifyContent="start"
                      >
                        <React.Fragment>
                          <Grid>
                            <IconButton
                              title="Edit"
                              onClick={() => handleShowModal(row.id, false)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Grid>
                          <Grid>
                            <IconButton
                              title="Delete"
                              onClick={() =>
                                handleShowDelModal(row.id, row.user_name)
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                          <Grid>
                            <Switch
                              color={row.is_active === "0" ? "error" : "success"}
                              title="Active"
                              checked={row.is_active === "1"}
                              onChange={() => handleActiveDeactive(row.id)}
                            />
                          </Grid>
                        </React.Fragment>
                      </Grid>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          currentPage={pagination.currentPage}
          perPageRows={pagination.perPageRows}
          totalItems={pagination.totalItems}
          totalPages={pagination.totalPages}
          handleOnChangePage={handlePageChange}
        />
      </div>
      {showModal && (
        <ViewEditPreApprovedUserModal
          id={modalId}
          closeModal={handleCloseModal}
          disabled={modalFiledDisabled}
        />
      )}
      {showCmfModal && (
        <ConfirmationModal
          closeModal={handleDelCloseModal}
          title={cmfModalDetails.title}
          message={cmfModalDetails.message}
        />
      )}
    </React.Fragment>
  );
}
