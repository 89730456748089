import * as Yup from "yup";
import { VENDOR_REGISTER } from "../../../services/AppServices";
import { toast } from "react-toastify";
import { appErrors, publicRoutePath } from "../../../AppConstants";
import history from "../../../history";
import { formatPhoneMaskNumber, getEncryptedText } from "../../../utils/sharedFunctions";
import { TVendorRegistration } from "../../../data/types/appUser";

export const INITIAL_FORM_STATE = {
  vendor_name: "",
  first_name: "",
  last_name: "",
  password: "",
  confirm_password: "",
  email_address: "",
  primary_contact_number: "",
  emergency_contact_number: "",
};

export const FORM_VALIDATION = Yup.object().shape({
  vendor_name: Yup.string()
    .required("Vendor name is required!")
    .max(75, "Vendor name must be 75 characters!"),
  first_name: Yup.string()
    .required("First name is required!")
    .max(30, "First name must be 30 characters!"),
  last_name: Yup.string()
    // .required("Last name is required!")
    .max(30, "Last name must be 30 characters!"),
  email_address: Yup.string()
    .required("Email address is required!")
    .email("Invalid email")
    .max(75, "Email must be 75 characters!"),
  password: Yup.string()
    .required("Password is required!")
    .min(8, "Password must be 8 characters!")
    .max(30, "Password must be 30 characters!")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/,
      "Must contain 8 characters, one uppercase, one lowercase, one number and one special case character"
    ),
  confirm_password: Yup.string()
    .required("Confirm password is required!")
    .oneOf([Yup.ref("password"), null], "Confirm password doesn`t match"),
  primary_contact_number: Yup.string()
    .required("Primary contact number is required!")
    .max(20, "Primary contact number must be 20 characters!")
    .min(10, "Primary contact number must be 10 characters!"),
  emergency_contact_number: Yup.string()
    // .required("Emergency contact number is required!")
    .max(20, "Emergency contact number must be 20 characters!")
    .min(10, "Emergency contact number must be 10 characters!")
});

export const vendorRegister = async (props: TVendorRegistration) => {
  const payload: TVendorRegistration = {
    password: props.password,
    vendor_name: props.vendor_name,
    first_name: props.first_name,
    last_name: props.last_name,
    email_address: props.email_address,
    primary_contact_number: formatPhoneMaskNumber(props.primary_contact_number),
    emergency_contact_number: formatPhoneMaskNumber(props.emergency_contact_number)
  };
  try {
    const data = await VENDOR_REGISTER(payload);
    if (data.status === 200 || data.status === "200") {
      if(data.data){
        history.push(
          `${publicRoutePath.accountVerification}/${getEncryptedText(data.data.toString())}`
        );
      }else{
        toast.success("Vendor successfully register");
        return true;
      }
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
  return false;
};
