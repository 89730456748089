import { connect } from "react-redux";
import VendorProfileView from "../../views/vendor/profile/VendroProfileView";
import { addUserInfo } from "../../store/actions/userInfoAction";
import { TReduxDispatch, TReduxState } from "../../store/store";

const mapStateToProps = (state: TReduxState) => ({
  userInfo: state.loginInfo.userInfo,
});

const mapDispatchToProps = (dispatch: any) => ({
  addMainUserInfoHandler: (data: any) => dispatch(addUserInfo(data)),
});

const VendorProfileContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(VendorProfileView);
export default VendorProfileContainer;
