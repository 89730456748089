import { TFetchTruckInformation,TUpdateTruckInfo } from "../../../data/types/adminApi";
import { TRUCK_INFO,UPDATE_TRUCK_INFO } from "../../../services/AdminServices";
import { toast } from "react-toastify";
import { appErrors } from "../../../AppConstants";
import * as Yup from "yup";

export const apiCallTruckInfo = async (fieldValues: any) => {
    const payload: TFetchTruckInformation = {
      searchFilter: fieldValues?.searchFilter ? fieldValues.searchFilter : "",
      perPageRows: fieldValues?.perPageRows ? fieldValues.perPageRows : 10,
      currentPage: fieldValues?.currentPage ? fieldValues.currentPage : 1,
      orderBy: fieldValues?.orderBy ? fieldValues.orderBy : "DESC",
      sortBy: fieldValues?.sortBy ? fieldValues.sortBy : "id",
    };
  
    try {
      const data = await TRUCK_INFO(payload);
      // console.log(data);
      if (data.status === 200 || data.status === "200") {
        return data.data;
      } else {
        toast.error(data?.message);
      }
    } catch (e: any) {
      toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
    }
  };

  export const INITIAL_FORM_STATE = {
    height: 0,
    length: 0,
    width: 0,
    weight: 0,
    engine_type: "",
    mpg: 0,
    per_galen_rate: 0,
    avg_speed: 0,
    avoid_toll: 0,
    
  };
  export const getInitialFormState = (userList: any) => {
    return {
      
      id: userList?.id || 0,
      height: userList?.height || 0,
      length: userList?.length || 0,
      width: userList?.width || 0,
      weight: userList?.weight || 0,
      engine_type: userList?.engine_type || "",
      mpg: userList?.mpg || 0,
      per_galen_rate: userList?.per_galen_rate || 0,
      avg_speed: userList?.avg_speed || 0,
      avoid_toll: userList?.avoid_toll || 0,
      avoid_sharp_ton: userList?.avoid_sharp_ton || 0,
      
    };
  };
   export const FORM_VALIDATION = Yup.object().shape({
    height: Yup.number()
         .required(" Height is required!"),
        
    length: Yup.number()
         .required("Length name is required!"),
         
    width: Yup.number()
          .required("Width name is required!"),
         
    mpg: Yup.number()
         .required("MPG is required!"),
        
    per_galen_rate: Yup.number()
          .required("$/G rate is required!"),

    avoid_toll: Yup.number()
          .required('Required'),
          
    avoid_sharp_ton: Yup.number()
          .required('Required'),
    engine_type: Yup.string()
          .oneOf(
            [],
            'Invalid Job Type'
          )
          .required('Required'),
   });

  export const updateTruck = async (props: any) => {
    const payload: TUpdateTruckInfo = {
      id: props.id,
      height: props.height,
      length: props.length,
      width: props.width,
      weight: props.weight,
      engine_type: props.engine_type,
      mpg: props.mpg,
      per_galen_rate: props.per_galen_rate,
      avg_speed: props.avg_speed,
      avoid_toll: props.avoid_toll,
      avoid_sharp_ton: props.avoid_sharp_ton,
     
    }
    try {
      const data = await UPDATE_TRUCK_INFO(payload);
      if (data.status === 200 || data.status === "200") {
        return true;
      } else {
        toast.error(data?.message);
      }
    } catch (e: any) {
      toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
    }
    return false;
  };