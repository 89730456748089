import { apiEndPoints } from "../AppConstants";
import { httpMethods, serviceMaker } from "./ServiceWarpper";
import {
  TAddBrandMerchants,
  TFetchAllBrandMerchants,
  TActiveInactiveBrandMerchants,
} from "../data/types/merchantApi";
import { getEncryptedText } from "../utils/sharedFunctions";

export const FETCH_ALL_BRAND_MERCHANTS = (payload: TFetchAllBrandMerchants) =>
  serviceMaker(
    `${apiEndPoints.FETCH_ALL_BRAND_MERCHANTS}?searchFilter=${payload.searchFilter}&brandId=${payload.brandId}
&perPageRows=${payload.perPageRows}&currentPage=${payload.currentPage}
&orderBy=${payload.orderBy}&sortBy=${payload.sortBy}`,
    httpMethods.GET
  );

export const FETCH_ALL_MERCHANTS = (brandId: number) =>
  serviceMaker(`${apiEndPoints.ALL_MERCHANTS}`, httpMethods.POST, {
    id_brand: brandId,
  });

export const ADD_BRAND_MERCHANT = (payload: TAddBrandMerchants) =>
  serviceMaker(`${apiEndPoints.ADD_BRAND_MERCHANT}`, httpMethods.POST, payload);

export const DELETE_BRAND_MERCHANT = (payload: number) =>
  serviceMaker(
    `${apiEndPoints.DELETE_BRAND_MERCHANT}/${getEncryptedText(
      payload.toString()
    )}`,
    httpMethods.DELETE
  );

export const ACTIVE_INACTIVE_BRAND_MERCHANTS = (payload: TActiveInactiveBrandMerchants) =>
  serviceMaker(
    `${apiEndPoints.ACTIVE_INACTIVE_BRAND_MERCHANTS}`,
    httpMethods.POST,
    payload,
  );
