import React, { useState } from "react";
import AppContext from "./components/AppContext";

import { useSystemErrorState } from "./hooks/useSytemErrorState";
import { useIsUserSignedInState } from "./hooks/auth/useIsUserSignedInState";
import { localStorageUtils } from "./utils/localStorageUtils";
import { Provider } from 'react-redux';
import store from "./store/store";
import Router from "./route/router";

const App: React.FC = () => {
  const globalState = {
    errorMessageState: useSystemErrorState(),
    isUserSignedInState: useIsUserSignedInState(),
  };

  const [isRendered, setIsRendered] = useState<boolean>(false);

  React.useEffect(() => {
    // debugger;
    var result = !!localStorageUtils.getUserSignedInStatus();
    globalState.isUserSignedInState.setIsUserSignedInFlag(
      !!localStorageUtils.getUserSignedInStatus()
    );
    setIsRendered(true);
  }, []);

  return isRendered ? (
    <Provider store={store}>
    <AppContext.Provider value={globalState}>
      <div className="App">
        <Router />
        <div id="modal-placement"></div>
      </div>
    </AppContext.Provider>
    </Provider>
  ) : null;
};

export default App;
