import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { useField } from "formik";

const DropDown = (props: any) => {
  const [field, mata] = useField(props.name);
  const configProps = { ...props };

  const configDropDown: any = {
    ...field,
    ...configProps,
    className: `text-align-left ${props.className ? props.className : ""}`,
  };

  if (mata && mata.touched && mata.error) {
    configDropDown.error = true;
    configDropDown.helpertext = mata.error;
  }

  return (
    <FormControl fullWidth>
      <InputLabel error={configDropDown.error}>{props.label}</InputLabel>
      <Select {...configDropDown}>
        <MenuItem
          value={
            configProps?.selectOptionValue ||
            configProps?.selectOptionValue === 0
              ? configProps.selectOptionValue
              : ""
          }
        >
          Select
        </MenuItem>
        {props.itemlist.map((item: any, index: number) => (
          <MenuItem key={index} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error={configDropDown.error}>
        {configDropDown.helpertext}
      </FormHelperText>
    </FormControl>
  );
};

export default DropDown;
