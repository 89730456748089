import React from "react";
import { Drawer, Box, Grid, Button } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Formik, Form } from "formik";
import {
  getInitialState,
  FORM_VALIDATION,
  fetchPreApprovedUserById,
  updatePreApprovedUser,
  addPreApprovedUser
} from "./ViewEditPreApprovedUser";
import Textfield from "../../../components/FormsUI/Textfield";

const ViewEditPreApprovedUserModal = (props: any) => {
  const [preApprovedUser, setPreApprovedUser] = React.useState<any>(null);
  React.useEffect(() => {
    if (props.id !== 0) {
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    const data = await fetchPreApprovedUserById(props.id);
    setPreApprovedUser(data);
  }

  const checkApiCall = async (fieldValues: any) => {
    if (preApprovedUser) {
    const data = await updatePreApprovedUser({
      ...fieldValues,
      id: preApprovedUser.id,
    });
    if (data) props.closeModal(true);
  }
  else{
    const data = await addPreApprovedUser(
      {
        ...fieldValues,
      }
    );
    if (data) props.closeModal(true);
  }
  };

  return (
    <React.Fragment>
      <Drawer open anchor="right" className="side-modal">
        <Box className="modal-dialog-box">
          <div className="modal-header">
            <div className="modal-title">{preApprovedUser?.user_name}</div>
            <div className="modal-header-action">
              <span
                className="modal-btn-close"
                onClick={() => props.closeModal(false)}
              >
                <Close />
              </span>
            </div>
          </div>
          <div className="modal-content">
            <Formik
              enableReinitialize
              initialValues={getInitialState(preApprovedUser)}
              validateOnBlur={false}
              validationSchema={FORM_VALIDATION}
              onSubmit={async (fieldValues) => checkApiCall(fieldValues)}
            >
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Textfield
                      name="user_name"
                      label="Username"
                      disabled={props.disabled}
                    />
                  </Grid>
                  <Grid item>
                    {!props.disabled && (
                      <Button
                        type="submit"
                        className="btn-primary margin-right-16"
                      >
                        SUBMIT
                      </Button>
                    )}
                    <Button
                      className="btn-outline"
                      onClick={() => props.closeModal(false)}
                    >
                      CANCEL
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </Formik>
          </div>
        </Box>
      </Drawer>
    </React.Fragment>
  );
};

export default ViewEditPreApprovedUserModal;
