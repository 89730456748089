import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import history from "../../../history";
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh";
import SortTableColumn from "../../../components/common/SortTableColumn";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../components/common/TableCell";
import {
  headCells,
  fetchBrandMerchants,
  fetchAllMerchants,
  addBrandMerchant,
  deleteBrandMerchant,
  activeInactiveBrandMerchant,
} from "./BrandMerchantConfiguration";
import {
  TableCell,
  Table,
  TableBody,
  TableContainer,
  Paper,
  Grid,
  IconButton,
  Switch,
  Autocomplete,
  TextField,
  MenuItem,
  Select,
  Button,
} from "@mui/material";
import usePaginationState from "../../../hooks/usePaginationState";
import TablePagination from "../../../components/common/TablePagination";
import {
  getDecryptedText,
  getEncryptedText,
  UserType,
} from "../../../utils/sharedFunctions";
import { TMerchant } from "../../../data/types/merchantState";
import useConfirmationModalState from "../../../hooks/useConfirmationModalState";
import ConfirmationModal from "../../../components/common/ConfirmationModal";
import {
  vendorRoutePath,
  adminRoutePath,
  publicRoutePath,
} from "../../../AppConstants";
import { PER_PAGE_ROWS_OPTIONS } from "../../../AppConfig";

const BrandMerchantConfiguration = (props: any) => {
  const params = useParams();
  const brandId = params.id ? getDecryptedText(params.id) : null;
  const [brand, setBrand] = useState<any>(null);
  const [merchantList, setMerchantList] = useState<TMerchant[]>([]);
  const [brandMerchantList, setBrandMerchantList] = useState<any>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const { pagination, setPagination } = usePaginationState({
    searchFilter: "",
    brandId: brandId,
  });
  const [addMerchantInput, setAddMerchantInput] = useState("");
  const { showCmfModal, setShowCmfModal, cmfModalDetails, setCmfModalDetails } =
    useConfirmationModalState();
  let timer: ReturnType<typeof setTimeout>;

  useEffect(() => {
    setInitialState();
  }, []);

  const setInitialState = async () => {
    if (!brandId) {
      history.push(publicRoutePath.pageNotFound);
      return;
    }
    fetchData();
    fetchMerchantList();
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      setBrandMerchantList([]);
      const data = await fetchBrandMerchants(pagination);
      if (data) {
        setPagination({
          searchFilter: data.pagination.searchFilter
            ? data.pagination.searchFilter
            : "",
          perPageRows: data.pagination.perPageRows
            ? data.pagination.perPageRows
            : 10,
          currentPage: data.pagination.currentPage
            ? data.pagination.currentPage
            : 1,
          totalPages: data.pagination.totalPages
            ? data.pagination.totalPages
            : 0,
          totalItems: data.pagination.totalItems
            ? data.pagination.totalItems
            : 0,
          orderBy: data.pagination.orderBy ? data.pagination.orderBy : "desc",
          sortBy: data.pagination.sortBy ? data.pagination.sortBy : "id",
          brandId: data.pagination.brandId ? data.pagination.brandId : null,
        });
        setBrandMerchantList(data.brand_merchants);
      }
    } catch (e) {}
    setIsLoading(false);
  };

  const fetchMerchantList = async (bmc: any = null) => {
    try {
      setMerchantList([]);
      const data = await fetchAllMerchants(brandId);
      if (data) {
        setMerchantList(data.merchants);
        setBrand(data.brand);
      }
    } catch (e) {}
  };

  const addBMC = async (merchant: TMerchant) => {
    setIsLoading(true);
    try {
      const data = await addBrandMerchant({
        id_brand: brandId,
        id_merchant: merchant.id,
      });
      if (data) {
        const tempList = merchantList.filter(
          (obj: TMerchant) => obj.id !== merchant.id
        );
        setMerchantList(tempList);
        fetchData();
      }
    } catch (e) {}
    setIsLoading(false);
  };

  const deleteBMC = async (id: number) => {
    setIsLoading(true);
    try {
      const data = await deleteBrandMerchant(id);
      if (data) {
        fetchData();
        fetchMerchantList();
      }
    } catch (e) {}
    setIsLoading(false);
  };

  const activeInactiveBMC = async (id: number, is_active: string) => {
    try {
      const data = await activeInactiveBrandMerchant({
        id,
        is_active: is_active === "0" ? 1 : 0,
      });
      if (data) {
        const tempList = brandMerchantList.map((obj: any) => {
          if (obj.id === id) {
            return { ...obj, is_active: is_active === "0" ? "1" : "0" };
          }
          return obj;
        });
        setBrandMerchantList(tempList);
        fetchMerchantList();
      }
    } catch (e) {}
  };

  const handleShowDelModal = (id: number, name: string) => {
    setCmfModalDetails({
      id,
      title: "Delete Merchant",
      message: `You are about to delete ${name} merchant.`,
    });
    setShowCmfModal(true);
  };

  const handleDelCloseModal = async (status: boolean) => {
    setShowCmfModal(false);
    if (status) {
      await deleteBMC(cmfModalDetails.id);
    }
  };

  const handleRequestSort = (property: string) => {
    pagination.orderBy =
      pagination.sortBy === property
        ? pagination.orderBy === "asc"
          ? "desc"
          : "asc"
        : "asc";
    pagination.sortBy = property;
    fetchData();
  };

  const handleRowsPerPage = (rowCount: number) => {
    pagination.perPageRows = rowCount;
    pagination.currentPage = 1;
    fetchData();
  };

  const handleSearchChange = async (e: any) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(async () => {
      if (e.target.name === "searchFilter") {
        pagination.searchFilter = e.target.value;
        pagination.currentPage = 1;
        fetchData();
      }
    }, 500);
  };

  const handlePageChange = (page: number) => {
    pagination.currentPage = page;
    fetchData();
  };

  const goBack = () => {
    if (props.loginInfo.user_type === UserType.Vendor) {
      history.push(`${vendorRoutePath.brands}`);
    } else {
      history.push(
        `${adminRoutePath.vendorBrands}/${getEncryptedText(
          brand?.id_vendor.toString()
        )}`
      );
    }
  };

  return (
    <React.Fragment>
      <div className="page-header">
        <span className="page-title">Brand Merchant Configuration</span>
      </div>
      <div className="brand-merchant-config-container">
        <Grid container spacing={2} className="brand-merchant-header">
          <Grid
            item
            md={3}
            sm={5}
            xs={12}
            className="brand-merchant-header-brand-name"
          >
            <p>Brand: {brand?.brand_name}</p>
          </Grid>
          <Grid item md={9} sm={7} xs={12} className="header-add-merchant-box">
            <Autocomplete
              freeSolo
              disableClearable
              onChange={(event: any, value: any, reason: string) => {
                if (reason === "selectOption") {
                  addBMC(value);
                }
              }}
              inputValue={addMerchantInput}
              onInputChange={(e, value, reason) =>
                setAddMerchantInput(reason === "reset" ? "" : value)
              }
              options={merchantList}
              // getOptionLabel={(option: TMerchant) =>
              //   (option.merchant_id as string) + " - " + (option.merchant_name as string)
              // }
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    size={"small"}
                    label="Add Merchant"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
        <hr className="brand-merchant-header-separator" />
        <Grid container spacing={2} className="headElements">
          <Grid item className="leftHead">
            <p className="selectPages">Show</p>
            <Select
              value={pagination.perPageRows}
              label="Page"
              className="dropDown"
              onChange={(e) => handleRowsPerPage(Number(e.target.value))}
            >
              {PER_PAGE_ROWS_OPTIONS.map((item: number) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
            <span
              className="margin-left-16 btn-icon"
              title="Reload"
              onClick={() => fetchData()}
            >
              <RefreshIcon fontSize="large" className="color-light-blue" />
            </span>
          </Grid>
          <Grid item className="rightHead">
            <p className="selectPages">Search</p>
            <TextField
              size={"small"}
              className="searchField"
              id="outlined-basic"
              name="searchFilter"
              placeholder="Search Merchants"
              onChange={(e: any) => handleSearchChange(e)}
            />
            <Button
              className="btn-primary margin-left-16 margin-right-8"
              onClick={() => goBack()}
            >
              BACK
            </Button>
          </Grid>
        </Grid>
        <TableContainer component={Paper} className="tableContainer">
          <Table stickyHeader className="table" aria-label="simple table">
            <SortTableColumn
              order={pagination.orderBy}
              orderBy={pagination.sortBy}
              headCells={headCells}
              handleRequestSort={handleRequestSort}
            />
            <TableBody>
              {isLoading ? (
                <TableCell colSpan={4}>
                  <div className="loader-container">
                    <div className="loader"></div>
                  </div>
                </TableCell>
              ) : (
                brandMerchantList.map((row: any, index: any) => (
                  <StyledTableRow hover key={index}>
                    <StyledTableCell className="table-cell-left-align">
                      <Grid item lg={10}>
                        {row.merchant_id}
                      </Grid>
                    </StyledTableCell>
                    <StyledTableCell className="table-cell-left-align">
                      {row.merchant_name}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell-left-align">
                      {row.mcc}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell-left-align">
                      <Grid
                        container
                        className="actionGrid"
                        justifyContent="start"
                      >
                        <Grid>
                          <IconButton
                            title="Delete"
                            onClick={() =>
                              handleShowDelModal(row.id, row.merchant_name)
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                        <Grid>
                          <Switch
                            color={row.is_active === "0" ? "error" : "success"}
                            title="Active/Inactive"
                            checked={row.is_active === "1"}
                            onChange={() =>
                              activeInactiveBMC(row.id, row.is_active)
                            }
                          />
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          currentPage={pagination.currentPage}
          perPageRows={pagination.perPageRows}
          totalItems={pagination.totalItems}
          totalPages={pagination.totalPages}
          handleOnChangePage={handlePageChange}
        />
      </div>
      {showCmfModal && (
        <ConfirmationModal
          closeModal={handleDelCloseModal}
          title={cmfModalDetails.title}
          message={cmfModalDetails.message}
        />
      )}
    </React.Fragment>
  );
};

export default BrandMerchantConfiguration;
