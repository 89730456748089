import React from "react";
import usePaginationState from "../../../hooks/usePaginationState";
import { Grid,Select,MenuItem,TextField,TableContainer,Table,TableHead,TableRow,TableCell,Paper, TableBody, IconButton} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import ShowerIcon from '@mui/icons-material/Shower';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import ScaleIcon from '@mui/icons-material/Scale';
import Rating from "@mui/material/Rating";
import { apiCallFetchStation } from "./ApiStation";
import {PER_PAGE_ROWS_OPTIONS} from "../../../AppConfig";
import TablePagination from "../../../components/common/TablePagination";
const Popularstations = () =>
{
 
    const [stationList, setStationList] = React.useState([]);
    let timer: ReturnType<typeof setTimeout>;
    const { pagination, setPagination } = usePaginationState({
      searchFilter: "",
    });
   
    const [isLoading, setIsLoading] = React.useState(false);
      React.useEffect(()=>{
        fetchData();
       },[])   
        const fetchData = async () =>{
              setIsLoading(true);
              try{
                setStationList([]);
                const data = await apiCallFetchStation(pagination);
                
                console.log(data);
               
                if(data && data.rows && data.count){
                  let count = data.count ? (parseInt(data.count)) : 1
                  let perPage = parseInt(pagination.perPageRows)
                  let totalPages = count > perPage ? parseInt((count / perPage).toString()) + 1 : 1
    
                  setPagination({
                    searchFilter: pagination.searchFilter,
                  perPageRows: pagination.perPageRows,
                  currentPage: pagination.currentPage,
                  totalPages: totalPages,
                  totalItems: count,
                 
                  });
    
                  setStationList(data.rows);
                  
                }
              }catch (e) {}
              setIsLoading(false);
        }
        const handleRowsPerPage = (rowCount: number) => {
          pagination.perPageRows = rowCount;
          pagination.currentPage = 1;
          fetchData();
        };
    
        const handlePageChange = (page: number) => {
          pagination.currentPage = page;
          fetchData();
        };

        const handleSearchChange = async (e: any) => {
          if (timer) {
            clearTimeout(timer);
          }
          timer = setTimeout(async () => {
            if (e.target.name == "searchFilter") {
              pagination.searchFilter = e.target.value;
              pagination.currentPage = 1;
              fetchData();
            }
          }, 500);}


    return(
        <React.Fragment>
        <div className="page-header">
            <span className="page-title">Popular Stations</span>
        </div>
        <div>
        <Grid container spacing={2} className="headElements">
          <Grid item className="leftHead">
          <p className="selectPages">Show</p>
            <Select value={pagination.perPageRows}
              label="Page"
              className="dropDown"
              onChange={(e:any) => handleRowsPerPage(Number(e.target.value))}
            >
              {PER_PAGE_ROWS_OPTIONS.map((item: number) => <MenuItem value={item}>{item}</MenuItem>)}
            </Select>
            <span  className="margin-left-16 btn-icon" title="Reload" onClick={()=>fetchData()}>
                <RefreshIcon fontSize="large" className="color-light-blue" />
            </span>
          </Grid>
          <Grid item className="rightHead">
            <p className="selectPages">Search</p>
            <TextField
              size={"small"}
              className="searchField"
              id="outlined-basic"
              name="searchFilter"
              placeholder="Search Trips"
              onChange={(e: any) => handleSearchChange(e)}
            />
          </Grid>
        </Grid>
        <TableContainer component={Paper} className="tableContainer">
          <Table stickyHeader className="table" aria-label="simple table">
         <TableHead>
            <TableRow>
                <TableCell  style={{ fontWeight:"bold",  fontSize:16}}>Sr.No</TableCell>
                <TableCell style={{ fontWeight:"bold",  fontSize:16}}> Sation Type </TableCell> 
                <TableCell style={{ fontWeight:"bold",  fontSize:16}}> Station Name </TableCell>
                <TableCell style={{ fontWeight:"bold",  fontSize:16}}> Station Address </TableCell>
                <TableCell style={{ fontWeight:"bold",  fontSize:16}}> Number of Stops</TableCell>
                <TableCell style={{ fontWeight:"bold",  fontSize:16}}> Facilities </TableCell>
                <TableCell style={{ fontWeight:"bold",  fontSize:16}}>Ratings</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {isLoading ? (
                <TableCell colSpan={7}>
                  <div className="loader-container">
                    <div className="loader"></div>
                  </div>
                </TableCell>
              ):(
                stationList.map((row: any,i) =>(
                <TableRow key={i}>
                  <TableCell  style={{ fontWeight:"bold"}}>{i + 1}</TableCell>          
                  <TableCell>{row.type}</TableCell>
                  <TableCell  style={{ width:"20%"}}>{row.title}</TableCell>
                  <TableCell  style={{ width:"20%"}}>{row.address}</TableCell>
                  <TableCell>{row.tripStopCount}</TableCell>
                  <TableCell>
                      
                          {row.rest_area === 1 ? <IconButton><ShowerIcon /></IconButton>:<></>}
                          {row.parking === 1 ? <IconButton><LocalParkingIcon /></IconButton>:<></>}
                      <IconButton>
                          <MiscellaneousServicesIcon />
                           
                      </IconButton>
                          {row.weight_station === 1 ? <IconButton><ScaleIcon /></IconButton>:<></>}
                       
                          {row.fuel_station == 1?<IconButton><LocalGasStationIcon /></IconButton>:<></>}
                      
                  </TableCell>
                  <TableCell>
                  <Rating name="half-rating" defaultValue={row.rating}  precision={0.5} readOnly />
                 
                  </TableCell>

             </TableRow>)))}
       </TableBody>
        </Table>
        </TableContainer>
        <TablePagination
          currentPage={pagination.currentPage}
          perPageRows={pagination.perPageRows}
          totalItems={pagination.totalItems}
          totalPages={pagination.totalPages}
          handleOnChangePage={handlePageChange}
        />
          </div>
         
        </React.Fragment>
    )
}

export default Popularstations