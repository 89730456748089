import { apiEndPoints } from "../AppConstants";
import { httpMethods, serviceMaker } from "./ServiceWarpper";
import {
  TActiveInactiveOffer,
  TFetchAllOffers,
  TFetchOfferInitital,
  TApiDeleteOfferCriteria,
  TApiUpdateOfferEndDate,
} from "../data/types/OfferApi";
import { getEncryptedText } from "../utils/sharedFunctions";

export const FETCH_ALL_OFFERS = (payload: TFetchAllOffers) =>
  serviceMaker(
    `${apiEndPoints.FETCH_ALL_OFFERS}?searchFilter=${payload.searchFilter}
&vendorId=${getEncryptedText(payload.vendorId?.toString())}&perPageRows=${
      payload.perPageRows
    }&currentPage=${payload.currentPage}
&isActive=${payload.isActive}&orderBy=${payload.orderBy}&sortBy=${
      payload.sortBy
    }`,
    httpMethods.GET
  );

export const DELETE_OFFER = (payload: number) =>
  serviceMaker(
    `${apiEndPoints.DELETE_OFFER}/${getEncryptedText(payload.toString())}`,
    httpMethods.DELETE
  );

export const ADD_OFFER = (payload: any) =>
  serviceMaker(`${apiEndPoints.ADD_OFFER}`, httpMethods.POST, payload);

export const UPDATE_OFFER = (payload: any) =>
  serviceMaker(`${apiEndPoints.UPDATE_OFFER}`, httpMethods.PUT, payload);

export const FETCH_ALL_OFFER_CATEGORIES = () =>
  serviceMaker(`${apiEndPoints.FETCH_ALL_OFFER_CATEGORIES}`, httpMethods.GET);

export const FETCH_OFFER_INITIAL = (payload: TFetchOfferInitital) =>
  serviceMaker(`${apiEndPoints.OFFER_INITIAL}`, httpMethods.POST, payload);

export const ACTIVE_INACTIVE_OFFER = (payload: TActiveInactiveOffer) =>
  serviceMaker(
    `${apiEndPoints.ACTIVE_INACTIVE_OFFER}`,
    httpMethods.POST,
    payload
  );

export const DELETE_OFFER_CRITERIA = (payload: TApiDeleteOfferCriteria) =>
  serviceMaker(`${apiEndPoints.DELETE_CRITERIA}`, httpMethods.POST, payload);

export const UPDATE_OFFER_END_DATE = (payload: TApiUpdateOfferEndDate) =>
  serviceMaker(
    `${apiEndPoints.UPDATE_OFFER_END_DATE}`,
    httpMethods.POST,
    payload
  );
