import React from 'react'

type TComfirmationModalDetails = {
  id: number;
  title: string;
  message: string;
}

const useConfirmationModalState = (): {
  showCmfModal: boolean
  setShowCmfModal: (value: boolean) => void
  cmfModalDetails: TComfirmationModalDetails
  setCmfModalDetails: (value: TComfirmationModalDetails) => void
} => {
  const [showCmfModal, setShowCmfModalState] = React.useState<boolean>(false);
  // const [cmfModalId, setCmfModalIdState] = React.useState<number>(0);
  const [cmfModalDetails, setCmfModalDetailState] = React.useState<TComfirmationModalDetails>({
    id: 0,
    title: '',
    message: '',
  });

  const setShowCmfModal = (value: boolean) => {
    setShowCmfModalState(value)
  }

  const setCmfModalDetails = (state: TComfirmationModalDetails) => {
    setCmfModalDetailState({...cmfModalDetails, ...state});
  }

  return {
    showCmfModal,
    setShowCmfModal,
    cmfModalDetails,
    setCmfModalDetails,
  }
}

export default useConfirmationModalState;