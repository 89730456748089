import { Switch } from "@mui/material";
import { useField } from "formik";

const ToggleSwitch = (props: any) => {
  const [field, mata] = useField(props.name);

  const configSwitch: any = {
    ...field,
    ...props,
  };

  return (
    <div className="display-flex align-items-center">
      <span>{props.label}</span>
      <Switch {...configSwitch} checked={configSwitch.value} />
    </div>
  );
};

export default ToggleSwitch;
