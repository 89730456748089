import Axios from "axios";

import { localStorageUtils } from "../utils/localStorageUtils";
import { API_ENDPOINT } from "../AppConfig";
import { appConstant } from "../AppConstants";

process.env.NODE_TLS_REJECT_UNAUTHORIZED = "0";

export function isValidResponse(resp: any) {
  return resp && resp.status === 200 && resp.data.status === 1;
}

const baseURL = API_ENDPOINT;
const apiPrefix = "/api/";

export const CONFIG = Axios.create({
  baseURL: `${baseURL}${apiPrefix}`,
});

CONFIG.interceptors.request.use((config: any) => {
  const token = localStorageUtils.getAccessToken();
  config.headers.Authorization = `${token}`;
  config.headers["Content-Type"] = "application/json";
  config.headers["x-access-token"] = `${token}`;
  return config;
});

export const httpMethods = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
  GET_IMAGE: "GET_IMAGE",
};

export const serviceMaker = async (url: string, method: string, data = {}) => {
  try {
    let result;
    let APIInstance = CONFIG;

    switch (method) {
      case httpMethods.GET: {
        result = await APIInstance.get(url);
        break;
      }
      case httpMethods.POST: {
        result = await APIInstance.post(url, data);
        break;
      }
      case httpMethods.PUT: {
        result = await APIInstance.put(url, data);
        break;
      }
      case httpMethods.DELETE: {
        result = await APIInstance.delete(url, data);
        break;
      }
      case httpMethods.GET_IMAGE: {
        result = await APIInstance.get(`${baseURL}${url}`, {
          responseType: "arraybuffer",
        });
        break;
      }
      default: {
        throw appConstant.INVALID_METHOD;
      }
    }
    if (!isValidResponse) {
      throw appConstant.INVALID_RESPONSE;
    }
    return result.data;
  } catch (err: any) {
    throw new APIError(
      err.response
        ? err.response
        : {
            data: {
              status: "error",
              code: 500,
              message: "Something went wrong",
              data: null,
            },
          }
    );
  }
};

export class APIError {
  data;
  constructor(msg: any) {
    this.data = msg.data;
  }
}
