import React from "react";
import { Grid, Button, Card, IconButton, Box } from "@mui/material";
import Textfield from "../../../components/FormsUI/Textfield";
import { Formik, Form } from "formik";
import history from "../../../history";
import {
  getInitialFormState,
  FORM_VALIDATION,
  apiCallUpdateProfile,
  apiCallFetchVendorProfile,
  handleDropImage,
  apiCallDeleteImages,
} from "./VendorProfile";
import Dropzone from "react-dropzone";
import NoImgAvailable from "../../../assets/images/no-image.jpg";
import ImageRenderer from "../../../components/core/ImageRenderer";
import { TImageDetails } from "../../../data/types/OfferState";
import { TVendorProfile } from "../../../data/types/appUser";
import { appErrors, vendorRoutePath } from "../../../AppConstants";
import { toast } from "react-toastify";
import { localStorageUtils } from "../../../utils/localStorageUtils";
import useImageState from "../../../hooks/useImageState";
import {
  ImageFileType,
  checkIsValidImage,
} from "../../../utils/sharedFunctions";
import PhoneInputMaskField from "../../../components/FormsUI/PhoneInputMaskField";

const VendorProfileView = (props: any) => {
  const [vendor, setVendor] = React.useState<TVendorProfile | null>(null);
  const {
    imageDetails,
    setImageDetails,
    delImageList,
    setDelImageList,
    isImgLoading,
    setIsImgLoading,
  } = useImageState();
  React.useEffect(() => {
    fetchProfile();
  }, []);

  const fetchProfile = async () => {
    const data = await apiCallFetchVendorProfile();
    if (data) {
      setVendor(data);
      if (data.vendor_image) {
        setImageDetails({
          id: data.vendor_image.id,
          image_path: data.vendor_image.image_path,
        });
      }
    }
  };

  const dropzoneSelectedImage = async (acceptedImage: any) => {
    const isValidImage = await checkIsValidImage(acceptedImage[0], ImageFileType.ProfileImage);
    if (isValidImage) {
      const vendorId = vendor ? vendor.id : null;
      handleDropImage(
        acceptedImage,
        vendorId,
        imageDetails,
        setImageDetails,
        setIsImgLoading,
        delImageList,
        setDelImageList
      );
    } else {
      toast.error(appErrors.PROFILE_IMAGE_SIZE_TYPE);
    }
  };

  const handleRemoveImage = async () => {
    setIsImgLoading(true);
    if (imageDetails.id) {
      const data = await apiCallDeleteImages([
        ...delImageList,
        imageDetails.id,
      ]);
      if (data) {
        setDelImageList([]);
        setImageDetails({ id: null, image_path: "" });
        props.addMainUserInfoHandler({ ...props.userInfo, vendor_image: null });
        localStorageUtils.setUserInfo({
          ...props.userInfo,
          vendor_image: null,
        });
        toast.success("Image successfully removed");
      } else {
        toast.error(appErrors.UNKNOWN_ERROR_TRY_AGAIN);
      }
    }
    setIsImgLoading(false);
  };

  return (
    <React.Fragment>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={8} lg={6}>
          <Card className="sm-page">
            <div className="sm-page-header">
              <span className="sm-page-title">Vendor Profile</span>
            </div>
            <div className="sm-page-container">
              <Formik
                enableReinitialize
                initialValues={getInitialFormState(vendor)}
                validateOnBlur={false}
                validationSchema={FORM_VALIDATION}
                onSubmit={async (fieldValues) =>
                  apiCallUpdateProfile(
                    fieldValues,
                    props.addMainUserInfoHandler,
                    imageDetails,
                    setVendor,
                    delImageList,
                    setDelImageList
                  )
                }
              >
                <Form>
                  <Grid container spacing={2} sx={{ p: 2 }}>
                    <Grid item className="display-flex">
                      <div className="padding-right-16">
                        {imageDetails.image_path ? (
                          isImgLoading ? (
                            <div className="offer-image-view">
                              <div className="loader-container">
                                <span className="loader"></span>
                              </div>
                            </div>
                          ) : (
                            <ImageRenderer
                              src={imageDetails.image_path}
                              height="140px"
                              width="140px"
                              style={{
                                border: "1px solid lightgrey",
                                borderRadius: "4px",
                              }}
                              title={"Offer Image"}
                              alt={"Offer"}
                            />
                          )
                        ) : (
                          <img
                            src={NoImgAvailable}
                            style={{
                              border: "0.5px solid lightgrey",
                              borderRadius: "4px",
                              height: "140px",
                              width: "140px",
                            }}
                            title={"Offer Image"}
                            alt={"Offer"}
                          />
                        )}
                      </div>
                      <div>
                        <p className="text-align-left">
                          {vendor ? vendor.vendor_name : ""}
                        </p>
                        <Box display={{ sm: "inline-block", xs: "flex" }}>
                          <Dropzone
                            onDrop={(acceptedFiles: any) =>
                              dropzoneSelectedImage(acceptedFiles)
                            }
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <Button className="margin-right-16 btn-primary">
                                  CHANGE
                                </Button>
                              </div>
                            )}
                          </Dropzone>
                        </Box>
                        <Box
                          display={{ sm: "inline-block", xs: "flex" }}
                          pt={{ xs: 1, sm: 0 }}
                        >
                          <Button
                            className="btn-outline"
                            onClick={() => handleRemoveImage()}
                          >
                            REMOVE
                          </Button>
                        </Box>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <Textfield name="vendor_name" label="Vendor Name" />
                    </Grid>
                    <Grid item>
                      <fieldset className="primary-fieldset">
                        <legend>
                          <span className="bold-text-3">Primary Details</span>
                        </legend>
                        <Grid container spacing={2} sx={{ pt: 2, pb: 1 }}>
                          <Grid item xs={12} sm={6}>
                            <Textfield
                              name="first_name"
                              label="Contact First Name"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Textfield
                              name="last_name"
                              label="Contact Last Name"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <PhoneInputMaskField
                              name="primary_contact_number"
                              label="Contact Number"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <PhoneInputMaskField
                              name="emergency_contact_number"
                              label="Emergency Contact Number"
                            />
                          </Grid>
                        </Grid>
                      </fieldset>
                    </Grid>
                    <Grid item xs={12} className="padding-top-24">
                      <Textfield
                        name="email_address"
                        label="Email Address"
                        disabled
                      />
                    </Grid>
                    <Grid container justifyContent="center" sx={{ pt: 3 }}>
                      <Button
                        className="margin-right-16 btn-primary"
                        type="submit"
                      >
                        SAVE
                      </Button>
                      <Button
                        className="btn-outline"
                        onClick={() => history.push(vendorRoutePath.dashboard)}
                      >
                        CANCEL
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              </Formik>
            </div>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default VendorProfileView;
