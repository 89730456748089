import { getIpAddress, ImageFileType } from "./../../../utils/sharedFunctions";
import { TAdminAddBrand, TAdminUpdateBrand } from "../../../data/types/adminApi";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { appErrors } from "../../../AppConstants";
import {
  ADD_IMAGE,
  ADMIN_ADD_BRAND,
  ADMIN_FETCH_BRAND,
  ADMIN_UPDATE_BRAND,
  DELETE_IMAGE,
} from "../../../services/AppServices";
import moment from "moment";
import { TImageDetails } from "../../../data/types/OfferState";

export const FORM_VALIDATION = Yup.object().shape({
  brand_name: Yup.string()
    .required("Brand name is required!")
    .max(30, "Brand name must be 30 characters!"),
  web_site_address: Yup.string().required("Web URL is required"),
  brand_about: Yup.string()
  .max(200, "Description must be less than 200 characters!"),
address: Yup.string()
  .min(4, "Address must have atleast 4 character")
  .max(50, "Addrese must be less than 50 characters!"),
});

export const getInitialState = (brand: any) => {
  return {
    brand_name: brand?.brand_name || "",
    brand_about: brand?.brand_about || "",
    address: brand?.address || "",
    web_site_address: brand?.web_site_address || "",
  };
};

export const fetchBrandById = async (id: number) => {
  try {
    const data = await ADMIN_FETCH_BRAND(id);
    if (data.code === 200 || data.code === "200") {
      return data.data;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
};

export const addBrand = async (props: any, imageDetails: TImageDetails, delImageList: number[], setDelImageList: (value: number[]) => void) => {
  const payload: TAdminAddBrand = {
    id_vendor: props.id_vendor,
    brand_name: props.brand_name,
    brand_about: props.brand_about,
    address: props.address,
    web_site_address: props.web_site_address,
    id_image: imageDetails.id
  };
  try {
    const data = await ADMIN_ADD_BRAND(payload);
    if (data.code === 200 || data.code === "200") {
      toast.success(data?.message);
      apiCallDeleteImages(delImageList);
      setDelImageList([]);
      return true;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }

  return false;
};

export const updateBrand = async (props: any, imageDetails: TImageDetails, delImageList: number[], setDelImageList: (value: number[]) => void) => {
  const payload: TAdminUpdateBrand = {
    id: props.id,
    id_app_user: props.id_app_user,
    brand_name: props.brand_name,
    brand_about: props.brand_about,
    address: props.address,
    web_site_address: props.web_site_address,
    id_image: imageDetails.id
  };
  try {
    const data = await ADMIN_UPDATE_BRAND(payload);
    if (data.code === 200 || data.code === "200") {
      toast.success(data?.message);
      apiCallDeleteImages(delImageList);
      setDelImageList([]);
      return true;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }

  return false;
};

export const handleDropImage = async (
  acceptedFiles: any,
  vendor_id: number | null,
  imageDetails: TImageDetails,
  setImageDetails: (value: TImageDetails) => void,
  setIsImgLoading: (value: boolean) => void,
  delImageList: number[],
  setDelImageList: (value: number[]) => void
) => {
  setIsImgLoading(true);
  let formData = new FormData();
  formData.append("image", acceptedFiles[0]);
  formData.append("vendorId", vendor_id ? vendor_id.toString() : "");
  formData.append("ftype", ImageFileType.BrandImage.toString());
  try {
    const data = await ADD_IMAGE(formData);
    if (data.code === 200 || data.code === "200") {
      if (imageDetails.id) {
        setDelImageList([...delImageList, imageDetails.id]);
      }
      setImageDetails({ id: data.data.id, image_path: data.data.image_path });
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
  setIsImgLoading(false);
};

export const apiCallDeleteImages = async (delImageList: number[]) => {
  for (const id of delImageList) {
    try {
      await DELETE_IMAGE({ id });
    } catch (e: any) {
      return false;
    }
  }
  return true;
};