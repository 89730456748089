import * as Yup from "yup";
import { M2PCardLockFlag } from "../../utils/sharedFunctions";

export const getInitialState = (reason: string) => {
  return {
    reason: reason || "",
  };
};

export const FORM_VALIDATION = Yup.object().shape({
  reason: Yup.string()
    .required("Reason is required!")
    .min(3, "Reason must be 3 characters!")
    .max(30, "Reason must be 30 characters!"),
});

export const getModalTile = (type: number) => {
  switch (type) {
    case M2PCardLockFlag.Lock:
      return "Lock Card";
    case M2PCardLockFlag.UnLock:
      return "Unlock Card";
    case M2PCardLockFlag.PermanentBlock:
      return "Permanent Block Card";
  }
  return "";
};
