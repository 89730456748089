import { TextField } from "@mui/material";
import { useField } from "formik";

const Textfield = (props: any) => {
  const [field, mata] = useField(props.name);

  const configTextfield: any = {
    ...field,
    ...props,
    fullWidth: true,
    variant: "outlined",
  };

  if (mata && mata.touched && mata.error) {
    configTextfield.error = true;
    configTextfield.helperText = mata.error;
  }

  return <TextField {...configTextfield} />;
};

export default Textfield;
