import * as Yup from "yup";
import { toast } from "react-toastify";
import { appErrors } from "../../../AppConstants";

import {
  FETCH_PRE_APPROVED_USER_BY_ID,
  ADD_PRE_APPROVED_USER,
  UPDATE_PRE_APPROVED_USER,
} from "../../../services/PreApprovedUserServices";
import {
  TAddPreApprovedUser,
  TUpdatePreApprovedUser,
} from "../../../data/types/preApprovedUserApi";

export const FORM_VALIDATION = Yup.object().shape({
  user_name: Yup.string()
    .required("Username is required!")
    .max(50, "Username must be less than 50 characters!"),
});

export const getInitialState = (preApprovedUser: any) => {
  return {
    user_name: preApprovedUser?.user_name || "",
  };
};

export const fetchPreApprovedUserById = async (id: number) => {
  try {
    const data = await FETCH_PRE_APPROVED_USER_BY_ID(id);
    if (data.code === 200 || data.code === "200") {
      return data.data;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
};

export const addPreApprovedUser = async (props: any = {}) => {
  const payload: TAddPreApprovedUser = {
    user_name: props.user_name,
  };
  try {
    const data = await ADD_PRE_APPROVED_USER(payload);
    if (data.code === 200 || data.code === "200") {
      toast.success(data?.message);
      return true;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
  return false;
};

export const updatePreApprovedUser = async (props: any = {}) => {
  const payload: TUpdatePreApprovedUser = {
    id: props.id,
    user_name: props.user_name,
  };
  try {
    const data = await UPDATE_PRE_APPROVED_USER(payload);
    if (data.code === 200 || data.code === "200") {
      toast.success(data?.message);
      return true;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
  return false;
};
