import { toast } from "react-toastify";
import { appErrors } from "../../../AppConstants";
import { TFetchAllInfluencer } from "../../../data/types/adminApi";
import {
  ADMIN_ACCOUNT_ACTIVE_DEACTIVE,
  ADMIN_ACCOUNT_APPROVE_DISAPPROVE,
  ADMIN_FETCH_ALL_INFLUENCER,
  DELETE_INFLUENCER,
} from "../../../services/AppServices";
import { getEncryptedText } from "../../../utils/sharedFunctions";

export const fetchInfluencerList = async (props: any = {}) => {
  const payload: TFetchAllInfluencer = {
    searchFilter: props.searchFilter ? props.searchFilter : "",
    perPageRows: props.perPageRows ? props.perPageRows : 10,
    currentPage: props.currentPage ? props.currentPage : 1,
    isActive: props.isActive ? props.isActive : "",
    orderBy: props.orderBy ? props.orderBy : "DESC",
    sortBy: props.sortBy ? props.sortBy : "id",
  };
  try {
    const data = await ADMIN_FETCH_ALL_INFLUENCER(payload);
    if (data.code === 200 || data.code === "200") {
      // debugger ;
      return data.data;
    } else {
      // debugger ;
      toast.error(data?.message);
    }
  } catch (e: any) {
    // debugger ;
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
};

export const deleteInfluencer = async (id: number) => {
  try {
    const payload = getEncryptedText(id.toString());
    const data = await DELETE_INFLUENCER(payload);
    if (data.code === 200 || data.code === "200") {
      toast.success("Influencer successfully deleted");
      return true;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
  return false;
};

export const activeDeactiveAccount = async (id: number, is_active: number) => {
  try {
    const payload = { id, is_active };
    const data = await ADMIN_ACCOUNT_ACTIVE_DEACTIVE(payload);
    if (data.code === 200 || data.code === "200") {
      toast.success(data.message);
      return true;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
  return false;
};

export const approvedDeapprovedAccount = async (
  id: number,
  isApproved: number
) => {
  try {
    const payload = { id, isApproved };
    const data = await ADMIN_ACCOUNT_APPROVE_DISAPPROVE(payload);
    if (data.code === 200 || data.code === "200") {
      toast.success(data.message);
      return true;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
  return false;
};

export enum FWEFilterField {
  FollowersCount = 1,
  WyldScore = 2,
  ER = 3,
}

export enum FWEFilterComparator {
  GreaterThan = 1,
  LessThan = 2,
}

export const FWEFilterFieldLabel = new Map([
  [FWEFilterField.FollowersCount, "Followers Count"],
  [FWEFilterField.WyldScore, "WYLD Score"],
  [FWEFilterField.ER, "ER"],
]);

export const FWEFilterComparatorLabel = new Map([
  [FWEFilterComparator.GreaterThan, ">"],
  [FWEFilterComparator.LessThan, "<"],
]);

const headCells = [
  {
    id: "first_name",
    //   numeric: false,
    // disablePadding: true,
    label: "First Name",
    textAlign: "left",
    width: "10%",
  },
  {
    id: "last_name",
    disablePadding: false,
    label: "Last Name",
    textAlign: "left",
    width: "10%",
  },
  {
    id: "insta_uname",
    disablePadding: false,
    label: "Instagram Handle",
    textAlign: "left",
    width: "10%",
  },
  {
    id: "contact_number",
    disablePadding: false,
    label: "Contact No",
    textAlign: "left",
    width: "14%",
  },
  {
    id: "followers",
    numeric: true,
    disablePadding: false,
    label: "Followers",
    width: "7%",
  },
  {
    id: "social_score",
    numeric: true,
    disablePadding: false,
    label: "WYLD Score",
    width: "7%",
  },
  {
    id: "engagement_rate",
    numeric: true,
    disablePadding: false,
    label: "ER",
    width: "6%",
  },
  // {
  //   id: "min_kyc_date",
  //   //   numeric: true,
  //   disablePadding: false,
  //   label: "Min. Kyc Date",
  //   width: "18%",
  // },
  {
    id: "user_status",
    numeric: true,
    disablePadding: false,
    label: "Status",
    width: "14%",
  },
  {
    id: "isActive",
    //   numeric: true,
    disablePadding: false,
    label: "Active",
    width: "5%",
    isNotSortable: true,
  },
  {
    id: "action",
    disablePadding: false,
    label: "Actions",
    width: "17%",
    textAlign: "left",
    isNotSortable: true,
  },
];

export { headCells };
