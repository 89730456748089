import * as React from "react";
import Avatar from "@mui/material/Avatar";

const NameAvatar = (props: any) => {
  const stringToColor = (str: string) => {
    let hash = 0;
    let i;

    for (i = 0; i < str.length; i += 1) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    return color;
  };

  const getAlias = (name: string) => {
    try {
      let splitName = name.split(" ");
      splitName = splitName.filter((item) => item);
      if (splitName.length >= 2) {
        return splitName[0][0].toUpperCase() + splitName[1][0].toUpperCase();
      }
      return splitName[0][0].toUpperCase() + splitName[0][1].toUpperCase();
    } catch (e) {
        return "VN";
    }
  };

  const stringAvatar = (name: string) => {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: getAlias(name),
    };
  };

  return (
    <Avatar {...stringAvatar(props.name)} sx={{ width: 40, height: 40 }} />
  );
};

export default NameAvatar;
