import { TFetchStation } from "../../../data/types/adminApi";
import { FETCH_STATION } from "../../../services/AdminServices";
import { toast } from "react-toastify";
import { appErrors } from "../../../AppConstants";
export const apiCallFetchStation = async (fieldValues: any) => {
    const payload: TFetchStation = {
      searchFilter: fieldValues?.searchFilter ? fieldValues.searchFilter : "",
      perPageRows: fieldValues?.perPageRows ? fieldValues.perPageRows : 10,
      currentPage: fieldValues?.currentPage ? fieldValues.currentPage : 1,
      orderBy: fieldValues?.orderBy ? fieldValues.orderBy : "DESC",
      sortBy: fieldValues?.sortBy ? fieldValues.sortBy : "id",
    };
  
    try {
      const data = await FETCH_STATION(payload);
      // console.log(data);
      if (data.status === 200 || data.status === "200") {
        return data.data;
      } else {
        toast.error(data?.message);
      }
    } catch (e: any) {
      toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
    }
  };
 