import { LoginInfo, UserInfo } from "../../data/types/appUser";
import { LOGIN_INFO, USER_INFO } from "../actionTypes";

export const addLoginInfo = (loginInfo: LoginInfo) => ({
  type: LOGIN_INFO,
  data: loginInfo,
});

export const addUserInfo = (userInfo: UserInfo) => ({
  type: USER_INFO,
  data: userInfo,
});

