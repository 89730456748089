import React from "react";
import { toast } from "react-toastify";
import { useParams, useLocation } from "react-router-dom";
import {
  adminRoutePath,
  vendorRoutePath,
  publicRoutePath,
  appErrors,
} from "../../AppConstants";
import NoImgAvailable from "../../assets/images/no-image.jpg";
import moment from "moment";
import Dropzone from "react-dropzone";
import {
  Grid,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TextField,
  Chip,
  IconButton,
  Box,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  AddCircleOutline as AddCircleOutlinIcon,
} from "@mui/icons-material";
import CircleIcon from "@mui/icons-material/Circle";
import { Formik, Form, FieldArray } from "formik";
import {
  getInitialState,
  FORM_VALIDATION,
  apiCallSaveOffer,
  fetchOfferInitialDetails,
  deleteOfferCriteria,
  deletePostCriteria,
  deleteStoryCriteria,
  apiCallUpdateOfferEndDate,
  fetchBrandById,
  fetchBrandImage,
} from "./AddEditOffer";
import {
  TOfferCriteria,
  TPostCriteria,
  TStoryCriteria,
  TOfferBrand,
  TOfferVendor,
  TOfferCategory,
  TSelectedImage,
  TOfferBucket,
  TOfferTermAndCondition,
} from "../../data/types/OfferState";
import AddEditOfferCriteria from "./AddEditOfferCriteria";
import AddEditPostCriteria from "./AddEditPostCriteria";
import AddEditStoryCriteria from "./AddEditStoryCriteria";
import Textfield from "../FormsUI/Textfield";
import DropDown from "../FormsUI/DropDown";
import ToggleSwitch from "../FormsUI/ToggleSwitch";
import RadioButton from "../FormsUI/RadioButton";
import {
  DiscountIn,
  DiscountType,
  generateGUID,
  getCurrentDate,
  getDecryptedText,
  criteriaType,
  UserType,
  checkIsValidImage,
  ImageFileType,
  generateRandomNumber,
} from "../../utils/sharedFunctions";
import history from "../../history";
import { useTagListState } from "../../hooks/useTagListState";
import useCriteriaDelCmfModalState from "../../hooks/useCriteriaDelCmfModalState";
import ConfirmationModal from "../common/ConfirmationModal";
import ImageRenderer from "../core/ImageRenderer";
import useImageState from "../../hooks/useImageState";

const AddEditOffer = (props: any) => {
  const params = useParams();
  const offerId = params.id ? getDecryptedText(params.id) : null;
  const location = useLocation();
  const {
    imageDetails,
    setImageDetails,
    delImageList,
    setDelImageList,
    isImgLoading,
    setIsImgLoading,
  } = useImageState();
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [isShowSaveNext, setIsShowSaveNext] = React.useState(true);
  const [isOnlyEndDateEditable, setIsOnlyEndDateEditable] =
    React.useState(false);
  const [vendorList, setVendorList] = React.useState<TOfferVendor[]>([]);
  const [brandList, setBrandList] = React.useState<TOfferBrand[]>([]);
  const [tabIndex, setTabIndex] = React.useState<number>(1);
  const [offer, setOffer] = React.useState<any>(null);
  const [offerCategories, setOfferCategories] = React.useState<
    TOfferCategory[]
  >([]);
  const [offerCriteriaList, setOfferCriteriaList] = React.useState<
    TOfferCriteria[]
  >([]);
  const [postCriteriaList, setPostCriteriaList] = React.useState<
    TPostCriteria[]
  >([]);
  const [storyCriteriaList, setStoryCriteriaList] = React.useState<
    TStoryCriteria[]
  >([]);
  const [offerCriteriaModalData, setOfferCriteriaModalData] = React.useState<{
    criteria: TOfferCriteria | null;
    type: number;
    minValue: number | null;
  }>({ criteria: null, type: 0, minValue: null });
  const [showOfferCriteriaModal, setShowOfferCriteriaModal] =
    React.useState<boolean>(false);
  const [postCriteriaModalData, setPostCriteriaModalData] = React.useState<{
    criteria: TPostCriteria | null;
    type: number;
  }>({ criteria: null, type: 0 });
  const [storyCriteriaModalData, setStoryCriteriaModalData] = React.useState<{
    criteria: TStoryCriteria | null;
    type: number;
  }>({ criteria: null, type: 0 });
  const [showPostCriteriaModal, setShowPostCriteriaModal] =
    React.useState<boolean>(false);
  const [showStoryCriteriaModal, setShowStoryCriteriaModal] =
    React.useState<boolean>(false);
  const {
    writtenTag,
    setWrittenTag,
    tagList,
    setTagList,
    addToTagList,
    removeFromTagList,
  } = useTagListState();
  const {
    showCriteriaDelCmfModal,
    setShowCriteriaDelCmfModal,
    criteriaDelCmfModalDetails,
    setCriteriaDelCmfModalDetails,
  } = useCriteriaDelCmfModalState();
  const todaysDate = moment(getCurrentDate()).format("YYYY-MM-DD");
  const [selectedImage, setSelectedImage] = React.useState<TSelectedImage>({
    src: null,
    file: null,
    isBase64: false,
  });
  const [offerBucketList, setOfferBucket] = React.useState<TOfferBucket[]>([]);
  const isAdmin: boolean = props.loginInfo.user_type === UserType.Administrator;
  const [termConditionList, setTermConditionList] = React.useState<
    TOfferTermAndCondition[]
  >([]);

  React.useEffect(() => {
    if (
      location.pathname.includes(adminRoutePath.viewOffer) ||
      location.pathname.includes(vendorRoutePath.viewOffer)
    ) {
      setIsDisabled(true);
    }
    if (
      location.pathname.includes(adminRoutePath.addOffer) ||
      location.pathname.includes(vendorRoutePath.addOffer)
    ) {
      setIsShowSaveNext(false);
    }
    fetchInitialDetails();
  }, []);

  const fetchInitialDetails = async () => {
    let vendorId: any = null;
    if (adminRoutePath.addOffer === location.pathname) {
    } else if (
      location.pathname.includes(adminRoutePath.updateOffer) ||
      location.pathname.includes(vendorRoutePath.updateOffer)
    ) {
      if (!offerId) {
        history.push(publicRoutePath.pageNotFound);
        return;
      }
    }
    if (
      location.pathname.includes(vendorRoutePath.updateOffer) ||
      location.pathname.includes(vendorRoutePath.addOffer) ||
      location.pathname.includes(vendorRoutePath.viewOffer)
    ) {
      if (props.loginInfo.user_type === UserType.Vendor) {
        vendorId = props.loginInfo.id;
      } else {
        history.push(publicRoutePath.pageNotFound);
        return;
      }
    }

    const data = await fetchOfferInitialDetails(vendorId, offerId);
    if (data) {
      if (
        props.loginInfo.user_type === UserType.Vendor &&
        data.vendors.length === 0
      ) {
        if (!offerId) history.push(publicRoutePath.pageNotFound);
      }
      setVendorList(data.vendors);
      setOfferCategories(data.offer_categories);
      setOffer(data.offer);
      setOfferBucket(data.offer_bucket);
      if (
        location.pathname.includes(vendorRoutePath.addOffer) ||
        location.pathname.includes(adminRoutePath.addOffer)
      ) {
        let tempPCList: TPostCriteria[] = [];
        let tempSCList: TStoryCriteria[] = [];
        for (const bucket of data.offer_bucket) {
          tempPCList.push({
            id: 0,
            id_offer_bucket: bucket.id,
            score_min_value: bucket.min_score,
            score_max_value: bucket.max_score,
            post_discount: bucket.default_post_discount_percentage,
          });

          tempSCList.push({
            id: 0,
            id_offer_bucket: bucket.id,
            score_min_value: bucket.min_score,
            score_max_value: bucket.max_score,
            story_discount: bucket.default_story_discount_percentage,
          });
        }
        setPostCriteriaList(tempPCList);
        setStoryCriteriaList(tempSCList);
      }
      if (
        location.pathname.includes(adminRoutePath.updateOffer) ||
        location.pathname.includes(vendorRoutePath.updateOffer) ||
        location.pathname.includes(vendorRoutePath.addOffer) ||
        location.pathname.includes(adminRoutePath.viewOffer) ||
        location.pathname.includes(vendorRoutePath.viewOffer)
      ) {
        if (data.offer) {
          if (
            location.pathname.includes(adminRoutePath.updateOffer) ||
            location.pathname.includes(vendorRoutePath.updateOffer)
          ) {
            if (
              new Date(data.offer.start_date).getTime() <=
              getCurrentDate().getTime()
            ) {
              setIsDisabled(true);
              setIsOnlyEndDateEditable(true);
            }
          }

          if (data.offer.offer_criteria.length !== 0) {
            let tempOCList: TOfferCriteria[] = [];
            for (const offerCriteria of data.offer.offer_criteria) {
              tempOCList.push({
                id: offerCriteria.id,
                uid: generateGUID(),
                order_min_value: offerCriteria.order_min_value,
                order_discount: offerCriteria.order_discount,
              });
            }

            let tempPCList: TPostCriteria[] = [];
            for (const postCriteria of data.offer
              .offer_post_discount_criteria) {
              if (postCriteria.is_default === "0") {
                tempPCList.push({
                  id: postCriteria.id,
                  id_offer_bucket: postCriteria.id_offer_bucket,
                  score_min_value: postCriteria.score_min_value,
                  score_max_value: postCriteria.score_max_value,
                  post_discount: postCriteria.post_discount,
                });
              }
              // tempPCList.push({
              //   id: postCriteria.id,
              //   uid: generateGUID(),
              //   score_min_value: postCriteria.score_min_value,
              //   post_discount: postCriteria.post_discount,
              // });
            }

            let tempSCList: TStoryCriteria[] = [];
            for (const storyCriteria of data.offer
              .offer_story_discount_criteria) {
              if (storyCriteria.is_default === "0") {
                tempSCList.push({
                  id: storyCriteria.id,
                  id_offer_bucket: storyCriteria.id_offer_bucket,
                  score_min_value: storyCriteria.score_min_value,
                  score_max_value: storyCriteria.score_max_value,
                  story_discount: storyCriteria.story_discount,
                });
              }
              // tempSCList.push({
              //   id: storyCriteria.id,
              //   uid: generateGUID(),
              //   score_min_value: storyCriteria.score_min_value,
              //   story_discount: storyCriteria.story_discount,
              // });
            }

            setOfferCriteriaList(tempOCList);
            setPostCriteriaList(tempPCList);
            setStoryCriteriaList(tempSCList);
          }

          if (data.offer.tags) {
            setTagList(data.offer.tags.split("|"));
          }

          if (data.offer.offer_image) {
            setImageDetails({
              id: data.offer.offer_image.id,
              image_path: data.offer.offer_image.image_path,
            });
          }
        }

        const findVendor = data.vendors.find((objVendor: any) => {
          if (data.offer) {
            return objVendor.id === data.offer.id_vendor;
          } else {
            return objVendor.id === vendorId;
          }
        });
        if (findVendor) {
          setBrandList(findVendor.brands);
        } else {
          history.push(publicRoutePath.pageNotFound);
        }
      }
    } else {
      history.push(publicRoutePath.pageNotFound);
    }
  };

  const openOfferCriteria = (
    criteria: TOfferCriteria | null,
    type: number,
    minValue: number | null
  ) => {
    setOfferCriteriaModalData({
      criteria,
      type: Number(type),
      minValue: minValue ? Number(minValue) : null,
    });
    setShowOfferCriteriaModal(true);
  };

  const closeOfferCriteria = (criteria: TOfferCriteria | null) => {
    setShowOfferCriteriaModal(false);
    if (criteria) {
      const findCriteria = offerCriteriaList.find(
        (offerCriteria) => offerCriteria.uid === criteria.uid
      );
      if (findCriteria) {
        const tempList = offerCriteriaList.map((offerCriteria) => {
          if (offerCriteria.uid === criteria.uid) {
            return criteria;
          }
          return offerCriteria;
        });
        setOfferCriteriaList(tempList);
      } else {
        setOfferCriteriaList([...offerCriteriaList, criteria]);
      }
    }
  };

  const openPostCriteria = (criteria: TPostCriteria | null, type: number) => {
    setPostCriteriaModalData({ criteria, type: Number(type) });
    setShowPostCriteriaModal(true);
  };

  const closePostCriteria = (
    criteria: TPostCriteria | null,
    old_id_offer_bucket: number | null
  ) => {
    setShowPostCriteriaModal(false);
    if (criteria) {
      const findCriteria = postCriteriaList.find(
        (postCriteria) => postCriteria.id_offer_bucket === old_id_offer_bucket
      );
      if (findCriteria) {
        const tempList = postCriteriaList.map((postCriteria) => {
          if (postCriteria.id_offer_bucket === old_id_offer_bucket) {
            return criteria;
          }
          return postCriteria;
        });
        setPostCriteriaList(tempList);
      } else {
        setPostCriteriaList([...postCriteriaList, criteria]);
      }
    }
  };

  const openStoryCriteria = (criteria: TStoryCriteria | null, type: number) => {
    setStoryCriteriaModalData({ criteria, type: Number(type) });
    setShowStoryCriteriaModal(true);
  };

  const closeStoryCriteria = (
    criteria: TStoryCriteria | null,
    old_id_offer_bucket: number | null
  ) => {
    setShowStoryCriteriaModal(false);
    if (criteria) {
      const findCriteria = storyCriteriaList.find(
        (storyCriteria) => storyCriteria.id_offer_bucket === old_id_offer_bucket
      );
      if (findCriteria) {
        const tempList = storyCriteriaList.map((storyCriteria) => {
          if (storyCriteria.id_offer_bucket === old_id_offer_bucket) {
            return criteria;
          }
          return storyCriteria;
        });
        setStoryCriteriaList(tempList);
      } else {
        setStoryCriteriaList([...storyCriteriaList, criteria]);
      }
    }
  };

  const handleCriteriaDelShowDelModal = (
    id: number | string,
    type: number,
    value: number | string
  ) => {
    setCriteriaDelCmfModalDetails({
      id,
      title: `Delete ${
        type === criteriaType.Offer
          ? "Offer Criteria"
          : criteriaType.Post
          ? "Post Criteria"
          : "Story Criteria"
      }`,
      message: `You are about to delete ${
        type === criteriaType.Offer
          ? "offer criteria"
          : criteriaType.Post
          ? "post criteria"
          : "story criteria"
      } with ${
        type === criteriaType.Offer ? "min value" : "score range"
      } ${value}.`,
      type,
    });
    setShowCriteriaDelCmfModal(true);
  };

  const handleCriteriaDelCloseModal = async (status: boolean) => {
    setShowCriteriaDelCmfModal(false);
    if (status) {
      if (criteriaDelCmfModalDetails.type === criteriaType.Offer) {
        deleteOfferCriteria(
          criteriaDelCmfModalDetails.id,
          offerCriteriaList,
          setOfferCriteriaList
        );
      } else if (criteriaDelCmfModalDetails.type === criteriaType.Post) {
        deletePostCriteria(
          criteriaDelCmfModalDetails.id,
          postCriteriaList,
          setPostCriteriaList
        );
      } else if (criteriaDelCmfModalDetails.type === criteriaType.Story) {
        deleteStoryCriteria(
          criteriaDelCmfModalDetails.id,
          storyCriteriaList,
          setStoryCriteriaList
        );
      }
    }
  };

  const handleChangeVendorName = (event: any, setFormikFieldValues: any) => {
    for (const vendor of vendorList) {
      if (vendor.id === event.target.value) {
        setBrandList(vendor.brands);
        setFormikFieldValues("id_vendor", event.target.value);
        setFormikFieldValues("id_brand", "");
      }
    }
  };

  const handleChangeBrandName = async (
    event: any,
    setFormikFieldValues: any
  ) => {
    for (const brand of brandList) {
      if (brand.id === event.target.value) {
        setFormikFieldValues("id_brand", event.target.value);
        const data = await fetchBrandById(brand.id);
        if (data) {
          setFormikFieldValues("offer_description", data.brand_about || "");
          setFormikFieldValues("ecommerce_site_address", data.web_site_address);
          if (data.brand_image) {
            const imageData = await fetchBrandImage(
              data.brand_image.image_path
            );
            if (imageData) {
              setSelectedImage({
                src: imageData,
                file: imageData,
                isBase64: true,
              });
            } else {
              setSelectedImage({ src: null, file: null, isBase64: false });
            }
          } else {
            setSelectedImage({ src: null, file: null, isBase64: false });
          }
        }
        return;
      }
    }
  };

  const dropzoneSelectedImage = async (acceptedImage: any) => {
    const isValidImage = await checkIsValidImage(
      acceptedImage[0],
      ImageFileType.OfferImage
    );
    if (isValidImage) {
      const reader = new FileReader();
      reader.onloadend = function () {
        setSelectedImage({
          src: reader.result,
          file: acceptedImage[0],
          isBase64: false,
        });
      };
      reader.readAsDataURL(acceptedImage[0]);
    } else {
      toast.error(appErrors.PRODUCT_IMAGE_SIZE_TYPE);
    }
  };

  const checkApiCall = async (
    fieldValues: any,
    goToNextTab: boolean = false
  ) => {
    let data = null;
    if (isOnlyEndDateEditable) {
      data = await apiCallUpdateOfferEndDate(
        offerId,
        fieldValues,
        imageDetails.id,
        selectedImage,
        tagList,
        fieldValues.id_vendor
      );
    } else {
      data = await apiCallSaveOffer(
        fieldValues,
        offerCriteriaList,
        postCriteriaList,
        storyCriteriaList,
        tagList,
        offerId,
        imageDetails.id,
        selectedImage
      );
    }
    if (data) {
      if (goToNextTab) {
        if (
          new Date(fieldValues.start_date).getTime() <=
          getCurrentDate().getTime()
        ) {
          setIsDisabled(true);
          setIsOnlyEndDateEditable(true);
        }
        if (tabIndex < 4) {
          setTabIndex((prev) => prev + 1);
        }
      } else {
        if (props.loginInfo.user_type === UserType.Administrator) {
          history.push(adminRoutePath.vendorOffers);
        } else if (props.loginInfo.user_type === UserType.Vendor) {
          history.push(vendorRoutePath.brandOffers);
        }
      }
    }
  };

  const onCancelGoBack = () => {
    if (props.loginInfo.user_type === UserType.Administrator) {
      history.push(adminRoutePath.vendorOffers);
    } else if (props.loginInfo.user_type === UserType.Vendor) {
      history.push(vendorRoutePath.brandOffers);
    }
  };

  return (
    <React.Fragment>
      <div className="container-box">
        <div className="page-header">
          <span className="page-title">Brand Offer</span>
        </div>
        <div className="container-content">
          <div className="tab-bar">
            <div
              className={`tab-item ${tabIndex === 1 ? "active" : ""}`}
              onClick={() => setTabIndex(1)}
            >
              General
            </div>
            <div
              className={`tab-item ${tabIndex === 2 ? "active" : ""}`}
              onClick={() => setTabIndex(2)}
            >
              Instant Discount
            </div>
            <div
              className={`tab-item ${tabIndex === 3 ? "active" : ""}`}
              onClick={() => setTabIndex(3)}
            >
              Instagram Post Cashback
            </div>
            <div
              className={`tab-item ${tabIndex === 4 ? "active" : ""}`}
              onClick={() => setTabIndex(4)}
            >
              Instagram Story Cashback
            </div>
          </div>
          <Formik
            enableReinitialize
            initialValues={getInitialState(
              offer,
              props.loginInfo.user_type === UserType.Vendor
                ? props.loginInfo.id
                : null
            )}
            validateOnBlur={false}
            validationSchema={FORM_VALIDATION}
            onSubmit={async (fieldValues) => checkApiCall(fieldValues)}
          >
            {({ values, errors, touched, setFieldValue }) => {
              return (
                <Form>
                  <div className="tab-content">
                    {tabIndex === 1 && (
                      <div>
                        <Grid container spacing={3}>
                          <Grid
                            container
                            item
                            xs={12}
                            sm={9}
                            md={10}
                            spacing={3}
                          >
                            {props.loginInfo.user_type ===
                            UserType.Administrator ? (
                              <Grid item xs={12}>
                                <DropDown
                                  disabled={isDisabled}
                                  name="id_vendor"
                                  label="Vendor"
                                  onChange={(event: any) =>
                                    handleChangeVendorName(event, setFieldValue)
                                  }
                                  itemlist={vendorList.map((vendor) => {
                                    return {
                                      value: vendor.id,
                                      label: vendor.vendor_name,
                                    };
                                  })}
                                />
                              </Grid>
                            ) : (
                              <Grid item xs={12}>
                                <Textfield
                                  name="vendor_name"
                                  label="Vendor"
                                  value={
                                    vendorList.length !== 0
                                      ? vendorList[0].vendor_name
                                      : ""
                                  }
                                  disabled
                                />
                              </Grid>
                            )}
                            <Grid item xs={12}>
                              <DropDown
                                disabled={isDisabled}
                                name="id_brand"
                                label="Brand"
                                onChange={(event: any) =>
                                  handleChangeBrandName(event, setFieldValue)
                                }
                                itemlist={brandList.map((brand) => {
                                  return {
                                    value: brand.id,
                                    label: (
                                      <div>
                                        {brand.brand_name}
                                        {brand.offers &&
                                          brand.offers.length > 0 && (
                                            <IconButton>
                                              <CircleIcon className="offer-brand-dropdown-cicle-icon" />
                                            </IconButton>
                                          )}
                                      </div>
                                    ),
                                  };
                                })}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={3}
                            md={2}
                            className="justify-content-center display-flex "
                          >
                            <div className="relative-offer-image-view">
                              {!(isDisabled && !isOnlyEndDateEditable) && (
                                <Dropzone
                                  onDrop={(acceptedFiles: any) =>
                                    dropzoneSelectedImage(acceptedFiles)
                                  }
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <div
                                      className="select-image-container"
                                      {...getRootProps()}
                                    >
                                      <input {...getInputProps()} />
                                      <IconButton className="select-image-button">
                                        <EditIcon
                                          fontSize="small"
                                          className="color-white "
                                        />
                                      </IconButton>
                                    </div>
                                  )}
                                </Dropzone>
                              )}
                              {selectedImage.src || imageDetails.image_path ? (
                                isImgLoading ? (
                                  <div className="offer-image-view">
                                    <div className="loader-container">
                                      <span className="loader"></span>
                                    </div>
                                  </div>
                                ) : selectedImage.src ? (
                                  <img
                                    src={selectedImage.src}
                                    style={{
                                      border: "0.5px solid lightgrey",
                                      borderRadius: "4px",
                                      height: "140px",
                                      width: "140px",
                                    }}
                                    title={"Offer Image"}
                                    alt={"Offer"}
                                  />
                                ) : (
                                  <ImageRenderer
                                    src={imageDetails.image_path}
                                    height="140px"
                                    width="140px"
                                    style={{
                                      border: "1px solid lightgrey",
                                      borderRadius: "4px",
                                    }}
                                    title={"Offer Image"}
                                    alt={"Offer"}
                                  />
                                )
                              ) : (
                                <img
                                  src={NoImgAvailable}
                                  style={{
                                    border: "0.5px solid lightgrey",
                                    borderRadius: "4px",
                                    height: "140px",
                                    width: "140px",
                                  }}
                                  title={"Offer Image"}
                                  alt={"Offer"}
                                />
                              )}
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container spacing={3} sx={{ pt: 3 }}>
                          <Grid
                            item
                            className="offer-seperater"
                            display={"flex"}
                            alignItems={"center"}
                          >
                            <IconButton>
                              <CircleIcon className="offer-circle-point-icon" />
                            </IconButton>
                            <span className="offer-seperater-text">
                              Offer Details
                            </span>
                            <hr className="offer-seperater-line" />
                          </Grid>
                          <Grid item xs={12}>
                            <Textfield
                              disabled={
                                isDisabled &&
                                !(isOnlyEndDateEditable && isAdmin)
                              }
                              required
                              name="offer_title"
                              label="Offer Title"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Textfield
                              multiline
                              minRows={2}
                              disabled={
                                isDisabled &&
                                !(isOnlyEndDateEditable && isAdmin)
                              }
                              name="offer_description"
                              label="Offer Description"
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <Textfield
                              className="offer-date-field"
                              disabled={isDisabled}
                              required
                              name="start_date"
                              label="Offer Start Date"
                              type="date"
                              placeholder="dd-mm-yyyy"
                              inputProps={{ min: todaysDate }}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <Textfield
                              className="offer-date-field"
                              disabled={isDisabled && !isOnlyEndDateEditable}
                              required
                              name="end_date"
                              label="Offer End Date"
                              type="date"
                              inputProps={{ min: todaysDate }}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <Textfield
                              disabled={
                                isDisabled &&
                                !(isOnlyEndDateEditable && isAdmin)
                              }
                              required
                              name="post_duration"
                              label="Post Duration (Days)"
                              type="number"
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <Textfield
                              disabled={
                                isDisabled &&
                                !(isOnlyEndDateEditable && isAdmin)
                              }
                              required
                              name="ecommerce_site_address"
                              label="E-commerce site address"
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <DropDown
                              disabled={
                                isDisabled &&
                                !(isOnlyEndDateEditable && isAdmin)
                              }
                              name="id_offer_category"
                              label="Offer Category"
                              itemlist={offerCategories.map(
                                (offerCategory: any) => {
                                  return {
                                    value: offerCategory.id,
                                    label: offerCategory.category_name,
                                  };
                                }
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} md={4} className="text-align-left">
                            <ToggleSwitch
                              disabled={isDisabled}
                              name="is_active"
                              label="Active/Inactive"
                            />
                          </Grid>
                          <Grid container item xs={12} spacing={3}>
                            <Grid
                              item
                              xs={12}
                              md={4}
                              className="text-align-left"
                            >
                              <ToggleSwitch
                                disabled={isDisabled}
                                name="is_limited"
                                label="Limited Time Offer"
                              />
                            </Grid>
                            <Grid item xs={12} md={4} className="display-flex">
                              <Textfield
                                name="avail_times"
                                label="Avail Offer"
                                type="number"
                                disabled={isDisabled || !values.is_limited}
                              />
                              <span className="padding-left-16 avail-offer-textarea">
                                Time(s)
                              </span>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <DropDown
                                name="avail_cycle"
                                label="Period"
                                disabled={isDisabled || !values.is_limited}
                                itemlist={[
                                  { value: 1, label: "Month" },
                                  { value: 2, label: "Year" },
                                ]}
                              />
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <fieldset className="primary-fieldset display-contents">
                              {/* <legend>
                                <span className="bold-text-3">
                                  Insta Post Requirement
                                </span>
                              </legend> */}
                              <Grid
                                item
                                className="offer-seperater"
                                display={"flex"}
                                alignItems={"center"}
                              >
                                <IconButton>
                                  <CircleIcon className="offer-circle-point-icon" />
                                </IconButton>
                                <span className="offer-seperater-text">
                                  Insta Post Requirement
                                </span>
                                <hr className="offer-seperater-line" />
                              </Grid>
                              <Grid container spacing={3} sx={{ pt: 2 }}>
                                <Grid item xs={12} md={12}>
                                  <TextField
                                    disabled={
                                      isDisabled &&
                                      !(isOnlyEndDateEditable && isAdmin)
                                    }
                                    className="tag-field"
                                    fullWidth
                                    multiline
                                    label="Tags"
                                    value={writtenTag.replace(/\n/g, "")}
                                    // value={writtenTag}
                                    onChange={(e) =>
                                      setWrittenTag(e.target.value)
                                    }
                                    onKeyDown={(event: any) => {
                                      if (event.keyCode === 13) {
                                        addToTagList();
                                      }
                                    }}
                                    InputProps={{
                                      startAdornment:
                                        tagList.length !== 0
                                          ? tagList.map((item: string) => (
                                              <Chip
                                                disabled={
                                                  isDisabled &&
                                                  !(
                                                    isOnlyEndDateEditable &&
                                                    isAdmin
                                                  )
                                                }
                                                size="small"
                                                key={item}
                                                tabIndex={-1}
                                                label={item}
                                                className="tag-chip bg-color-light-blue color-white"
                                                onDelete={() =>
                                                  removeFromTagList(item)
                                                }
                                              />
                                            ))
                                          : null,
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                  <Textfield
                                    disabled={
                                      isDisabled &&
                                      !(isOnlyEndDateEditable && isAdmin)
                                    }
                                    name="post_content"
                                    label="Post Content"
                                    minRows={2}
                                    multiline
                                  />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                  <Textfield
                                    disabled={
                                      isDisabled &&
                                      !(isOnlyEndDateEditable && isAdmin)
                                    }
                                    multiline
                                    minRows={2}
                                    name="picture_should_contains"
                                    label="Picture Should Contains"
                                  />
                                </Grid>
                                {/* <Grid item xs={12} md={12}>
                                  {termAndConditionView(values)}
                                </Grid> */}
                              </Grid>
                            </fieldset>
                          </Grid>
                        </Grid>
                      </div>
                    )}
                    {tabIndex === 2 && (
                      <div>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={12}>
                            <RadioButton
                              disabled={isDisabled}
                              name="order_discount_type"
                              label="Order Discount Type :"
                              itemlist={[
                                { value: DiscountType.Flat, label: "Flat" },
                                {
                                  value: DiscountType.Percentage,
                                  label: "Percentage",
                                },
                              ]}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <Textfield
                              disabled={isDisabled}
                              name="min_order_value"
                              label="Order Min Value"
                              type="number"
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <Textfield
                              disabled={isDisabled}
                              name="order_max_discount"
                              label="Order Max Discount"
                              type="number"
                            />
                          </Grid>
                        </Grid>
                        <div className="criteria-panel">
                          <div className="criteria-header">
                            <span className="color-white">Offer Criteria</span>
                            <Button
                              disabled={isDisabled}
                              className="btn-outline btn-criteria"
                              onClick={() => {
                                openOfferCriteria(
                                  null,
                                  values.order_discount_type,
                                  values.min_order_value
                                );
                              }}
                            >
                              Add Criteria
                            </Button>
                          </div>
                          <div className="criteria-content">
                            {offerCriteriaList.length === 0 ? (
                              <div>No criteria added</div>
                            ) : (
                              <TableContainer component={Paper}>
                                <Table
                                  sx={{ minWidth: 650 }}
                                  aria-label="simple table"
                                >
                                  <TableHead className="bg-color-light-blue">
                                    <TableRow>
                                      <TableCell className="offer-criteria-table-header">
                                        Order Value (Greater than or equals to)
                                      </TableCell>
                                      <TableCell className="offer-criteria-table-header">
                                        Order Discount (
                                        {values?.order_discount_type &&
                                        Number(values.order_discount_type) ===
                                          DiscountType.Percentage
                                          ? "%"
                                          : "INR"}
                                        )
                                      </TableCell>
                                      <TableCell className="offer-criteria-table-header">
                                        Actions
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {offerCriteriaList.map(
                                      (
                                        offerCriteria: TOfferCriteria,
                                        index: number
                                      ) => (
                                        <TableRow
                                          key={index}
                                          sx={{
                                            "&:last-child td, &:last-child th":
                                              {
                                                border: 0,
                                              },
                                          }}
                                        >
                                          <TableCell className="offer-criteria-table-body-cell">
                                            {offerCriteria.order_min_value}
                                          </TableCell>
                                          <TableCell className="offer-criteria-table-body-cell">
                                            {offerCriteria.order_discount}
                                          </TableCell>
                                          <TableCell className="offer-criteria-table-body-cell">
                                            <IconButton
                                              disabled={isDisabled}
                                              title="Edit"
                                              onClick={() =>
                                                openOfferCriteria(
                                                  offerCriteria,
                                                  values.order_discount_type,
                                                  values.min_order_value
                                                )
                                              }
                                            >
                                              <EditIcon />
                                            </IconButton>
                                            <IconButton
                                              disabled={isDisabled}
                                              title="Delete"
                                              onClick={() =>
                                                handleCriteriaDelShowDelModal(
                                                  offerCriteria.uid,
                                                  criteriaType.Offer,
                                                  offerCriteria.order_min_value
                                                )
                                              }
                                            >
                                              <DeleteIcon />
                                            </IconButton>
                                          </TableCell>
                                        </TableRow>
                                      )
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {tabIndex === 3 && (
                      <div>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={6} display={"none"}>
                            <RadioButton
                              disabled={isDisabled}
                              name="post_discount_type"
                              label="Post Discount Type :"
                              itemlist={[
                                { value: DiscountType.Flat, label: "Flat" },
                                {
                                  value: DiscountType.Percentage,
                                  label: "Percentage",
                                },
                              ]}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} display={"none"}>
                            <RadioButton
                              disabled={isDisabled}
                              name="post_discount_in"
                              label="Post Discount In :"
                              itemlist={[
                                { value: DiscountIn.Coins, label: "Coins" },
                                { value: DiscountIn.Amount, label: "Amount" },
                              ]}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} display={"none"}>
                            <Textfield
                              disabled={isDisabled}
                              name="post_max_discount"
                              label="Post Max Discount"
                              type="number"
                            />
                          </Grid>
                        </Grid>
                        <div className="criteria-panel">
                          <div className="criteria-header">
                            <span className="color-white">Post Criteria</span>
                            <Button
                              className="btn-outline btn-criteria"
                              disabled={isDisabled}
                              onClick={() => {
                                openPostCriteria(
                                  null,
                                  values.post_discount_type
                                );
                              }}
                            >
                              Add Criteria
                            </Button>
                          </div>
                          <div className="criteria-content">
                            {postCriteriaList.length === 0 ? (
                              <div>No criteria added</div>
                            ) : (
                              <TableContainer component={Paper}>
                                <Table
                                  sx={{ minWidth: 650 }}
                                  aria-label="simple table"
                                >
                                  <TableHead className="bg-color-light-blue">
                                    <TableRow>
                                      <TableCell className="offer-criteria-table-header">
                                        Insta Score Range
                                      </TableCell>
                                      <TableCell className="offer-criteria-table-header">
                                        Post Discount (%)
                                      </TableCell>
                                      <TableCell className="offer-criteria-table-header">
                                        Actions
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {postCriteriaList.map(
                                      (
                                        postCriteria: TPostCriteria,
                                        index: number
                                      ) => (
                                        <TableRow
                                          key={index}
                                          sx={{
                                            "&:last-child td, &:last-child th":
                                              {
                                                border: 0,
                                              },
                                          }}
                                        >
                                          <TableCell className="offer-criteria-table-body-cell">
                                            {postCriteria.score_min_value +
                                              " - " +
                                              postCriteria.score_max_value}
                                          </TableCell>
                                          <TableCell className="offer-criteria-table-body-cell">
                                            {Number(
                                              postCriteria.post_discount
                                            ).toFixed(2)}
                                          </TableCell>
                                          <TableCell className="offer-criteria-table-body-cell">
                                            <IconButton
                                              disabled={isDisabled}
                                              title="Edit"
                                              onClick={() => {
                                                openPostCriteria(
                                                  postCriteria,
                                                  values.post_discount_type
                                                );
                                              }}
                                            >
                                              <EditIcon />
                                            </IconButton>
                                            <IconButton
                                              disabled={isDisabled}
                                              title="Delete"
                                              onClick={() =>
                                                handleCriteriaDelShowDelModal(
                                                  postCriteria.id_offer_bucket,
                                                  criteriaType.Post,
                                                  postCriteria.score_min_value +
                                                    " - " +
                                                    postCriteria.score_max_value
                                                )
                                              }
                                            >
                                              <DeleteIcon />
                                            </IconButton>
                                          </TableCell>
                                        </TableRow>
                                      )
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    {tabIndex === 4 && (
                      <div>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={6} display={"none"}>
                            <RadioButton
                              disabled={isDisabled}
                              name="story_discount_type"
                              label="Story Discount Type :"
                              itemlist={[
                                { value: DiscountType.Flat, label: "Flat" },
                                {
                                  value: DiscountType.Percentage,
                                  label: "Percentage",
                                },
                              ]}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} display={"none"}>
                            <RadioButton
                              disabled={isDisabled}
                              name="story_discount_in"
                              label="Story Discount In :"
                              itemlist={[
                                { value: DiscountIn.Coins, label: "Coins" },
                                { value: DiscountIn.Amount, label: "Amount" },
                              ]}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} display={"none"}>
                            <Textfield
                              disabled={isDisabled}
                              name="story_max_discount"
                              label="Story Max Discount"
                              type="number"
                            />
                          </Grid>
                        </Grid>
                        <div className="criteria-panel">
                          <div className="criteria-header">
                            <span className="color-white">Story Criteria</span>
                            <Button
                              className="btn-outline btn-criteria"
                              disabled={isDisabled}
                              onClick={() => {
                                openStoryCriteria(
                                  null,
                                  values.story_discount_type
                                );
                              }}
                            >
                              Add Criteria
                            </Button>
                          </div>
                          <div className="criteria-content">
                            {storyCriteriaList.length === 0 ? (
                              <div>No criteria added</div>
                            ) : (
                              <TableContainer component={Paper}>
                                <Table
                                  sx={{ minWidth: 650 }}
                                  aria-label="simple table"
                                >
                                  <TableHead className="bg-color-light-blue">
                                    <TableRow>
                                      <TableCell className="offer-criteria-table-header">
                                        Insta Score Range
                                      </TableCell>
                                      <TableCell className="offer-criteria-table-header">
                                        Story Discount (%)
                                      </TableCell>
                                      <TableCell className="offer-criteria-table-header">
                                        Actions
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {storyCriteriaList.map(
                                      (
                                        storyCriteria: TStoryCriteria,
                                        index: number
                                      ) => (
                                        <TableRow
                                          key={index}
                                          sx={{
                                            "&:last-child td, &:last-child th":
                                              {
                                                border: 0,
                                              },
                                          }}
                                        >
                                          <TableCell className="offer-criteria-table-body-cell">
                                            {storyCriteria.score_min_value +
                                              " - " +
                                              storyCriteria.score_max_value}
                                          </TableCell>
                                          <TableCell className="offer-criteria-table-body-cell">
                                            {Number(
                                              storyCriteria.story_discount
                                            ).toFixed(2)}
                                          </TableCell>
                                          <TableCell className="offer-criteria-table-body-cell">
                                            <IconButton
                                              disabled={isDisabled}
                                              title="Edit"
                                              onClick={() => {
                                                openStoryCriteria(
                                                  storyCriteria,
                                                  values.story_discount_type
                                                );
                                              }}
                                            >
                                              <EditIcon />
                                            </IconButton>
                                            <IconButton
                                              disabled={isDisabled}
                                              title="Delete"
                                              onClick={() =>
                                                handleCriteriaDelShowDelModal(
                                                  storyCriteria.id_offer_bucket,
                                                  criteriaType.Story,
                                                  storyCriteria.score_min_value +
                                                    " - " +
                                                    storyCriteria.score_max_value
                                                )
                                              }
                                            >
                                              <DeleteIcon />
                                            </IconButton>
                                          </TableCell>
                                        </TableRow>
                                      )
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="display-flex justify-content-end padding-top-24">
                    {tabIndex !== 4 && (
                      <React.Fragment>
                        <Button
                          className="btn-primary margin-right-16"
                          onClick={() => setTabIndex(tabIndex + 1)}
                        >
                          NEXT
                        </Button>
                        {!(isDisabled && !isOnlyEndDateEditable) &&
                          isShowSaveNext && (
                            <Button
                              disabled={isDisabled && !isOnlyEndDateEditable}
                              className="btn-primary margin-right-16"
                              onClick={() => checkApiCall(values, true)}
                            >
                              SAVE & NEXT
                            </Button>
                          )}
                      </React.Fragment>
                    )}
                    {tabIndex === 4 && (
                      <Button
                        disabled={isDisabled && !isOnlyEndDateEditable}
                        className="btn-primary margin-right-16"
                        type="submit"
                      >
                        SAVE
                      </Button>
                    )}
                    <Button
                      className="btn-outline"
                      onClick={() => onCancelGoBack()}
                    >
                      CANCEL
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
      {showOfferCriteriaModal && (
        <AddEditOfferCriteria
          offerCriteria={offerCriteriaModalData.criteria}
          type={offerCriteriaModalData.type}
          minValue={offerCriteriaModalData.minValue}
          closeModal={closeOfferCriteria}
        />
      )}
      {showPostCriteriaModal && (
        <AddEditPostCriteria
          postCriteria={postCriteriaModalData.criteria}
          type={postCriteriaModalData.type}
          closeModal={closePostCriteria}
          offerBucketList={offerBucketList}
          postCriteriaList={postCriteriaList}
        />
      )}
      {showStoryCriteriaModal && (
        <AddEditStoryCriteria
          storyCriteria={storyCriteriaModalData.criteria}
          type={storyCriteriaModalData.type}
          closeModal={closeStoryCriteria}
          offerBucketList={offerBucketList}
          storyCriteriaList={storyCriteriaList}
        />
      )}
      {showCriteriaDelCmfModal && (
        <ConfirmationModal
          closeModal={handleCriteriaDelCloseModal}
          title={criteriaDelCmfModalDetails.title}
          message={criteriaDelCmfModalDetails.message}
        />
      )}
    </React.Fragment>
  );
};

export default AddEditOffer;
