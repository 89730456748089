import { toast } from "react-toastify";
import { appErrors } from "../../../AppConstants";
import { TFetchAllUsers } from "../../../data/types/adminApi";
import {
  ADMIN_ACCOUNT_ACTIVE_DEACTIVE,
  ADMIN_ACCOUNT_APPROVE_DISAPPROVE,
  DELETE_VENDOR,
  DELETE_INFLUENCER,
  FETCH_ALL_USERS,
} from "../../../services/AppServices";
import { getEncryptedText, UserStatus, UserType } from "../../../utils/sharedFunctions";

export const apiCallFetchAllUsers = async (fieldValues: any) => {
  const payload: TFetchAllUsers = {
    searchFilter: fieldValues?.searchFilter ? fieldValues.searchFilter : "",
    perPageRows: fieldValues?.perPageRows ? fieldValues.perPageRows : 10,
    currentPage: fieldValues?.currentPage ? fieldValues.currentPage : 1,
    startDate: fieldValues?.startDate ? fieldValues.startDate : "",
    endDate: fieldValues?.endDate ? fieldValues.endDate : "",
  };

  try {
    const data = await FETCH_ALL_USERS(payload);
    // console.log(data);
    if (data.status === 200 || data.status === "200") {
      return data.data;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
};

export const handleActiveDeactive = async (
  id: number,
  userList: any,
  setUserList: any
) => {
  const tempUser: any = userList.find((user: any) => id == user.id);
  if (!tempUser) return;
  const result = await apiCallActiveDeactiveAccount(
    id,
    tempUser.is_active == "0" ? 1 : 0
  );
  if (result) {
    const tempUserList: any = userList.map((user: any) => {
      if (user.id == id) {
        return { ...user, is_active: user.is_active == "0" ? "1" : "0" };
      }
      return user;
    });
    setUserList(tempUserList);
  }
};

export const apiCallActiveDeactiveAccount = async (
  id: number,
  is_active: number
) => {
  try {
    const payload = { id, is_active };
    const data = await ADMIN_ACCOUNT_ACTIVE_DEACTIVE(payload);
    if (data.code === 200 || data.code === "200") {
      toast.success(data.message);
      return true;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
  return false;
};

export const apiCallDeleteUser = async (id: number, type: number) => {
  try {
    const payload = getEncryptedText(id.toString());
    let data = null;
    if(type == UserType.Vendor){
      data = await DELETE_VENDOR(payload);
    }else if(type == UserType.Influencer){
      data = await DELETE_INFLUENCER(payload);
    }else{
      return false;
    }
    if (data.code === 200 || data.code === "200") {
      toast.success("User successfully deleted");
      return true;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
  return false;
};


export const apiCallApproveUser = async (userList: any, setUserList: any, id: number) => {
  const tempUser: any = userList.find(
    (user: any) => id == user.id
  );
  if (!tempUser) { toast.error("User not found"); return;}
  try {
    const payload = {id, isApproved: 1};
    const data = await ADMIN_ACCOUNT_APPROVE_DISAPPROVE(payload);
    if (data.code === 200 || data.code === "200") {
      toast.success(data.message);
      const tempList: any = userList.map((user: any) => {
        if (user.id == id) {
          return { ...user, user_status: UserStatus.Approved ,approved_date: new Date() };
        }
        return user;
      });
      setUserList(tempList);
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
}

export const headCells = [
  {
    id: "user_name",
    disablePadding: false,
    label: "User Name",
    textAlign: "left",
    width: "20%",
  },
  {
    id: "first_name",
    disablePadding: false,
    label: "First Name",
    textAlign: "left",
    width: "11%",
  },
  {
    id: "last_name",
    disablePadding: false,
    label: "Last Name",
    textAlign: "left",
    width: "11%",
  },
  {
    id: "user_type",
    disablePadding: false,
    label: "User type",
    textAlign: "left",
    width: "10%",
    height: "80px",
  },
  {
    id: "approved_date",
    disablePadding: false,
    label: "Approved Date",
    width: "12%",
  },
  {
    id: "user_status",
    disablePadding: false,
    label: "Status",
    width: "14%",
  },
  {
    id: "action",
    disablePadding: false,
    label: "Actions",
    textAlign: "left",
    width: "12%",
    isNotSortable: true,
  },
];
