import React from "react";
import { TImageDetails } from "../data/types/OfferState";

const useImageState = (): {
  imageDetails: TImageDetails;
  setImageDetails: (value: TImageDetails) => void;
  delImageList: number[];
  setDelImageList: (value: number[]) => void;
  isImgLoading: boolean;
  setIsImgLoading: (value: boolean) => void;
} => {
  const [imageDetails, setImageDetailsState] = React.useState<TImageDetails>({
    id: null,
    image_path: "",
  });
  const [delImageList, setDelImageListState] = React.useState<number[]>([]);
  const [isImgLoading, setIsImgLoadingState] = React.useState<boolean>(false);

  const setImageDetails = (value: TImageDetails) => {
    setImageDetailsState(value);
  };

  const setDelImageList = (value: number[]) => {
    setDelImageListState(value);
  };

  const setIsImgLoading = (value: boolean) => {
    setIsImgLoadingState(value);
  };

  return {
    imageDetails,
    setImageDetails,
    delImageList,
    setDelImageList,
    isImgLoading,
    setIsImgLoading,
  };
};

export default useImageState;
