import { toast } from "react-toastify";
import { appErrors } from "../../../AppConstants";
import { TFetchAllFeedbacks } from "../../../data/types/adminApi";
import {
  ADMIN_FETCH_ALL_FEEDBACKS
} from "../../../services/AppServices";

export const fetchFeedbackList = async (props: any = {}) => {
  const payload: TFetchAllFeedbacks = {
    searchFilter: props.searchFilter ? props.searchFilter : "",
    perPageRows: props.perPageRows ? props.perPageRows : 10,
    currentPage: props.currentPage ? props.currentPage : 1,
    orderBy: props.orderBy ? props.orderBy : "DESC",
    sortBy: props.sortBy ? props.sortBy : "id",
  };
  try {
    const data = await ADMIN_FETCH_ALL_FEEDBACKS(payload);
    if (data.code === 200 || data.code === "200") {
      return data.data;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
};


export const headCells = [
  {
    id: "feedbacker",
    disablePadding: false,
    label: "Feedbacker",
    width: "15%",
    textAlign: "left",
  },
  {
    id: "feedback_date",
    disablePadding: false,
    label: "Feedback Date",
    textAlign: "center",
    width: "15%",
  },
  {
    id: "feedback_type",
    disablePadding: true,
    label: "Feedback Type",
    textAlign: "center",
    width: "15%",
  },
  {
    id: "feedback_suggestion",
    disablePadding: false,
    label: "Feedback",
    textAlign: "left",
    width: "55%",
    isNotSortable: true,
  },
];
