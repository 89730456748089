import React from "react";
import { Drawer, Box, Grid, Button } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Formik, Form } from "formik";
import {
  getInitialState,
  FORM_VALIDATION,
  fetchInfluencerById,
  updateInfluencer,
} from "./ViewEditInfluencer";
import Textfield from "../../../components/FormsUI/Textfield";
import moment from "moment";
import PhoneInputMaskField from "../../../components/FormsUI/PhoneInputMaskField";

const ViewEditInfluencerModal = (props: any) => {
  const [influencer, setInfluencer] = React.useState<any>(null);
  React.useEffect(() => {
    new Promise(async () => {
      const data = await fetchInfluencerById(props.id);
      setInfluencer(data);
    });
  }, []);
  const checkApiCall = async (fieldValues: any) => {
    if (!influencer) return;
    const data = await updateInfluencer({
      ...fieldValues,
      id: influencer.id,
      id_app_user: influencer.id_app_user,
    });
    if (data) props.closeModal(true);
  };

  return (
    <React.Fragment>
      <Drawer open anchor="right" className="side-modal">
        <Box className="modal-dialog-box">
          <div className="modal-header">
            <div className="modal-title">{influencer?.first_name}</div>
            <div className="modal-header-action">
              <span
                className="modal-btn-close"
                onClick={() => props.closeModal(false)}
              >
                <Close />
              </span>
            </div>
          </div>
          <div className="modal-content">
            <Formik
              enableReinitialize
              initialValues={getInitialState(influencer)}
              validateOnBlur={false}
              validationSchema={FORM_VALIDATION}
              onSubmit={async (fieldValues) => checkApiCall(fieldValues)}
            >
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Textfield
                      name="first_name"
                      label="First Name"
                      disabled={props.disabled}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Textfield
                      name="last_name"
                      label="Last Name"
                      disabled={props.disabled}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <PhoneInputMaskField
                      name="contact_number"
                      label="Contact Number"
                      disabled={props.disabled}
                    />
                    {/* <Textfield
                      name="contact_number"
                      label="Contact Number"
                      disabled={props.disabled}
                    /> */}
                  </Grid>
                  <Grid item xs={12}>
                    <Textfield
                      className="min-kyc-date-field"
                      name="min_kyc_date"
                      label="Minimum kyc Date"
                      type={"date"}
                      disabled
                    />
                  </Grid>
                  <Grid item>
                    {!props.disabled && (
                      <Button
                        type="submit"
                        className="btn-primary margin-right-16"
                      >
                        SUBMIT
                      </Button>
                    )}
                    <Button
                      className="btn-outline"
                      onClick={() => props.closeModal(false)}
                    >
                      CANCEL
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </Formik>
          </div>
        </Box>
      </Drawer>
    </React.Fragment>
  );
};

export default ViewEditInfluencerModal;
