import { Card } from "@mui/material";
import { NavLink } from "react-router-dom";

const HorizontalFooter = () =>{
    return(
        <Card className="horizontal-header-card card-box-shadow">
            <div className="app-horizontal">
                <span><p>copy Right:@2022 easyMile ,  Developed By: <a href="https://vihaainfotech.com">vihaainfotech</a></p></span> 
               
            </div>
        </Card>
    )
}
export default HorizontalFooter