import { apiEndPoints } from "../AppConstants";
import { httpMethods, serviceMaker } from "./ServiceWarpper";
import { getEncryptedText } from "../utils/sharedFunctions";
import {
  TAddPreApprovedUser,
  TFetchAllPreApprovedUser,
  TUpdatePreApprovedUser,
  TActiveInactivePreApprovedUser,
} from "../data/types/preApprovedUserApi";

export const FETCH_ALL_PRE_APPROVED_USER = (
  payload: TFetchAllPreApprovedUser
) =>
  serviceMaker(
    `${apiEndPoints.FETCH_ALL_PRE_APPROVED_USER}?searchFilter=${payload.searchFilter}&perPageRows=${payload.perPageRows}&currentPage=${payload.currentPage}
&orderBy=${payload.orderBy}&sortBy=${payload.sortBy}`,
    httpMethods.GET
  );

export const FETCH_PRE_APPROVED_USER_BY_ID = (payload: number) =>
  serviceMaker(
    `${apiEndPoints.FETCH_PRE_APPROVED_USER_BY_ID}/${getEncryptedText(
      payload.toString()
    )}`,
    httpMethods.GET
  );

export const ADD_PRE_APPROVED_USER = (payload: TAddPreApprovedUser) =>
  serviceMaker(
    `${apiEndPoints.ADD_PRE_APPROVED_USER}`,
    httpMethods.POST,
    payload
  );

export const UPDATE_PRE_APPROVED_USER = (payload: TUpdatePreApprovedUser) =>
  serviceMaker(
    `${apiEndPoints.UPDATE_PRE_APPROVED_USER}`,
    httpMethods.PUT,
    payload
  );

export const DELETE_PRE_APPROVED_USER = (payload: number) =>
  serviceMaker(
    `${apiEndPoints.DELETE_PRE_APPROVED_USER}/${payload}`,
    httpMethods.DELETE
  );

  export const ACTIVE_INACTIVE_PRE_APPROVED_USER = (payload: TActiveInactivePreApprovedUser) =>
  serviceMaker(
    `${apiEndPoints.ACTIVE_INACTIVE_PRE_APPROVED_USER}`,
    httpMethods.POST,
    payload,
  );
