import { createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./reducers/rootReducer";

const store = createStore(rootReducer, composeWithDevTools());

export type TReduxState = ReturnType<typeof store.getState>
export type TReduxDispatch = typeof store.dispatch

export default store;
