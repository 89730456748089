import React, { useState } from "react";
import {
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Paper,
  Grid,
  Select,
  MenuItem,
  Switch,
  IconButton,
  Pagination,
  Button,
  Typography,
} from "@mui/material";
import moment from "moment";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import FiberManualRecordOutlinedIcon from "@mui/icons-material/FiberManualRecordOutlined";
import { getComparator } from "../../../utils/sortFunction";
import useListState from "../../../hooks/useListState";
import SortTableColumn from "../../../components/common/SortTableColumn";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../components/common/TableCell";
import ViewEditInfluencerModal from "./ViewEditInfluencerModal";
import {
  headCells,
  fetchInfluencerList,
  deleteInfluencer,
  activeDeactiveAccount,
  approvedDeapprovedAccount,
  FWEFilterField,
  FWEFilterComparator,
  FWEFilterFieldLabel,
} from "./influencerList";
import {
  formatContactNumber,
  UserStatus,
  UserStatusLabel,
  getUserStatusBgColorClass,
} from "../../../utils/sharedFunctions";
import useViewEditVendorModalState from "../../../hooks/vendor/useViewEditVendorModalState";
import usePaginationState from "../../../hooks/usePaginationState";
import useConfirmationModalState from "../../../hooks/useConfirmationModalState";
import ConfirmationModal from "../../../components/common/ConfirmationModal";
import RefreshIcon from "@mui/icons-material/Refresh";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TablePagination from "../../../components/common/TablePagination";
import {
  PER_PAGE_ROWS_OPTIONS,
  INSTAGRAM_API_ENDPOINT,
} from "../../../AppConfig";
import { TFWEFilter } from "../../../data/types/InflucncerList";

export default function MuiTable() {
  const [INFLUENCER, setInfluencerList] = React.useState([]);
  const { pagination, setPagination } = usePaginationState({
    searchFilter: "",
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const { showCmfModal, setShowCmfModal, cmfModalDetails, setCmfModalDetails } =
    useConfirmationModalState();
  const {
    showModal,
    setShowModalState,
    modalId,
    setModalIdState,
    modalFiledDisabled,
    setModalFiledDisabledState,
  } = useViewEditVendorModalState();
  const [FWEFilter, setFWEFilter] = React.useState<TFWEFilter>({
    field: 0,
    comparator: 0,
    value: 0,
  });
  let timer: ReturnType<typeof setTimeout>;

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      setInfluencerList([]);
      const data = await fetchInfluencerList(pagination);
      if (data) {
        setPagination({
          searchFilter: data.pagination.searchFilter
            ? data.pagination.searchFilter
            : "",
          perPageRows: data.pagination.perPageRows
            ? data.pagination.perPageRows
            : 10,
          currentPage: data.pagination.currentPage
            ? data.pagination.currentPage
            : 1,
          totalPages: data.pagination.totalPages
            ? data.pagination.totalPages
            : 0,
          totalItems: data.pagination.totalItems
            ? data.pagination.totalItems
            : 0,
          orderBy: data.pagination.orderBy ? data.pagination.orderBy : "desc",
          sortBy: data.pagination.sortBy ? data.pagination.sortBy : "id",
        });
        setInfluencerList(data.influencer);
      }
    } catch (e) {}
    setIsLoading(false);
  };

  const handleRequestSort = (property: string) => {
    pagination.orderBy =
      pagination.sortBy == property
        ? pagination.orderBy == "asc"
          ? "desc"
          : "asc"
        : "asc";
    pagination.sortBy = property;
    fetchData();
  };

  const handleRowsPerPage = (rowCount: number) => {
    pagination.perPageRows = rowCount;
    pagination.currentPage = 1;
    fetchData();
  };

  const handleCloseModal = (status: boolean) => {
    setShowModalState(false);
    if (status) {
      fetchData();
    }
  };

  const handleShowModal = (id: number, disabled: boolean) => {
    setModalIdState(id);
    setModalFiledDisabledState(disabled);
    setShowModalState(true);
  };

  const handleShowDelModal = (id: number, name: string) => {
    setCmfModalDetails({
      id,
      title: "Influencer Delete",
      message: `You are about to delete ${name}.`,
    });
    setShowCmfModal(true);
  };

  const handleDelCloseModal = async (status: boolean) => {
    setShowCmfModal(false);
    if (status) {
      const resDelete = await deleteInfluencer(cmfModalDetails.id);
      if (resDelete) fetchData();
    }
  };

  const handleActiveDeactive = async (id: number) => {
    const tempInfluencer: any = INFLUENCER.find(
      (influencer: any) => id == influencer.id_app_user
    );
    if (!tempInfluencer) return;
    const result = await activeDeactiveAccount(
      id,
      tempInfluencer.is_active == "0" ? 1 : 0
    );
    if (result) {
      const tempInfluencerList: any = INFLUENCER.map((influencer: any) => {
        if (influencer.id_app_user == id) {
          return {
            ...influencer,
            is_active: influencer.is_active == "0" ? "1" : "0",
          };
        }
        return influencer;
      });
      setInfluencerList(tempInfluencerList);
    }
  };

  const handleApproved = async (id: number) => {
    const tempInfluencer: any = INFLUENCER.find(
      (influencer: any) => id == influencer.id_app_user
    );
    if (!tempInfluencer) return;
    const result = await approvedDeapprovedAccount(id, 1);
    if (result) {
      const tempInfluencerList: any = INFLUENCER.map((influencer: any) => {
        if (influencer.id_app_user == id) {
          return { ...influencer, approved_date: new Date() };
        }
        return influencer;
      });
      setInfluencerList(tempInfluencerList);
    }
  };

  const handleSearchChange = async (e: any) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(async () => {
      if (e.target.name == "searchFilter") {
        pagination.searchFilter = e.target.value;
        pagination.currentPage = 1;
        fetchData();
      }
    }, 500);
  };

  const handlePageChange = (page: number) => {
    pagination.currentPage = page;
    fetchData();
  };
  return (
    <React.Fragment>
      <div className="page-header">
        <span className="page-title">Influencer List</span>
      </div>
      <div>
        <Grid container spacing={2} className="headElements">
          <Grid item className="leftHead">
            <p className="selectPages">Show</p>
            <Select
              value={pagination.perPageRows}
              label="Page"
              className="dropDown"
              onChange={(e) => handleRowsPerPage(Number(e.target.value))}
            >
              {PER_PAGE_ROWS_OPTIONS.map((item: number) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
            <span
              className="margin-left-16 btn-icon"
              title="Reload"
              onClick={() => fetchData()}
            >
              <RefreshIcon fontSize="large" className="color-light-blue" />
            </span>
          </Grid>
          <Grid item className="rightHead">
            <p className="selectPages">Search</p>
            <TextField
              size={"small"}
              className="searchField"
              id="outlined-basic"
              name="searchFilter"
              placeholder="Search Influencers"
              onChange={(e: any) => handleSearchChange(e)}
            />
          </Grid>
        </Grid>
        <TableContainer component={Paper} className="tableContainer">
          <Table stickyHeader className="table" aria-label="simple table">
            <SortTableColumn
              order={pagination.orderBy}
              orderBy={pagination.sortBy}
              headCells={headCells}
              handleRequestSort={handleRequestSort}
            />
            {/* <TableBody>
              <TableCell colSpan={10}>
                <Select
                  size={"small"}
                  style={{ textAlign: "left" }}
                  // value={pagination.status}
                  // onChange={(e) =>
                  //   handleStatus(Number(e.target.value))
                  // }
                >
                  <MenuItem value={0}>{"All"}</MenuItem>
                  {Array.from(FWEFilterFieldLabel.entries()).map(([key, value]) => (
                    <MenuItem value={key}>{value}</MenuItem>
                  ))}
                </Select>
                <Select
                  size={"small"}
                  style={{ textAlign: "left" }}
                  // value={pagination.status}
                  // onChange={(e) =>
                  //   handleStatus(Number(e.target.value))
                  // }
                >
                  <MenuItem value={0}>{"All"}</MenuItem>
                  <MenuItem value={1}>{"All 1"}</MenuItem>
                  <MenuItem value={2}>{"All 2"}</MenuItem>
                </Select>
              </TableCell>
            </TableBody> */}
            <TableBody>
              {isLoading ? (
                <TableCell colSpan={10}>
                  <div className="loader-container">
                    <div className="loader"></div>
                  </div>
                </TableCell>
              ) : (
                INFLUENCER.map((row: any, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell className="table-cell-left-align">
                      <Grid item lg={10}>
                        <Typography>{row.first_name}</Typography>
                      </Grid>
                    </StyledTableCell>
                    <StyledTableCell className="table-cell-left-align">
                      <Typography color="black" variant="subtitle2">
                        {row.last_name}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell className="table-cell-left-align">
                      <a
                        rel="noreferrer"
                        className="text-decoration-none"
                        href={`${INSTAGRAM_API_ENDPOINT}/${row.insta_uname}`}
                        target="_blank"
                      >
                        <Typography color="black" variant="subtitle2">
                          {row.insta_uname}
                        </Typography>
                      </a>
                    </StyledTableCell>
                    <StyledTableCell className="table-cell-left-align">
                      {formatContactNumber(row.contact_number)}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell-center-align">
                      {row.followers}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell-center-align">
                      {row.social_score ? row.social_score : "-"}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell-center-align">
                      {row.engagement_rate ? row.engagement_rate : "-"}
                    </StyledTableCell>
                    {/* <StyledTableCell className="table-cell-center-align">
                      {row.min_kyc_date
                        ? moment(row.min_kyc_date).format("DD/MM/YYYY")
                        : ""}
                    </StyledTableCell> */}
                    <StyledTableCell className="table-cell-center-align">
                      <span
                        className={`text-rounded-chip ${getUserStatusBgColorClass(
                          row.user_status,
                          row.is_active
                        )}`}
                      >
                        {row.user_status === UserStatus.Approved &&
                        row.is_active === "0"
                          ? "Inactive"
                          : UserStatusLabel.get(row.user_status)}
                      </span>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography>
                        {row.is_active === "1" ? (
                          <FiberManualRecordIcon color="success" />
                        ) : (
                          <FiberManualRecordOutlinedIcon color="error" />
                        )}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Grid
                        container
                        className="actionGrid"
                        justifyContent="start"
                      >
                        <Grid>
                          <IconButton
                            title="View"
                            onClick={() => handleShowModal(row.id, true)}
                          >
                            <RemoveRedEyeIcon />
                          </IconButton>
                        </Grid>
                        {row.approved_date ? (
                          <React.Fragment>
                            <Grid>
                              <IconButton
                                title="Edit"
                                onClick={() => handleShowModal(row.id, false)}
                              >
                                <EditIcon />
                              </IconButton>
                            </Grid>
                            <Grid>
                              <IconButton
                                title="Delete"
                                onClick={() =>
                                  handleShowDelModal(
                                    row.id,
                                    row.first_name + row.last_name
                                  )
                                }
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                            <Grid>
                              <Switch
                                color={
                                  row.is_active == "0" ? "error" : "success"
                                }
                                title="Active"
                                checked={row.is_active == "1"}
                                onChange={() =>
                                  handleActiveDeactive(row.id_app_user)
                                }
                              />
                            </Grid>
                          </React.Fragment>
                        ) : (
                          row.user_status == UserStatus.PendingApproval &&
                          row.min_kyc_date && (
                            <Grid>
                              <IconButton
                                title="Approve"
                                onClick={() => handleApproved(row.id_app_user)}
                              >
                                <CheckCircleIcon color="success" />
                              </IconButton>
                            </Grid>
                          )
                        )}
                      </Grid>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          currentPage={pagination.currentPage}
          perPageRows={pagination.perPageRows}
          totalItems={pagination.totalItems}
          totalPages={pagination.totalPages}
          handleOnChangePage={handlePageChange}
        />
      </div>
      {showModal && (
        <ViewEditInfluencerModal
          id={modalId}
          closeModal={handleCloseModal}
          disabled={modalFiledDisabled}
        />
      )}{" "}
      {showCmfModal && (
        <ConfirmationModal
          closeModal={handleDelCloseModal}
          title={cmfModalDetails.title}
          message={cmfModalDetails.message}
        />
      )}
    </React.Fragment>
  );
}
