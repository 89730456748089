import { toast } from "react-toastify";
import { appErrors } from "../../../AppConstants";
import {
  TAddBrandMerchants,
  TFetchAllBrandMerchants,
  TActiveInactiveBrandMerchants,
} from "../../../data/types/merchantApi";
import {
  FETCH_ALL_BRAND_MERCHANTS,
  ADD_BRAND_MERCHANT,
  FETCH_ALL_MERCHANTS,
  DELETE_BRAND_MERCHANT,
  ACTIVE_INACTIVE_BRAND_MERCHANTS,
} from "../../../services/AppServices";
import { getEncryptedText } from "../../../utils/sharedFunctions";

export const fetchBrandMerchants = async (props: any = {}) => {
  const payload: TFetchAllBrandMerchants = {
    searchFilter: props.searchFilter ? props.searchFilter : "",
    perPageRows: props.perPageRows ? props.perPageRows : 10,
    currentPage: props.currentPage ? props.currentPage : 1,
    orderBy: props.orderBy ? props.orderBy : "DESC",
    sortBy: props.sortBy ? props.sortBy : "id",
    brandId: props.brandId ? getEncryptedText(props.brandId.toString()) : "",
  };
  try {
    const data = await FETCH_ALL_BRAND_MERCHANTS(payload);
    if (data.code === 200 || data.code === "200") {
      return data.data;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
};

export const fetchAllMerchants = async (brandId: number) => {
  try {
    const data = await FETCH_ALL_MERCHANTS(brandId);
    if (data.code === 200 || data.code === "200") {
      return data.data;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
};

export const addBrandMerchant = async (props: any = {}) => {
  const payload: TAddBrandMerchants = {
    id_brand: props.id_brand,
    id_merchant: props.id_merchant,
  };
  try {
    const data = await ADD_BRAND_MERCHANT(payload);
    if (data.code === 200 || data.code === "200") {
      toast.success(data.message);
      return true;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
  return false;
};

export const deleteBrandMerchant = async (id: number) => {
  try {
    const data = await DELETE_BRAND_MERCHANT(id);
    if (data.code === 200 || data.code === "200") {
      toast.success(data.message);
      return true;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
  return false;
};

export const activeInactiveBrandMerchant = async (props: any) => {
  try {
    const payload: TActiveInactiveBrandMerchants = {
      id: props.id,
      is_active: props.is_active,
    }
    const data = await ACTIVE_INACTIVE_BRAND_MERCHANTS(payload);
    if (data.code === 200 || data.code === "200") {
      toast.success(data.message);
      return true;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
  return false;
};

export const headCells = [
  {
    id: "merchant_id",
    label: "Merchant ID",
    width: "25%",
    textAlign: "left",
  },
  {
    id: "merchant_name",
    disablePadding: false,
    label: "Merchant Name",
    textAlign: "left",
    width: "35%",
  },
  {
    id: "mcc",
    disablePadding: false,
    label: "MCC",
    width: "20%",
    textAlign: "left",
  },
  {
    id: "action",
    disablePadding: false,
    label: "Actions",
    width: "20%",
    textAlign: "left",
    isNotSortable: true,
  },
];
