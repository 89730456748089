import { Grid,Select,MenuItem,OutlinedInput,FormControlLabel,FormGroup,Checkbox,Button,FormControl,InputLabel } from "@mui/material"
import React, {useState} from "react";
import Textfield from "@mui/material/TextField";
import { apiCallTruckInfo } from "./ApiTruck";
import usePaginationState from "../../../hooks/usePaginationState";
import { updateTruck } from "./ApiTruck";
import { TUpdateTruckInfo } from "../../../data/types/adminApi";
import { toast } from "react-toastify";
import { Formik,Form } from "formik";
import { getInitialFormState } from "./ApiTruck";
import { FORM_VALIDATION } from "./ApiTruck";


const Preferences = () =>
{
  const [userList, setUserList] = React.useState({ height: 9.5, length: 50, width: 105, weight: 18500,mpg: 12,
    per_galen_rate: 10,engine_type:"gas",avoid_toll: 1,avoid_sharp_ton: 1,avg_speed:70});
 
    const [height ,setHeight] = useState("");
    const [width, setWidth] = useState("");
    const [length, setLength] = useState("");
    const [mpg, setMpg] = useState("");
    const [id, setId] = useState("");
    const [weight, setWeight] = useState("");
    const [engine_type, setEngine_type] = useState("");
    const [avg_speed, setAvg_speed] = useState("");
    const [avoid_sharp_ton, setAvoid_sharp_ton] = useState(0);
    const [avoid_toll, setAvoid_toll] = useState(0);
    const [per_galen_rate,setPer_galen_rate] = useState("");
   
  const { pagination, setPagination } = usePaginationState({
    searchFilter: "",
  });
 
  const [isLoading, setIsLoading] = React.useState(false);
   React.useEffect(()=>{
    fetchData();
   },[])   
    const fetchData = async () =>{
          setIsLoading(true);
          try{
          
            const data = await apiCallTruckInfo(pagination);
            
            console.log(data);
           
            if(data){
              setPagination({
             
              orderBy: data.orderBy ? data.orderBy : "desc",
              sortBy: data.sortBy ? data.sortBy : "id",
              });

              setUserList(data);
              setId(id);
              setHeight(data.height);
              setWidth(data.width);
              setLength(data.length);
              setMpg(data.mpg);
              setWeight(data.weight)
              setAvg_speed(data.avg_speed);
              setAvoid_toll(parseInt(data.avoid_toll ? data.avoid_toll.toString() : "0"));
              setAvoid_sharp_ton(parseInt(data.avoid_sharp_ton ? data.avoid_sharp_ton.toString() : "0"));
              setEngine_type(data.engine_type);
              setPer_galen_rate(data.per_galen_rate);
              
            }
          }catch (e) {}
          setIsLoading(false);
    }

    const onSubmitHandler = async (props:any) =>{
      setIsLoading(true);
      const payload: TUpdateTruckInfo = {
        id: props.id,
        height: parseFloat(height) ,
        length:parseFloat(length),
        width:parseFloat(width),
        weight:parseFloat(weight),
        engine_type:engine_type,
        mpg:parseFloat(mpg),
        per_galen_rate:parseFloat(per_galen_rate),
        avg_speed:parseFloat(avg_speed),
        avoid_toll:parseInt(avoid_toll ? avoid_toll.toString() : "0"),
        avoid_sharp_ton:parseInt(avoid_sharp_ton ? avoid_sharp_ton.toString() : "0"),}
      try{
        

        const data = await updateTruck(payload);
        
        console.log(data);
       
        if(data){
          toast.success(
            `Data updated success fully`
          );
          fetchData();
          
          
        }
      }catch (e) {}
      setIsLoading(false);
      

    }
   

    return(
        <React.Fragment>
                

        <div>
          <Formik
            enableReinitialize
            initialValues={getInitialFormState(userList)}
            validateOnBlur={false}
            validationSchema={FORM_VALIDATION}
            onSubmit={onSubmitHandler}
            
          >
            <Form >
          
                <div className="page-header">
                    <span className="page-title">Truck Information</span>
                </div>
                <Grid item container spacing={3} className="headElements">
                <Grid item xs={12}>
                    <Textfield
                      autoFocus fullWidth
                      name="height"
                      label="Height"
                      type="text" 
                      value={height}
                      onChange={e => setHeight(e.target.value)}
                    ></Textfield>
                  </Grid>
                  <Grid item xs={12}>
                    <Textfield
                      autoFocus fullWidth
                      name="width"
                      label="Width"
                      type="text"
                      value={width}
                      onChange={e => setWidth(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Textfield
                      autoFocus fullWidth
                      name="length"
                      label="Length"
                      type="text"
                      value={length}
                      onChange={e => setLength(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Textfield
                      autoFocus fullWidth
                      name="mpg"
                      label="MPG"
                      type="text"
                      value={mpg}
                      onChange={e => setMpg(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Textfield
                      autoFocus fullWidth
                      name="per_galen_rate"
                      label=" $/G rate"
                      type="text"
                      value={per_galen_rate}
                      onChange={e => setPer_galen_rate(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Textfield
                      autoFocus fullWidth
                      name="weight"
                      label="Weight"
                      type="text"
                      value={weight}
                      onChange={e => setWeight(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-helper-label">Fule</InputLabel>
                    <Select
                      autoFocus
                      style={{textAlign:"initial"}}
                      value={engine_type}
                       label="Fuel"
                      onChange={e => setEngine_type(e.target.value)}
                    >  
                    <MenuItem value={"gas"}>Gas</MenuItem>
                    <MenuItem value={"petrol"}>Petrol</MenuItem>
                    <MenuItem value={"diesel"}>Diesel</MenuItem>
                     </Select>
                  </FormControl>
                       
                  </Grid>
                 
                  <Grid item xs={12}>
                  <h2 className="header"> Route Preferences </h2>
                  <FormGroup>
                        
                        <FormControlLabel 
                        control={<Checkbox checked={parseInt(avoid_toll.toString()) == 1 ?  true : false}  
                        onChange={e => {
                          console.log(e.target.checked)
                            setAvoid_toll(parseInt(avoid_toll.toString()) == 1 ? 0 : 1)  }} />}
                        label=" Avoid Tolls " 
                        />
                        <FormControlLabel  
                        control={<Checkbox checked={parseInt(avoid_sharp_ton.toString()) == 1 ?  true : false}    
                        onChange={e => {
                          console.log(e.target.checked)
                          setAvoid_sharp_ton(parseInt(avoid_sharp_ton.toString()) === 1 ? 0 : 1)  }} />}
                        label=" Avoid Sharp Turns " 
                        />
         
                  </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                  <Button type="submit"  color="primary" className="btn btn-primary" style={{width: 200}} onClick={onSubmitHandler} >Update</Button>
                 
                  </Grid>
                   
                </Grid>
            </Form>
            </Formik>
        </div>
       
        

        
        </React.Fragment>
    )
}

export default Preferences