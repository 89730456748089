import * as Yup from "yup";

export const getInitialState = (offerCriteria: any) => {
  return {
    order_min_value: offerCriteria?.order_min_value || "",
    order_discount: offerCriteria?.order_discount || "",
  };
};

export const FORM_VALIDATION = Yup.object().shape({
  order_min_value: Yup.number()
    .max(99999.99, "Order minimum value must be less than 99999.99")
    .required("Order minimum value is required!"),
  order_discount: Yup.number()
    .max(99999.99, "Order discount must be less than 99999.99")
    .required("Order discount is required"),
});
