import React from "react";
import VerticalHeader from "../components/layouts/admin/VerticalHeader";
import { Outlet } from "react-router-dom";
import useNavExpandedState from "../hooks/layout/useNavExpandedState";
import { useLocation } from "react-router-dom";
import { checkValidUser } from "./userLayout";
import { adminRoutePathList } from "../AppConstants";
import { UserType } from "../utils/sharedFunctions";
import HorizontalHeaderContainer from "../containers/HorizontalHeaderContainer";
import HorizontalFooter from "../components/layouts/admin/HorizontalFooter";

const AdminLayout = () => {
  const { isNavExpanded, toggleExpanded } = useNavExpandedState();
  const location = useLocation();

  React.useEffect(() => {
    checkValidUser(location.pathname, adminRoutePathList, UserType.Administrator);
  }, [location.pathname]);

  return (
    <React.Fragment>
      <div className="app-layout-panel">
        <div className={`app-layout-left-panel`}>
          <VerticalHeader
            isNavExpanded={isNavExpanded}
            toggleExpanded={toggleExpanded}
          />
        </div>
        <div
          className={`${
            isNavExpanded ? "collapsed-right-panel" : "expanded-right-panel"
          } app-layout-right-panel`}
        >
          <div className="app-right-content">
            <div className="app-horizontal-bar">
              <div className="app-horizontal-bar-padding">
                <HorizontalHeaderContainer toggleExpanded={toggleExpanded} />
              </div>
            </div>
            
            <div className="content-container">
              <div className="content-container-padding">
                <Outlet />
              </div>
            </div>
          
              <div className="app-horizontal-bar-padding">
                <HorizontalFooter />
              </div>
            
          </div>
        </div>
        
      </div>
    </React.Fragment>
  );
};

export default AdminLayout;
