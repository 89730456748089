import * as Yup from "yup";
import { toast } from "react-toastify";
import { appErrors } from "../../../AppConstants";
import { ADMIN_FETCH_VENDOR, ADMIN_UPDATE_VENDOR } from "../../../services/AppServices";
import { TAdminUpdateVendor } from "../../../data/types/adminApi";
import { formatPhoneMaskNumber } from "../../../utils/sharedFunctions";

export const FORM_VALIDATION = Yup.object().shape({
  vendor_name: Yup.string()
      .required("Vendor name is required!")
      .max(75, "Vendor name must be 75 characters!"),
  first_name: Yup.string()
      .required("First name is required!")
      .max(30, "First name must be 30 characters!"),
  last_name: Yup.string()
      // .required("Last name is required!")
      .max(30, "Last name must be 30 characters!"),
  primary_contact_number: Yup.string()
      .required("Primary contact number is required!")
      .max(20, "Primary contact number must be 20 characters!")
      .min(10, "Primary contact number must be 10 characters!"),
  emergency_contact_number: Yup.string()
      // .required("Emergency contact number is required!")
      .max(20, "Emergency contact number must be 20 characters!")
      .min(10, "Emergency contact number must be 10 characters!")

});

export const getInitialState = (vendor: any) => {
  return {
    vendor_name: vendor?.vendor_name || "",
    email_address: vendor?.email_address || "",
    first_name: vendor?.first_name || "",
    last_name: vendor?.last_name || "",
    primary_contact_number: vendor?.primary_contact_number || "",
    emergency_contact_number: vendor?.emergency_contact_number || "",
  }
}

export const fetchVendorById = async (id: number) => {

  try {
    const data = await ADMIN_FETCH_VENDOR(id);
    if (data.code === 200 || data.code === "200") {
      return data.data;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
};

export const updateVendor = async (props: any) => {
  const payload: TAdminUpdateVendor = {
    id: props.id,
    id_app_user: props.id_app_user,
    vendor_name: props.vendor_name,
    first_name: props.first_name,
    last_name: props.last_name,
    primary_contact_number: formatPhoneMaskNumber(props.primary_contact_number),
    emergency_contact_number: formatPhoneMaskNumber(props.emergency_contact_number),
  }
  try {
    const data = await ADMIN_UPDATE_VENDOR(payload);
    if (data.code === 200 || data.code === "200") {
      return true;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
  return false;
};
