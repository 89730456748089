import { toast } from "react-toastify";
import { appErrors } from "../../../AppConstants";
import { TFetchAllMerchants } from "../../../data/types/adminApi";
import {
  ADMIN_ACTIVE_INACTIVE_MERCHANT,
  ADMIN_FETCH_ALL_MERCHANTS,
  DELETE_MERCHANT,
} from "../../../services/AppServices";
import { getEncryptedText } from "../../../utils/sharedFunctions";

export const fetchMerchantList = async (props: any = {}) => {
  const payload: TFetchAllMerchants = {
    searchFilter: props.searchFilter ? props.searchFilter : "",
    perPageRows: props.perPageRows ? props.perPageRows : 10,
    currentPage: props.currentPage ? props.currentPage : 1,
    isActive: props.isActive ? props.isActive : "",
    orderBy: props.orderBy ? props.orderBy : "DESC",
    sortBy: props.sortBy ? props.sortBy : "id",
  };
  try {
    const data = await ADMIN_FETCH_ALL_MERCHANTS(payload);
    if (data.code === 200 || data.code === "200") {
      return data.data;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
};

export const deleteMerchant = async (id: number) => {
  try {
    const payload = getEncryptedText(id.toString());
    const data = await DELETE_MERCHANT(payload);
    if (data.code === 200 || data.code === "200") {
      toast.success("Merchant successfully deleted");
      return true;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
  return false;
};

export const activeDeactiveAccount = async (id: number, is_active: number) => {
  try {
    const payload = { id, is_active };
    const data = await ADMIN_ACTIVE_INACTIVE_MERCHANT(payload);
    if (data.code === 200 || data.code === "200") {
      toast.success(data.message);
      return true;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
  return false;
};

export const headCells = [
  {
    id: "merchant_id",
    disablePadding: false,
    label: "Merchant Id",
    width: "25%",
    textAlign: "left",
  },
  {
    id: "merchant_name",
    disablePadding: false,
    label: "Merchant Name",
    width: "35%",
    textAlign: "left",
  },
  {
    id: "mcc",
    disablePadding: true,
    label: "MCC",
    width: "20%",
    textAlign: "left",
  },
  {
    id: "action",
    disablePadding: false,
    label: "Actions",
    textAlign: "left",
    width: "20%",
    isNotSortable: true,
  },
];
