import React from "react";
import { PER_PAGE_ROWS } from "../AppConfig";

const usePaginationState = (fields: any): {
  pagination: any;
  setPagination: (state: {[key: string]: any}) => void;
} => {
  const [pagination, setPaginationState] = React.useState<any>({
    orderBy: 'desc',
    sortBy: 'id',
    currentPage: 1,
    perPageRows: PER_PAGE_ROWS,
    totalPages: 0,
    totalItems: 0,
    ...fields
  });

  const setPagination = (state: {[key: string]: any}) => {
    setPaginationState({...pagination, ...state});  
  };

  return {
    pagination,
    setPagination
  };
};

export default usePaginationState;


// import React from "react";

// const useShowListState = (): {
//   orderBy: string;
//   setOrderBy: (value: string) => void;
//   sortBy: string;
//   setSortBy: (value: string) => void;
//   currentPage: number;
//   setCurrentPage: (value: number) => void;
//   perPageRows: number;
//   setPerPageRows: (value: number) => void;
//   totalPages: number;
//   setTotalPages: (value: number) => void;
//   totalItems: number;
//   setTotalItems: (value: number) => void;
// } => {
//   const [orderBy, setOrderByState] = React.useState<string>("");
//   const [sortBy, setSortByState] = React.useState<string>("");
//   const [currentPage, setCurrentPageState] = React.useState<number>(0);
//   const [perPageRows, setPerPageRowsState] = React.useState<number>(0);
//   const [totalPages, setTotalPagesState] = React.useState<number>(0);
//   const [totalItems, setTotalItemsState] = React.useState<number>(0);

//   const setOrderBy = (value: string) => {
//     setOrderByState(value);
//   };

//   const setSortBy = (value: string) => {
//     setSortByState(value);
//   };

//   const setCurrentPage = (value: number) => {
//     setCurrentPageState(value);
//   };

//   const setPerPageRows = (value: number) => {
//     setPerPageRowsState(value);
//   };

//   const setTotalPages = (value: number) => {
//     setTotalPagesState(value);
//   };

//   const setTotalItems = (value: number) => {
//     setTotalItemsState(value);
//   };

//   const handlePagination = (pagination: any) => {

//   };

//   return {
//     orderBy,
//     setOrderBy,
//     sortBy,
//     setSortBy,
//     currentPage,
//     setCurrentPage,
//     perPageRows,
//     setPerPageRows,
//     totalPages,
//     setTotalPages,
//     totalItems,
//     setTotalItems,
//   };
// };

// export default useShowListState;
