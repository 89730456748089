import React from "react";
import {
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
  Grid,
  Select,
  MenuItem,
} from "@mui/material";
import SortTableColumn from "../../../components/common/SortTableColumn";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../components/common/TableCell";

import {
  headCells,
  fetchFeedbackList,
} from "./feedbackList";
import usePaginationState from "../../../hooks/usePaginationState";
import RefreshIcon from "@mui/icons-material/Refresh";
import TablePagination from "../../../components/common/TablePagination";
import { PER_PAGE_ROWS_OPTIONS } from "../../../AppConfig";
import moment from "moment";
import { feedbackTypeLabel } from "../../../utils/sharedFunctions";

export default function MuiTable() {
  const [feedbackList, setFeedbackList] = React.useState([]);
  const { pagination, setPagination } = usePaginationState({
    searchFilter: "",
  });
  const [isLoading, setIsLoading] = React.useState(false);
  let timer: ReturnType<typeof setTimeout>;

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      setFeedbackList([]);
      const data = await fetchFeedbackList(pagination);
      if (data) {
        setPagination({
          searchFilter: data.pagination.searchFilter
            ? data.pagination.searchFilter
            : "",
          perPageRows: data.pagination.perPageRows
            ? data.pagination.perPageRows
            : 10,
          currentPage: data.pagination.currentPage
            ? data.pagination.currentPage
            : 1,
          totalPages: data.pagination.totalPages
            ? data.pagination.totalPages
            : 0,
          totalItems: data.pagination.totalItems
            ? data.pagination.totalItems
            : 0,
          orderBy: data.pagination.orderBy ? data.pagination.orderBy : "desc",
          sortBy: data.pagination.sortBy ? data.pagination.sortBy : "id",
        });
        setFeedbackList(data.feedbacks);
      }
    } catch (e) {}
    setIsLoading(false);
  };

  const handleRequestSort = (property: string) => {
    pagination.orderBy =
      pagination.sortBy === property
        ? pagination.orderBy === "asc"
          ? "desc"
          : "asc"
        : "asc";
    pagination.sortBy = property;
    fetchData();
  };

  const handleRowsPerPage = (rowCount: number) => {
    pagination.perPageRows = rowCount;
    pagination.currentPage = 1;
    fetchData();
  };

  const handleSearchChange = async (e: any) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(async () => {
      if (e.target.name === "searchFilter") {
        pagination.searchFilter = e.target.value;
        pagination.currentPage = 1;
        fetchData();
      }
    }, 500);
  };

  const handlePageChange = (page: number) => {
    pagination.currentPage = page;
    fetchData();
  };

  return (
    <React.Fragment>
      <div className="page-header">
        <span className="page-title">Feedback List</span>
      </div>
      <div>
        <Grid container spacing={2} className="headElements">
          <Grid item className="leftHead">
            <p className="selectPages">Show</p>
            <Select
              value={pagination.perPageRows}
              label="Page"
              className="dropDown"
              onChange={(e) => handleRowsPerPage(Number(e.target.value))}
            >
              {PER_PAGE_ROWS_OPTIONS.map((item: number) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
            <span
              className="margin-left-16 btn-icon"
              title="Reload"
              onClick={() => fetchData()}
            >
              <RefreshIcon fontSize="large" className="color-light-blue" />
            </span>
          </Grid>
          <Grid item className="rightHead">
            <p className="selectPages">Search</p>
            <TextField
              size={"small"}
              className="searchField"
              id="outlined-basic"
              name="searchFilter"
              placeholder="Search Feedbacks"
              onChange={(e: any) => handleSearchChange(e)}
            />
          </Grid>
        </Grid>
        <TableContainer component={Paper} className="tableContainer">
          <Table stickyHeader className="table" aria-label="simple table">
            <SortTableColumn
              order={pagination.orderBy}
              orderBy={pagination.sortBy}
              headCells={headCells}
              handleRequestSort={handleRequestSort}
            />
            <TableBody>
              {isLoading ? (
                <TableCell colSpan={6}>
                  <div className="loader-container">
                    <div className="loader"></div>
                  </div>
                </TableCell>
              ) : (
                feedbackList.map((row: any, index) => (
                  <StyledTableRow hover key={index} className="user-table-row-height">
                    <StyledTableCell className="tableCell table-cell-left-align">
                      {row.feedbacker}
                    </StyledTableCell>
                    <StyledTableCell>
                      {moment(row.feedback_date).format("DD/MM/YYYY")}
                    </StyledTableCell>
                    <StyledTableCell>
                      {feedbackTypeLabel.get(row.feedback_type)}
                    </StyledTableCell>
                    <StyledTableCell className="tableCell table-cell-left-align">
                      {row.feedback_suggestion}
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          currentPage={pagination.currentPage}
          perPageRows={pagination.perPageRows}
          totalItems={pagination.totalItems}
          totalPages={pagination.totalPages}
          handleOnChangePage={handlePageChange}
        />
      </div>
    </React.Fragment>
  );
}
