import { Grid,Select,MenuItem,FormControl,InputLabel,OutlinedInput,FormControlLabel,FormGroup,Checkbox, Paper, Button } from "@mui/material"
import React from "react"
import Textfield from "@mui/material/TextField"

const Configuration =() =>
{
    return(
        <React.Fragment>
                <FormControl>
                <Grid xs={12} md={8} lg={6}>
                <div className="page-header">
                    <span className="page-title"> Google API Keys </span>
                </div>  
                <Grid container spacing={3} className="headElements">
                <Grid item xs={12}>
                    <Textfield
                      autoFocus fullWidth
                      name=" Route API key  "
                      label=" Route API key  "
                      type="text"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Textfield
                      autoFocus fullWidth
                      name="Direction API key "
                      label="Direction API key "
                      type="text"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Textfield
                      autoFocus fullWidth
                      name=" Map API key "
                      label=" Map API key "
                      type="text"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Textfield
                      autoFocus fullWidth
                      name="Place API key "
                      label="Place API key "
                      type="text"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Textfield
                      autoFocus fullWidth
                      name="Firebase API key"
                      label="Firebase API key"
                      type="text"
                    />
                  </Grid>
                  <Grid item xs={12}>
                       <Button type="submit"  color="primary" className="btn btn-primary" style={{width: 200}}>Submit</Button>
                  </Grid>
                  </Grid>
                </Grid>
                
            </FormControl>
        </React.Fragment>
            
    )
}
export default Configuration 