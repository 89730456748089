import { connect } from "react-redux";
import BrandList from "../../views/admin/brand/index";
import { TReduxDispatch, TReduxState } from "../../store/store";

const mapStateToProps = (state: TReduxState) => ({
  loginInfo: state.loginInfo.loginInfo,
});

const mapDispatchToProps = (dispatch: TReduxDispatch) => ({});

const BrandListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BrandList);
export default BrandListContainer;
