import moment from "moment";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { appErrors } from "../../AppConstants";
import {
  ADD_OFFER,
  UPDATE_OFFER,
  FETCH_OFFER_INITIAL,
  DELETE_OFFER_CRITERIA,
  ADD_IMAGE,
  DELETE_IMAGE,
  UPDATE_OFFER_END_DATE,
  ADMIN_FETCH_BRAND,
  GET_IMAGE,
} from "../../services/AppServices";
import {
  TImageDetails,
  TOfferCriteria,
  TPostCriteria,
  TSelectedImage,
  TStoryCriteria,
} from "../../data/types/OfferState";
import {
  criteriaType,
  DiscountIn,
  DiscountType,
  GenerateRandomString,
  ImageFileType,
  base64ArrayBuffer,
  base64toBlob,
} from "../../utils/sharedFunctions";
import {
  TApiDeleteOfferCriteria,
  TApiOffer,
  TApiOfferCriteria,
  TApiOfferPostDiscountCriteria,
  TApiOfferStoryDiscountCriteria,
  TApiUpdateOfferEndDate,
} from "../../data/types/OfferApi";

export const getInitialState = (offer: any, vendorId: number | null) => {
  return {
    id_vendor: offer?.id_vendor ? offer.id_vendor : vendorId ? vendorId : "",
    id_brand: offer?.id_brand || "",
    offer_title: offer?.offer_title || "",
    offer_description: offer?.offer_description || "",
    start_date: offer?.start_date
      ? moment(new Date(offer.start_date)).format("YYYY-MM-DD")
      : "",
    end_date: offer?.end_date
      ? moment(new Date(offer.end_date)).format("YYYY-MM-DD")
      : "",
    post_duration: offer?.post_duration || "",
    ecommerce_site_address: offer?.ecommerce_site_address || "",
    id_offer_category: offer?.id_offer_category || "",
    is_limited: offer?.is_limited == "1" ? true : false,
    avail_times: offer?.avail_times || "",
    avail_cycle: offer?.avail_cycle || "",
    tags: offer?.tags || "",
    post_content: offer?.post_content || "",
    picture_should_contains: offer?.picture_should_contains || "",
    is_active: offer?.is_active == "1" ? true : false,
    order_discount_type: offer?.order_discount_type || DiscountType.Flat,
    order_max_discount: offer?.order_max_discount || "",
    min_order_value: offer?.min_order_value || "",
    post_discount_type: offer?.post_discount_type || DiscountType.Percentage,
    post_max_discount: offer?.post_max_discount || "",
    post_discount_in: offer?.post_discount_in || DiscountIn.Amount,
    story_discount_type: offer?.story_discount_type || DiscountType.Percentage,
    story_max_discount: offer?.story_max_discount || "",
    story_discount_in: offer?.story_discount_in || DiscountIn.Amount,
    // terms_and_conditions: [],
  };
};

export const FORM_VALIDATION = Yup.object().shape({
  id_vendor: Yup.number().required("Vendor name is required!"),
  id_brand: Yup.number().required("Brand name is required!"),
  offer_title: Yup.string()
    .required("Offer title is required!")
    .max(30, "Offer title must be 30 characters!"),
  offer_description: Yup.string().max(
    500,
    "Offer description must be 500 characters!"
  ),
  start_date: Yup.date().required("Start date is required!"),
  end_date: Yup.date()
    .required("end date is required!")
    .when(["start_date"], (start_date, schema) => {
      let date = new Date(start_date);
      date.setDate(date.getDate() + 1);
      return (
        start_date && schema.min(date, "End date must be after Start date!")
      );
    }),
  post_duration: Yup.number()
    .required("Post duration is required!")
    .integer("Post duration should not contain any decimal values!!"),
  is_limited: Yup.boolean().required("Limited time status is required!"),
  avail_times: Yup.string().when("is_limited", {
    is: true,
    then: Yup.string().required(
      "Avail time is required for limited time offer!"
    ),
  }),
  avail_cycle: Yup.number().when("is_limited", {
    is: true,
    then: Yup.number().required("Period is required for limited time offer!"),
  }),
  ecommerce_site_address: Yup.string()
    .required("E-commerce site address is required!")
    .max(200, "E-commerce site address must be 30 characters!")
    .test("is-url-valid", "E-commerce site address is not valid", (value) => {
      return isValidUrl(value);
    }),
  // .matches(
  //   /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
  //   "E-commerce site address is invalid!"
  // ),
  is_active: Yup.boolean().required("Active/Inactive status is required!"),
  order_discount_type: Yup.number().required(
    "Order discount type  is required"
  ),
  post_discount_type: Yup.number().required("Post discount type is required!"),
  story_discount_type: Yup.number().required(
    "Story discount type is required!"
  ),
  // post_discount_in: Yup.number().required(
  //   "Post discount in coins/amount is required!"
  // ),
  min_order_value: Yup.number().max(
    99999.99,
    "Order min value must be less than 99999.99"
  ),
  order_max_discount: Yup.number().max(
    99999.99,
    "Order max discount must be less than 99999.99"
  ),
  post_max_discount: Yup.number()
    .max(99999, "Post max discount must be less than 99999")
    .integer("Post discount should not contain any decimal values!"),
  story_max_discount: Yup.number()
    .max(99999, "Story discount must be less than 99999")
    .integer("Story max discount should not contain any decimal values!"),
  // .when("post_discount_in", {
  //   is: 1,
  //   then: Yup.number().integer(
  //     "Coins discount should not contain any decimal values!"
  //   ),
  // }),
  post_content: Yup.string().max(1000, "Post content must be 30 characters!"),
  picture_should_contains: Yup.string().max(
    1000,
    "Picture should contains must be 30 characters!"
  ),
  // terms_and_conditions: Yup.array().of(Yup.object().shape({
  //   id: Yup.number(),
  //   termAdmCondition: Yup.string().required(),
  // }))
});

export const isValidUrl = (url: any) => {
  try {
    new URL(url);
  } catch (e) {
    return false;
  }
  return true;
};

export const fetchOfferInitialDetails = async (
  vendorId: null | number,
  offerId: number | null
) => {
  const payload = {
    vendorId,
    offerId,
  };
  try {
    const data = await FETCH_OFFER_INITIAL(payload);
    if (data.code === 200 || data.code === "200") {
      return data.data;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
};

export const apiCallSaveOffer = async (
  fieldValues: any,
  offerCriteriaList: TOfferCriteria[],
  postCriteriaList: TPostCriteria[],
  storyCriteriaList: TStoryCriteria[],
  tagList: string[],
  id_offer: number | null,
  imageId: number | null,
  selectedImage: TSelectedImage
) => {
  if (offerCriteriaList.length == 0) {
    toast.error("Offer criteria is required!");
    return;
  }
  // if (postCriteriaList.length == 0) {
  //   toast.error("Post criteria is required!");
  //   return;
  // }
  // if (storyCriteriaList.length == 0) {
  //   toast.error("Story criteria is required!");
  //   return;
  // }
  let offerCriteriaPayload: TApiOfferCriteria[] = [];

  for (const offerCriteria of offerCriteriaList) {
    const findSameMinValue = offerCriteriaList.find(
      (criteria) =>
        offerCriteria.id !== criteria.id &&
        offerCriteria.order_min_value == criteria.order_min_value
    );
    if (findSameMinValue) {
      toast.error(
        `There are multiple offer criteria with a order min value of  ${offerCriteria.order_min_value}. You can not add offer criteria with the same order min value!`
      );
      return;
    }
    if (
      Number(fieldValues.min_order_value) >
      Number(offerCriteria.order_min_value)
    ) {
      toast.error(
        "Criteria order value must be greater than or equals to order minimum value!"
      );
      return;
    }
    if (
      fieldValues.order_discount_type == DiscountType.Percentage &&
      offerCriteria.order_discount > 100
    ) {
      toast.error("Order discount must be in between 1 to 100!");
      return;
    }
    offerCriteriaPayload.push({
      id: offerCriteria.id,
      order_min_value: offerCriteria.order_min_value,
      order_discount: offerCriteria.order_discount,
    });
  }
  let postCriteriaPayload: TApiOfferPostDiscountCriteria[] = [];
  for (const postCriteria of postCriteriaList) {
    // const findSameMinValue = postCriteriaList.find(
    //   (criteria) =>
    //     postCriteria.id !== criteria.id &&
    //     postCriteria.score_min_value == criteria.score_min_value
    // );
    // if (findSameMinValue) {
    //   toast.error(
    //     `There are multiple post criteria with a score min value of  ${postCriteria.score_min_value}. You can not add post criteria with the same score min value!`
    //   );
    //   return;
    // }
    for (const criteria of postCriteriaList) {
      if (
        criteria.id !== postCriteria.id &&
        ((criteria.score_min_value >= postCriteria.score_min_value &&
          criteria.score_min_value <= postCriteria.score_min_value) ||
          (criteria.score_max_value >= postCriteria.score_min_value &&
            criteria.score_max_value <= postCriteria.score_max_value) ||
          (criteria.score_min_value <= postCriteria.score_min_value &&
            criteria.score_max_value >= postCriteria.score_min_value))
      ) {
        toast.error(appErrors.INSTA_SCORE_RANGE_OVERLAP);
        return;
      }
    }
    if (
      fieldValues.post_discount_type === DiscountType.Percentage &&
      (postCriteria.post_discount > 100 || postCriteria.post_discount < 1)
    ) {
      toast.error("Post discount must be in between 1 to 100!");
      return;
    }
    if (
      fieldValues.post_discount_in === DiscountIn.Coins &&
      postCriteria.post_discount % 1 !== 0
    ) {
      toast.error(
        "Post criteria coins discount should not contain any decimal values!"
      );
      return;
    }
    postCriteriaPayload.push({
      id: postCriteria.id,
      id_offer_bucket: postCriteria.id_offer_bucket,
      score_min_value: postCriteria.score_min_value,
      score_max_value: postCriteria.score_max_value,
      post_discount: postCriteria.post_discount,
    });
  }

  let storyCriteriaPayload: TApiOfferStoryDiscountCriteria[] = [];
  for (const storyCriteria of storyCriteriaList) {
    // const findSameMinValue = storyCriteriaList.find(
    //   (criteria) =>
    //     storyCriteria.id !== criteria.id &&
    //     storyCriteria.score_min_value == criteria.score_min_value
    // );
    // if (findSameMinValue) {
    //   toast.error(
    //     `There are multiple story criteria with a score min value of  ${storyCriteria.score_min_value}. You can not add story criteria with the same score min value!`
    //   );
    //   return;
    // }
    for (const criteria of storyCriteriaList) {
      if (
        criteria.id !== storyCriteria.id &&
        ((criteria.score_min_value >= storyCriteria.score_min_value &&
          criteria.score_min_value <= storyCriteria.score_min_value) ||
          (criteria.score_max_value >= storyCriteria.score_min_value &&
            criteria.score_max_value <= storyCriteria.score_max_value) ||
          (criteria.score_min_value <= storyCriteria.score_min_value &&
            criteria.score_max_value >= storyCriteria.score_min_value))
      ) {
        toast.error(appErrors.INSTA_SCORE_RANGE_OVERLAP);
        return;
      }
    }
    if (
      fieldValues.story_discount_type === DiscountType.Percentage &&
      (storyCriteria.story_discount > 100 || storyCriteria.story_discount < 1)
    ) {
      toast.error("Story discount must be in between 1 to 100!");
      return;
    }
    if (
      fieldValues.story_discount_in === DiscountIn.Coins &&
      storyCriteria.story_discount % 1 !== 0
    ) {
      toast.error(
        "Story criteria coins discount should not contain any decimal values!"
      );
      return;
    }
    storyCriteriaPayload.push({
      id: storyCriteria.id,
      id_offer_bucket: storyCriteria.id_offer_bucket,
      score_min_value: storyCriteria.score_min_value,
      score_max_value: storyCriteria.score_max_value,
      story_discount: storyCriteria.story_discount,
    });
  }

  if (
    fieldValues.is_limited &&
    !(fieldValues.avail_times && fieldValues.avail_cycle)
  ) {
    toast.error("For limited time offer avail times period is required!");
    return;
  }
  if (selectedImage && selectedImage.file) {
    const resAI: any = await apiCallAddImage(
      selectedImage.file,
      fieldValues.id_vendor,
      selectedImage.isBase64
    );
    if (!resAI) {
      toast.error(appErrors.UNKNOWN_ERROR_TRY_AGAIN);
      return;
    }
    if (imageId) {
      apiCallDeleteImages([imageId]);
    }
    imageId = resAI?.data?.id;
  }

  const payload: TApiOffer = {
    id: id_offer,
    id_vendor: fieldValues.id_vendor,
    id_brand: fieldValues.id_brand,
    offer_title: fieldValues.offer_title,
    offer_description: fieldValues.offer_description,
    start_date: fieldValues.start_date,
    end_date: fieldValues.end_date,
    post_duration: fieldValues.post_duration,
    is_active: fieldValues.is_active,
    is_limited: fieldValues.is_limited,
    order_discount_type: fieldValues.order_discount_type,
    ecommerce_site_address: fieldValues.ecommerce_site_address,
    post_discount_type: fieldValues.post_discount_type,
    id_offer_category: fieldValues.id_offer_category,
    offer_criteria: offerCriteriaPayload,
    offer_post_discount_criteria: postCriteriaPayload,
    offer_story_discount_criteria: storyCriteriaPayload,
    avail_times: fieldValues.avail_times,
    avail_cycle: fieldValues.avail_cycle,
    tags: tagList.join("|"),
    post_content: fieldValues.post_content,
    picture_should_contains: fieldValues.picture_should_contains,
    order_max_discount: fieldValues.order_max_discount,
    post_max_discount: fieldValues.post_max_discount,
    min_order_value: fieldValues.min_order_value,
    post_discount_in: fieldValues.post_discount_in,
    id_image: imageId,
  };

  if (!id_offer) {
    try {
      const data = await ADD_OFFER(payload);
      if (data.code === 200 || data.code === "200") {
        toast.success(data.message);
        return data.data;
      } else {
        toast.error(data?.message);
      }
    } catch (e: any) {
      toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
    }
  } else {
    try {
      const data = await UPDATE_OFFER(payload);
      if (data.code === 200 || data.code === "200") {
        toast.success(data.message);
        return data;
      } else {
        toast.error(data?.message);
      }
    } catch (e: any) {
      toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
    }
  }
};

export const apiCallDeleteImages = async (delImageList: number[]) => {
  for (const id of delImageList) {
    try {
      DELETE_IMAGE({ id });
    } catch (e: any) {}
  }
};

export const deleteOfferCriteria = async (
  uid: string | number,
  offerCriteriaList: TOfferCriteria[],
  setOfferCriteriaList: (value: TOfferCriteria[]) => void
) => {
  // const findCriteria = offerCriteriaList.find(
  //   (criteria) => criteria.uid == uid && criteria.id != 0
  // );
  // if (findCriteria && findCriteria.id) {
  //   const data = await apiCallDeleteOfferCriteria(
  //     findCriteria.id,
  //     criteriaType.Offer
  //   );
  //   if (!data) {
  //     return;
  //   }
  // }
  let templist: TOfferCriteria[] = [];
  for (const offerCriteria of offerCriteriaList) {
    if (offerCriteria.uid !== uid) templist.push(offerCriteria);
  }
  setOfferCriteriaList(templist);
  // toast.success("Offer discout criteria deleted successfully");
};

export const deletePostCriteria = async (
  id_offer_bucket: number | string,
  postCriteriaList: TPostCriteria[],
  setPostCriteriaList: (value: TPostCriteria[]) => void
) => {
  // const findCriteria = postCriteriaList.find(
  //   (criteria) => criteria.uid == uid && criteria.id != 0
  // );
  // if (findCriteria && findCriteria.id) {
  //   const data = await apiCallDeleteOfferCriteria(
  //     findCriteria.id,
  //     criteriaType.Post
  //   );
  //   if (!data) return;
  // }
  let templist: TPostCriteria[] = [];
  for (const postCriteria of postCriteriaList) {
    if (postCriteria.id_offer_bucket !== id_offer_bucket)
      templist.push(postCriteria);
  }
  setPostCriteriaList(templist);
  // toast.success("Offer post discout criteria deleted successfully");
};

export const deleteStoryCriteria = async (
  id_offer_bucket: number | string,
  storyCriteriaList: TStoryCriteria[],
  setStoryCriteriaList: (value: TStoryCriteria[]) => void
) => {
  // const findCriteria = storyCriteriaList.find(
  //   (criteria) => criteria.uid == uid && criteria.id != 0
  // );
  // if (findCriteria && findCriteria.id) {
  //   const data = await apiCallDeleteOfferCriteria(
  //     findCriteria.id,
  //     criteriaType.Story
  //   );
  //   if (!data) return;
  // }
  let templist: TStoryCriteria[] = [];
  for (const storyCriteria of storyCriteriaList) {
    if (storyCriteria.id_offer_bucket !== id_offer_bucket)
      templist.push(storyCriteria);
  }
  setStoryCriteriaList(templist);
  // toast.success("Offer story discout criteria deleted successfully");
};

export const apiCallDeleteOfferCriteria = async (id: number, type: number) => {
  try {
    const payload: TApiDeleteOfferCriteria = { id, type };
    const data = await DELETE_OFFER_CRITERIA(payload);
    if (data.code === 200 || data.code === "200") {
      return data;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
  return null;
};

export const apiCallAddImage = (
  acceptedFile: any,
  vendor_id: number | null,
  isBase64: boolean = false
) => {
  return new Promise(async (resolve, reject) => {
    if (isBase64) {
      var block = acceptedFile.split(";");
      var contentType = block[0].split(":")[1];
      var realData = block[1].split(",")[1];
      acceptedFile = base64toBlob(realData, contentType);
    }
    let formData = new FormData();
    formData.append("image", acceptedFile);
    formData.append("vendorId", vendor_id ? vendor_id.toString() : "");
    formData.append("ftype", ImageFileType.OfferImage.toString());
    try {
      const data = await ADD_IMAGE(formData);
      if (data.code === 200 || data.code === "200") {
        resolve(data);
      } else {
        reject(data);
      }
    } catch (e: any) {
      reject(e);
    }
    reject(null);
  });
};

export const apiCallUpdateOfferEndDate = async (
  id: number,
  fieldValues: any,
  imageId: number | null,
  selectedImage: TSelectedImage,
  tagList: string[],
  id_vendor: number,
) => {
  try {
    if (selectedImage && selectedImage.file) {
      const resAI: any = await apiCallAddImage(
        selectedImage.file,
        id_vendor,
        selectedImage.isBase64
      );
      if (!resAI) {
        toast.error(appErrors.UNKNOWN_ERROR_TRY_AGAIN);
        return;
      }
      if (imageId) {
        apiCallDeleteImages([imageId]);
      }
      imageId = resAI?.data?.id;
    }

    const payload: TApiUpdateOfferEndDate = {
      id,
      offer_title: fieldValues.offer_title,
      offer_description: fieldValues.offer_description,
      end_date: fieldValues.end_date,
      post_duration: fieldValues.post_duration,
      ecommerce_site_address: fieldValues.ecommerce_site_address,
      id_offer_category: fieldValues.id_offer_category,
      tags: tagList.join("|"),
      post_content: fieldValues.post_content,
      picture_should_contains: fieldValues.picture_should_contains,
      id_image: imageId,
    };
    const data = await UPDATE_OFFER_END_DATE(payload);
    if (data.code === 200 || data.code === "200") {
      toast.success(data.message);
      return data;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
};

export const fetchBrandById = async (id: number) => {
  try {
    const data = await ADMIN_FETCH_BRAND(id);
    if (data.code === 200 || data.code === "200") {
      return data.data;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
};

export const fetchBrandImage = async (imagePath: string) => {
  try {
    const blob = await GET_IMAGE(imagePath);
    const b64encoded = base64ArrayBuffer(blob);
    let mimetype = "image/jpeg";
    return "data:" + mimetype + ";base64," + b64encoded;
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
};
