import { Routes, Route } from "react-router-dom";
import AdminLayout from "../layouts/AdminLayout";
import history from "../history";
import HistoryRouter from "./HistoryRouter";
import AllUserList from "./../views/admin/users/index";
import Dashboard from "./../views/admin/dashboard/index";
import VendorRegisterContainer from "../containers/VendorRegisterContainer";
import VendorList from "./../views/admin/vendor/index";
import MerchantList from "./../views/admin/merchant/index";
import InfluencerList from "./../views/admin/influencer/index";
import HomePage from "../views/public/home";
import LoginContainer from "../containers/LoginContainer";
import AddEditOfferContainer from "../containers/offer/AddEditOfferContainer";
import VendorLayout from "../layouts/VendorLayout";
import OfferListContainer from "../containers/offer/OfferListContainer";
import BrandListContainer from "../containers/brand/BrandListContainer";
import TransactionList from "../views/admin/transactions/index";
import VerifyAppUserContainer from "../containers/VerifyAppUserContainer";
import RecoverPasswordView from "../views/public/recover-password/RecoverPasswordView";
import VendorProfileContainer from "../containers/profile/VendorProfileContainer";
import CardListView from "../views/admin/card/CardListView";
import BrandMerchantConfigurationContainer from "../containers/merchant/BrandMerchantConfigurationContainer";
import PreApprovedUserList from "../views/admin/pre-approved-user/index";
import FeedbackList from "../views/admin/feedback/index";
import OfferTransaction from "../containers/offer-transaction/OfferTransactionContainer";
import Usermanage from "../views/admin/user-manage/Usermanage";
import TripsDetails from "../views/admin/tripsDetails/TripsDetails";
import Popularstations from "../views/admin/Popular-stations/Popularstations";
import Preferences from "../views/admin/Default-Preferences/Preferences";
import  Notifications  from "../views/admin/Notifications/Notifications";
import Configuration from "../views/admin/config/Configuration";
import Trips from "../views/admin/tripsDetails/Trips";
import  StaticPages  from "../views/admin/Static_Pages/StaticPages";
import AddPage from "../views/admin/Static_Pages/AddPage";
import EditePage from "../views/admin/Static_Pages/EditPage";
const AppRouter = () => {
  return (
    <HistoryRouter history={history}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="accounts">
          <Route path="login" element={<LoginContainer />} />
          <Route path="register" element={<VendorRegisterContainer />} />
          <Route path="verify-otp/:id" element={<VerifyAppUserContainer />} />
          <Route path="profile" element={<div>User Profile</div>} />
          <Route path="recover-password" element={<RecoverPasswordView />} />
        </Route>
        <Route path="admin" element={<AdminLayout />}>
          <Route path="board" element={<Dashboard />} />
          <Route path="users" element={<AllUserList />} />
          <Route path="usermanage" element={<Usermanage />} />
          <Route path="trips" element={<TripsDetails />} />
          <Route path="trip-details/:id" element={<Trips />} />
          <Route path="popularstation" element={<Popularstations />} />
          <Route path="Default-Preferences" element={<Preferences />} />
          <Route path="notification" element={<Notifications />} />
          <Route path="configuration" element={<Configuration />} />
          <Route path="StaticPages" element={<StaticPages />} />
          <Route path="AddPages" element={<AddPage/>} />
          <Route path="EditPage/:id" element={<EditePage />} />
          <Route path="vendors" element={<VendorList />} />
          <Route path="brands" element={<BrandListContainer />} />
          <Route
            path="brand-merchant-configuration/:id"
            element={<BrandMerchantConfigurationContainer />}
          />
          <Route path="transactions" element={<TransactionList />} />
          <Route path="merchants" element={<MerchantList />} />
          <Route path="vendor-brands/:id" element={<BrandListContainer />} />
          <Route
            path="brand-offers"
            element={<div>List of Brand Offers</div>}
          />
          <Route
            path="payment-due"
            element={
              <div>
                Total cashback each brand has to pay and has paid History
              </div>
            }
          />
          <Route path="settings" element={<div>System Settings</div>} />
          <Route path="erros" element={<div>List of Erros</div>} />
          <Route path="influencers" element={<InfluencerList />} />
          <Route path="vendor-offers" element={<OfferListContainer />} />
          <Route path="add-offer" element={<AddEditOfferContainer />} />
          <Route path="update-offer/:id" element={<AddEditOfferContainer />} />
          <Route path="view-offer/:id" element={<AddEditOfferContainer />} />
          <Route path="brand-posts" element={<div>List of Brand Posts</div>} />
          <Route
            path="transactions"
            element={<div>List of Transactions</div>}
          />
          <Route path="cards" element={<CardListView />} />
          <Route path="pre-approved-user" element={<PreApprovedUserList />} />
          <Route path="feedback" element={<FeedbackList />} />
          <Route path="offer-transactions" element={<OfferTransaction />} />
        </Route>
        <Route path="vendor" element={<VendorLayout />}>
          <Route path="board" element={<div>Vendor dashboard</div>} />
          <Route path="profile" element={<VendorProfileContainer />} />
          <Route path="brands" element={<BrandListContainer />} />
          <Route path="brand-offers" element={<OfferListContainer />} />
          <Route path="add-offer" element={<AddEditOfferContainer />} />
          <Route path="update-offer/:id" element={<AddEditOfferContainer />} />
          <Route path="view-offer/:id" element={<AddEditOfferContainer />} />
          <Route
            path="brand-merchant-configuration/:id"
            element={<BrandMerchantConfigurationContainer />}
          />
          <Route
            path="payment-due"
            element={
              <div>Total amount due to be paid as cashback to users.</div>
            }
          />
          <Route
            path="transactions"
            element={<div>List of Transactions</div>}
          />
          <Route path="offer-transactions" element={<OfferTransaction />} />
        </Route>
        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>There's nothing here!</p>
            </main>
          }
        />
      </Routes>
    </HistoryRouter>
  );
};

export default AppRouter;
