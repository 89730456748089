import React from "react";
import { useSearchParams } from "react-router-dom";
import { Grid, Button, Typography } from "@mui/material";
import Textfield from "../../../components/FormsUI/Textfield";
import { Formik, Form } from "formik";
import {
  getInitialState,
  FORM_VALIDATION,
  apiCallRecoverPassword,
} from "./RecoverPassword";
import history from "../../../history";
import { publicRoutePath, appErrors } from "../../../AppConstants";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import { CAPTCHA_SITE_KEY } from "../../../AppConfig";

const RecoverPasswordView = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token");
  const recaptchaRef = React.useRef<ReCAPTCHA>(null);

  const handleChangePassword = (fieldValues: any) => {
    const recaptchaValue = recaptchaRef?.current?.getValue();
    if (recaptchaValue) {
      apiCallRecoverPassword(fieldValues.password, token)
    } else {
      toast.error(appErrors.CAPTCHA_VERIFICATION);
    }
  };

  return (
    <React.Fragment>
      <Grid container justifyContent="center" className="form-container">
        <Grid item xs={12} md={8} lg={6}>
          <div className="form-box">
            <Formik
              initialValues={getInitialState()}
              validateOnBlur={false}
              validationSchema={FORM_VALIDATION}
              onSubmit={async (fieldValues) =>
                handleChangePassword(fieldValues)
              }
            >
              <Form>
                <Grid container spacing={2} sx={{ p: 2 }}>
                  <Grid item xs={12}>
                    <Typography className="text-5">Recover Password</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Textfield
                      autoFocus
                      name="password"
                      label="Password"
                      type="password"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Textfield
                      name="confirm_password"
                      label="Confirm Password"
                      type="password"
                    />
                  </Grid>
                  <Grid item sm={12} className="display-flex justify-content-center">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={CAPTCHA_SITE_KEY}
                    />
                  </Grid>
                  <Grid container justifyContent="center" sx={{ pt: 3 }}>
                    <Button
                      className="margin-right-16 btn-primary"
                      type="submit"
                    >
                      SUBMIT
                    </Button>
                    <Button
                      className="btn-outline"
                      onClick={() => history.push(publicRoutePath.login)}
                    >
                      CANCEL
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </Formik>
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default RecoverPasswordView;
