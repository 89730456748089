import * as Yup from "yup";
import { TOfferBucket, TStoryCriteria } from "../../data/types/OfferState";

export const getInitialState = (
  storyCriteria: TStoryCriteria | null,
  bucketList: TOfferBucket[]
) => {
  let bucketId: string | number = "";
  if (storyCriteria && storyCriteria.id_offer_bucket) {
    const findBucket = bucketList.find(
      (bucket) => bucket.id === storyCriteria.id_offer_bucket
    );
    if (
      findBucket &&
      findBucket.min_score === storyCriteria.score_min_value &&
      findBucket.max_score === storyCriteria.score_max_value
    ) {
      bucketId = findBucket.id;
    }
  }
  return {
    // score_min_value: storyCriteria?.score_min_value || "",
    score_range: bucketId,
    story_discount: storyCriteria?.story_discount ? Number(storyCriteria.story_discount).toFixed(2) : "",
  };
};

export const FORM_VALIDATION = Yup.object().shape({
  // score_min_value: Yup.number()
  //   .max(1000000, "Insta score must be less than 1000000")
  //   .required("Insta score is required!"),
  score_range: Yup.number().required("Score range is required"),
  story_discount: Yup.number()
    .max(99999, "Story discount must be less than 99999")
    .required("Story discount is required"),
});
