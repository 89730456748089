import * as Yup from "yup";
import { FORGOT_PASSWORD } from "../../services/AppServices";
import { toast } from "react-toastify";
import { appErrors } from "../../AppConstants";
import { TForgotPassword } from "../../data/types/appUser";

export const getInitialState = () => {
  return { email_address: "" };
};

export const FORM_VALIDATION = Yup.object().shape({
  email_address: Yup.string()
    .required("Email address is required!")
    .email("Invalid email")
    .max(75, "Email must be 75 characters!"),
});

export const apiCallForgotPassword = async (
  fieldValues: any,
  closeModal: () => void
) => {
  const payload: TForgotPassword = {
    user_name: fieldValues.email_address,
  };
  try {
    const data = await FORGOT_PASSWORD(payload);
    console.log(data);
    if (data.status === 200 || data.status === "200") {
      toast.success(data.message);
      closeModal();
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
};
