import React from "react";
import moment from "moment";
import {
  apiCallDeleteUser,
  apiCallFetchAllUsers,
  apiCallApproveUser
} from "./UserList";
import usePaginationState from "../../../hooks/usePaginationState";
import TablePagination from "../../../components/common/TablePagination";
import {
  TextField,
  Table,
  TableBody,
  TableContainer,
  Paper,
  Grid,
  Select,
  MenuItem,
  IconButton,
  Switch,
  TableCell,
} from "@mui/material";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../components/common/TableCell";
import SortTableColumn from "../../../components/common/SortTableColumn";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { headCells, handleActiveDeactive } from "./UserList";
import {
  UserType,
  UserTypeLabel,
  UserStatusLabel,
  UserStatus,
  formatContactNumber,
  getUserStatusBgColorClass,
} from "../../../utils/sharedFunctions";
import useConfirmationModalState from "../../../hooks/useConfirmationModalState";
import useViewEditUserModalState from "../../../hooks/useViewEditUserModalState";
import ConfirmationModal from "../../../components/common/ConfirmationModal";
import ViewEditVendorModal from "../vendor/ViewEditVendorModal";
import ViewEditInfluencerModal from "../influencer/ViewEditInfluencerModal";
import {PER_PAGE_ROWS_OPTIONS} from "../../../AppConfig";

const AllUserList = () => {
  const [userList, setUserList] = React.useState([]);
  const { pagination, setPagination } = usePaginationState({
    searchFilter: "",
  });
  const [isLoading, setIsLoading] = React.useState(false);
  let timer: ReturnType<typeof setTimeout>;
  const { showCmfModal, setShowCmfModal, cmfModalDetails, setCmfModalDetails } =
    useConfirmationModalState();
  const [delUserType, setDelUserType] = React.useState<number>(0);

  const {
    showModal,
    setShowModalState,
    modalId,
    setModalIdState,
    modalFiledDisabled,
    setModalFiledDisabledState,
    vieEditUserType,
    setViewEditUserTypeState,
  } = useViewEditUserModalState();

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      setUserList([]);
      const data = await apiCallFetchAllUsers(pagination);
      console.log(data);
      if (data && data.rows && data.count) {
        setPagination({
          searchFilter: pagination.searchFilter,
          perPageRows: pagination.perPageRows,
          currentPage: pagination.currentPage,
          totalPages: 1,
          totalItems: data.count
            ? data.count
            : 0,
          orderBy: data.orderBy ? data.orderBy : "desc",
          sortBy: data.sortBy ? data.sortBy : "id",
        });
        setUserList(data.rows);
      }
    } catch (e) {}
    setIsLoading(false);
  };

  const handlePageChange = (page: number) => {
    pagination.currentPage = page;
    fetchData();
  };

  const handleRequestSort = (property: string) => {
    pagination.orderBy =
      pagination.sortBy == property
        ? pagination.orderBy == "asc"
          ? "desc"
          : "asc"
        : "asc";
    pagination.sortBy = property;
    fetchData();
  };

  const handleRowsPerPage = (rowCount: number) => {
    pagination.perPageRows = rowCount;
    pagination.currentPage = 1;
    fetchData();
  };

  const handleSearchChange = async (e: any) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(async () => {
      if (e.target.name == "searchFilter") {
        pagination.searchFilter = e.target.value;
        pagination.currentPage = 1;
        fetchData();
      }
    }, 500);
  };

  const handleShowDelModal = (id: number, name: string, type: number) => {
    setCmfModalDetails({
      id,
      title: "User Delete",
      message: `You are about to delete ${name}.`,
    });
    setDelUserType(type)
    setShowCmfModal(true);
  };

  const handleDelCloseModal = async (status: boolean) => {
    setShowCmfModal(false);
    if (status) {
      const resDelete = await apiCallDeleteUser(cmfModalDetails.id, delUserType);
      if (resDelete) fetchData();
    }
  };

  const handleCloseModal = (status: boolean) => {
    setShowModalState(false);
    if (status) {
      fetchData();
    }
  };

  const handleShowModal = (id: number, disabled: boolean, type: number) => {
    setModalIdState(id);
    setModalFiledDisabledState(disabled);
    setShowModalState(true);
    setViewEditUserTypeState(type);
  };

  return (
    <React.Fragment>
      <div className="page-header">
        <span className="page-title">User List</span>
      </div>
      <div>
        <Grid container spacing={2} className="headElements">
          <Grid item className="leftHead">
            <p className="selectPages">Show</p>
            <Select
              value={pagination.perPageRows}
              label="Page"
              className="dropDown"
              onChange={(e:any) => handleRowsPerPage(Number(e.target.value))}
            >
              {PER_PAGE_ROWS_OPTIONS.map((item: number) => <MenuItem value={item}>{item}</MenuItem>)}
            </Select>
            <span
              className="margin-left-16 btn-icon"
              title="Reload"
              onClick={() => fetchData()}
            >
              <RefreshIcon fontSize="large" className="color-light-blue" />
            </span>
          </Grid>

          <Grid item className="rightHead">
            <p className="selectPages">Search</p>
            <TextField
              size={"small"}
              className="searchField"
              id="outlined-basic"
              name="searchFilter"
              placeholder="Search Users"
              onChange={(e: any) => handleSearchChange(e)}
            />
          </Grid>
        </Grid>
        <TableContainer component={Paper} className="tableContainer">
          <Table stickyHeader className="table" aria-label="simple table">
            <SortTableColumn
              order={pagination.orderBy}
              orderBy={pagination.sortBy}
              headCells={headCells}
              handleRequestSort={handleRequestSort}
            />

            <TableBody>
              {isLoading ? (
                <TableCell colSpan={7}>
                  <div className="loader-container">
                    <div className="loader"></div>
                  </div>
                </TableCell>
              ) : (
                userList.map((row: any, index) => (
                  <StyledTableRow
                    hover
                    key={index}
                    className="user-table-row-height"
                  >
                    <StyledTableCell className="table-cell-left-align">
                      <Grid item lg={10}>
                        {row.user_type == UserType.Influencer
                          ? formatContactNumber(row.user_name)
                          : row.user_name}
                      </Grid>
                    </StyledTableCell>
                    <StyledTableCell className="table-cell-left-align">
                      {row.first_name}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell-left-align">
                      {row.last_name}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell-left-align">
                      {row.user_type == UserType.Administrator
                        ? "Administrator"
                        : UserTypeLabel.get(row.user_type)}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell-center-align">
                      {row.approved_date
                        ? moment(row.approved_date).format("DD/MM/YYYY")
                        : ""}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell-center-align">
                      <span
                        className={`text-rounded-chip ${getUserStatusBgColorClass(
                          row.user_status,
                          row.is_active
                        )}`}
                      >
                        {row.user_status == UserStatus.Approved &&
                        row.is_active == "0"
                          ? "Inactive"
                          : UserStatusLabel.get(row.user_status)}
                      </span>
                    </StyledTableCell>
                    <StyledTableCell className="table-cell-left-align">
                      <Grid
                        container
                        className="actionGrid"
                        justifyContent="start"
                      >
                        {row.approved_date &&
                          row.user_type !== UserType.Administrator ? (
                            <React.Fragment>
                              <Grid>
                                <Switch
                                  color={
                                    row.is_active == "0" ? "error" : "success"
                                  }
                                  title="Active/Inactive"
                                  checked={row.is_active == "1"}
                                  onChange={() =>
                                    handleActiveDeactive(
                                      row.id,
                                      userList,
                                      setUserList
                                    )
                                  }
                                />
                              </Grid>
                              <Grid>
                                <IconButton
                                  title="Edit"
                                  onClick={() =>
                                    handleShowModal(
                                      row.id_user,
                                      false,
                                      row.user_type
                                    )
                                  }
                                >
                                  <EditIcon />
                                </IconButton>
                              </Grid>
                              <Grid>
                                <IconButton
                                  title="Delete"
                                  onClick={() =>
                                    handleShowDelModal(row.id_user, row.first_name + " " + row.last_name, row.user_type)
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Grid>
                            </React.Fragment>
                          ) : row.user_status == UserStatus.PendingApproval && row.user_type !== UserType.Administrator && (
                            <Grid>
                              <IconButton
                                title="Approve"
                                onClick={() => apiCallApproveUser(userList, setUserList, row.id)}
                              >
                                <CheckCircleIcon color="success" />
                              </IconButton>
                            </Grid>
                          )}
                      </Grid>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          currentPage={pagination.currentPage}
          perPageRows={pagination.perPageRows}
          totalItems={pagination.totalItems}
          totalPages={pagination.totalPages}
          handleOnChangePage={handlePageChange}
        />
      </div>
      {showModal &&
        (vieEditUserType == UserType.Vendor ? (
          <ViewEditVendorModal
            id={modalId}
            closeModal={handleCloseModal}
            disabled={modalFiledDisabled}
          />
        ) : (
          <ViewEditInfluencerModal
            id={modalId}
            closeModal={handleCloseModal}
            disabled={modalFiledDisabled}
          />
        ))}
      {showCmfModal && (
        <ConfirmationModal
          closeModal={handleDelCloseModal}
          title={cmfModalDetails.title}
          message={cmfModalDetails.message}
        />
      )}
    </React.Fragment>
  );
};

export default AllUserList;
