import { connect } from "react-redux";
import OfferList from "../../views/admin/vendorOffer/index";
import { TReduxDispatch, TReduxState } from "../../store/store";

const mapStateToProps = (state: TReduxState) => ({
  loginInfo: state.loginInfo.loginInfo,
});

const mapDispatchToProps = (dispatch: TReduxDispatch) => ({});

const OfferListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(OfferList);
export default OfferListContainer;
