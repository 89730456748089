import React from "react";
import { Grid, Button, Typography } from "@mui/material";
import Textfield from "../../../components/FormsUI/Textfield";
import { Formik, Form } from "formik";
import {
  INITIAL_FORM_STATE,
  FORM_VALIDATION,
  vendorRegister,
} from "./VendorRegister";
import history from "../../../history";
import PhoneInputMaskField from "../../../components/FormsUI/PhoneInputMaskField";
import { UserType } from "../../../utils/sharedFunctions";
import {
  adminRoutePath,
  publicRoutePath,
  appErrors,
} from "../../../AppConstants";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import { CAPTCHA_SITE_KEY } from "../../../AppConfig";

const VendorRegisterForm = (props: any) => {
  const recaptchaRef = React.useRef<ReCAPTCHA>(null);

  const apiCallVendorRegister = async (fieldValues: any, actions: any) => {
    const recaptchaValue = recaptchaRef?.current?.getValue();
    if (recaptchaValue) {
      const data = await vendorRegister(fieldValues);
      if (data) {
        actions.resetForm();
      }
    } else {
      toast.error(appErrors.CAPTCHA_VERIFICATION);
    }
  };

  const handleCancelNavigation = () => {
    if (props?.loginInfo?.user_type === UserType.Administrator) {
      history.push(adminRoutePath.vendors);
    } else {
      history.push(publicRoutePath.login);
    }
  };

  return (
    <React.Fragment>
      <Grid container justifyContent="center" className="form-container">
        <Grid item xs={12} md={8} lg={6}>
          <div className="form-box">
            <Formik
              initialValues={INITIAL_FORM_STATE}
              validateOnBlur={false}
              validationSchema={FORM_VALIDATION}
              onSubmit={async (fieldValues, actions) =>
                apiCallVendorRegister(fieldValues, actions)
              }
            >
              <Form>
                <Grid container spacing={2} sx={{ p: 2 }}>
                  <Grid item xs={12}>
                    <Typography className="text-5">
                      WYLD Registration
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Textfield name="vendor_name" label="Vendor Name" />
                  </Grid>
                  <Grid item>
                    <fieldset className="primary-fieldset">
                      <legend>
                        <span className="bold-text-3">Primary Details</span>
                      </legend>
                      <Grid container spacing={2} sx={{ pt: 2, pb: 1 }}>
                        <Grid item xs={12} sm={6}>
                          <Textfield
                            name="first_name"
                            label="Contact First Name"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Textfield
                            name="last_name"
                            label="Contact Last Name"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <PhoneInputMaskField
                            name="primary_contact_number"
                            label="Contact Number"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <PhoneInputMaskField
                            name="emergency_contact_number"
                            label="Emergency Contact Number"
                          />
                        </Grid>
                      </Grid>
                    </fieldset>
                  </Grid>
                  <Grid item xs={12} className="padding-top-24">
                    <Textfield name="email_address" label="Email Address" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Textfield
                      name="password"
                      label="Password"
                      type="password"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Textfield
                      name="confirm_password"
                      label="Confirm Password"
                      type="password"
                    />
                  </Grid>
                  <Grid item sm={12} className="display-flex justify-content-center">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={CAPTCHA_SITE_KEY}
                    />
                  </Grid>
                  <Grid container justifyContent="center" sx={{ pt: 3 }}>
                    <Button
                      className="margin-right-16"
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      REGISTER
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleCancelNavigation()}
                    >
                      CANCEL
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </Formik>
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default VendorRegisterForm;
