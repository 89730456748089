import React from "react"
import { Grid } from "@mui/material"
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import ScaleIcon from '@mui/icons-material/Scale';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import AdjustIcon from '@mui/icons-material/Adjust';
import {List,ListItem,ListItemText,ListItemAvatar} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import AlbumIcon from '@mui/icons-material/Album';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { apiCallFetchTripDetails1 } from "./ApiTrips";
import moment from "moment";
import { useParams } from "react-router-dom";
import  { useEffect } from "react";
import { GOOGLE_MAPS_API_KEY } from '../../../AppConfig';
// import Timeline from '@mui/lab/Timeline';

const Trips = () =>
{
    const [tripDetais, setTripDetais] = React.useState({start_location: "", end_location: "", start_time: '', end_time: "",trip_status:0,
    miles:0,tolls:0,fuel:0,time_duration:0,tripmap_img:""});
  
    const params = useParams()
   
    
  const [isLoading, setIsLoading] = React.useState(false);
   React.useEffect(()=>{
    fetchData(params.id);
   },[])   

    const fetchData = async (id:any) =>{
          setIsLoading(true);
          try{
            console.log("asasas")
            const data = await apiCallFetchTripDetails1(id);
            
            console.log(data);
           
            if(data){
              
              setTripDetais(data);
              
            }
          }catch (e) {}
          //setIsLoading(false);
    }
    const stutesHandlar = (row: any) =>{
        if(row.trip_status === 1){
          return(
            <span className="status0">Schedule</span>
          )
        }else if (row.trip_status === 2){
          return(
            <span className="status2">Running</span>
          )
        }else if (row.trip_status === 3){
          return( 
          <span className="status3">Running But Stop</span>
          )
        }else if (row.trip_status === 4){
          return( 
          <span className="status1">completed</span>
          )
        }
      } 
                                    
    return(
        <React.Fragment>
            <div>
            <div className="page-header">
                    <span className="page-title">Trips Details</span>
                </div>
                <Grid container spacing={3} className="headElements">
                    <Grid md={7} xs={12} >
                       <div className="img-map">
                       <img src={tripDetais.tripmap_img} />
                       </div>
                       
                       
                        {/* <MapWithAMarker
                            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBEec7JpxpejPA3Y9ZTZoyWI2Fo31pfjX4&v=3.exp&libraries=geometry,drawing,places"
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={<div style={{ height: `400px` }} />}
                            mapElement={<div style={{ height: `100%` }} />}
                            
                        /> */}
                    </Grid>
                    <Grid md={4} xs={12}>
                        <h2>Stops Details</h2>
                        <List>
                            <ListItem>
                                <ListItemAvatar>
                                    
                                        <AlbumIcon color="primary"/>
                                    
                                </ListItemAvatar>
                                    <ListItemText primary="RaRa Trucking inc" />
                                    <ListItemText primary="" />
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    
                                        <LocalParkingIcon color="primary" />
                                    
                                </ListItemAvatar>
                                    <ListItemText primary="RaRa Trucking inc" />
                                    <ListItemText primary="" />
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    
                                        <ScaleIcon color="primary"/>
                                    
                                </ListItemAvatar>
                                    <ListItemText primary="RaRa Trucking inc" />
                                    <ListItemText primary="" />
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    
                                        <LocalGasStationIcon color="primary" />
                                    
                                </ListItemAvatar>
                                    <ListItemText primary="RaRa Trucking inc" />
                                    <ListItemText primary="" />
                            </ListItem>
                            <h2>Status</h2>
                            <ListItem>
                                <ListItemAvatar>
                                    
                                        <AdjustIcon color="primary" />
                                    
                                </ListItemAvatar>
                                    <ListItemText primary={stutesHandlar(tripDetais)}/>
                                    
                            </ListItem>

                        </List>
              
                    </Grid>
                </Grid>
                <hr />
                <Grid container spacing={3} className="headElements">
                    <Grid md={6} xs={12}>
                        <h1 className="header1">Location</h1>
                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <AlbumIcon style={{color:"blue"}}/>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={tripDetais.start_location} secondary={tripDetais.start_time=moment(new Date(tripDetais.start_time)).format('YYYY-MM-DD')} />
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <FmdGoodIcon style={{color:"red"}} />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={tripDetais.end_location} secondary={tripDetais.end_time=moment(new Date(tripDetais.end_time)).format('YYYY-MM-DD')} />
                            </ListItem>
                            
                        </List>
                    </Grid>
                    <Grid md={6} xs={12}>
                        <h2 className="header1"> Details</h2>
                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                            <ListItem>
                                 <ListItemText>MILES:</ListItemText>
                                <ListItemText primary={tripDetais.miles} />
                            </ListItem>
                            <ListItem>
                                <ListItemText>TOLLS:</ListItemText>
                                <ListItemText primary={tripDetais.tolls}  />
                            </ListItem>
                            <ListItem>
                                <ListItemText>FULE:</ListItemText>
                                <ListItemText primary={tripDetais.fuel}  />
                            </ListItem>
                            <ListItem>
                                <ListItemText>TIME:</ListItemText>
                                <ListItemText primary={tripDetais.time_duration}  />
                            </ListItem>
                            
                        </List>
                    </Grid>
                </Grid>    
            </div>
        </React.Fragment>
    )
}
export default Trips