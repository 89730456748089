import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Formik, Form } from "formik";
import Textfield from "../../../components/FormsUI/Textfield";
import { INITIAL_FORM_STATE, FORM_VALIDATION} from "./ApiStaticpage";
import { Grid,Button} from "@mui/material";
import { NavLink } from "react-router-dom";
// import { apiAddStaticPage } from './ApiStaticpage';
import { useNavigate } from "react-router-dom";
import { TStaticPage } from "../../../data/types/adminApi";
import { ADD_STATICPAGES} from "../../../services/AdminServices";
import { toast } from "react-toastify";
import { appErrors } from "../../../AppConstants";
const AddPage = () =>{
    const navigate = useNavigate()
    const apiAddStaticPage = async (props: any) =>{

        const payload: TStaticPage = {
            id:props.id,
            page_name: props.page_name,
            contents: props.contents,
        };
        try {
          const data = await ADD_STATICPAGES(payload);
          console.log(data);
          if (data.code === 200 || data.code === "200") {
           
           return true
          } else {
            toast.success("Data Add successfully...");
            navigate("/admin/StaticPages")
          }
        } catch (e: any) {
          toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
        }
        return false;
      
    }
    return (
        <>
        {/* https://codeat21.com/react-wysiwyg-text-editor/ */}
        {/* https://stackoverflow.com/questions/70214394/form-validation-react-function-component-with-usestate */}

            <div className="page-header">
                <span className="page-title"> Add page </span>
            </div>  
            <Formik
              initialValues={{...INITIAL_FORM_STATE} }
              validateOnBlur={false}
              validationSchema={FORM_VALIDATION}
              onSubmit={async (fieldValues, { resetForm }) => {
                apiAddStaticPage(fieldValues);
                resetForm();
              }}
            >
              <Form>
            <Grid container spacing={3} className="headElements">
                <Grid item xs={12}>
                    <Textfield
                        autoFocus
                        fullWidth
                        name="page_name"
                        label="Title"
                        type={"text"}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Textfield
                        name="contents"
                        label="content"
                        fullWidth
                        type={"text"}
                         multiline
                        rows={10}
                    />
                            {/* <Editor
                     toolbarClassName="toolbarClassName"
                     wrapperClassName="wrapperClassName"
                     editorClassName="editorClassName" 
                     /> */}

                        </Grid>
                <Grid item xs={12}>
                    
                    <Button
                        type="submit"
                        color="primary"
                        className="btn btn-primary"
                        style={{ width: 200 }}>Submit</Button>
                </Grid>
                <Grid item xs={12}>
                    <NavLink to="/admin/StaticPages" style={{ textDecoration: 'none' }}>
                        <Button
                            type="button"
                            color="primary"
                            className="btn btn-primary"
                            style={{ width: 200 }}>Cancel</Button>
                    </NavLink>
                </Grid>

            </Grid>
            </Form>
            </Formik>
        </>
    )
}
export default AddPage;