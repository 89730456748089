import * as Yup from "yup";
import { toast } from "react-toastify";
import { appErrors } from "../../../AppConstants";
import {
  ADMIN_FETCH_MERCHANT,
  ADMIN_UPDATE_MERCHANT,
  ADMIN_ADD_MERCHANT
} from "../../../services/AppServices";
import { TAdminUpdateMerchant ,TAdminAddMerchant} from "../../../data/types/adminApi";
import { formatPhoneMaskNumber } from "../../../utils/sharedFunctions";

export const FORM_VALIDATION = Yup.object().shape({
  merchant_name: Yup.string()
    .required("Merchant name is required!")
    .max(50, "Merchant name must be less than 50 characters!"),
  mcc: Yup.string()
    .required("MCC is required!")
    .max(4, "MCC must be less than 4 characters!"),
  merchant_id: Yup.string()
    .required("MCC is required!")
    .max(30, "Merchant Id must be less than 30 characters!"),
});

export const getInitialState = (merchant: any) => {
  return {
    merchant_name: merchant?.merchant_name || "",
    mcc: merchant?.mcc || "",
    merchant_id: merchant?.merchant_id || "",
  };
};

export const fetchMerchantById = async (id: number) => {
  try {
    const data = await ADMIN_FETCH_MERCHANT(id);
    if (data.code === 200 || data.code === "200") {
      return data.data;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
};

export const addMerchant = async (props: any) => {
  const payload: TAdminAddMerchant = {
    merchant_name: props.merchant_name,
    mcc: props.mcc,
    merchant_id: props.merchant_id,
  };
  try {
    const data = await ADMIN_ADD_MERCHANT(payload);
    if (data.code === 200 || data.code === "200") {
      toast.success(data?.message);
      return true;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
  return false;
};

export const updateMerchant = async (props: any) => {
  const payload: TAdminUpdateMerchant = {
    id: props.id,
    merchant_name: props.merchant_name,
    mcc: props.mcc,
    merchant_id: props.merchant_id,
  };
  
  try {
    const data = await ADMIN_UPDATE_MERCHANT(payload);
    if (data.code === 200 || data.code === "200") {
      return true;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
  return false;
};
