import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Home,
  Group,
  RadioButtonChecked,
  RadioButtonUnchecked,
  Close,
  CreditCard,
} from "@mui/icons-material";
import DescriptionIcon from '@mui/icons-material/Description';
import PinDropIcon from '@mui/icons-material/PinDrop';
// import PaidIcon from '@mui/icons-material/Paid';
import TimeToLeaveIcon from '@mui/icons-material/TimeToLeave';
import history from "../../../history";
import { adminRoutePath } from "../../../AppConstants";
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import Logo from "../../../assets/images/logo.png";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
const VerticalHeader = (props: any) => {
  const location = useLocation();
  const hasWindow = typeof window !== "undefined";

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions()
  );

  useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [hasWindow]);

  const handleNavigation = (path: string) => {
    if (props.isNavExpanded) {
      if (windowDimensions && windowDimensions.width) {
        if (windowDimensions.width < 1200) props.toggleExpanded();
      } else {
        props.toggleExpanded();
      }
    }
    history.push(path);
  };

  return (
    <React.Fragment>
      <div
        className={`vertical-header ${
          props.isNavExpanded
            ? "vertical-header-expanded"
            : "vertical-header-collapsed"
        } card-box-shadow`}
      >
        <div className="logo-container">
          <div className="logo-component">
            <img className="logo-size" src={Logo} />
          </div>
          <span
            className="btn-nav-expanded"
            onClick={() => props.toggleExpanded()}
          >
            {props.isNavExpanded ? (
              <RadioButtonChecked />
            ) : (
              <RadioButtonUnchecked />
            )}
          </span>
          <span className="btn-nav-show" onClick={() => props.toggleExpanded()}>
            <Close />
          </span>
        </div>
        <ul className="nav-list">
          <li
            className={`${
              location.pathname === adminRoutePath.dashboard ? "active" : ""
            } nav-item`}
            onClick={() => handleNavigation(adminRoutePath.dashboard)}
          >
            <span className="nav-icon">
              <Home />
            </span>
            <span className="nav-title">Dashboard</span>
          </li>
          
          <li
            className={`${
              location.pathname === adminRoutePath.usermanage ? "active" : ""
            } nav-item`}
            onClick={() => handleNavigation(adminRoutePath.usermanage)}
          >
            <span className="nav-icon">
              <Group />
            </span>
            <span className="nav-title">Users Manage</span>
          </li>
          <li
            className={`${
              location.pathname === adminRoutePath.alltrips ? "active" : ""
            } nav-item`}
            onClick={() => handleNavigation(adminRoutePath.alltrips)}
          >
            <span className="nav-icon">
              <TimeToLeaveIcon />
            </span>
            <span className="nav-title">All Trips list</span>
          </li>
       

          <li
            className={`${
              location.pathname === adminRoutePath.popularstation ? "active" : ""
            } nav-item`}
            onClick={() => handleNavigation(adminRoutePath.popularstation)}
          >
            <span className="nav-icon">
              <PinDropIcon />
            </span>
            <span className="nav-title">Popular Station</span>
          </li>
          <li
            className={`${
              location.pathname === adminRoutePath.Preferences ? "active" : ""
            } nav-item`}
            onClick={() => handleNavigation(adminRoutePath.Preferences)}
          >
            <span className="nav-icon">
              <TimeToLeaveIcon />
            </span>
            <span className="nav-title">Default Preferences</span>
          </li>
          <li
            className={`${
              location.pathname === adminRoutePath.notification ? "active" : ""
            } nav-item`}
            onClick={() => handleNavigation(adminRoutePath.notification)}
          >
            <span className="nav-icon">
              <NotificationsActiveIcon />
            </span>
            <span className="nav-title">Notification</span>
          </li>
          {/* <li
            className={`${
              location.pathname === adminRoutePath.configuration ? "active" : ""
            } nav-item`}
            onClick={() => handleNavigation(adminRoutePath.configuration)}
          >
            <span className="nav-icon">
              <MiscellaneousServicesIcon />
            </span>
            <span className="nav-title">Configuration</span>
          </li> */}
          <li
            className={`${
              location.pathname === adminRoutePath.StaticPages ? "active" : ""
            } nav-item`}
            onClick={() => handleNavigation(adminRoutePath.StaticPages)}
          >
            <span className="nav-icon">
              <DescriptionIcon />
            </span>
            <span className="nav-title">Static Pages</span>
          </li>
          {/* <li
            className={`${
              location.pathname === adminRoutePath.influencers ? "active" : ""
            } nav-item`}
            onClick={() => handleNavigation(adminRoutePath.influencers)}
          >
            <span className="nav-icon">
              <Instagram />
            </span>
            <span className="nav-title">Influencers</span>
          </li>
          <li
            className={`${
              location.pathname === adminRoutePath.vendorOffers ? "active" : ""
            } nav-item`}
            onClick={() => handleNavigation(adminRoutePath.vendorOffers)}
          >
            <span className="nav-icon">
              <LocalOffer />
            </span>
            <span className="nav-title">Vendor Offers</span>
          </li>
          <li
            className={`${
              location.pathname === adminRoutePath.cards ? "active" : ""
            } nav-item`}
            onClick={() => handleNavigation(adminRoutePath.cards)}
          >
            <span className="nav-icon">
              <CreditCard />
            </span>
            <span className="nav-title">Cards</span>
          </li>
          <li
            className={`${
              location.pathname === adminRoutePath.transactions ? "active" : ""
            } nav-item`}
            onClick={() => handleNavigation(adminRoutePath.transactions)}
          >
            <span className="nav-icon">
              <Paid />
            </span>
            <span className="nav-title">Transactions</span>
          </li>
          <li
            className={`${
              location.pathname === adminRoutePath.merchants ? "active" : ""
            } nav-item`}
            onClick={() => handleNavigation(adminRoutePath.merchants)}
          >
            <span className="nav-icon">
              <BusinessCenter />
            </span>
            <span className="nav-title">Merchants</span>
          </li>
          <li
            className={`${
              location.pathname === adminRoutePath.preApprovedUser
                ? "active"
                : ""
            } nav-item`}
            onClick={() => handleNavigation(adminRoutePath.preApprovedUser)}
          >
            <span className="nav-icon">
              <VerifiedUser />
            </span>
            <span className="nav-title">Pre Approved User</span>
          </li>
          <li
            className={`${
              location.pathname === adminRoutePath.feedback
                ? "active"
                : ""
            } nav-item`}
            onClick={() => handleNavigation(adminRoutePath.feedback)}
          >
            <span className="nav-icon">
              <Feedback />
            </span>
            <span className="nav-title">Feedback</span>
          </li> */}
          
        </ul>
      </div>
    </React.Fragment>
  );
};

export default VerticalHeader;
