import publicIp from "public-ip";
import {
  CRYPTO_JS_KEY,
  CRYPTO_JS_IV,
  ALLOWED_IMAGE_TYPES,
  PRODUCT_IMAGE_ALLOWED_SIZE_KB,
  PROFILE_IMAGE_ALLOWED_SIZE_KB,
} from "../AppConfig";
import moment from "moment";

const CryptoJS = require("crypto-js");

export const RenderUserStatus = (pUserStatus: number) => {
  switch (pUserStatus) {
    case 0:
      return "Verification Pending";
    case 1:
      return "Approval Pending";
    case 2:
      return "Approved";
    case 2:
      return "Re-Verification Pending";
    default:
      return "";
  }
};

export const generateRandomNumber = (max: number = 100, min: number = 1) => {
  return Math.floor(Math.random() * max + min);
};

export var GenerateRandomString = (stringLength: number) => {
  var charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
    retVal = "";
  for (var i = 0, n = charset.length; i < stringLength; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
};

export function generateGUID() {
  const str = GenerateRandomString(32);
  const GUID =
    str.substring(0, 8) +
    "-" +
    str.substring(8, 12) +
    "-" +
    str.substring(12, 16) +
    "-" +
    str.substring(16, 20) +
    "-" +
    str.substring(20, 32);
  return GUID;
}

export function financial(x: string) {
  return Number.parseFloat(x).toFixed(2);
}

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const dateFormate = (item: string) => {
  const date = new Date(item);
  const month = months[date.getMonth()];
  let day: number;
  let dt: string;
  day = date.getDate();
  const year = date.getFullYear();
  if (day < 10) {
    dt = "0" + day;
  }
  return `${day} ${month} ${year}`;
};

export const getCurrentDate = () => {
  return new Date();
};

export const UserType = {
  Administrator: 1,
  Vendor: 2,
  Influencer: 3,
};

export const UserTypeLabel = new Map<number, string>([
  [UserType.Administrator, "System Administrator"],
  [UserType.Vendor, "Vendor"],
  [UserType.Influencer, "Influencer"],
]);

export enum UserStatus {
  PendingVerification = 0,
  PendingApproval = 1,
  Approved = 2,
  Blocked = 3,
  PendingReverification = 4,
  PendingKyc = 5,
}

export enum PostStatus {
  Pending = 1,
  Submitted = 2,
  Approved = 3,
  Rejected = 4,
  Resubmitted = 5,
}

export const getPostStatusLabel = new Map([
  [PostStatus.Pending, "Pending"],
  [PostStatus.Submitted, "Submitted"],
  [PostStatus.Approved, "Approved"],
  [PostStatus.Rejected, "Rejected"],
  [PostStatus.Resubmitted, "Resubmitted"],
]);

export const getPostStatusBgColorClass = (status: number) => {
  switch (status) {
    case PostStatus.Pending:
      return "bg-color-yellow";
    case PostStatus.Submitted:
      return "bg-color-dark-blue";
    case PostStatus.Approved:
      return "bg-color-success";
    case PostStatus.Rejected:
      return "bg-color-danger";
    case PostStatus.Resubmitted:
      return "bg-color-dark-blue";
    default:
      return "bg-color-danger";
  }
};

export const UserStatusLabel = new Map<number, string>([
  [UserStatus.PendingVerification, "Pending Verification"],
  [UserStatus.PendingApproval, "Pending Approval"],
  [UserStatus.Approved, "Approved"],
  [UserStatus.Blocked, "Blocked"],
  [UserStatus.PendingReverification, "Pending Reverification"],
  [UserStatus.PendingKyc, "Pending MIN KYC"],
]);

export const getUserStatusBgColorClass = (status: number, isActive: string) => {
  if (status === UserStatus.Approved && isActive === "0") {
    return "bg-color-dark-grey";
  }
  switch (status) {
    case UserStatus.Approved:
      return "bg-color-success";
    case UserStatus.PendingApproval:
      return "bg-color-dark-blue";
    case UserStatus.PendingVerification:
      return "bg-color-yellow";
    case UserStatus.PendingKyc:
      return "bg-color-danger";
  }
  return "bg-color-danger";
};

export const getEncryptedText = (text: string) => {
  const encryptedData = CryptoJS.AES.encrypt(text, CRYPTO_JS_KEY, {
    iv: CRYPTO_JS_IV,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  }).toString();
  const encrypted = encryptedData.replaceAll("+", "%2B").replaceAll("/", "%2F");
  return encrypted;
};

export const getDecryptedText = (text: string) => {
  try {
    if (!text) return null;
    const textToDecrypt = text.replaceAll("%2B", "+").replaceAll("%2F", "/");
    const descryptedText = CryptoJS.AES.decrypt(textToDecrypt, CRYPTO_JS_KEY, {
      iv: CRYPTO_JS_IV,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    }).toString(CryptoJS.enc.Utf8);
    return descryptedText;
  } catch (e) {
    return null;
  }
};

export const getIpAddress = async () => {
  try {
    const ipv4 = await publicIp.v4();
    return ipv4;
  } catch (e) {}

  return null;
};

export const TransactionType = {
  Credit: 1,
  Debit: 2,
};

export const SystemConfigurationKeys = {
  TakeOffline: "TakeOffline",
};

export enum DiscountType {
  Flat = 1,
  Percentage = 2,
}

export enum DiscountIn {
  Coins = 1,
  Amount = 2,
}

export enum AvailCycle {
  Month = 1,
  Year = 2,
}

export enum criteriaType {
  Offer = 1,
  Post = 2,
  Story = 3,
}

export enum ImageFileType {
  VendorImage = 1,
  BrandImage = 2,
  ProductImage = 3,
  ProfileImage = 4,
  OfferImage = 5,
  InfluencerImage = 6,
}

export enum M2PCardType {
  Physical = 1,
  Virtual = 2,
}

export enum M2PCardLockFlag {
  Lock = 1,
  UnLock = 2,
  PermanentBlock = 3,
}

export const M2PCardLockFlagLabel = new Map<number, string>([
  [M2PCardLockFlag.Lock, "Lock"],
  [M2PCardLockFlag.UnLock, "Unlock"],
  [M2PCardLockFlag.PermanentBlock, "Block"],
]);

export enum FeedbackType {
  Feedback = 1,
  BrandSuggestion = 2,
}

export const feedbackTypeLabel = new Map<number, string>([
  [FeedbackType.Feedback, "Feedback"],
  [FeedbackType.BrandSuggestion, "Brand Suggestion"],
]);

export const formatContactNumber = (contactNumber: string) => {
  if (contactNumber[0] !== "+") {
    return (
      contactNumber.slice(0, 5) +
      "-" +
      contactNumber.slice(5, 7) +
      "-" +
      contactNumber.slice(7)
    );
  }

  return (
    "(" +
    contactNumber.slice(0, 3) +
    ") " +
    contactNumber.slice(3, 8) +
    "-" +
    contactNumber.slice(8, 10) +
    "-" +
    contactNumber.slice(10)
  );
};

export const formatPhoneMaskNumber = (contactNumber: string) => {
  return contactNumber
    .replaceAll("-", "")
    .replaceAll("(", "")
    .replaceAll(")", "")
    .replaceAll(" ", "");
};

export const checkIsValidImage = async (file: any, imageType: number) => {
  try {
    const data = await new Promise((resolve: any, reject: any) => {
      const image = new Image();
      image.addEventListener("load", () => {
        if (image.width !== image.height) {
          return resolve(false);
        }
        return resolve(true);
      });
      image.src = URL.createObjectURL(file);
    });
    if (!data) {
      return false;
    }
    let isValidImage = false;
    if (ImageFileType.ProfileImage == imageType) {
      if (file.size <= PROFILE_IMAGE_ALLOWED_SIZE_KB) {
        for (var i = 0; i < ALLOWED_IMAGE_TYPES.length; i++) {
          if (file.type == ALLOWED_IMAGE_TYPES[i]) {
            isValidImage = true;
          }
        }
      }
    } else {
      if (file.size <= PRODUCT_IMAGE_ALLOWED_SIZE_KB) {
        for (var i = 0; i < ALLOWED_IMAGE_TYPES.length; i++) {
          if (file.type == ALLOWED_IMAGE_TYPES[i]) {
            isValidImage = true;
          }
        }
      }
    }

    return isValidImage;
  } catch (e) {
    return false;
  }
};

export const base64ArrayBuffer = (arrayBuffer: any) => {
  var base64 = "";
  var encodings =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";

  var bytes = new Uint8Array(arrayBuffer);
  var byteLength = bytes.byteLength;
  var byteRemainder = byteLength % 3;
  var mainLength = byteLength - byteRemainder;

  var a, b, c, d;
  var chunk;

  // Main loop deals with bytes in chunks of 3
  for (var i = 0; i < mainLength; i = i + 3) {
    // Combine the three bytes into a single integer
    chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

    // Use bitmasks to extract 6-bit segments from the triplet
    a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
    b = (chunk & 258048) >> 12; // 258048   = (2^6 - 1) << 12
    c = (chunk & 4032) >> 6; // 4032     = (2^6 - 1) << 6
    d = chunk & 63; // 63       = 2^6 - 1

    // Convert the raw binary segments to the appropriate ASCII encoding
    base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
  }

  // Deal with the remaining bytes and padding
  if (byteRemainder == 1) {
    chunk = bytes[mainLength];

    a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2

    // Set the 4 least significant bits to zero
    b = (chunk & 3) << 4; // 3   = 2^2 - 1

    base64 += encodings[a] + encodings[b] + "==";
  } else if (byteRemainder == 2) {
    chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];

    a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
    b = (chunk & 1008) >> 4; // 1008  = (2^6 - 1) << 4

    // Set the 2 least significant bits to zero
    c = (chunk & 15) << 2; // 15    = 2^4 - 1

    base64 += encodings[a] + encodings[b] + encodings[c] + "=";
  }
  return base64;
};

export const base64toBlob = (b64Data: string, contentType: string) => {
  contentType = contentType || "";
  const sliceSize = 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const blobToBase64 = (blob: any) => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};
