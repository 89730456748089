import {DASHBOARD_LIST} from "../../../services/AdminServices"


import { toast } from "react-toastify";   
import { appErrors } from "../../../AppConstants";


export const apiCallDASHBOARDLIST = async () => {
    
    try {
      const data = await DASHBOARD_LIST();
      
      if (data.status === 200 || data.status === "200") {
        return data.data;
      } else {
        toast.error(data?.message);
      }
    } catch (e: any) {
      toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
    }
  };