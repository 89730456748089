import React from "react";
import { Button, Modal, Grid } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Form, Formik } from "formik";
import Textfield from "../FormsUI/Textfield";
import {
  getInitialState,
  FORM_VALIDATION,
  apiCallChangePassword,
} from "./ChangePassword";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import { appErrors } from "../../AppConstants";
import { CAPTCHA_SITE_KEY } from "../../AppConfig";

const ChangePasswordModal = (props: any) => {
  const recaptchaRef = React.useRef<ReCAPTCHA>(null);

  const handleChangePassword = (fieldValues: any) => {
    const recaptchaValue = recaptchaRef?.current?.getValue();
    if (recaptchaValue) {
      apiCallChangePassword(fieldValues, props.closeModal);
    } else {
      toast.error(appErrors.CAPTCHA_VERIFICATION);
    }
  };

  return (
    <React.Fragment>
      <Modal open={true}>
        <div className="center-modal-box">
          <div className="modal-header">
            <div className="modal-title">Change Password</div>
            <span
              className="modal-btn-close"
              onClick={() => props.closeModal()}
            >
              <Close />
            </span>
          </div>
          <hr />
          <Formik
            enableReinitialize
            initialValues={getInitialState()}
            validateOnBlur={false}
            validationSchema={FORM_VALIDATION}
            onSubmit={async (fieldValues) => {
              handleChangePassword(fieldValues);
            }}
          >
            <Form>
              <div className="modal-body">
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Textfield
                      autoFocus
                      name="oldpassword"
                      label="Old Password"
                      type="password"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Textfield
                      name="password"
                      label="New Password"
                      type="password"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Textfield
                      name="cfmPassword"
                      label="Confirm Password"
                      type="password"
                    />
                  </Grid>
                  <Grid item sm={12} className="display-flex justify-content-center">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={CAPTCHA_SITE_KEY}
                    />
                  </Grid>
                </Grid>
              </div>
              <div className="display-flex justify-content-center">
                <Button className="btn-primary margin-right-16" type="submit">
                  SAVE
                </Button>
                <Button
                  className="btn-outline"
                  onClick={() => props.closeModal()}
                >
                  CANCEL
                </Button>
              </div>
            </Form>
          </Formik>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default ChangePasswordModal;
