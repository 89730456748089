import { localStorageUtils } from "../../../utils/localStorageUtils";
import { getIpAddress } from "../../../utils/sharedFunctions";
import { USER_LOGOUT } from "../../../services/AppServices";
import { appErrors, publicRoutePath } from "../../../AppConstants";
import { toast } from "react-toastify";
import history from "../../../history";

export const logout = async (id_user: number) => {
  // try {
  //   let ip_address = await getIpAddress();
  //   if (ip_address == null) {
  //     toast.error(appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  //     return;
  //   }
  //   const payload = { id_user, ip_address };
  //   const data = await USER_LOGOUT(payload);
  //   if (data.code === 200 || data.code === "200") {
     
  //   } else {
  //     toast.error(data?.message);
  //   }
  // } catch (e: any) {
  //   toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  // }
  toast.success("See You Soon!");
  
  localStorageUtils.setUserSignedInStatus(false);
  localStorageUtils.setUserInfo(null);
  localStorageUtils.setAccessToken("");
  localStorageUtils.setUserType("");
  history.push(publicRoutePath.appEntryPoint);
  return true;
};
