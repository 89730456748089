import { TFETCHTRIPLIST } from "../../../data/types/adminApi";
import { FETCH_TRIP_LIST,FETCH_TRIPS_DETAILS } from "../../../services/AdminServices";
import { toast } from "react-toastify";   
import { appErrors } from "../../../AppConstants";


export const apiCallFETCHTRIPLIST = async (fieldValues: any) => {
    const payload: TFETCHTRIPLIST = {
      searchFilter: fieldValues?.searchFilter ? fieldValues.searchFilter : "",
      perPageRows: fieldValues?.perPageRows ? fieldValues.perPageRows : 10,
      currentPage: fieldValues?.currentPage ? fieldValues.currentPage : 1,
      startDate: fieldValues?.startDate ? fieldValues.startDate : "",
    endDate: fieldValues?.endDate ? fieldValues.endDate : "",
    };
  
    try {
      const data = await FETCH_TRIP_LIST(payload);
      console.log(data);
      if (data.status === 200 || data.status === "200") {
        return data.data;
      } else {
        toast.error(data?.message);
      }
    } catch (e: any) {
      toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
    }
  };

  export const apiCallFetchTripDetails1 = async (id: any) => {
   
   
    try {
      
      const data = await FETCH_TRIPS_DETAILS(id);
      console.log(data);
      if (data.status === 200 || data.status === "200") {
        return data.data;
      } else {
        toast.error(data?.message);
      }
    } catch (e: any) {
      toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
    }
  };