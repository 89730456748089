import { connect } from "react-redux";
import HorizontalHeader from "../components/layouts/admin/HorizontalHeader";

import { TReduxDispatch, TReduxState } from "../store/store";

const mapStateToProps = (state: TReduxState) => ({
  loginInfo: state.loginInfo.loginInfo,
  userInfo: state.loginInfo.userInfo,
});

const mapDispatchToProps = (dispatch: TReduxDispatch) => ({});

const HorizontalHeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HorizontalHeader);
export default HorizontalHeaderContainer;