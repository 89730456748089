import React from "react";
import { Button, Modal, Grid } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Form, Formik } from "formik";
import Textfield from "../FormsUI/Textfield";
import { getInitialState, FORM_VALIDATION } from "./TransactionRejectionReason";

const TransactionRejectionReasonModal = (props: any) => {
  return (
    <React.Fragment>
      <Modal open={true}>
        <div className="center-modal-box">
          <div className="modal-header">
            <div className="modal-title">Reject Transaction</div>
            <span
              className="modal-btn-close"
              onClick={() => {
                if (!props.isTrnLoading) props.closeModal();
              }}
            >
              <Close />
            </span>
          </div>
          <hr />
          <Formik
            enableReinitialize
            initialValues={getInitialState(props.reason)}
            validateOnBlur={false}
            validationSchema={FORM_VALIDATION}
            onSubmit={async (fieldValues) =>
              props.handleReasonSubmit(fieldValues.reason)
            }
          >
            <Form>
              <div className="modal-body">
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Textfield
                      autoFocus
                      name="reason"
                      label="Reason"
                      multiline
                      minRows={2}
                      disabled={props.reason ? true : false}
                    />
                  </Grid>
                </Grid>
              </div>
              <div className="display-flex justify-content-center">
                {!props.reason && (
                  <Button
                    className="btn-primary margin-right-16"
                    type="submit"
                    disabled={props.isTrnLoading}
                  >
                    SAVE
                  </Button>
                )}
                <Button
                  className="btn-outline"
                  onClick={() => props.closeModal()}
                  disabled={props.isTrnLoading}
                >
                  CANCEL
                </Button>
              </div>
            </Form>
          </Formik>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default TransactionRejectionReasonModal;
