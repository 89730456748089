import { toast } from "react-toastify";
import { appErrors } from "../../../AppConstants";
import {
  TFetchAllPreApprovedUser,
} from "../../../data/types/preApprovedUserApi";
import {
  ACTIVE_INACTIVE_PRE_APPROVED_USER,
  DELETE_PRE_APPROVED_USER,
  FETCH_ALL_PRE_APPROVED_USER,
} from "../../../services/AppServices";
import { getEncryptedText } from "../../../utils/sharedFunctions";

export const fetchPreApprovedUserList = async (props: any = {}) => {
  const payload: TFetchAllPreApprovedUser = {
    searchFilter: props.searchFilter ? props.searchFilter : "",
    perPageRows: props.perPageRows ? props.perPageRows : 10,
    currentPage: props.currentPage ? props.currentPage : 1,
    orderBy: props.orderBy ? props.orderBy : "DESC",
    sortBy: props.sortBy ? props.sortBy : "id",
  };
  try {
    const data = await FETCH_ALL_PRE_APPROVED_USER(payload);
    if (data.code === 200 || data.code === "200") {
      return data.data;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
};

export const deletePreApprovedUser = async (id: number) => {
  try {
    const payload = getEncryptedText(id.toString());
    const data = await DELETE_PRE_APPROVED_USER(payload);
    if (data.code === 200 || data.code === "200") {
      toast.success(data?.message);
      return true;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
  return false;
};

export const activeDeactivePreApprovedUser = async (id: number, is_active: number) => {
  try {
    const payload = { id, is_active };
    const data = await ACTIVE_INACTIVE_PRE_APPROVED_USER(payload);
    if (data.code === 200 || data.code === "200") {
      toast.success(data.message);
      return true;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
  return false;
};

export const headCells = [
  {
    id: "user_name",
    disablePadding: false,
    label: "UserName",
    width: "80%",
    textAlign: "left",
  },
  {
    id: "action",
    disablePadding: false,
    label: "Actions",
    textAlign: "left",
    width: "20%",
    isNotSortable: true,
  },
];
