import { toast } from "react-toastify";
import { appErrors } from "../../../AppConstants";
import { TFetchAllCards } from "../../../data/types/adminApi";
import { TLockUnlockCard } from "../../../data/types/M2PType";
import {
  FETCH_ALL_CARDS,
  LOCK_UNLOCK_CARD,
} from "../../../services/AppServices";

export const apiCallFetchAllCards = async (props: any) => {
  const payload: TFetchAllCards = {
    searchFilter: props.searchFilter ? props.searchFilter : "",
    perPageRows: props.perPageRows ? props.perPageRows : 10,
    currentPage: props.currentPage ? props.currentPage : 1,
    orderBy: props.orderBy ? props.orderBy : "DESC",
    sortBy: props.sortBy ? props.sortBy : "id",
  };

  try {
    const data = await FETCH_ALL_CARDS(payload);
    if (data.code === 200 || data.code === "200") {
      return data.data;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
};

export const apiCallLockUnlockCard = async (
  payload: TLockUnlockCard,
  fetchData: () => void
) => {
  try {
    const data = await LOCK_UNLOCK_CARD(payload);
    if (data.code === 200 || data.code === "200") {
      fetchData();
      toast.success(data?.message);
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
};

export const headCells = [
  {
    id: "card_holder_name",
    disablePadding: false,
    label: "Cardholder Name",
    textAlign: "left",
    width: "20%",
  },
  {
    id: "address",
    disablePadding: false,
    label: "Address",
    textAlign: "left",
    width: "15%",
  },
  // {
  //   id: "contact_number",
  //   disablePadding: false,
  //   label: "Contact Number",
  //   textAlign: "left",
  //   width: "15%",
  // },
  {
    id: "card_last_four_digits",
    disablePadding: false,
    label: "Card Number",
    textAlign: "left",
    width: "16%",
  },
  {
    id: "card_expiry",
    disablePadding: false,
    label: "Card Expiry",
    width: "16%",
  },
  {
    // id: "m2p_card_type",
    id: "card_type",
    disablePadding: false,
    label: "Card Type",
    textAlign: "left",
    width: "11%",
  },
  {
    id: "current_status",
    disablePadding: false,
    label: "Card Status",
    textAlign: "left",
    width: "12%",
  },
  {
    id: "action",
    disablePadding: false,
    label: "Actions",
    textAlign: "left",
    width: "10%",
    isNotSortable: true,
  },
];
