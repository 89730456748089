import React from "react";

export const useTagListState = (): {
  writtenTag: string;
  setWrittenTag: (tag: string) => void;
  tagList: string[];
  setTagList: (list: string[]) => void;
  addToTagList: () => void;
  removeFromTagList: (tag: string) => void;
} => {
  const [tagList, setTagListState] = React.useState<string[]>([]);
  const [writtenTag, setWrittenTagState] = React.useState<string>("");

  const setWrittenTag = (tag: string) => {
    setWrittenTagState(tag);
  };

  const setTagList = (list: string[]) => {
    setTagListState(list);
  }
  const addToTagList = (tag: string = "") => {
    if (!tag) {
      if (!writtenTag) return;
      tag = writtenTag;
    }
    const index = tagList.indexOf(tag, 0);
    if (!(index > -1)) {
      setTagListState([...tagList, tag]);
    }
    setWrittenTagState("");
  };

  const removeFromTagList = (tag: string) => {
    const index = tagList.indexOf(tag, 0);
    if (index > -1) {
      let temp: string[] = [];
      for(const obj of tagList){
        if(obj !== tag) temp.push(obj);
      };   
      setTagListState(temp);
    }
  };
  return {
    writtenTag,
    setWrittenTag,
    tagList,
    setTagList,
    addToTagList,
    removeFromTagList,
  };
};
