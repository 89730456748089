import React, { useState } from "react";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Formik, Form } from "formik";
import { Grid,Button,TextField} from "@mui/material";
import { NavLink } from "react-router-dom";

import { apiCallFetchEditDetails } from "./ApiStaticpage";
import { useParams } from "react-router-dom";
import { TStaticPage } from "../../../data/types/adminApi";
import { updateStaticpage } from "./ApiStaticpage";
import { toast } from "react-toastify";
import { INITIAL_FORM_STATE, FORM_VALIDATION} from "./ApiStaticpage";
const EditePage = () =>{
    const [pageList, setPageList] = useState({page_name:"",contents:""});
    const [isLoading, setIsLoading] = useState(false);
    const [id, setId] = useState(0);
    const [page_name,setPage_name] = useState("");
    const [contents,setContents] = useState("");
    const params = useParams()

    React.useEffect(()=>{
     fetchData(params.id);
    },[])   
 
     const fetchData = async (id:any) =>{
           setIsLoading(true);
           try{
          
             const data = await apiCallFetchEditDetails(id);
             
             console.log(data);
            
             if(data){
               
                setPageList(data);
                setId(id);
                setPage_name(data.page_name);
                setContents(data.contents)
                
             }
           }catch (e) {}
           //setIsLoading(false);
     }
     const onSubmitEditPage = async () =>{
        setIsLoading(true);
        const payload: TStaticPage = {
          id:id,
          page_name:page_name,
         contents:contents}
        try{
          const data = await updateStaticpage(payload);
          
          console.log(data);
         
          if(data){
            toast.success(
              `Data updated success fully`
            );
          
          }
        }catch (e) {}
        setIsLoading(false);
        
  
      }
    return (
        <React.Fragment>
            <div className="page-header">
                <span className="page-title"> Edit page </span>
            </div> 
            
          
            <Grid container spacing={3} className="headElements">
                <Grid item xs={12}>
              
                <TextField
                      autoFocus fullWidth
                      name="page_name"
                      label="Title"
                      type="text"
                      value={page_name}
                      onChange={(e:any) => setPage_name(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                <TextField
                        name="contents"
                        label="content"
                        fullWidth
                        type={"text"}
                         multiline
                        rows={10}
                        value={contents}
                        onChange={(e:any) => setContents(e.target.value)}
                    />
                    {/* <Editor
                    //value={pageList.contents}
                     toolbarClassName="toolbarClassName"
                     wrapperClassName="wrapperClassName"
                     editorClassName="editorClassName"
                     /> */}
                    
                </Grid>

                <Grid item xs={12}>
                <NavLink to="/admin/StaticPages" style={{ textDecoration: 'none' }}>
                    <Button
                        type="submit"
                        color="primary"
                        className="btn btn-primary"
                        onClick={onSubmitEditPage}
                        style={{ width: 200 }}>Submit</Button>
                 </NavLink>        
                </Grid>
                <Grid item xs={12}>
                    <NavLink to="/admin/StaticPages" style={{ textDecoration: 'none' }}>
                        <Button
                            type="button"
                            color="primary"
                            className="btn btn-primary"
                            style={{ width: 200 }}>Cancel</Button>
                    </NavLink>
                </Grid>

            </Grid>
         
           

        </React.Fragment>
    )
}
export default EditePage