import React from 'react';

const useNavExpandedState = (): {
  isNavExpanded: boolean;
  toggleExpanded: () => void;
} => {
  const [isNavExpanded, setIsNavExpanded] = React.useState<boolean>(false);
  
  const toggleExpanded = () => {
    setIsNavExpanded(!isNavExpanded)
  };

  return {
    isNavExpanded,
    toggleExpanded,
  }
}

export default useNavExpandedState;