import { toast } from "react-toastify";
import * as Yup from "yup";

import { adminRoutePath, appErrors, publicRoutePath, vendorRoutePath } from "../../../AppConstants";
import history from "../../../history";
import {
  getEncryptedText,
  getIpAddress,
  UserType,
} from "../../../utils/sharedFunctions";
import { APP_LOGIN } from "../../../services/AppServices";
import { localStorageUtils } from "../../../utils/localStorageUtils";
import { UserAuthentictation } from "../../../data/types/appUser";

export const INITIAL_FORM_STATE = {
  userName: "",
  password: "",
  ipAddress: "",
};

export const FORM_VALIDATION = Yup.object().shape({
  userName: Yup.string()
    .required("User name is required!")
    .email("Invalid user name!"),
  password: Yup.string().required("Password is required!"),
});

export const authenticateUser = async (props: any, addMainLoginInfoHandler: any, addMainUserInfoHandler: any) => {
  const client_ip = await getIpAddress();
  const payload: UserAuthentictation = {
    userName: props.userName,
    password: props.password,
    ipAddress: client_ip || "",
  };
  try {
    const data = await APP_LOGIN(payload);
    console.log(data);
    if (data.status === 200 || data.status === "200") {
      toast.success(
        `Hello, ${data.data.first_name} ${data.data.last_name}. Welcome to EasyMile!`
      );

      localStorageUtils.setUserSignedInStatus(true);
      localStorageUtils.setUserInfo(data.data);
      localStorageUtils.setAccessToken(data.data.authorizeToken);
      

      addMainLoginInfoHandler(data.data);
      addMainUserInfoHandler(data.data);
      history.push(adminRoutePath.dashboard);
    } else {
      toast.error(data?.message);
    }
  } catch ( e: any ) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
};
