import React from "react";
import { Button, Modal, Grid } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Form, Formik } from "formik";
import Textfield from "../FormsUI/Textfield";
import { getInitialState, FORM_VALIDATION } from "./PostCriteria";
import { toast } from "react-toastify";
import { DiscountType, generateGUID } from "../../utils/sharedFunctions";
import { TPostCriteria, TOfferBucket } from "../../data/types/OfferState";
import DropDown from "../FormsUI/DropDown";
import { appErrors } from "../../AppConstants";

const AddEditPostCriteria = (props: any) => {
  const [offerBucketList, setOfferBucketList] = React.useState<TOfferBucket[]>(
    []
  );

  React.useEffect(() => {
    let bucketIdList: number[] = [];
    if (props.postCriteriaList) {
      for (const criteria of props.postCriteriaList) {
        bucketIdList.push(criteria.id_offer_bucket);
      }
    }
    if (
      props.postCriteria &&
      bucketIdList.includes(props.postCriteria.id_offer_bucket)
    ) {
      bucketIdList = bucketIdList.filter(
        (id) => id !== props.postCriteria.id_offer_bucket
      );
    }
    const tempBucketList = props.offerBucketList.filter(
      (bucket: TOfferBucket) => !bucketIdList.includes(bucket.id)
    );
    setOfferBucketList(tempBucketList);
  }, [props]);

  const handleChangeScoreRange = (event: any, setFormikFieldValues: any) => {
    const findBucket = offerBucketList.find(
      (bucket) => bucket.id === event.target.value
    );
    if (!findBucket) {
      setFormikFieldValues({ score_range: "", post_discount: 0 });
      return;
    }
    if (props.postCriteriaList) {
      for (const criteria of props.postCriteriaList) {
        if (
          !(props.postCriteria && criteria.id === props.postCriteria.id) &&
          ((criteria.score_min_value >= findBucket.min_score &&
            criteria.score_min_value <= findBucket.max_score) ||
            (criteria.score_max_value >= findBucket.min_score &&
              criteria.score_max_value <= findBucket.max_score) ||
            (criteria.score_min_value <= findBucket.min_score &&
              criteria.score_max_value >= findBucket.min_score))
        ) {
          setFormikFieldValues({ score_range: "", post_discount: 0 });
          toast.error(appErrors.INSTA_SCORE_RANGE_OVERLAP);
          return;
        }
      }
      setFormikFieldValues({
        score_range: findBucket.id,
        post_discount: findBucket.default_post_discount_percentage,
      });
    } else {
      setFormikFieldValues({
        score_range: findBucket.id,
        post_discount: findBucket.default_post_discount_percentage,
      });
    }
  };

  const handleSubmit = (fieldValues: any) => {
    if (
      props.type === DiscountType.Percentage &&
      (fieldValues.post_discount > 100 || fieldValues.post_discount < 1)
    ) {
      toast.error("Post discount must be in between 1 to 100!");
    } else {
      // const criteria: TPostCriteria = {
      //   id: props.postCriteria ? props.postCriteria.id : 0,
      //   uid: props.postCriteria ? props.postCriteria.uid : generateGUID(),
      //   score_min_value: fieldValues.score_min_value,
      //   post_discount: fieldValues.post_discount,
      // };
      // props.closeModal(criteria);

      const findBucket = offerBucketList.find(
        (bucket) => bucket.id === fieldValues.score_range
      );

      if (!findBucket) {
        toast.error("Score range not found!");
        return;
      }
      const criteria: TPostCriteria = {
        id: props.postCriteria ? props.postCriteria.id : 0,
        id_offer_bucket: fieldValues.score_range,
        score_min_value: findBucket.min_score,
        score_max_value: findBucket.max_score,
        post_discount: fieldValues.post_discount,
      };
      props.closeModal(
        criteria,
        props.postCriteria ? props.postCriteria.id_offer_bucket : null
      );
    }
  };

  return (
    <React.Fragment>
      <Modal open={true}>
        <div className="center-modal-box">
          <div className="modal-header">
            <div className="modal-title">Post Criteria</div>
            <span
              className="modal-btn-close"
              onClick={() => props.closeModal(null)}
            >
              <Close />
            </span>
          </div>
          <hr />
          <Formik
            enableReinitialize
            initialValues={getInitialState(props.postCriteria, offerBucketList)}
            validateOnBlur={false}
            validationSchema={FORM_VALIDATION}
            onSubmit={async (fieldValues) => handleSubmit(fieldValues)}
          >
            {({ values, setValues }) => (
              <Form>
                <div className="modal-body">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <DropDown
                        name="score_range"
                        label="Score Range"
                        onChange={(event: any) =>
                          handleChangeScoreRange(event, setValues)
                        }
                        itemlist={offerBucketList.map((bucket) => {
                          return {
                            value: bucket.id,
                            label: bucket.min_score + "-" + bucket.max_score,
                          };
                        })}
                      />
                      {/* <Textfield
                      name="score_min_value"
                      label="Score Min Value"
                      type="number"
                    /> */}
                    </Grid>
                    <Grid item xs={12}>
                      <Textfield
                        name="post_discount"
                        label="Post Discount"
                        type="number"
                      />
                    </Grid>
                  </Grid>
                </div>
                <div className="display-flex justify-content-center">
                  <Button className="btn-primary margin-right-16" type="submit">
                    SAVE
                  </Button>
                  <Button
                    className="btn-outline"
                    onClick={() => props.closeModal(null)}
                  >
                    CANCEL
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default AddEditPostCriteria;
