import * as Yup from "yup";
import { toast } from "react-toastify";
import { TChangePassword } from "../../data/types/appUser";
import { getIpAddress } from "../../utils/sharedFunctions";
import { CHANGE_PASSWORD } from "../../services/AppServices";
import { appErrors } from "../../AppConstants";

export const getInitialState = () => {
  return {
    oldpassword: "",
    password: "",
    cfmPassword: "",
  };
};

export const FORM_VALIDATION = Yup.object().shape({
  oldpassword: Yup.string()
    .required("Old password is required!")
    .min(6, "Old password must be 6 characters!")
    .max(30, "Old password must be 30 characters!"),
    password: Yup.string()
    .required("New password is required")
    .min(6, "New password must be 6 characters!")
    .max(30, "New password must be 30 characters!")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{6,})/,
      "Must contain 6 characters, one uppercase, one lowercase, one number and one special case character!"
    ),
    cfmPassword: Yup.string()
    .required("Confirm password is required")
    .oneOf([Yup.ref("password"), null], "Confirm password doesn`t match!"),
});

export const apiCallChangePassword = async (fieldValues: any, closeModal: () => void) => {
  // const ipAddress = await getIpAddress();
  const payload: TChangePassword = {
    oldpassword: fieldValues.oldpassword,
    password: fieldValues.password, 
    cfmPassword: fieldValues.cfmPassword,
    
  }

  try {
    const data = await CHANGE_PASSWORD(payload);
    if (data.status === 200 || data.status === "200") {
      toast.success(data.message);
      closeModal();
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
};
