import { connect } from "react-redux";
import BrandMerchantConfiguration from "../../views/vendor/brand-merchant-configuration";
import { TReduxDispatch, TReduxState } from "../../store/store";

const mapStateToProps = (state: TReduxState) => ({
  loginInfo: state.loginInfo.loginInfo,
});

const mapDispatchToProps = (dispatch: TReduxDispatch) => ({});

const BrandMerchantConfigurationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BrandMerchantConfiguration);
export default BrandMerchantConfigurationContainer;
