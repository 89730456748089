import React from "react";

export const useVerifyOtpState = (): {
  otp: string;
  setOtpValue: (value: string) => void;
  canResendOtp: boolean;
  setCanResendValue: (value: boolean) => void;
  timer: number;
  increaseTimerValue: () => void;
  waitingTime: number;
  setWaitingTimeValue: (value: number) => void;
} => {
  const [otp, setOto] = React.useState<string>("");
  const [canResendOtp, setCanResendOtp] = React.useState<boolean>(false);
  const [timer, setTimer] = React.useState<number>(0);
  const [waitingTime, setWaitingTime] = React.useState<number>(0);

  const setOtpValue = (value: string) => {
    setOto(value);
  };

  const setCanResendValue = (value: boolean) => {
    setCanResendOtp(value);
  };

  const increaseTimerValue = () => {
    setTimer(timer => timer+1)
  }

  const setWaitingTimeValue = (value: number) => {
    setWaitingTime(value);
  };

  return {
    otp,
    setOtpValue,
    canResendOtp,
    setCanResendValue,
    timer,
    increaseTimerValue,
    waitingTime,
    setWaitingTimeValue,
  };
};
