import { toast } from "react-toastify";
import { appErrors, publicRoutePath, vendorRoutePath } from "../../../AppConstants";
import { TVendorVerification, TReSendOtp } from "../../../data/types/VerifyOtp";
import history from "../../../history";
import { VENDOR_VERIFICATION, FETCH_RESEND_OTP_WAITING_TIME } from "../../../services/AppServices";
import { RE_SEND_OTP } from "../../../services/AuthServices";
import { localStorageUtils } from "../../../utils/localStorageUtils";
import { UserType } from "../../../utils/sharedFunctions";

export const verifyUserAccount = async (props: any, addMainLoginInfoHandler: any, addMainUserInfoHandler: any) => {
  const payload: TVendorVerification = {
    id: props.id,
    one_time_pass: props.otp,
  }

  try {
    const data = await VENDOR_VERIFICATION(payload);
    if (data.code === 200 || data.code === "200") {
      toast.success(`Howdy ${data.data.loginInfo.first_name} ${data.data.loginInfo.last_name}, Welcome to the EasyMile!`);

      localStorageUtils.setUserSignedInStatus(true);
      localStorageUtils.setUserInfo(data.data.loginInfo);
      localStorageUtils.setAccessToken(data.data.token);
      localStorageUtils.setUserType(data.data.loginInfo.user_type);

      addMainLoginInfoHandler(data.data.loginInfo);
      addMainUserInfoHandler(data.data.loginInfo);
      if (data.data.loginInfo.user_type === UserType.Vendor) {
        history.push(vendorRoutePath.dashboard);
      } else {
        history.push(publicRoutePath.appEntryPoint);
      }

    } else {
      toast.error(data?.message);
    }
  } catch (e : any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
} 

export const reSendOtp = async (id: string) => {
  if(!id){
    toast.error("Invalid id");
    return;
  }

  const payload : TReSendOtp = { id }
  try {
    const data = await RE_SEND_OTP(payload);
    if (data.code === 200 || data.code === "200") {
      toast.success(`Otp successfully sent to your register email`);
    } else {
      toast.error(data?.message);
    }
  } catch (e : any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
}

export const apiCallFetchResndOtpWaitingTime = async () => {
  try {
    const data = await FETCH_RESEND_OTP_WAITING_TIME();
    if (data.code === 200 || data.code === "200") {
      return data.data;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
  return null;
}