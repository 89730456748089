import React from "react";
import { Button } from "@mui/material";
import { Close } from "@mui/icons-material";
import ReactDOM from "react-dom";

const ConfirmationModal = (props: any) => {
  const closeModal = (status: boolean) => {
    ReactDOM.render(<div></div>, document.getElementById("modal-placement"));
    props.closeModal(status);
  };

  React.useEffect(() => {
    ReactDOM.render(
      <div
        className={`center-modal ${
          props.open ? "center-modal-open" : "center-modal-open"
        }`}
      >
        <div className="center-modal-box">
          <div className="modal-header">
            <div className="modal-title">{props.title}</div>
            <span className="modal-btn-close" onClick={() => closeModal(false)}>
              <Close />
            </span>
          </div>
          <hr />
          <div className="modal-body">
            {props.message} <br /> Would you like to proceed further?
          </div>
          <div className="modal-footer">
            <Button
              className="btn-primary margin-right-16"
              onClick={() => closeModal(true)}
            >
              YES
            </Button>
            <Button className="btn-outline" onClick={() => closeModal(false)}>
              NO
            </Button>
          </div>
        </div>
      </div>,
      document.getElementById("modal-placement")
    );
  }, []);

  return <React.Fragment></React.Fragment>;
};

export default ConfirmationModal;
