import { Button, Grid,TableContainer,Table,MenuItem,TableHead,Select,TableRow,TableCell,Paper,TableBody,TextField, IconButton, } from "@mui/material";
import { NavLink } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { fetchStaticPage } from "./ApiStaticpage";
import React, { useState } from "react";
import usePaginationState from "../../../hooks/usePaginationState";
import { TStaticPage } from "../../../data/types/adminApi";
import { deleteStaticpage } from "./ApiStaticpage";
import { toast } from "react-toastify";
import RefreshIcon from "@mui/icons-material/Refresh";
import { PER_PAGE_ROWS_OPTIONS } from "../../../AppConfig";
import TablePagination from "../../../components/common/TablePagination";

const StaticPages = () =>{
  let timer: ReturnType<typeof setTimeout>;
    const [pageList, setPageList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { pagination, setPagination } = usePaginationState({
        searchFilter: "",
      });
    React.useEffect(() => {
        fetchData();
      }, [])
      const fetchData = async () => {
        setIsLoading(true);
        try {
          setPageList([]);
          const data = await fetchStaticPage(pagination);
    
          console.log(data);
    
          if (data && data.rows && data.count) {
            let count = data.count ? (parseInt(data.count)) : 1
            let perPage = parseInt(pagination.perPageRows)
            let totalPages = count > perPage ? parseInt((count/perPage).toString()) + 1 : 1
    
            setPagination({
              searchFilter: pagination.searchFilter,
              startDate: pagination.startDate,
              
              endDate: pagination.endDate,
              perPageRows: pagination.perPageRows,
              currentPage: pagination.currentPage,
              totalPages: totalPages,
              totalItems : count
              
            });

            setPageList(data.rows);
    
          }
        } catch (e) { }
        setIsLoading(false);
      }
  const handleSearchChange = async (e: any) => {
        if (timer) {
          clearTimeout(timer);
        }
        timer = setTimeout(async () => {
          if (e.target.name == "searchFilter") {
            pagination.searchFilter = e.target.value;
            pagination.currentPage = 1;
            fetchData();
          }
        }, 500);
      }

      const handleRowsPerPage = (rowCount: number) => {
        pagination.perPageRows = rowCount;
        pagination.currentPage = 1;
        fetchData();
      };
    
      const handlePageChange = (page: number) => {
        pagination.currentPage = page;
        fetchData();
      };

  const onSubmitDeletePage = async (props:any) =>{
        setIsLoading(true);
        const payload: TStaticPage = {
          id:props.id
        }
         console.log(payload);
        try{
          const data = await deleteStaticpage(payload);
          
          console.log(data);
         
          if(data){
            toast.success(
              `Data Deleted success fully`
            );
            fetchData();
          }
        }catch (e) {}
        setIsLoading(false);
        
  
      }
    return(
    <>
    <div className="page-header">
                    <span className="page-title"> Static Pages </span>
                </div>  
        <Grid container spacing={3} className="headElements">
        <Grid item className="leftHead">
            <p className="selectPages">Show</p>
            <Select value={pagination.perPageRows}
              label="Page"
              className="dropDown"
              onChange={(e:any) => handleRowsPerPage(Number(e.target.value))}
            >
              {PER_PAGE_ROWS_OPTIONS.map((item: number) => <MenuItem value={item}>{item}</MenuItem>)}
            </Select>
            <span className="margin-left-16 btn-icon" title="Reload" onClick={() => fetchData()}>
              <RefreshIcon fontSize="large" className="color-light-blue" />
            </span>
          </Grid>
          <Grid item className="rightHead">
            <p className="selectPages">Search</p>
            <TextField
              size={"small"}
              className="searchField"
              id="outlined-basic"
              name="searchFilter"
              placeholder="Search Users"
              onChange={(e: any) => handleSearchChange(e)}
            />
          </Grid>
          <Grid item className="rightHead">
            <NavLink to='/admin/AddPages' style={{ textDecoration: 'none' }}>
              <Button
                type="button"
                color="primary"
                className="btn btn-primary rightHead"
                style={{ width: 200 }}>Add
              </Button></NavLink>

          </Grid>
          </Grid>
          <TableContainer component={Paper} className="tableContainer">
            <Table stickyHeader className="table" aria-label="simple table">
              <TableHead>
                <TableRow className="hedsize">

                  <TableCell style={{ fontWeight: "bold", fontSize: 16 }}>Sr No.</TableCell>
                  <TableCell style={{ fontWeight: "bold", fontSize: 16 }}>Page Title</TableCell>
                  <TableCell style={{ fontWeight: "bold", fontSize: 16 }}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableCell colSpan={7}>
                    <div className="loader-container">
                      <div className="loader"></div>
                    </div>
                  </TableCell>
                ) : (
                  pageList.map((row: any, i) => (

                    <TableRow key={i}>
                      <TableCell style={{ fontWeight: "bold" }}>{i + 1}</TableCell>
                      <TableCell>{row.page_name}</TableCell>
                      <TableCell>
                        <IconButton><NavLink to={`/admin/EditPage/${row.id}`} style={{ textDecoration: 'none' }}><EditIcon color="success" /></NavLink></IconButton>
                        <IconButton> <DeleteIcon color="error" onClick={() => onSubmitDeletePage(row)} /></IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
          currentPage={pagination.currentPage}
          perPageRows={pagination.perPageRows}
          totalItems={pagination.totalItems}
          totalPages={pagination.totalPages}
          handleOnChangePage={handlePageChange}
        />
    </>
    )

}
export default StaticPages;