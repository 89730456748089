import React from "react";
import { Formik, Form } from "formik";
import Textfield from "../../../components/FormsUI/Textfield";
import { authenticateUser, INITIAL_FORM_STATE, FORM_VALIDATION } from "./login";
import { Link } from "react-router-dom";
import { ReactComponent as LoginSvg } from "../../../assets/images/login/login-v2.svg";
import wyldLogo from "../../../assets/images/login/wyld.png";
import {
  Button,
  Container,
  Grid,
  Typography,
  InputAdornment,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import useLogin from "../../../hooks/auth/directory/useLogin";
import ForgotPasswordModal from "../../../components/forgot-password/ForgotPasswordModal";
import history from "../../../history";

const PublicLogin = (props: any) => {
  const { showPassword, togglePasswordVisibility } = useLogin();
  const [showForgotPasswordModal, setShowForgotPasswordModal] = React.useState<boolean>(false);
  return (
    <React.Fragment>
      <Grid container className="signin" spacing={4}>
        <Grid item md={8} className="signin-logo-container">
          <LoginSvg className="logo" />
        </Grid>
        <Grid
          item
          md={4}
          sm={8}
          className="signin-container"
          justifyContent={"center"}
        >
          <Grid item className="signin-child">
            <h3 className="left-align-text ">Welcome to EasyMile! 👋</h3>
            <p className="left-align-text medium-font">
              Please sign-in to your account and start the adventure
            </p>
            <Formik
              initialValues={{ ...INITIAL_FORM_STATE }}
              validateOnBlur={false}
              validationSchema={FORM_VALIDATION}
              onSubmit={async (fieldValues) => {
                authenticateUser(fieldValues, props.addMainLoginInfoHandler, props.addMainUserInfoHandler);
              }}
            >
              <Form>
                <p className="margin-blcok left-align-text small-font">Email</p>
                <Textfield
                  name="userName"
                  type={"email"}
                  placeholder="john@example.com"
                />
                <p className="margin-blcok left-align-text small-font">
                  Password
                </p>
                <Textfield
                  name="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="············"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Grid container className="signin-space-between">
                  {/* <Grid>
                    <FormGroup className="signin-formgroup ">
                      <FormControlLabel
                        className="signin-formlable medium-font"
                        control={
                          <Checkbox className="remember-checkbox medium-font" />
                        }
                        label="Remember me"
                      ></FormControlLabel>
                    </FormGroup>
                  </Grid> */}
                  <Grid>
                    <a className="signin-link " onClick={() => setShowForgotPasswordModal(true)}>
                      <p>Forgot Password?</p>
                    </a>
                  </Grid>
                </Grid>
                {/* <p className="text-center">
                  <span>New on our platform?</span>
                  <a className="signin-link" onClick={() => history.push('/accounts/register')}>
                    <span>&nbsp;Create an account</span>
                  </a>
                </p> */}
                <Button
                  type="submit"
                  className="btn-primary signin-button"
                  variant="contained"
                  fullWidth
                >
                  Sign In
                </Button>
              </Form>
            </Formik>
          </Grid>
        </Grid>
      </Grid>
      {showForgotPasswordModal && (
        <ForgotPasswordModal closeModal={() => setShowForgotPasswordModal(false)}/>
      )}
    </React.Fragment>
  );
};

export default PublicLogin;
