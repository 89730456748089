export const API_ENDPOINT =
  process.env.REACT_APP_API_ENDPOINT || "https://api.easymiles.co";

export const CRYPTO_JS_KEY =
  process.env.REACT_APP_CRYPTO_JS_KEY || "ce99ad7767f981e6fd77895ca3b766b2";
export const CRYPTO_JS_IV = process.env.REACT_APP_CRYPTO_JS_IV || [
  "6a279fd5baeda3c760f639efb26777ea",
];

export const PHONE_MASK = process.env.PHONE_MASK || "(+91) 99999-99-999";
export const POSTAL_CODE_MASK = process.env.POSTAL_CODE_MASK || "999 999";
export const APP_DATE_FORMAT = process.env.APP_DATE_FORMAT || "DD-MM-YYYY";
export const DATEPICKER_DATE_FORMAT =
  process.env.DATEPICKER_DATE_FORMAT || "YYYY-MM-DD";

export const PER_PAGE_ROWS_OPTIONS = [
  10, 25, 50,
];
export const PER_PAGE_ROWS = process.env.PER_PAGE_ROWS || 10;
export const PROFILE_IMAGE_ALLOWED_SIZE_KB = 1024 * 1000;
export const PRODUCT_IMAGE_ALLOWED_SIZE_KB = 512 * 1000;
export const ALLOWED_IMAGE_TYPES = process.env.ALLOWED_IMAGE_TYPES || ['image/jpg','image/jpeg','image/png'];

export const CAPTCHA_SITE_KEY = "6LfbL1cgAAAAAAUKNilbTOJt-A4zYdO9p7yttc5y";

export const INSTAGRAM_API_ENDPOINT = process.env.INSTAGRAM_API_ENDPOINT || "https://www.instagram.com";

export const GOOGLE_MAPS_API_KEY = "AIzaSyBEec7JpxpejPA3Y9ZTZoyWI2Fo31pfjX4";