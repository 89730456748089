import {
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";
import { useField } from "formik";

const RadioButton = (props: any) => {
  const [field, mata] = useField(props.name);
  const configProps = { ...props };

  const configRadioButton: any = {
    ...field,
    ...configProps,
  };

  if (mata && mata.touched && mata.error) {
    configRadioButton.error = true;
    configRadioButton.helpertext = mata.error;
  }

  return (
    <div className="text-align-left">
      <span className="padding-right-16">{props.label}</span>
      <RadioGroup row {...configRadioButton} className="radio-btn-box">
        {props.itemlist.map((item: any, index: number) => (
          <FormControlLabel
            disabled={configRadioButton.disabled}
            key={index}
            value={item.value}
            control={<Radio />}
            label={item.label}
          />
        ))}
      </RadioGroup>
      
    </div>
  );
};

export default RadioButton;
