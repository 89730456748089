import React from "react";
import { Button, Modal, Grid } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Form, Formik } from "formik";
import Textfield from "../FormsUI/Textfield";
import { getInitialState, FORM_VALIDATION } from "./OfferCriteria";
import { toast } from "react-toastify";
import { DiscountType, generateGUID } from "../../utils/sharedFunctions";
import { TOfferCriteria } from "../../data/types/OfferState";

const AddEditOfferCriteria = (props: any) => {
  const handleSubmit = (fieldValues: any) => {
    if (
      props.type === DiscountType.Percentage &&
      fieldValues.order_discount > 100
    ) {
      toast.error("Order discount must be in between 1 to 100!");
    } else if (
      props.minValue &&
      props.minValue > fieldValues.order_min_value
    ) {
      toast.error("Order min value must be greater than offer order min value!");
    } else {
      const criteria: TOfferCriteria = {
        id: props.offerCriteria ? props.offerCriteria.id : 0,
        uid: props.offerCriteria ? props.offerCriteria.uid : generateGUID(),
        order_min_value: fieldValues.order_min_value,
        order_discount: fieldValues.order_discount,
      };
      props.closeModal(criteria);
    }
  };

  return (
    <React.Fragment>
      <Modal open={true}>
        <div className="center-modal-box">
          <div className="modal-header">
            <div className="modal-title">Offer Criteria</div>
            <span
              className="modal-btn-close"
              onClick={() => props.closeModal(null)}
            >
              <Close />
            </span>
          </div>
          <hr />
          <Formik
            enableReinitialize
            initialValues={getInitialState(props.offerCriteria)}
            validateOnBlur={false}
            validationSchema={FORM_VALIDATION}
            onSubmit={async (fieldValues) => handleSubmit(fieldValues)}
          >
            <Form>
              <div className="modal-body">
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Textfield
                      name="order_min_value"
                      label="Order Min Value"
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Textfield
                      name="order_discount"
                      label="Order Discount"
                      type="number"
                    />
                  </Grid>
                </Grid>
              </div>
              <div className="display-flex justify-content-center">
                <Button className="btn-primary margin-right-16" type="submit">
                  SAVE
                </Button>
                <Button
                  className="btn-outline"
                  onClick={() => props.closeModal(null)}
                >
                  CANCEL
                </Button>
              </div>
            </Form>
          </Formik>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default AddEditOfferCriteria;
