import React from "react";
import { Pagination, Grid } from "@mui/material";
import { TTablePaginationProps } from "../../data/types/componentProps";

const TablePagination = (props: TTablePaginationProps) => {
  return (
    <React.Fragment>
      <div>
        <Grid container className="pagination-container">
          <Grid item sm={6} xs={12}>
            <p className="text-align-left">
              Showing{" "}
              {props.totalItems == 0
                ? 0
                : (props.currentPage - 1) * props.perPageRows + 1}{" "}
              to{" "}
              {props.totalItems < props.currentPage * props.perPageRows
                ? props.totalItems
                : props.currentPage * props.perPageRows}{" "}
              of {props.totalItems} entries
            </p>
          </Grid>
          <Grid item sm={6} xs={12} className="pagination-grid-container">
            <Pagination
              count={props.totalPages}
              page={props.currentPage}
              shape="rounded"
              onChange={(e: any, page: number) => {
                props.handleOnChangePage(page);
              }}
            />
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default TablePagination;
