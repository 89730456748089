import React,{useState,useRef,useEffect} from "react";
import { apiNotification } from "./ApiNotification";
import { Button,Grid} from "@mui/material";
import { Formik, Form } from "formik";
import { getInitialState, FORM_VALIDATION} from "./ApiNotification";
import Textfield from "../../../components/FormsUI/Textfield";
import { useFormikContext } from "formik";
const Notifications = () =>
{
  const handleReset = () => {
    if (!window.confirm('Reset?')) {
      throw new Error('Cancel reset');
    }
  };
  
    return(
          <div>
           <Formik
              enableReinitialize
              initialValues={getInitialState()}
              validateOnBlur={false} 
              validationSchema={FORM_VALIDATION}
              onSubmit={async (fieldValues, { resetForm }) => {
                apiNotification(fieldValues);
                resetForm();
              }}
            >
              <Form>
            
                <div className="page-header">
                    <span className="page-title"> Notification </span>
                </div>  
                <Grid container spacing={3} className="headElements">
                <Grid item xs={12}>
                    <Textfield
                      autoFocus 
                      fullWidth
                      name="title"
                      label="Title"
                      type="text"
                   
                    />
                  </Grid>
                <Grid item xs={12}>
                
                <Textfield
                     name="content"
                    label="Notification"
                    fullWidth
                    type="text"
                    multiline
                    rows={10}
                   />
                  </Grid>
                  <Grid item xs={12}>
                    
                  </Grid>
                  
                  <Grid item xs={12}>
                       <Button   
                       type="submit"  
                       color="primary" 
                       className="btn btn-primary" 
                       style={{width: 200}}>Submit</Button>
                  </Grid>
                  </Grid>
                  </Form>
            </Formik>
            
        </div>
    )
}
export default Notifications