import { connect } from "react-redux";
import OfferTransaction from "../../views/vendor/offer-transaction";
import { TReduxDispatch, TReduxState } from "../../store/store";

const mapStateToProps = (state: TReduxState) => ({
  loginInfo: state.loginInfo.loginInfo,
});

const mapDispatchToProps = (dispatch: TReduxDispatch) => ({});

const OfferTransactionContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(OfferTransaction);
export default OfferTransactionContainer;
