import React from "react";
import PinDropIcon from '@mui/icons-material/PinDrop';
import { styled } from '@mui/material/styles';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import GroupIcon from '@mui/icons-material/Group';
import { useTheme } from '@mui/material/styles';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import { apiCallDASHBOARDLIST } from "./DashboardList";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));  
const AdminBoard = () => {
  const [dashboardList, setDashboardList] = React.useState({users: 0, trips: 0, popularStation: 0, popularRoute: 0});
  
 
 
  const [isLoading, setIsLoading] = React.useState(false);
   React.useEffect(()=>{
    fetchData();
   },[])   
    const fetchData = async () =>{
          setIsLoading(true);
          try{
            const data = await apiCallDASHBOARDLIST();
            
            console.log(data);
           
            if(data){
              

              setDashboardList(data);
              
            }
          }catch (e) {}
          //setIsLoading(false);
    }
  
 
  const theme = useTheme();
  return(
    <>
             
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3}} >
                <Grid item xs={3}>
                  <Item>
                  <Grid item xs={6} sm container>
                      <Grid item xs className="cartcount">
                        <div className="textsize">{dashboardList.trips}</div>
                        <div className="textsize1">All Trips</div>
              
                      </Grid>
                      <Grid item xs className="iconcolor">
                        <LocalShippingIcon fontSize="large"/>
                      </Grid>
                      </Grid>
                  </Item>
                </Grid>
                <Grid item xs={3}>
                <Item>
                  <Grid item xs={6} sm container>
                      <Grid item xs className="cartcount">
                        <div className="textsize">{dashboardList.users}</div>
                        <div className="textsize1">Total Users</div>
              
                      </Grid>
                      <Grid item xs className="iconcolor">
                        <GroupIcon fontSize="large"/>
                      </Grid>
                      </Grid>
                  </Item>
                </Grid>
                <Grid item xs={3}>
                <Item>
                  <Grid item xs={6} sm container>
                      <Grid item xs className="cartcount">
                        <div className="textsize">{dashboardList.popularStation}</div>
                        <div className="textsize1">Popular Stops </div>
              
                      </Grid>
                      <Grid item xs className="iconcolor">
                        <PinDropIcon fontSize="large"/>
                      </Grid>
                      </Grid>
                  </Item>
                </Grid>
                <Grid item xs={3}>
                <Item>
                  <Grid item xs={6} sm container>
                      <Grid item xs className="cartcount">
                        <div className="textsize">{dashboardList.popularRoute}</div>
                        <div className="textsize1">Popular Routes  </div>
              
                      </Grid>
                      <Grid item xs className="iconcolor">
                        <AltRouteIcon fontSize="large"/>
                      </Grid>
                      </Grid>
                  </Item>
                </Grid>
              </Grid>
              
              
                
    
    </>
  )
}
export default AdminBoard