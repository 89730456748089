import * as Yup from "yup";
import { RECOVER_PASSWORD } from "../../../services/AppServices";
import { toast } from "react-toastify";
import { appErrors } from "../../../AppConstants";
import history from "../../../history";
import { getIpAddress } from "../../../utils/sharedFunctions";
import { TRecoverPassword } from "../../../data/types/appUser";

export const getInitialState = () => {
  return {
    password: "",
    confirm_password: "",
  };
};

export const FORM_VALIDATION = Yup.object().shape({
  password: Yup.string()
    .required("Password is required!")
    .min(8, "Password must be 8 characters!")
    .max(30, "Password must be 30 characters!")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/,
      "Must contain 8 characters, one uppercase, one lowercase, one number and one special case character"
    ),
  confirm_password: Yup.string()
    .required("Confirm password is required!")
    .oneOf([Yup.ref("password"), null], "Confirm password doesn`t match"),
});

export const apiCallRecoverPassword = async (
  password: string,
  activation_hash: string | null,
) => {
  if(!activation_hash){
    toast.error("Invalid token!");
    return;
  }
  const ipAddress = await getIpAddress();
  const payload: TRecoverPassword = {
    password,
    activation_hash,
    
  };
  try {
    const data = await RECOVER_PASSWORD(payload);
    console.log(data);
    if (data.status === 200 || data.status === "200") {
      toast.success(data.message);
      history.push(`/accounts/login`);
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
};
