import React from "react";
import Box from "@mui/material/Box";
import { visuallyHidden } from "@mui/utils";
import {
  TableSortLabel,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  TextField,
} from "@mui/material";
import "../../assets/sass/custom.scss";
import upArrow from "../../assets/images/icons/up-simple.svg";
import downArrow from "../../assets/images/icons/down-simple.svg";

export default function Sort({
  order,
  orderBy,
  headCells,
  handleRequestSort,
}: any) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell: any) => (
          <TableCell
            key={headCell.id}
            style={{ width: headCell.width, textAlign: headCell.textAlign }}
            className="headStyle"
            align={"center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.isNotSortable ? (
              headCell.label
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={() => handleRequestSort(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
