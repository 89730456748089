import { Flip, ToastContainer } from "react-toastify";

const ToastMessageContainer = () => {
  return (
    <ToastContainer
      className="toast-sec"
      autoClose={3000}
      position="top-center"
      transition={Flip}
      hideProgressBar={false}
      newestOnTop={false}
      closeButton
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
};

export { ToastMessageContainer };
