import { TAdminUpdateInfluencer } from "./../../../data/types/adminApi";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { appErrors } from "../../../AppConstants";
import {
  ADMIN_FETCH_INFLUENCER,
  ADMIN_UPDATE_INFLUENCER,
} from "../../../services/AppServices";
import moment from "moment";
import { formatPhoneMaskNumber } from "../../../utils/sharedFunctions";
export const FORM_VALIDATION = Yup.object().shape({
  first_name: Yup.string()
    .required("First name is required!")
    .max(30, "First name must be 30 characters!"),
  last_name: Yup.string()
    .required("Last name is required!")
    .max(30, "Last name must be 30 characters!"),
  contact_number: Yup.string()
    .required("Primary contact number is required!")
    .max(20, "Primary contact number must be 20 characters!")
    .min(10, "Primary contact number must be 10 characters!")
});

export const getInitialState = (influencer: any) => {
  return {
    first_name: influencer?.first_name || "",
    last_name: influencer?.last_name || "",
    contact_number: influencer?.contact_number || "",
    min_kyc_date: influencer?.min_kyc_date
      ? moment(new Date(influencer.min_kyc_date)).format("YYYY-MM-DD")
      : "",
  };
};

export const fetchInfluencerById = async (id: number) => {
  try {
    const data = await ADMIN_FETCH_INFLUENCER(id);
    if (data.code === 200 || data.code === "200") {
      return data.data;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
};

export const updateInfluencer = async (props: any) => {
  const payload: TAdminUpdateInfluencer = {
    id: props.id,
    id_app_user: props.id_app_user,
    first_name: props.first_name,
    last_name: props.last_name,
    contact_number: formatPhoneMaskNumber(props.contact_number),
    min_kyc_date: props.min_kyc_date,
  };
  try {
    const data = await ADMIN_UPDATE_INFLUENCER(payload);
    if (data.code === 200 || data.code === "200") {
      return true;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }

  return false;
};
