import React, { useState } from "react";
import {Button,Modal, Grid,Select,MenuItem,TextField,TableContainer,Table,TableHead,TableRow,TableCell,Paper, TableBody, IconButton} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { NavLink } from "react-router-dom";
import { Visibility } from "@mui/icons-material";
import usePaginationState from "../../../hooks/usePaginationState";
import { apiCallFETCHTRIPLIST } from "./ApiTrips";
import {PER_PAGE_ROWS_OPTIONS} from "../../../AppConfig";
import moment from "moment";
import {List,ListItem,ListItemText,ListItemAvatar} from '@mui/material';

import { Close } from "@mui/icons-material";
import AlbumIcon from '@mui/icons-material/Album';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import ScaleIcon from '@mui/icons-material/Scale';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { getCurrentDate } from "../../../utils/sharedFunctions";
import { toast } from "react-toastify";
import { DATEPICKER_DATE_FORMAT } from "../../../AppConfig";
import TablePagination from "../../../components/common/TablePagination";
const TripsDetails = () =>{
  const [startDate, setStartDate] = useState("");
  const [tripsList, setTripsList] = React.useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const todaysDate = moment(getCurrentDate()).format(DATEPICKER_DATE_FORMAT);
  const { pagination, setPagination } = usePaginationState({
    searchFilter: "",
  });
  let timer: ReturnType<typeof setTimeout>;
  const [isLoading, setIsLoading] = React.useState(false);
   React.useEffect(()=>{
    fetchData();
   },[])   
    const fetchData = async () =>{
          setIsLoading(true);
          try{
            setTripsList([]);
            const data = await apiCallFETCHTRIPLIST(pagination);
            
            console.log(data);
           

            if (data && data.rows && data.count) {
              let count = data.count ? (parseInt(data.count)) : 1
              let perPage = parseInt(pagination.perPageRows)
              let totalPages = count > perPage ? parseInt((count / perPage).toString()) + 1 : 1

              setPagination({
                searchFilter: pagination.searchFilter,
                startDate: pagination.startDate,

                endDate: pagination.endDate,
                perPageRows: pagination.perPageRows,
                currentPage: pagination.currentPage,
                totalPages: totalPages,
                totalItems: count

              });

              setTripsList(data.rows);
              
            }
          }catch (e) {}
          setIsLoading(false);
    }

    const handleRowsPerPage = (rowCount: number) => {
      pagination.perPageRows = rowCount;
      pagination.currentPage = 1;
      fetchData();
    };
  

    const handlePageChange = (page: number) => {
      pagination.currentPage = page;
      fetchData();
    };
    const handleSearchChange = async (e: any) => {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(async () => {
        if (e.target.name == "searchFilter") {
          pagination.searchFilter = e.target.value;
          pagination.currentPage = 1;
          fetchData();
        }
      }, 500);}
    

    const stutesHandlar = (row: any) =>{
      if(row.trip_status === 1){
        return(
          <span className="status0">Schedule</span>
        )
      }else if (row.trip_status === 2){
        return(
          <span className="status2">Running</span>
        )
      }else if (row.trip_status === 3){
        return( 
        <span className="status3">Running But Stop</span>
        )
      }else if (row.trip_status === 4){
        return( 
        <span className="status1">completed</span>
        )
      }
    } 
    const handleSearchStartdate = async (e: any) => {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(async () => {
        if (e.target.name == "startDate") {
          pagination.startDate = e.target.value;
          const firstDate = moment (new Date(pagination.startDate)).format(DATEPICKER_DATE_FORMAT);
          setStartDate(firstDate);
          
        }
      }, 500);
    }
    const handleSearchEnddate = async (e: any) => {
        if (timer) {
          clearTimeout(timer);
        }
        timer = setTimeout(async () => {
          
          if (e.target.name == "endDate") {
            pagination.endDate = e.target.value;
          }
        
        }, 500);
    }
    const fetchdate = () =>{
      if(startDate >= pagination.endDate){
        toast.error("Enter the Date GreaterThen Start Date")
      }else if( startDate == ""){
        toast.error("First Enter the Start date..")
      }else{ pagination.currentPage = 1;
        fetchData();}
      }
    return (
        <React.Fragment>
        <div className="page-header">
            <span className="page-title">Trip List</span>
        </div>
        <div>
        <Grid container spacing={2} className="headElements">
          <Grid item className="leftHead">
            <p className="selectPages">Show</p>
            <Select value={pagination.perPageRows}
              label="Page"
              className="dropDown"
              onChange={(e:any) => handleRowsPerPage(Number(e.target.value))}
            >
              {PER_PAGE_ROWS_OPTIONS.map((item: number) => <MenuItem value={item}>{item}</MenuItem>)}
            </Select>
            <span  className="margin-left-16 btn-icon" title="Reload" onClick={()=>fetchData()}>
                <RefreshIcon fontSize="large" className="color-light-blue" />
            </span>
          </Grid>
            <Grid item className="rightHead">
              <TextField
                id="date"
                label="Start Date"
                type="date"
                name="startDate"
                sx={{ width: 220 }}
                inputProps={{ max: todaysDate }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e: any) => handleSearchStartdate(e)}
              />
              <TextField
                id="date"
                label="End Date"
                type="date"
                name="endDate"
                disabled={startDate === "" ? true : false}
                inputProps={{ max: todaysDate, min: startDate }}
                sx={{ width: 220 }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e: any) => handleSearchEnddate(e)}
              />
              <IconButton><SearchOutlinedIcon fontSize="large" className="color-light-blue" onClick={() => fetchdate()} /></IconButton>
            </Grid>
          <Grid item className="rightHead">
            <p className="selectPages">Search</p>
            <TextField
              size={"small"}
              className="searchField"
              id="outlined-basic"
              name="searchFilter"
              placeholder="Search Trips"
              onChange={(e: any) => handleSearchChange(e)}
            />
          </Grid>
        </Grid>
        <TableContainer component={Paper} className="tableContainer">
          <Table stickyHeader className="table" aria-label="simple table">
         <TableHead>
            <TableRow>
                <TableCell  style={{ fontWeight:"bold",  fontSize:16}}>Sr.No</TableCell>
                <TableCell style={{ fontWeight:"bold",  fontSize:16}}>Start Location</TableCell> 
                <TableCell style={{ fontWeight:"bold",  fontSize:16}}>End Location</TableCell>
                <TableCell style={{ fontWeight:"bold",  fontSize:16}}>Start Time</TableCell>
                <TableCell style={{ fontWeight:"bold",  fontSize:16}}>End Time</TableCell>
                <TableCell style={{ fontWeight:"bold",  fontSize:16}}>Trips Miles</TableCell>
                <TableCell style={{ fontWeight:"bold",  fontSize:16}}>Trips Duration</TableCell>
                <TableCell style={{ fontWeight:"bold",  fontSize:16}}>Stops</TableCell>
                <TableCell style={{ fontWeight:"bold",  fontSize:16}}>Status</TableCell>
                <TableCell style={{ fontWeight:"bold",  fontSize:16}}>Action</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
               {isLoading ? (
                <TableCell colSpan={7}>
                <div className="loader-container">
                  <div className="loader"></div>
                </div>
              </TableCell>
               ):(
                tripsList.map((row: any,i) =>(
                <TableRow  key={i}>
                <TableCell  style={{ fontWeight:"bold"}}>{i + 1}</TableCell>           
                <TableCell>{row.start_location}</TableCell>
                <TableCell>{row.end_location}</TableCell>
                <TableCell>{row.start_time = moment(new Date(row.start_time)).format(DATEPICKER_DATE_FORMAT)}</TableCell>
                
                <TableCell>{(row.end_time == undefined || row.end_time == null )?<div>-</div>:(row.end_time=moment(row.end_time).format(DATEPICKER_DATE_FORMAT))}</TableCell>
                <TableCell>{row.miles}</TableCell>
                <TableCell>{row.time_duration}</TableCell>  
                <TableCell> <Button onClick={() => setModalIsOpen(true)}>Viewall</Button></TableCell>
                <TableCell>{stutesHandlar(row)}</TableCell>
                <TableCell><NavLink to={`/admin/trip-details/${row.id}`} style={{ textDecoration: 'none' }}><IconButton/><Visibility/></NavLink></TableCell>
                </TableRow>
                ))
               )}          
                </TableBody>
        </Table>
        </TableContainer>
        <TablePagination
          currentPage={pagination.currentPage}
          perPageRows={pagination.perPageRows}
          totalItems={pagination.totalItems}
          totalPages={pagination.totalPages}
          handleOnChangePage={handlePageChange}
        />
          </div>
          <Modal open={modalIsOpen}>
          <div className="center-modal-box">
            <div className="modal-header">
              <div className="modal-title">Stops Details</div>
              <span className="modal-btn-close">
                <Close onClick={() => setModalIsOpen(false)} />
              </span>
            </div>
            <hr />
            <List>
              <ListItem>
                <ListItemAvatar>

                  <AlbumIcon color="primary" />

                </ListItemAvatar>
                <ListItemText primary="RaRa Trucking inc" />
                <ListItemText primary="2.3 Mile" />
              </ListItem>
              <ListItem>
                <ListItemAvatar>

                  <LocalParkingIcon color="primary" />

                </ListItemAvatar>
                <ListItemText primary="RaRa Trucking inc" />
                <ListItemText primary="50 Mile" />
              </ListItem>
              <ListItem>
                <ListItemAvatar>

                  <ScaleIcon color="primary" />

                </ListItemAvatar>
                <ListItemText primary="RaRa Trucking inc" />
                <ListItemText primary="150 Mile" />
              </ListItem>
              <ListItem>
                <ListItemAvatar>

                  <LocalGasStationIcon color="primary" />

                </ListItemAvatar>
                <ListItemText primary="RaRa Trucking inc" />
                <ListItemText primary="150 Mile" />
              </ListItem>

            </List>


            <div className="display-flex justify-content-center">

              <Button onClick={() => setModalIsOpen(false)}>
                CANCEL
              </Button>
            </div>

          </div>
        </Modal>
         
        </React.Fragment>
    )
}

export default TripsDetails

