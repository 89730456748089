import {
  ADMIN_BRAND_APPROVE_DISAPPROVE,
  BRAND_ACTIVE_DEACTIVE,
} from "./../../../services/AdminServices";
import { toast } from "react-toastify";
import { appErrors } from "../../../AppConstants";
import { TFetchAllBrand } from "../../../data/types/adminApi";
import {
  ADMIN_ACCOUNT_ACTIVE_DEACTIVE,
  ADMIN_ACCOUNT_APPROVE_DISAPPROVE,
  ADMIN_FETCH_ALL_BRAND,
  DELETE_BRAND,
} from "../../../services/AppServices";
import { getEncryptedText } from "../../../utils/sharedFunctions";

export const fetchBrandList = async (props: any = {}) => {
  const payload: TFetchAllBrand = {
    vendorId: props.vendorId ? props.vendorId : "",
    searchFilter: props.searchFilter ? props.searchFilter : "",
    perPageRows: props.perPageRows ? props.perPageRows : 10,
    currentPage: props.currentPage ? props.currentPage : 1,
    isActive: props.isActive ? props.isActive : "",
    orderBy: props.orderBy ? props.orderBy : "DESC",
    sortBy: props.sortBy ? props.sortBy : "id",
  };
  try {
    const data = await ADMIN_FETCH_ALL_BRAND(payload);
    if (data.code === 200 || data.code === "200") {
      return data.data;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
};

export const deleteBrand = async (id: number) => {
  try {
    const payload = getEncryptedText(id.toString());
    const data = await DELETE_BRAND(payload);
    if (data.code === 200 || data.code === "200") {
      toast.success(data?.message);
      return true;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
  return false;
};

export const activeDeactiveAccount = async (id: number, is_active: number) => {
  try {
    const payload = { id, is_active };
    const data = await BRAND_ACTIVE_DEACTIVE(payload);
    if (data.code === 200 || data.code === "200") {
      toast.success(data.message);
      return true;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
  return false;
};

export const approvedDeapprovedBrand = async (
  id: number,
  isApproved: number
) => {
  try {
    const payload = { id, isApproved };
    const data = await ADMIN_BRAND_APPROVE_DISAPPROVE(payload);
    if (data.code === 200 || data.code === "200") {
      toast.success(data.message);
      return true;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
  return false;
};

const headCells = [
  {
    id: "id_image",
    label: "Brand Logo",
    textAlign: "left",
    width: "10%",
  },
  {
    id: "brand_name",
    disablePadding: false,
    label: "Brand Name",
    textAlign: "left",
    width: "15%",
  },
  {
    id: "brand_about",
    disablePadding: false,
    label: "Description",
    textAlign: "left",
    width: "20%",
  },
  {
    id: "modified_date",
    disablePadding: false,
    label: "Last Modification",
    textAlign: "center",
    width: "14%",
  },
  {
    id: "isActive",
    //   numeric: true,
    disablePadding: false,
    label: "Status",
    width: "8%",
    isNotSortable: true,
  },
  {
    id: "action",
    disablePadding: false,
    label: "Actions",
    width: "18%",
    textAlign: "left",
    isNotSortable: true,
  },
];

export { headCells };
