import React from "react";
import { GET_IMAGE } from "../../services/AppServices";
import NoImgAvailable from "../../assets/images/no-image.jpg";

const ImageRenderer = (props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [imgSrc, setImgSrc] = React.useState(null);

  React.useEffect(() => {
    fetchImage();
  }, []);

  React.useEffect(() => {
    // fetchImage();
  }, [props]);

  function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  const fetchImage = async () => {
    try {
      setIsLoading(true);
      const blob = await GET_IMAGE(props.src);
      // const b64encoded = await  blobToBase64(blob)
      let b64encoded = btoa(
        [].reduce.call(
          new Uint8Array(blob),
          function (p, c) {
            return p + String.fromCharCode(c);
          },
          ""
        )
      );
      let mimetype = "image/jpeg";

      setImgSrc("data:" + mimetype + ";base64," + b64encoded);
    } catch (e) {
      errorImage();
    } finally {
      setIsLoading(false);
    }
  };

  const errorImage = () => {
    setImgSrc(NoImgAvailable);
  };

  return (
    <React.Fragment>
      {isLoading && 
        !props.noLoader && (<div className="display-flex justify-content-center align-items-center w-100 h-100" style={{ height: props.height || '200px', width: props.width || '200Px' }}>
          <div className="loader-container">
            <span className="loader"></span>
          </div>
        </div>)
      
      }
      {!isLoading && (
        <img
          onError={() => errorImage()}
          src={imgSrc}
          width={props.width || 200}
          height={props.height || 200}
          className={props.className || ""}
          title={props.title || ""}
          alt={props.alt || "Image"}
          style={props.style || {}}
        />
      )}
    </React.Fragment>
  );
};

export default ImageRenderer;
