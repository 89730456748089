import { connect } from "react-redux";
import Login from '../views/public/auth/AppLogin';
import { addLoginInfo, addUserInfo } from "../store/actions/userInfoAction";
import { TReduxDispatch, TReduxState } from "../store/store";

const mapStateToProps = (state: TReduxState) => ({
});

const mapDispatchToProps = (dispatch: any) => ({
  addMainLoginInfoHandler: (data: any) => dispatch(addLoginInfo(data)),
  addMainUserInfoHandler: (data: any) => dispatch(addUserInfo(data)),
});

const LoginContainer = connect(mapStateToProps, mapDispatchToProps)(Login);
export default LoginContainer;
