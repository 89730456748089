import React from "react";
import { Grid, Button, Box, Typography } from "@mui/material";
import {
  verifyUserAccount,
  reSendOtp,
  apiCallFetchResndOtpWaitingTime,
} from "./verify";
import { useParams } from "react-router-dom";
import { useVerifyOtpState } from "../../../hooks/verify-otp/useVerifyOtpState";
import OtpInput from 'react-otp-input';

const VerifyAppUser = (props: any) => {
  let params = useParams();
  const {
    otp,
    setOtpValue,
    canResendOtp,
    setCanResendValue,
    timer,
    increaseTimerValue,
    waitingTime,
    setWaitingTimeValue,
  } = useVerifyOtpState();
  let timeout: ReturnType<typeof setTimeout>;
  let interval: ReturnType<typeof setInterval>;

  React.useEffect(() => {
    fetchData();
  }, []);

  React.useEffect(() => {
    if (canResendOtp) {
      clearInterval(timeout);
      clearInterval(interval);
    }
    return () => {
      clearInterval(timeout);
      clearInterval(interval);
    };
  }, [canResendOtp]);

  const fetchData = async () => {
    const data = await apiCallFetchResndOtpWaitingTime();
    if (data) {
      setWaitingTimeValue(data);
      timeout = setTimeout(function () {
        setCanResendValue(true);
      }, Number(data) * 1000);
      interval = setInterval(function () {
        increaseTimerValue();
      }, 1000);
    }
  };

  return (
    <React.Fragment>
      <Grid container justifyContent="center" className="form-container">
        <Grid item xs={12} sm={8} md={6} lg={4}>
          <div className="form-box">
            <Grid container spacing={2} sx={{ p: 2 }} justifyContent="center">
              <Box>
                {/* <OtpInput
                  inputStyle="custom-otp"
                  separator={<span className="custom-otp-separator">-</span>}
                  value={otp}
                  onChange={setOtpValue}
                  numInputs={6}
                  isInputNum={true}
                /> */}
              </Box>
              <Grid container justifyContent="center" sx={{ pt: 3 }}>
                <Button
                  disabled={otp.length !== 6}
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    verifyUserAccount(
                      { id: params.id, otp: otp },
                      props.addMainLoginInfoHandler,
                      props.addMainUserInfoHandler
                    )
                  }
                >
                  VERIFY
                </Button>
              </Grid>
              <Grid container justifyContent="center" sx={{ pt: 3 }}>
                <Button
                  disabled={!canResendOtp}
                  variant="contained"
                  color="primary"
                  onClick={() => reSendOtp(params.id || "")}
                >
                  RE SEND OTP
                </Button>
              </Grid>
              <Grid container justifyContent="center" sx={{ pt: 1 }}>
                <Typography className="text-3">
                  {canResendOtp
                    ? "Now you can resend otp to your registered email"
                    : `You can resend otp in ${waitingTime - timer} seconds`}
                </Typography>
              </Grid>
            </Grid>
            {/* </Form>
          </Formik> */}
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default VerifyAppUser;
