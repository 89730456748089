import React from "react";
import { Card, ClickAwayListener } from "@mui/material";
import { Menu } from "@mui/icons-material";
import { localStorageUtils } from "../../../utils/localStorageUtils";
import { UserType, UserTypeLabel } from "../../../utils/sharedFunctions";
import NameAvatar from "../../common/NameAvatar";
import useConfirmationModalState from "../../../hooks/useConfirmationModalState";
import ConfirmationModal from "../../common/ConfirmationModal";
import { logout } from "./header";
import ChangePasswordModal from "../../change-password/ChangePasswordModal";
import history from "../../../history";
import ImageRenderer from "../../core/ImageRenderer";
import { vendorRoutePath } from "../../../AppConstants";

const HorizontalHeader = (props: any) => {
  const { showCmfModal, setShowCmfModal, cmfModalDetails, setCmfModalDetails } =
    useConfirmationModalState();
  const [showMenu, setShowMenu] = React.useState(false);
  const [isImageLoading, setIsImageLoading] = React.useState<boolean>(false);
  const [showChangePasswordModal, setShowChangePasswordModal] =
    React.useState(false);

    React.useEffect(() => {
      if(props.userInfo && props.userInfo.user_type == UserType.Vendor){
        setIsImageLoading(true)
        setTimeout(() => {
          setIsImageLoading(false)
        },200);
      }
    }, [props.userInfo]);
  React.useEffect(() => {
    setCmfModalDetails({
      id: 0,
      title: "Logout",
      message: `You are about to logout from the system.`,
    });
  }, []);

  const handleDelCloseModal = (status: boolean) => {
    setShowCmfModal(false);
    if (status) {
      logout(props.loginInfo?.id_app_user);
    }
  };

  return (
    <React.Fragment>
      <Card className="horizontal-header-card card-box-shadow">
     
        <div>
          <span
            className="toggle-menu-icon"
            onClick={() => props.toggleExpanded()}
          >
            <Menu />
          </span>
        </div>
        <div className="user-profile-wraper">
          <div className="profile-user-name">
            <div className="text-4">
              {props.loginInfo?.user_type == UserType.Administrator
                ? "System Administrator"
                : props.userInfo?.first_name + " " + props.userInfo?.last_name}
            </div>
            <div className="text-2">
              {UserTypeLabel.get(props.loginInfo?.user_type)}
            </div>
          </div>
          <ClickAwayListener onClickAway={() => setShowMenu(false)}>
            <span id="user-avatar" onClick={() => setShowMenu(!showMenu)}>
              {props.loginInfo?.user_type == UserType.Vendor &&
              props.userInfo?.vendor_image &&
              props.userInfo?.vendor_image.image_path &&
              !isImageLoading
              ? (
                <ImageRenderer
                  noLoader={true}
                  src={props.userInfo?.vendor_image.image_path}
                  height="40px"
                  width="40px"
                  style={{
                    border: "1px solid lightgrey",
                    borderRadius: "50%",
                  }}
                  title={"Offer Image"}
                  alt={"Offer"}
                />
              ) : (
                <NameAvatar
                  name={
                    props.loginInfo?.user_type == UserType.Administrator
                      ? "System Administrator"
                      : props.userInfo?.first_name +
                        " " +
                        props.userInfo?.last_name
                  }
                />
              )}
            </span>
          </ClickAwayListener>
          {showMenu && (
            <div className="user-icon-dropdown-menu-container">
              <ul className="user-icon-dropdown-menu">
                {props.loginInfo?.user_type == UserType.Vendor && (
                  <li
                    className="dropdown-menu-item"
                    onClick={() => history.push(vendorRoutePath.profile)}
                  >
                    My Profile
                  </li>
                )}
                <li
                  className="dropdown-menu-item"
                  onClick={() => setShowCmfModal(true)}
                >
                  Logout
                </li>
                <li
                  className="dropdown-menu-item"
                  onClick={() => setShowChangePasswordModal(true)}
                >
                  Change Password
                </li>
              </ul>
            </div>
          )}
        </div>
      </Card>
      {showCmfModal && (
        <ConfirmationModal
          closeModal={handleDelCloseModal}
          title={cmfModalDetails.title}
          message={cmfModalDetails.message}
        />
      )}
      {showChangePasswordModal && (
        <ChangePasswordModal
          closeModal={() => setShowChangePasswordModal(false)}
        />
      )}
    </React.Fragment>
  );
};

export default HorizontalHeader;
