import React, { useState } from "react";
import {
  Grid, Select, MenuItem, TextField, TableContainer, Table, TableHead, TableRow, TableCell,Modal,
  Tabs ,Box,Tab ,Typography,Paper,TableBody,Button, IconButton, Alert} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import usePaginationState from "../../../hooks/usePaginationState";
import {  Visibility } from "@mui/icons-material";
import { apiCallFetchAllUsers } from "../users/UserList";
import { PER_PAGE_ROWS_OPTIONS } from "../../../AppConfig";
import moment from "moment";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ShowerIcon from '@mui/icons-material/Shower';
import { getCurrentDate } from "../../../utils/sharedFunctions";
import { toast } from "react-toastify";
import { Close } from "@mui/icons-material";
import PropTypes from 'prop-types';
import {List,ListItem,ListItemText} from '@mui/material';
import TablePagination from "../../../components/common/TablePagination";
//import ListItem from '@mui/material/ListItem';
// import ListItemText from '@mui/material/ListItemText';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import ScaleIcon from '@mui/icons-material/Scale';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import { DATEPICKER_DATE_FORMAT } from "../../../AppConfig";
import HorizontalFooter from "../../../components/layouts/admin/HorizontalFooter";
function TabPanel(props:any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index:any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
/////////////////////////////////////////
const Usermanage = () => {
  const todaysDate = moment(getCurrentDate()).format(DATEPICKER_DATE_FORMAT);
  //const firstDate = moment (new Date(pagination.startDate).format("YYYY-MM-DD"));
  const [userList, setUserList] = React.useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [preference,setPreference] = useState({height: 9.5,width:50,length:105,mpg:12,per_galen_rate:10,engine_type:"gas",avg_speed:70});
  const [stoppreferences,setStopPreferences] =  useState([])
  const { pagination, setPagination } = usePaginationState({
    searchFilter: "",
  });
  const [startDate, setStartDate] = useState("");
  
  let timer: ReturnType<typeof setTimeout>;
  const [isLoading, setIsLoading] = React.useState(false);
 
  React.useEffect(() => {
    fetchData();
  }, [])
  const fetchData = async () => {
    setIsLoading(true);
    try {
      setUserList([]);
      const data = await apiCallFetchAllUsers(pagination);

      console.log(data);

      if (data && data.rows && data.count) {
        let count = data.count ? (parseInt(data.count)) : 1
        let perPage = parseInt(pagination.perPageRows)
        let totalPages = count > perPage ? parseInt((count/perPage).toString()) + 1 : 1

        setPagination({
          searchFilter: pagination.searchFilter,
          startDate: pagination.startDate,
          
          endDate: pagination.endDate,
          perPageRows: pagination.perPageRows,
          currentPage: pagination.currentPage,
          totalPages: totalPages,
          totalItems : count
          
        });

        setUserList(data.rows);

      }
    } catch (e) { }
    setIsLoading(false);
  }

 

  const handleRowsPerPage = (rowCount: number) => {
    pagination.perPageRows = rowCount;
    pagination.currentPage = 1;
    fetchData();
  };

  const handleSearchChange = async (e: any) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(async () => {
      if (e.target.name == "searchFilter") {
        pagination.searchFilter = e.target.value;
        pagination.currentPage = 1;
        fetchData();
      }
    }, 500);
  }
  const handleSearchStartdate = async (e: any) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(async () => {
      if (e.target.name == "startDate") {
        pagination.startDate = e.target.value;
        const firstDate = moment (new Date(pagination.startDate)).format(DATEPICKER_DATE_FORMAT);
        setStartDate(firstDate);
        
      }
    }, 500);
  }
  const handleSearchEnddate = async (e: any) => {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(async () => {
        
        if (e.target.name == "endDate") {
          pagination.endDate = e.target.value;
        }
      
      }, 500);
  }
  const fetchdate = () =>{
    if(startDate == ""){
      toast.error("First Enter the Start date..")
    }else if(startDate >= pagination.endDate){
      toast.error("Enter the Date GreaterThen Start Date")
    }else{ pagination.currentPage = 1;
      fetchData();}
    }
    const [value, setValue] = React.useState(0);
    const handleChange = (event:any, newValue:any) => {
      setValue(newValue);
    };
  //const date = moment(new Date(pagination.startDate)).format("YYYY-MM-DD")
  const fetchPreference = (row:any) => {
      if(row.preference)
      {
        setPreference(row.preference);
        setStopPreferences(row.stop_preferences || []);
        setModalIsOpen(true);
      }else {
        toast.error("Data not Found")
      }
    
  }
 
  const handlePageChange = (page: number) => {
    pagination.currentPage = page;
    fetchData();
  };
  return (
    <React.Fragment>
      <div className="page-header">
        <span className="page-title">User List</span>
      </div>
      <div>

        <Grid container spacing={2} className="headElements">

          <Grid item className="leftHead">
            <p className="selectPages">Show</p>
            <Select value={pagination.perPageRows}
              label="Page"
              className="dropDown"
              onChange={(e:any) => handleRowsPerPage(Number(e.target.value))}
            >
              {PER_PAGE_ROWS_OPTIONS.map((item: number) => <MenuItem value={item}>{item}</MenuItem>)}
            </Select>
            <span className="margin-left-16 btn-icon" title="Reload" onClick={() => fetchData()}>
              <RefreshIcon fontSize="large" className="color-light-blue" />
            </span>
          </Grid>

          <Grid item className="rightHead">
            <TextField
              id="date"
              label="Start Date"
              type="date"
              name="startDate"
              sx={{ width: 220 }}
              inputProps={{ max: todaysDate }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e: any) => handleSearchStartdate(e)}
            />
          <TextField
              id="date"
              label="End Date"
              type="date"
              name="endDate"
              disabled={ startDate === "" ? true: false}
              inputProps={{ max: todaysDate,min:startDate}}
              sx={{ width: 220 }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e: any) => handleSearchEnddate(e)}
            /> 
            <IconButton>
              <SearchOutlinedIcon fontSize="large" className="color-light-blue" onClick={() => fetchdate()}/>
            </IconButton>
          </Grid>

          <Grid item className="rightHead">
            <p className="selectPages">Search</p>
            <TextField
              size={"small"}
              className="searchField"
              id="outlined-basic"
              name="searchFilter"
              placeholder="Search Users"
              onChange={(e: any) => handleSearchChange(e)}
            />
          </Grid>
        </Grid>

        <TableContainer component={Paper} className="tableContainer">
          <Table stickyHeader className="table" aria-label="simple table">
            
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "bold", fontSize: 16 }}>Sr.No</TableCell>
                <TableCell style={{ fontWeight: "bold", fontSize: 16 }}>First Name</TableCell>
                <TableCell style={{ fontWeight: "bold", fontSize: 16 }}>Last Name</TableCell>
                <TableCell style={{ fontWeight: "bold", fontSize: 16 }}>Mobile Number</TableCell>
                <TableCell style={{ fontWeight: "bold", fontSize: 16 }}>Signup Date</TableCell>
                <TableCell style={{ fontWeight: "bold", fontSize: 16 }}>Number of Trips</TableCell>
                <TableCell style={{ fontWeight: "bold", fontSize: 16 }}>Preferences</TableCell>
                <TableCell style={{ fontWeight: "bold", fontSize: 16 }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableCell colSpan={7}>
                  <div className="loader-container">
                    <div className="loader"></div>
                  </div>
                </TableCell>
              ) : (
                userList.map((row: any, i) => (

                  <TableRow key={i}>
                    <TableCell  style={{ fontWeight:"bold"}}>{i + 1}</TableCell>
                    <TableCell>{row.first_name}</TableCell>
                    <TableCell>{row.last_name}</TableCell>
                    <TableCell>{row.mobile_number}</TableCell>
                    <TableCell>{row.created_date = moment(new Date(row.created_date)).format(DATEPICKER_DATE_FORMAT)}</TableCell>
                    <TableCell>{row.tripCount}</TableCell>
                    <TableCell>
                    <Button onClick={() => fetchPreference(row)}>Viewall</Button>
                    </TableCell>
                    <TableCell>
                      
                      <IconButton>
                        <Visibility />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          currentPage={pagination.currentPage}
          perPageRows={pagination.perPageRows}
          totalItems={pagination.totalItems}
          totalPages={pagination.totalPages}
          handleOnChangePage={handlePageChange}
        />
        {/* <HorizontalFooter /> */}
      </div>
          <Modal open={modalIsOpen}>
          <div className="center-modal-box">
            <div className="modal-header">
              <div className="modal-title">	Preferences</div>
              <span className="modal-btn-close">
                <Close onClick={() => setModalIsOpen(false)} />
              </span>
            </div>
            <hr />
            <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Truck Preferences" {...a11yProps(0)} />
                <Tab label="stop Preferences" {...a11yProps(1)} />
               
              </Tabs>
            </Box>
            <TabPanel value={value} index={0} >
            
                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                            <ListItem >
                                 <ListItemText>Height:</ListItemText>
                                <ListItemText className="text-align-center" primary={preference.height} />
                            </ListItem>
                            <ListItem>
                                <ListItemText>Width:</ListItemText>
                                <ListItemText className="text-align-center" primary={preference.width}  />
                            </ListItem>
                            <ListItem>
                                <ListItemText>Length:</ListItemText>
                                <ListItemText className="text-align-center" primary={preference.length}  />
                            </ListItem>
                            <ListItem>
                                <ListItemText>MPG:</ListItemText>
                                <ListItemText className="text-align-center" primary={preference.mpg}  />
                            </ListItem>
                            <ListItem>
                                <ListItemText>$/G rate:</ListItemText>
                                <ListItemText className="text-align-center" primary={preference.per_galen_rate}  />
                            </ListItem>
                            <ListItem>
                                <ListItemText>Fuel Types:</ListItemText>
                                <ListItemText className="text-align-center" primary={preference.engine_type}  />
                            </ListItem>
                            <ListItem>
                                <ListItemText>speed:</ListItemText>
                                <ListItemText className="text-align-center" primary={preference.avg_speed}  />
                            </ListItem>
                        </List>
                       
            </TabPanel>
            <TabPanel value={value} index={1}>
            <TableContainer component={Paper} className="tableContainer poppuptableheight">
          <Table stickyHeader className="table table2" aria-label="simple table">
         <TableHead>
            <TableRow>
                <TableCell style={{ fontWeight:"bold"}}> Sr.No </TableCell>
                <TableCell style={{ fontWeight:"bold"}}> Sation Type </TableCell> 
                <TableCell style={{ fontWeight:"bold"}}> Station Name </TableCell>
                <TableCell style={{ fontWeight:"bold"}}> Station Address </TableCell>
                <TableCell style={{ fontWeight:"bold"}}> Facilities </TableCell>
               
            </TableRow>
            </TableHead>
            <TableBody>
            
                {stoppreferences.map((row: any,i) =>(
                <TableRow key={i}>
                  <TableCell style={{ fontWeight:"bold"}}>{i + 1}</TableCell>         
                  <TableCell>{row.type}</TableCell>
                  <TableCell>{row.title}</TableCell>
                  <TableCell>{row.address}</TableCell>
                 
                  <TableCell>
                      
                          {row.rest_area === 1 ? <IconButton><ShowerIcon /></IconButton>:<></>}
                          {row.parking === 1 ? <IconButton><LocalParkingIcon /></IconButton>:<></>}
                      <IconButton>
                          <MiscellaneousServicesIcon />
                      </IconButton>
                          {row.weight_station === 1 ? <IconButton><ScaleIcon /></IconButton>:<></>}
                     
                      
                          {row.fuel_station == 1?<IconButton><LocalGasStationIcon /></IconButton>:<></>}
                      
                  </TableCell>
                  <TableCell>
                  
                 
                  </TableCell>

             </TableRow>))}
       </TableBody>
            
         

        </Table>
        </TableContainer>
            </TabPanel>
           
          </Box>
           
            <div className="display-flex justify-content-center">

              <Button onClick={() => setModalIsOpen(false)}>
                CANCEL
              </Button>
            </div>

          </div>
        </Modal>

    </React.Fragment>
  )
}
export default Usermanage;