import * as Yup from "yup";

export const getInitialState = (discount_amount: number | null = null) => {
  return {
    discount_amount: discount_amount || "",
  };
};

export const FORM_VALIDATION = Yup.object().shape({
  discount_amount: Yup.number()
    .required("Discount amount is required!")
});