import React from "react";
import usePaginationState from "../../../hooks/usePaginationState";
import {
  headCells,
  apiCallFetchAllCards,
  apiCallLockUnlockCard,
} from "./CardList";
import TablePagination from "../../../components/common/TablePagination";
import {
  TableCell,
  TextField,
  Table,
  TableBody,
  TableContainer,
  Paper,
  Grid,
  Select,
  MenuItem,
  IconButton,
  Switch,
} from "@mui/material";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../components/common/TableCell";
import SortTableColumn from "../../../components/common/SortTableColumn";
import RefreshIcon from "@mui/icons-material/Refresh";
import { InfoOutlined, Block } from "@mui/icons-material";
import {
  M2PCardLockFlag,
  M2PCardType,
  M2PCardLockFlagLabel,
  formatContactNumber,
} from "../../../utils/sharedFunctions";
import useCardStatusChangeReasonModalState from "../../../hooks/useCardStatusChangeReasonModalState";
import CardStatusChangeReasonModal from "../../../components/card/CardStatusChangeReasonModal";
import useConfirmationModalState from "../../../hooks/useConfirmationModalState";
import ConfirmationModal from "../../../components/common/ConfirmationModal";
import { PER_PAGE_ROWS_OPTIONS } from "../../../AppConfig";

const CardListView = () => {
  const [cardList, setCardList] = React.useState<any>([]);
  const { pagination, setPagination } = usePaginationState({
    searchFilter: "",
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const { showSCRModal, setShowSCRModal, scrModalDetails, setSCRModalDetail } =
    useCardStatusChangeReasonModalState();
  const { showCmfModal, setShowCmfModal, cmfModalDetails, setCmfModalDetails } =
    useConfirmationModalState();
  let timer: ReturnType<typeof setTimeout>;

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      setCardList([]);
      const data = await apiCallFetchAllCards(pagination);
      if (data) {
        setPagination({
          searchFilter: data.pagination.searchFilter
            ? data.pagination.searchFilter
            : "",
          perPageRows: data.pagination.perPageRows
            ? data.pagination.perPageRows
            : 10,
          currentPage: data.pagination.currentPage
            ? data.pagination.currentPage
            : 1,
          totalPages: data.pagination.totalPages
            ? data.pagination.totalPages
            : 0,
          totalItems: data.pagination.totalItems
            ? data.pagination.totalItems
            : 0,
          orderBy: data.pagination.orderBy ? data.pagination.orderBy : "desc",
          sortBy: data.pagination.sortBy ? data.pagination.sortBy : "id",
        });
        setCardList(data.cards);
      }
    } catch (e) {}
    setIsLoading(false);
  };

  const handlePageChange = (page: number) => {
    pagination.currentPage = page;
    fetchData();
  };

  const handleRequestSort = (property: string) => {
    pagination.orderBy =
      pagination.sortBy == property
        ? pagination.orderBy == "asc"
          ? "desc"
          : "asc"
        : "asc";
    pagination.sortBy = property;
    fetchData();
  };

  const handleRowsPerPage = (rowCount: number) => {
    pagination.perPageRows = rowCount;
    pagination.currentPage = 1;
    fetchData();
  };

  const handleSearchChange = async (e: any) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(async () => {
      if (e.target.name == "searchFilter") {
        pagination.searchFilter = e.target.value;
        pagination.currentPage = 1;
        fetchData();
      }
    }, 500);
  };

  const handleReasonSubmit = (reason: string) => {
    setShowSCRModal(false);
    apiCallLockUnlockCard(
      {
        id: scrModalDetails.id,
        flag: scrModalDetails.status,
        reason,
      },
      fetchData
    );
  };

  const handleShowReasonModal = (
    id: number,
    type: number,
    reason: string | null
  ) => {
    setSCRModalDetail({ id, status: type, reason });
    setShowSCRModal(true);
  };

  const showBlockCardCmfModal = (id: number, type: number, name: string) => {
    setSCRModalDetail({ id, status: type, reason: null });
    setCmfModalDetails({
      id: 0,
      title: "Permanent Block Card",
      message: `You are about to permanently block ${name}'s card`,
    });
    setShowCmfModal(true);
  };

  const closeBlockCardCmfModal = (status: boolean) => {
    if (status) {
      setShowSCRModal(true);
    }
    setShowCmfModal(false);
  };

  return (
    <React.Fragment>
      <div className="page-header">
        <span className="page-title">Card List</span>
      </div>
      <div>
        <Grid container spacing={2} className="headElements">
          <Grid item className="leftHead">
            <p className="selectPages">Show</p>
            <Select
              value={pagination.perPageRows}
              label="Page"
              className="dropDown"
              onChange={(e:any) => handleRowsPerPage(Number(e.target.value))}
            >
              {PER_PAGE_ROWS_OPTIONS.map((item: number) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
            <span
              className="margin-left-16 btn-icon"
              title="Reload"
              onClick={() => fetchData()}
            >
              <RefreshIcon fontSize="large" className="color-light-blue" />
            </span>
          </Grid>

          <Grid item className="rightHead">
            <p className="selectPages">Search</p>
            <TextField
              size={"small"}
              className="searchField"
              id="outlined-basic"
              name="searchFilter"
              placeholder="Search Cards"
              onChange={(e: any) => handleSearchChange(e)}
            />
          </Grid>
        </Grid>
        <TableContainer component={Paper} className="tableContainer">
          <Table stickyHeader className="table" aria-label="simple table">
            <SortTableColumn
              order={pagination.orderBy}
              orderBy={pagination.sortBy}
              headCells={headCells}
              handleRequestSort={handleRequestSort}
            />
            <TableBody>
              {isLoading ? (
                <TableCell colSpan={7}>
                  <div className="loader-container">
                    <div className="loader"></div>
                  </div>
                </TableCell>
              ) : (
                cardList.map((row: any, index: number) => (
                  <StyledTableRow
                    hover
                    key={index}
                    className="user-table-row-height"
                  >
                    <StyledTableCell className="table-cell-left-align">
                      <Grid item lg={10}>
                        {row.card_holder_name}
                      </Grid>
                    </StyledTableCell>
                    {/* <StyledTableCell className="table-cell-left-align">
                      {formatContactNumber(row.contact_number)}
                    </StyledTableCell> */}
                    <StyledTableCell className="table-cell-left-align">
                      {/* {formatContactNumber(row.contact_number)} */}
                      {row.address ? row.address : "-"}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell-left-align">
                      XXXX-XXXX-XXXX-{row.card_last_four_digits}
                    </StyledTableCell>
                    <StyledTableCell>{row.card_expiry}</StyledTableCell>
                    <StyledTableCell className="table-cell-left-align">
                      {row.card_type}
                      {/* {row.m2p_card_type == M2PCardType.Physical
                        ? "Physical"
                        : "Virtual"} */}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell-left-align">
                      {row.is_active == "0" ? (
                        "Inactive"
                      ) : (
                        <span>
                          {M2PCardLockFlagLabel.get(row.current_status)}
                          {row.wyld_card_idle_reasons.length > 0 && (
                            <IconButton
                              onClick={() =>
                                handleShowReasonModal(
                                  row.id,
                                  row.current_status,
                                  row.wyld_card_idle_reasons[0].reason
                                )
                              }
                            >
                              <InfoOutlined />
                            </IconButton>
                          )}
                        </span>
                      )}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell-left-align">
                      <Grid
                        container
                        className="actionGrid"
                        justifyContent="start"
                      >
                        {row.current_status !==
                          M2PCardLockFlag.PermanentBlock &&
                          row.is_active == "1" && (
                            <React.Fragment>
                              <Grid>
                                <Switch
                                  className={`${
                                    row.current_status == M2PCardLockFlag.Lock
                                      ? "mui-red-color-switch"
                                      : ""
                                  }`}
                                  color={
                                    row.current_status == M2PCardLockFlag.Lock
                                      ? "error"
                                      : "success"
                                  }
                                  title="Lock/UnLock"
                                  checked={
                                    row.current_status == M2PCardLockFlag.UnLock
                                  }
                                  onChange={() =>
                                    handleShowReasonModal(
                                      row.id,
                                      row.current_status == M2PCardLockFlag.Lock
                                        ? M2PCardLockFlag.UnLock
                                        : M2PCardLockFlag.Lock,
                                      null
                                    )
                                  }
                                />
                              </Grid>
                              <Grid>
                                <IconButton
                                  title="Permanent Block"
                                  onClick={() =>
                                    showBlockCardCmfModal(
                                      row.id,
                                      M2PCardLockFlag.PermanentBlock,
                                      row.card_holder_name
                                    )
                                  }
                                >
                                  <Block className="color-danger" />
                                </IconButton>
                              </Grid>
                            </React.Fragment>
                          )}
                      </Grid>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          currentPage={pagination.currentPage}
          perPageRows={pagination.perPageRows}
          totalItems={pagination.totalItems}
          totalPages={pagination.totalPages}
          handleOnChangePage={handlePageChange}
        />
      </div>
      {showSCRModal && (
        <CardStatusChangeReasonModal
          status={scrModalDetails.status}
          reason={scrModalDetails.reason}
          handleReasonSubmit={handleReasonSubmit}
          closeModal={() => setShowSCRModal(false)}
        />
      )}
      {showCmfModal && (
        <ConfirmationModal
          closeModal={closeBlockCardCmfModal}
          title={cmfModalDetails.title}
          message={cmfModalDetails.message}
        />
      )}
    </React.Fragment>
  );
};

export default CardListView;
