import { connect } from "react-redux";
import VerifyAppUser from '../views/public/verify-otp/VerifyAppUser';
import { addLoginInfo, addUserInfo } from "../store/actions/userInfoAction";
import { TReduxDispatch, TReduxState } from "../store/store";

const mapStateToProps = (state: TReduxState) => ({
});

const mapDispatchToProps = (dispatch: any) => ({
  addMainLoginInfoHandler: (data: any) => dispatch(addLoginInfo(data)),
  addMainUserInfoHandler: (data: any) => dispatch(addUserInfo(data)),
});

const VerifyAppUserContainer = connect(mapStateToProps, mapDispatchToProps)(VerifyAppUser);
export default VerifyAppUserContainer;
