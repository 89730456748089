import {
  TAdminActiveInactiveMerchant,
  TAdminAddMerchant,
} from "./../data/types/adminApi";
import { fetchMerchantById } from "./../views/admin/merchant/ViewEditMerchant";
import { apiEndPoints } from "../AppConstants";
import { httpMethods, serviceMaker } from "./ServiceWarpper";
import { getEncryptedText } from "../utils/sharedFunctions";
import {
  TFetchAllVendors,
  TAdminUpdateVendor,
  TFetchAllInfluencer,
  TAdminUpdateInfluencer,
  TAccountActiveDeactive,
  TAccountApprovedDisapproved,
  TFetchAllBrand,
  TAdminAddBrand,
  TAdminUpdateBrand,
  TNotification,
  TStaticPage,
  TBrandActiveDeactive,
  TBrandApprovedDisapproved,
  TFetchAllUsers,
  TFetchAllCards,
  TFetchAllTransactions,
  TTransactionApproveRejet,
  TFetchAllMerchants,
  TAdminUpdateMerchant,
  TFetchAllFeedbacks,TFETCHTRIPLIST,TFetchStation,
  TFetchTruckInformation,TUpdateTruckInfo,
  TFetchTripDetails,
} from "../data/types/adminApi";


//-----------------------------------------------------------------------------------------------
export const DASHBOARD_LIST = () =>
serviceMaker(
    `${apiEndPoints.DASHBOARD_LIST}`,
    httpMethods.GET,
); 

export const FETCH_ALL_USERS = (payload: TFetchAllUsers) =>
  serviceMaker(
    `${apiEndPoints.FETCH_ALL_USERS}?limit=${payload.perPageRows}&page=${payload.currentPage}&search=${payload.searchFilter}&dateRange[startDate]=${payload.startDate}&dateRange[endDate]=${payload.endDate}`,
    httpMethods.GET
  );

  export const FETCH_TRIP_LIST = (payload: TFETCHTRIPLIST) =>
serviceMaker(
    `${apiEndPoints.FETCH_TRIP_LIST}?limit=${payload.perPageRows}&page=${payload.currentPage}&search=${payload.searchFilter}&dateRange[startDate]=${payload.startDate}&dateRange[endDate]=${payload.endDate}`,
    httpMethods.GET
);

  export const FETCH_TRIPS_DETAILS = (payload:number) =>
  serviceMaker(
    `${apiEndPoints.FETCH_TRIPS_DETAILS_ID}/${payload}`,
    httpMethods.GET
  );
 
  export const FETCH_STATION = (payload: TFetchStation) =>
  serviceMaker(
    `${apiEndPoints.FETCH_STATION}?limit=${payload.perPageRows}&page=${payload.currentPage}`,
    httpMethods.GET
  );
  export const TRUCK_INFO = (payload: TFetchTruckInformation) =>
  serviceMaker(
    `${apiEndPoints.TRUCK_INFO}?limit=${payload.perPageRows}&page=${payload.currentPage}`,
    httpMethods.GET
  );

export const UPDATE_TRUCK_INFO = (payload: TUpdateTruckInfo) =>
  serviceMaker(`${apiEndPoints.UPDATE_TRUCK_INFO}`, httpMethods.PUT, payload);

export const   ADMIN_NOTIFICATION = (
  payload:TNotification
) =>  serviceMaker(
  `${apiEndPoints.ADMIN_NOTIFICATION}`,
  httpMethods.POST,{
    title: payload.title,
    content: payload.content,
}
);
//////////....static page......///////
export const   ADD_STATICPAGES = (
  payload:TStaticPage
) =>  serviceMaker(
  `${apiEndPoints.STATICPAGES}`,
  httpMethods.POST,{
    page_name: payload.page_name,
    contents: payload.contents,}
);

export const FETCH_STATIC_PAGE_LIST = (payload:TStaticPage) => 
serviceMaker(`${apiEndPoints.STATICPAGES}`, httpMethods.GET, payload);

export const FETCH_BY_ID_DETAILS = (payload:number) =>
serviceMaker(
  `${apiEndPoints.STATICPAGES}/${payload}`,
  httpMethods.GET
);

export const UPDATE_STATIC_PAGE = (payload: TStaticPage) =>
  serviceMaker(`${apiEndPoints.STATICPAGES}/${payload.id}`, httpMethods.PUT, payload);

  export const DELETE_STATIC_PAGE = (payload: TStaticPage) =>
  serviceMaker(`${apiEndPoints.DELETE_STATIC_PAGE}/${payload.id}`, httpMethods.DELETE);

//////////////////////////////////////////////////

//---------------------------------------------------------------------------------------------------------------


export const DELETE_USER = (payload: number) =>
  serviceMaker(`${apiEndPoints.DELETE_USER}/${payload}`, httpMethods.DELETE);

export const ADMIN_FETCH_ALL_VENDORS = (payload: TFetchAllVendors) =>
  serviceMaker(
    `${apiEndPoints.FETCH_ALL_VENDOR}?searchFilter=${payload.searchFilter}
&perPageRows=${payload.perPageRows}&currentPage=${payload.currentPage}
&isActive=${payload.isActive}&orderBy=${payload.orderBy}&sortBy=${payload.sortBy}`,
    httpMethods.GET
  );

export const ADMIN_FETCH_VENDOR = (payload: number) =>
  serviceMaker(
    `${apiEndPoints.FETCH_VENDOR_BY_ID}/${getEncryptedText(
      payload.toString()
    )}`,
    httpMethods.GET
  );

export const ADMIN_UPDATE_VENDOR = (payload: TAdminUpdateVendor) =>
  serviceMaker(`${apiEndPoints.UPDATE_VENDOR}`, httpMethods.POST, payload);

export const DELETE_VENDOR = (payload: number) =>
  serviceMaker(
    `${apiEndPoints.ADMIN_DELETE_VENDOR}/${payload}`,
    httpMethods.DELETE
  );

export const ADMIN_ACCOUNT_ACTIVE_DEACTIVE = (
  payload: TAccountActiveDeactive
) =>
  serviceMaker(
    `${apiEndPoints.ACCOUNT_ACTIVE_DEACTIVE}`,
    httpMethods.POST,
    payload
  );

export const ADMIN_ACCOUNT_APPROVE_DISAPPROVE = (
  payload: TAccountApprovedDisapproved
) =>
  serviceMaker(
    `${apiEndPoints.ACCOUNT_APPROVE_DISAPPROVE}`,
    httpMethods.POST,
    payload
  );



export const ADMIN_FETCH_ALL_INFLUENCER = (payload: TFetchAllInfluencer) =>
  serviceMaker(
    `${apiEndPoints.FETCH_ALL_INFLUENCER}?searchFilter=${payload.searchFilter}&perPageRows=${payload.perPageRows}&currentPage=${payload.currentPage}
&isActive=${payload.isActive}&orderBy=${payload.orderBy}&sortBy=${payload.sortBy}`,
    httpMethods.GET
  );

export const ADMIN_FETCH_INFLUENCER = (payload: number) =>
  serviceMaker(
    `${apiEndPoints.FETCH_INFLUENCER_BY_ID}/${getEncryptedText(
      payload.toString()
    )}`,
    httpMethods.GET
  );

export const ADMIN_UPDATE_INFLUENCER = (payload: TAdminUpdateInfluencer) =>
  serviceMaker(`${apiEndPoints.UPDATE_INFLUENCER}`, httpMethods.POST, payload);

export const DELETE_INFLUENCER = (payload: number) =>
  serviceMaker(
    `${apiEndPoints.ADMIN_DELETE_INFLUENCER}/${payload}`,
    httpMethods.DELETE
  );

export const ADMIN_FETCH_ALL_BRAND = (payload: TFetchAllBrand) =>
  serviceMaker(
    `${apiEndPoints.FETCH_ALL_BRAND}?vendorId=${payload.vendorId}&searchFilter=${payload.searchFilter}&perPageRows=${payload.perPageRows}&currentPage=${payload.currentPage}
&isActive=${payload.isActive}&orderBy=${payload.orderBy}&sortBy=${payload.sortBy}`,
    httpMethods.GET
  );

export const ADMIN_FETCH_BRAND = (payload: number) =>
  serviceMaker(
    `${apiEndPoints.FETCH_BRAND_BY_ID}/${getEncryptedText(payload.toString())}`,
    httpMethods.GET
  );

export const ADMIN_ADD_BRAND = (payload: TAdminAddBrand) =>
  serviceMaker(`${apiEndPoints.ADD_BRAND}`, httpMethods.POST, payload);

export const ADMIN_UPDATE_BRAND = (payload: TAdminUpdateBrand) =>
  serviceMaker(`${apiEndPoints.UPDATE_BRAND}`, httpMethods.POST, payload);

export const DELETE_BRAND = (payload: number) =>
  serviceMaker(`${apiEndPoints.DELETE_BRAND}/${payload}`, httpMethods.POST);

export const BRAND_ACTIVE_DEACTIVE = (payload: TBrandActiveDeactive) =>
  serviceMaker(
    `${apiEndPoints.BRAND_ACTIVE_DEACTIVE}`,
    httpMethods.POST,
    payload
  );

export const ADMIN_BRAND_APPROVE_DISAPPROVE = (
  payload: TBrandApprovedDisapproved
) =>
  serviceMaker(
    `${apiEndPoints.ACCOUNT_BRAND_DISAPPROVE}`,
    httpMethods.POST,
    payload
  );

export const FETCH_ALL_CARDS = (payload: TFetchAllCards) =>
  serviceMaker(
    `${apiEndPoints.FETCH_ALL_CARDS}?searchFilter=${payload.searchFilter}
&perPageRows=${payload.perPageRows}&currentPage=${payload.currentPage}
&orderBy=${payload.orderBy}&sortBy=${payload.sortBy}`,
    httpMethods.GET
  );

export const ADMIN_FETCH_ALL_TRANSACTIONS = (payload: TFetchAllTransactions) =>
  serviceMaker(
    `${apiEndPoints.FETCH_ALL_TRANSACTIONS}?first_name=${payload.first_name}&offer_title=${payload.offer_title}&transaction_date=${payload.transaction_date}&transaction_reference_number=${payload.transaction_reference_number}
&perPageRows=${payload.perPageRows}&currentPage=${payload.currentPage}
&orderBy=${payload.orderBy}&sortBy=${payload.sortBy}&status=${payload.status}`,
    httpMethods.GET
  );

export const ADMIN_TRANSACTION_APPROVE_REJECT = (
  payload: TTransactionApproveRejet
) =>
  serviceMaker(
    `${apiEndPoints.TRANSACTION_APPROVE_REJECT}`,
    httpMethods.POST,
    payload
  );

export const ADMIN_ADD_MERCHANT = (payload: TAdminAddMerchant) =>
  serviceMaker(`${apiEndPoints.ADD_MERCHANT}`, httpMethods.POST, payload);

export const DELETE_MERCHANT = (payload: number) =>
  serviceMaker(
    `${apiEndPoints.DELETE_MERCHANT}/${payload}`,
    httpMethods.DELETE
  );

export const ADMIN_FETCH_MERCHANT = (payload: number) =>
  serviceMaker(
    `${apiEndPoints.FETCH_MERCHANT_BY_ID}/${getEncryptedText(
      payload.toString()
    )}`,
    httpMethods.GET
  );

export const ADMIN_FETCH_ALL_MERCHANTS = (payload: TFetchAllMerchants) =>
  serviceMaker(
    `${apiEndPoints.FETCH_ALL_MERCHANT}?searchFilter=${payload.searchFilter}&perPageRows=${payload.perPageRows}&currentPage=${payload.currentPage}
&isActive=${payload.isActive}&orderBy=${payload.orderBy}&sortBy=${payload.sortBy}`,
    httpMethods.GET
  );

export const ADMIN_UPDATE_MERCHANT = (payload: TAdminUpdateMerchant) =>
  serviceMaker(`${apiEndPoints.UPDATE_MERCHANT}`, httpMethods.PUT, payload);

export const ADMIN_ACTIVE_INACTIVE_MERCHANT = (
  payload: TAdminActiveInactiveMerchant
) =>
  serviceMaker(
    `${apiEndPoints.ACTIVE_INACTIVE_MERCHANT}`,
    httpMethods.POST,
    payload
  );

export const ADMIN_FETCH_ALL_FEEDBACKS = (payload: TFetchAllFeedbacks) =>
  serviceMaker(
    `${apiEndPoints.FETCH_ALL_FEEDBACKS}?searchFilter=${payload.searchFilter}&perPageRows=${payload.perPageRows}&currentPage=${payload.currentPage}
&orderBy=${payload.orderBy}&sortBy=${payload.sortBy}`,
    httpMethods.GET
  );
