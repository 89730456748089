import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Home,
  Style,
  LocalOffer,
  RadioButtonChecked,
  RadioButtonUnchecked,
  Close,
} from "@mui/icons-material";
import history from "../../../history";
import { vendorRoutePath } from "../../../AppConstants";
import wyldLogo from '../../../assets/images/login/wyld.png'
import Logo from '../../../assets/images/logo.png';

const VerticalHeader = (props: any) => {
  const location = useLocation();
  const hasWindow = typeof window !== 'undefined';

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());

  useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [hasWindow]);

  const handleNavigation = (path: string) => {
    if(props.isNavExpanded){
      if(windowDimensions && windowDimensions.width){
        if(windowDimensions.width < 1200) props.toggleExpanded();
      }else{
        props.toggleExpanded();
      }
    } 
    history.push(path);
  }

  return ( 
    <React.Fragment>
      <div className={`vertical-header ${props.isNavExpanded ? 'vertical-header-expanded' : 'vertical-header-collapsed'} card-box-shadow`}>
        <div className="logo-container">
          <div className="logo-component">    
            <img className='logo-size' src={Logo}/>
          </div>
          <span className="btn-nav-expanded" 
            onClick={() => props.toggleExpanded()}>
            {props.isNavExpanded ? <RadioButtonChecked /> : <RadioButtonUnchecked />}
          </span>
          <span className="btn-nav-show" 
            onClick={() => props.toggleExpanded()}>
            <Close />
          </span>
        </div>
        <ul className="nav-list">
          <li className={`${location.pathname == vendorRoutePath.dashboard ? 'active' : ''} nav-item`}
            onClick={() => handleNavigation(vendorRoutePath.dashboard)}>
            <span className="nav-icon">
              <Home />
            </span>
            <span className="nav-title">Dashboard</span>
          </li>
          <li className={`${location.pathname == vendorRoutePath.brands ? 'active' : ''} nav-item`}
            onClick={() => handleNavigation(vendorRoutePath.brands)}>
            <span className="nav-icon">
              <Style />
            </span>
            <span className="nav-title">Brands</span>
          </li>
          <li className={`${location.pathname == vendorRoutePath.brandOffers ? 'active' : ''} nav-item`}
            onClick={() => handleNavigation(vendorRoutePath.brandOffers)}>
            <span className="nav-icon">
              <LocalOffer />
            </span>
            <span className="nav-title">Brand Offers</span>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};

export default VerticalHeader;
