import {
  ALLOWED_IMAGE_TYPES,
  APP_DATE_FORMAT,
  DATEPICKER_DATE_FORMAT,
  PER_PAGE_ROWS,
  PRODUCT_IMAGE_ALLOWED_SIZE_KB,
  PROFILE_IMAGE_ALLOWED_SIZE_KB,
} from "./AppConfig";

export const appConstant = {
  GRID_PAGE_SIZE: PER_PAGE_ROWS,
  APP_DATE_FORMAT: APP_DATE_FORMAT,
  DATEPICKER_DATE_FORMAT: DATEPICKER_DATE_FORMAT,
  INVALID_METHOD: "Invalid Method",
  INVALID_RESPONSE: "Invalid Response",
};

export const httpResponseCode = {
  RES_CODE_200: 200,
  RES_CODE_500: 500,
};

export const appErrors = {
  UNKNOWN_ERROR_TRY_AGAIN: "Unknown Errror Occured...! Try again.",
  PAGE_NOT_FOUND: "Page Not Found...!",
  CAPTCHA_VERIFICATION: "Please verify that you are a human!",
  INSTA_SCORE_RANGE_OVERLAP:
    "You can not add this score range! because score range is overlapping with other criteria's score range.",
  PRODUCT_IMAGE_SIZE_TYPE: `Invalid Image! Image size must be less than ${PRODUCT_IMAGE_ALLOWED_SIZE_KB/1000}KB, aspect ratio must be 1:1 and type must be ${ALLOWED_IMAGE_TYPES}`,
  PROFILE_IMAGE_SIZE_TYPE: `Invalid Image! Image size must be less than ${PROFILE_IMAGE_ALLOWED_SIZE_KB/1000}KB, aspect ratio must be 1:1 and type must be ${ALLOWED_IMAGE_TYPES}`,
};

export const apiEndPoints = {
  LOGIN: "/user/admin/login",
  FETCH_ALL_USERS: "user/admin/userlist",
  FETCH_TRIP_LIST: "trip/admin/list",
  FETCH_TRIPS_DETAILS_ID: "trip/admin/details",
  FETCH_STATION:"stop/preferences/popular",
  TRUCK_INFO: "preferences/admin",
  ADMIN_NOTIFICATION:"user/admin/notification",
  UPDATE_TRUCK_INFO: "preferences/admin",
  STATICPAGES:"public/staticpage",
  DELETE_STATIC_PAGE:"public/staticpage",
  LOGOUT: "logout",
  VENDOR_REGISTER: "vendor-register",
  VERIFY_VENDOR: "verify-vendor",
  RESEND_OTP: "resend-otp",
  FETCH_RESEND_OTP_WAITING_TIME: "resend-otp-waiting-time",
 
  DELETE_USER: "account-delete",
  FETCH_ALL_VENDOR: "vendors",
  FETCH_VENDOR_BY_ID: "vendor",
  UPDATE_VENDOR: "vendor-update-profile",
  ADMIN_DELETE_VENDOR: "vendor",
  FETCH_ALL_INFLUENCER: "influencers",
  FETCH_INFLUENCER_BY_ID: "influencer",
  UPDATE_INFLUENCER: "influencer-update-profile",
  ADMIN_DELETE_INFLUENCER: "influencer",

  FETCH_ALL_BRAND: "brand",
  FETCH_BRAND_BY_ID: "brand",
  ADD_BRAND: "add-brand",
  UPDATE_BRAND: "brand",
  DELETE_BRAND: "delete-brand",

  FETCH_ALL_MERCHANT: "merchants",
  FETCH_MERCHANT_BY_ID: "merchants",
  ADD_MERCHANT: "merchants",
  UPDATE_MERCHANT: "merchants",
  DELETE_MERCHANT: "merchants",
  ACTIVE_INACTIVE_MERCHANT: "/active-inactive-merchants",

  BRAND_ACTIVE_DEACTIVE: "brand-activate-deactivate",
  ACCOUNT_BRAND_DISAPPROVE: "brand-approve-disapprove",

  ACCOUNT_ACTIVE_DEACTIVE: "account-activate-deactivate",
  ACCOUNT_APPROVE_DISAPPROVE: "account-approve-disapprove",

  TRANSACTION_APPROVE_REJECT: "transaction-approve-disapprove",

  FETCH_ALL_OFFERS: "offers",
  DELETE_OFFER: "offers",
  ADD_OFFER: "offers",
  UPDATE_OFFER: "offers",
  FETCH_ALL_OFFER_CATEGORIES: "offer-categories",
  OFFER_INITIAL: "offer-initial",
  ACTIVE_INACTIVE_OFFER: "active-inactive-offer",
  DELETE_CRITERIA: "delete-criteria",
  UPDATE_OFFER_END_DATE: "/update-offer-end-date",
  UPLOAD_IMAGE: "upload-image",
  DELETE_IMAGE: "delete-image",

  CHANGE_PASSWORD: "/user/admin/changepassword",
  FORGOT_PASSWORD: "/user/admin/forgotlink",
  RECOVER_PASSWORD: "/user/admin/setnewpassword",
  DASHBOARD_LIST: "user/admin/dashboard",

  GET_PROFILE: "get-profile",
  FETCH_ALL_CARDS: "cards",
  LOCK_UNLOCK_CARD: "lock-unlock-card",
  FETCH_ALL_TRANSACTIONS: "transactions",

  FETCH_ALL_BRAND_MERCHANTS: "brand-merchants",
  ALL_MERCHANTS: "all-merchants-add-bmc",
  ADD_BRAND_MERCHANT: "brand-merchants",
  DELETE_BRAND_MERCHANT: "brand-merchants",
  ACTIVE_INACTIVE_BRAND_MERCHANTS: "active-inactive-brand-merchants",

  FETCH_ALL_PRE_APPROVED_USER: "pre-approved-insta-user",
  FETCH_PRE_APPROVED_USER_BY_ID: "pre-approved-insta-user",
  ADD_PRE_APPROVED_USER: "pre-approved-insta-user",
  UPDATE_PRE_APPROVED_USER: "pre-approved-insta-user",
  DELETE_PRE_APPROVED_USER: "pre-approved-insta-user",
  ACTIVE_INACTIVE_PRE_APPROVED_USER: "active-inactive-pre-approved-insta-user",

  FETCH_ALL_FEEDBACKS: "feedbacks",
};

export const adminRoutePath = {
  dashboard: "/admin/board",
  users: "/admin/users",
  usermanage: "/admin/usermanage",
  alltrips:"/admin/trips",
  tripsDetails:"/admin/trip-details",
  popularstation:"/admin/popularstation",
  Preferences:"/admin/Default-Preferences",
  notification:"/admin/notification",
  configuration:"/admin/configuration",
  StaticPages:"/admin/StaticPages",
  AddPages:"/admin/AddPages",
  EditPage:"/admin/EditPage",
  vendors: "/admin/vendors",
  brands: "/admin/brands",
  vendorBrands: "/admin/vendor-brands",
  vendorOffers: "/admin/vendor-offers",
  brandOffers: "/admin/brand-offers",
  addOffer: "/admin/add-offer",
  updateOffer: "/admin/update-offer",
  viewOffer: "/admin/view-offer",
  brandMerchantConfiguration: "/admin/brand-merchant-configuration",
  influencers: "/admin/influencers",
  paymentDue: "/admin/payment-due",
  settings: "/admin/settings",
  erros: "/admin/erros",
  transactions: "/admin/transactions",
  brandPosts: "/admin/brand-posts",
  cards: "/admin/cards",
  merchants: "/admin/merchants",
  preApprovedUser: "/admin/pre-approved-user",
  feedback: "/admin/feedback",
  offerTransactions: "/admin/offer-transactions",
};

export const adminRoutePathList = Object.entries(adminRoutePath).map(
  ([key, value]) => {
    return value;
  }
);

export const vendorRoutePath = {
  dashboard: "/vendor/board",
  brands: "/vendor/brands",
  brandOffers: "/vendor/brand-offers",
  addOffer: "/vendor/add-offer",
  updateOffer: "/vendor/update-offer",
  viewOffer: "/vendor/view-offer",
  brandMerchantConfiguration: "/vendor/brand-merchant-configuration",
  profile: "/vendor/profile",
  paymentDue: "/vendor/payment-due",
  transactions: "/vendor/transactions",
  offerTransactions: "/vendor/offer-transactions",
};

export const publicRoutePath = {
  appEntryPoint: "/",
  vendorRegister: "/accounts/register",
  login: "/accounts/login",
  accountVerification: "/accounts/verify-otp",
  pageNotFound: "/page-not-found",
};

export const vendorRoutePathList = Object.entries(vendorRoutePath).map(
  ([key, value]) => {
    return value;
  }
);
