import { UserInfo } from "../data/types/appUser";
import { publicRoutePath } from "../AppConstants";
import history from "../history";







export const localStorageUtils = {

  setUserInfo: (userInfo: UserInfo| null) => {
    localStorage.setItem("wyld:userInfo", JSON.stringify(userInfo));
  },
  getUserInfo: () => {
    var userInfoStr = localStorage.getItem("wyld:userInfo");
    let userInfo;
    if (userInfoStr) {
      userInfo = JSON.parse(userInfoStr);
    } else {
      userInfo = undefined;
    }
    return userInfo;
  },
  setAccessToken: (token: string) => {
    localStorage.setItem("wyld:accessToken", token);
  },
  getAccessToken: () => {
    
    const token = localStorage.getItem("wyld:accessToken");
    if (token && token != "") {
      return token;
    } else{
      return history.push(publicRoutePath.login);
    } 
   //history.push(publicRoutePath.login);
  },
  setUserType: (user_type: string) => {
    localStorage.setItem("wyld:userType", JSON.stringify(user_type));
  },
  getUserType: () => {
    const userType = localStorage.getItem("wyld:userType");
    if (userType && userType != "") {
      return userType;
    } else return "";
  },
  setUserSettings: (userSettings: any) => {
    localStorage.setItem("wyld:userSettings", JSON.stringify(userSettings));
  },
  getUserSettings: () => {
    const userSettings = localStorage.getItem("wyld:userSettings");
    if (userSettings) {
      return userSettings;
    } else return null;
  },
  setUserSignedInStatus: (isUserSignedIn: boolean) => {
    localStorage.setItem("wyld:isUserSignedIn", JSON.stringify(isUserSignedIn));
  },
  getUserSignedInStatus: () => {
    //console.log(localStorage.getItem("wyld:isUserSignedIn"));
    return (localStorage.getItem("wyld:isUserSignedIn") === "false" ? false : true);//Boolean(localStorage.getItem("wyld:isUserSignedIn"));
  },
};
