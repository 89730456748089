import React from "react";

export const useIsUserSignedInState = (): {
  isUserSignedIn: boolean;
  setIsUserSignedInFlag: (flag: boolean) => void;
  getIsUserSignedIn: () => boolean;
} => {
  const [isUserSignedIn, setIsUserSignedIn] = React.useState<boolean>(false);
  const setIsUserSignedInFlag = (flag: boolean) => {
    // debugger;
    setIsUserSignedIn(flag);
  };
  const getIsUserSignedIn = () => {
    return isUserSignedIn;
  };
  return {
    isUserSignedIn,
    setIsUserSignedInFlag,
    getIsUserSignedIn,
  };
};
