import React, { useState } from "react";
import {
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Grid,
  Select,
  MenuItem,
  IconButton,
  Button,
  Switch,
  Pagination,
  Typography,
} from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import StyleIcon from "@mui/icons-material/Style";
import { getComparator } from "../../../utils/sortFunction";
import useListState from "../../../hooks/useListState";
import SortTableColumn from "../../../components/common/SortTableColumn";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../components/common/TableCell";
import {
  headCells,
  fetchVendorList,
  deleteVendor,
  activeDeactiveAccount,
  approvedDeapprovedAccount,
} from "./vendorList";
import ViewEditVendorModal from "./ViewEditVendorModal";
import useViewEditVendorModalState from "../../../hooks/vendor/useViewEditVendorModalState";
import usePaginationState from "../../../hooks/usePaginationState";
import useConfirmationModalState from "../../../hooks/useConfirmationModalState";
import ConfirmationModal from "../../../components/common/ConfirmationModal";
import RefreshIcon from "@mui/icons-material/Refresh";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TablePagination from "../../../components/common/TablePagination";
import {
  getEncryptedText,
  formatContactNumber,
  UserStatus,
} from "../../../utils/sharedFunctions";
import history from "../../../history";
import { adminRoutePath } from "../../../AppConstants";
import { publicRoutePath } from "../../../AppConstants";
import { PER_PAGE_ROWS_OPTIONS } from "../../../AppConfig";

export default function MuiTable() {
  const [VENDORS, setVendorList] = React.useState([]);
  const { showCmfModal, setShowCmfModal, cmfModalDetails, setCmfModalDetails } =
    useConfirmationModalState();
  const {
    showModal,
    setShowModalState,
    modalId,
    setModalIdState,
    modalFiledDisabled,
    setModalFiledDisabledState,
  } = useViewEditVendorModalState();
  const { pagination, setPagination } = usePaginationState({
    searchFilter: "",
  });
  const [isLoading, setIsLoading] = React.useState(false);
  let timer: ReturnType<typeof setTimeout>;

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      setVendorList([]);
      const data = await fetchVendorList(pagination);
      if (data) {
        setPagination({
          searchFilter: data.pagination.searchFilter
            ? data.pagination.searchFilter
            : "",
          perPageRows: data.pagination.perPageRows
            ? data.pagination.perPageRows
            : 10,
          currentPage: data.pagination.currentPage
            ? data.pagination.currentPage
            : 1,
          totalPages: data.pagination.totalPages
            ? data.pagination.totalPages
            : 0,
          totalItems: data.pagination.totalItems
            ? data.pagination.totalItems
            : 0,
          orderBy: data.pagination.orderBy ? data.pagination.orderBy : "desc",
          sortBy: data.pagination.sortBy ? data.pagination.sortBy : "id",
        });
        setVendorList(data.vendors);
      }
    } catch (e) {}
    setIsLoading(false);
  };

  const handleRequestSort = (property: string) => {
    pagination.orderBy =
      pagination.sortBy == property
        ? pagination.orderBy == "asc"
          ? "desc"
          : "asc"
        : "asc";
    pagination.sortBy = property;
    fetchData();
  };

  const handleRowsPerPage = (rowCount: number) => {
    pagination.perPageRows = rowCount;
    pagination.currentPage = 1;
    fetchData();
  };

  const handleCloseModal = (status: boolean) => {
    setShowModalState(false);
    if (status) {
      fetchData();
    }
  };

  const handleShowModal = (id: number, disabled: boolean) => {
    setModalIdState(id);
    setModalFiledDisabledState(disabled);
    setShowModalState(true);
  };

  const handleShowDelModal = (id: number, name: string) => {
    setCmfModalDetails({
      id,
      title: "Vendor Delete",
      message: `You are about to delete ${name}.`,
    });
    setShowCmfModal(true);
  };

  const handleDelCloseModal = async (status: boolean) => {
    setShowCmfModal(false);
    if (status) {
      const resDelete = await deleteVendor(cmfModalDetails.id);
      if (resDelete) fetchData();
    }
  };

  const handleActiveDeactive = async (id: number) => {
    const tempVendor: any = VENDORS.find(
      (vendor: any) => id == vendor.id_app_user
    );
    if (!tempVendor) return;
    const result = await activeDeactiveAccount(
      id,
      tempVendor.is_active == "0" ? 1 : 0
    );
    if (result) {
      const tempVendorList: any = VENDORS.map((vendor: any) => {
        if (vendor.id_app_user == id) {
          return { ...vendor, is_active: vendor.is_active == "0" ? "1" : "0" };
        }
        return vendor;
      });
      setVendorList(tempVendorList);
    }
  };

  const handleApproved = async (id: number) => {
    const tempVendor: any = VENDORS.find(
      (vendor: any) => id == vendor.id_app_user
    );
    if (!tempVendor) return;
    const result = await approvedDeapprovedAccount(id, 1);
    if (result) {
      const tempVendorList: any = VENDORS.map((vendor: any) => {
        if (vendor.id_app_user == id) {
          return { ...vendor, approved_date: new Date() };
        }
        return vendor;
      });
      setVendorList(tempVendorList);
    }
  };

  const handleSearchChange = async (e: any) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(async () => {
      if (e.target.name == "searchFilter") {
        pagination.searchFilter = e.target.value;
        pagination.currentPage = 1;
        fetchData();
      }
    }, 500);
  };

  const handlePageChange = (page: number) => {
    pagination.currentPage = page;
    fetchData();
  };

  const handleNavigation = (id: number) => {
    const encryptId = getEncryptedText(id.toString());
    history.push(`${adminRoutePath.vendorBrands}/${encryptId}`);
  };

  return (
    <React.Fragment>
      <div className="page-header">
        <span className="page-title">Vendor List</span>
      </div>
      <div>
        <Grid container spacing={2} className="headElements">
          <Grid item className="leftHead">
            <p className="selectPages">Show</p>
            <Select
              value={pagination.perPageRows}
              label="Page"
              className="dropDown"
              onChange={(e) => handleRowsPerPage(Number(e.target.value))}
            >
              {PER_PAGE_ROWS_OPTIONS.map((item: number) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
            {/* <Button className='recordButton'>Add Record</Button> */}
            <span
              className="margin-left-16 btn-icon"
              title="Reload"
              onClick={() => fetchData()}
            >
              <RefreshIcon fontSize="large" className="color-light-blue" />
            </span>
            <Button
              className="btn-primary margin-left-16"
              onClick={() => history.push(publicRoutePath.vendorRegister)}
            >
              Register Vendor
            </Button>
          </Grid>

          <Grid item className="rightHead">
            <p className="selectPages">Search</p>
            <TextField
              size={"small"}
              className="searchField"
              id="outlined-basic"
              name="searchFilter"
              placeholder="Search Vendors"
              onChange={(e: any) => handleSearchChange(e)}
            />
          </Grid>
        </Grid>
        <TableContainer component={Paper} className="tableContainer">
          <Table stickyHeader className="table" aria-label="simple table">
            <SortTableColumn
              order={pagination.orderBy}
              orderBy={pagination.sortBy}
              headCells={headCells}
              handleRequestSort={handleRequestSort}
            />
            <TableBody>
              {isLoading ? (
                <TableCell colSpan={6}>
                  <div className="loader-container">
                    <div className="loader"></div>
                  </div>
                </TableCell>
              ) : (
                VENDORS.map((row: any, index) => (
                  <StyledTableRow hover key={index}>
                    <StyledTableCell className="table-cell-left-align">
                      <Grid item lg={10}>
                        {row.first_name}
                      </Grid>
                    </StyledTableCell>
                    <StyledTableCell className="table-cell-left-align">
                      {row.last_name}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell-left-align">
                      {row.vendor_name}
                    </StyledTableCell>
                    <StyledTableCell className="tableCell table-cell-center-align">
                      {formatContactNumber(row.primary_contact_number)}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell-left-align">
                      {row.email_address}
                    </StyledTableCell>

                    <StyledTableCell className="table-cell-left-align">
                      <Grid
                        container
                        className="actionGrid"
                        justifyContent="start"
                      >
                        <Grid>
                          <IconButton
                            title="View"
                            onClick={() => handleShowModal(row.id, true)}
                          >
                            <RemoveRedEyeIcon />
                          </IconButton>
                        </Grid>
                        {row.approved_date ? (
                          <React.Fragment>
                            <Grid>
                              <IconButton
                                title="Edit"
                                onClick={() => handleShowModal(row.id, false)}
                              >
                                <EditIcon />
                              </IconButton>
                            </Grid>
                            <Grid>
                              <IconButton
                                title="Delete"
                                onClick={() =>
                                  handleShowDelModal(
                                    row.id,
                                    row.first_name + row.last_name
                                  )
                                }
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                            <Grid>
                              <Switch
                                color={
                                  row.is_active == "0" ? "error" : "success"
                                }
                                title="Active"
                                checked={row.is_active == "1"}
                                onChange={() =>
                                  handleActiveDeactive(row.id_app_user)
                                }
                              />
                            </Grid>
                          </React.Fragment>
                        ) : (
                          row.user_status == UserStatus.PendingApproval && (
                            <Grid>
                              <IconButton
                                title="Approve"
                                onClick={() => handleApproved(row.id_app_user)}
                              >
                                <CheckCircleIcon color="success" />
                              </IconButton>
                            </Grid>
                          )
                        )}
                        <Grid>
                          <IconButton
                            title="Brand"
                            onClick={() => handleNavigation(row.id)}
                          >
                            <StyleIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          currentPage={pagination.currentPage}
          perPageRows={pagination.perPageRows}
          totalItems={pagination.totalItems}
          totalPages={pagination.totalPages}
          handleOnChangePage={handlePageChange}
        />
      </div>
      {showModal && (
        <ViewEditVendorModal
          id={modalId}
          closeModal={handleCloseModal}
          disabled={modalFiledDisabled}
        />
      )}
      {showCmfModal && (
        <ConfirmationModal
          closeModal={handleDelCloseModal}
          title={cmfModalDetails.title}
          message={cmfModalDetails.message}
        />
      )}
    </React.Fragment>
  );
}
