import { TextField } from "@mui/material";
import { useField } from "formik";
import InputMask from "react-input-mask";
import { PHONE_MASK } from "../../AppConfig";

const PhoneInputMaskField = (props: any) => {
  const [field, mata] = useField(props.name);

  const configTextfield: any = {
    ...field,
    ...props,
    fullWidth: true,
    variant: "outlined",
  };

  if (mata && mata.touched && mata.error) {
    configTextfield.error = true;
    configTextfield.helperText = mata.error;
  }

  return (
    <InputMask mask={PHONE_MASK} maskChar={null} {...configTextfield}>
      {(inputProps: any) => <TextField {...inputProps} disabled={props.disabled}/>}
    </InputMask>
  );
};

export default PhoneInputMaskField;
