import { PostStatus } from "../../../utils/sharedFunctions";

export const OfferTransactionData = [
  {
    first_name: "Influnite Official",
    transaction_date: "2022-07-07",
    transaction_amount: 125.0,
    post_status: PostStatus.Pending,
    likes: 0,
    comments: 0,
    reach: 122,
    post_url: null,
  },
  {
    first_name: "Influnite Official",
    transaction_date: "2022-07-09",
    transaction_amount: 599.99,
    post_status: PostStatus.Submitted,
    likes: 6890,
    comments: 506,
    reach: 122,
    post_url: "https://www.instagram.com/p/CHCUTt6lGPl/?utm_source=ig_web_copy_link",
  },
  {
    first_name: "Influnite Official",
    transaction_date: "2022-07-11",
    transaction_amount: 1350.00,
    post_status: PostStatus.Resubmitted,
    likes: 9009,
    comments: 453,
    reach: 122,
    post_url: "https://www.instagram.com/p/CJaujb9lkrd/?utm_source=ig_web_copy_link",
  },
  {
    first_name: "Andre Anderson",
    transaction_date: "2022-07-02",
    transaction_amount: 2535.00,
    post_status: PostStatus.Rejected,
    likes: 1265,
    comments: 86,
    reach: 23,
    post_url: "https://www.instagram.com/p/CHFfRTqFO_e/?utm_source=ig_web_copy_link",
  },
  {
    first_name: "Alex Patel",
    transaction_date: "2022-07-08",
    transaction_amount: 650.00,
    post_status: PostStatus.Approved,
    likes: 3695,
    comments: 101,
    reach: 46,
    post_url: "https://www.instagram.com/p/CHAjs4BlFZl/?utm_source=ig_web_copy_link",
  },
  {
    first_name: "Andre Anderson",
    transaction_date: "2022-07-10",
    transaction_amount: 480.80,
    post_status: PostStatus.Pending,
    likes: 0,
    comments: 0,
    reach: 23,
    post_url: null,
  },
];

export const fetchAllOfferTransaction = (pagination: any) => {
  return {
    offer_transactions: OfferTransactionData,
    pagination: { ...pagination, totalPages: 1, totalItems: 6 },
  };
};

export const headCells = [
  {
    id: "first_name",
    disablePadding: false,
    label: "Name",
    width: "16%",
    textAlign: "left",
  },
  {
    id: "transaction_date",
    disablePadding: false,
    label: "Transaction Date",
    textAlign: "center",
    width: "14%",
  },
  {
    id: "transaction_amount",
    disablePadding: true,
    label: "Amount",
    textAlign: "right",
    width: "12%",
  },
  {
    id: "post_status",
    disablePadding: false,
    label: "Status",
    textAlign: "center",
    width: "14%",
  },
  {
    id: "likes",
    disablePadding: false,
    label: "Likes",
    textAlign: "center",
    width: "8%",
  },
  {
    id: "comments",
    disablePadding: false,
    label: "Comments",
    textAlign: "center",
    width: "8%",
  },
  {
    id: "reach",
    disablePadding: false,
    label: "Reach",
    textAlign: "center",
    width: "8%",
  },
  {
    id: "action",
    disablePadding: false,
    label: "Actions",
    textAlign: "left",
    width: "20%",
    isNotSortable: true,
  },
];
