import { TableCell, TableRow } from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: "0px 15px",
  textAlign: "center",
  alignItems: "center",

  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "lightgrey !important",
    color: "black !important",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#f9f8ff",
  },

  "&:last-child td, &:last-child th": {
    border: 5,
  },

  "&:hover": {
    backgroundColor: "#efeef7 !important",
  },
}));

export { StyledTableCell, StyledTableRow };
