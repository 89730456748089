import { TNotification } from "../../../data/types/adminApi";
import { ADMIN_NOTIFICATION } from "../../../services/AdminServices";
import { toast } from "react-toastify";
import { appErrors } from "../../../AppConstants";
import * as Yup from "yup";

// export const INITIAL_FORM_STATE = {
//   title: "",
//   content: "",
  
// };
export const getInitialState = () => {
  return {
    title: "",
  content: "",
  };
};
export const FORM_VALIDATION = Yup.object().shape({
  
      title: Yup.string().required("Title is required!"),
      content: Yup.string().required("content is required!"),
});


export const apiNotification = async (props: any) =>{
    
        const payload: TNotification = {
         
            title: props.title,
            content: props.content,
        };
        try {
          const data = await ADMIN_NOTIFICATION(payload);
          console.log(data);
          if (data.code === 200 || data.code === "200") {
           
           return true
          } else {
            toast.success("Data Add successfully...");
            
          }
          
        } catch (e: any) {
          toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
        }
        return false;
      
      
}