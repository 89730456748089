import React from "react";
import { TCSCRModalDetails } from "../data/types/componentState";

const useCardStatusChangeReasonModalState = (): {
  showSCRModal: boolean;
  setShowSCRModal: (value: boolean) => void;
  scrModalDetails: TCSCRModalDetails;
  setSCRModalDetail: (value: TCSCRModalDetails) => void;
} => {
  const [showSCRModal, setShowSCRModalState] = React.useState<boolean>(false);
  const [scrModalDetails, setSCRModalDetailState] =
    React.useState<TCSCRModalDetails>({
      id: 0,
      status: 0,
      reason: null,
    });

  const setShowSCRModal = (value: boolean) => {
    setShowSCRModalState(value);
  };

  const setSCRModalDetail = (state: TCSCRModalDetails) => {
    setSCRModalDetailState({ ...scrModalDetails, ...state });
  };

  return {
    showSCRModal,
    setShowSCRModal,
    scrModalDetails,
    setSCRModalDetail,
  };
};

export default useCardStatusChangeReasonModalState;
