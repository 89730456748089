import { PostStatus } from "../../../utils/sharedFunctions";
import React, { useState } from "react";
import {
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Grid,
  TableHead,
  Select,
  MenuItem,
  IconButton,
  Button,
  Switch,
  Pagination,
  Typography,
  FormControl,
} from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import StyleIcon from "@mui/icons-material/Style";
import { getComparator } from "../../../utils/sortFunction";
import useListState from "../../../hooks/useListState";
import SortTableColumn from "../../../components/common/SortTableColumn";
import moment from "moment";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../components/common/TableCell";
import {
  getEncryptedText,
  formatContactNumber,
  UserStatus,
  getPostStatusLabel,
} from "../../../utils/sharedFunctions";
import history from "../../../history";
import {
  headCells,
  fetchTransactionList,
  approveRejectTransaction,
  approveOfferTransaction,
} from "./transactionsList";
import usePaginationState from "../../../hooks/usePaginationState";
import useConfirmationModalState from "../../../hooks/useConfirmationModalState";
import ConfirmationModal from "../../../components/common/ConfirmationModal";
import RefreshIcon from "@mui/icons-material/Refresh";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import TablePagination from "../../../components/common/TablePagination";
import { SearchRounded } from "@mui/icons-material";
import TransactionRejectionReasonModal from "../../../components/transaction/TransactionRejectionReasonModal";
import TransactionApproveModal from "../../../components/transaction/TransactionApproveModal";
import {
  PER_PAGE_ROWS_OPTIONS,
  INSTAGRAM_API_ENDPOINT,
} from "../../../AppConfig";

export default function Transaction() {
  const [TRANSACTIONS, setTransactionList] = React.useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const { pagination, setPagination } = usePaginationState({
    searchFilter: "",
    status: 0,
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const [showRejectionModal, setShowRejectionModal] = React.useState(false);
  const [rejectionModalId, setRejectionModalId] = React.useState(0);
  const [showApproveModal, setShowApproveModal] = React.useState(false);
  const [approveModalId, setApproveModalId] = React.useState(0);
  const [isTrnLoading, setIsTrnLoading] = React.useState(false);
  const { showCmfModal, setShowCmfModal, cmfModalDetails, setCmfModalDetails } =
    useConfirmationModalState();
  let timer: ReturnType<typeof setTimeout>;

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      setTransactionList([]);
      const data = await fetchTransactionList(pagination);
      if (data) {
        setPagination({
          first_name: data.pagination.first_name
            ? data.pagination.first_name
            : "",
          offer_title: data.pagination.offer_title
            ? data.pagination.offer_title
            : "",
          transaction_date: data.pagination.transaction_date
            ? data.pagination.transaction_date
            : "",
          transaction_reference_number: data.pagination
            .transaction_reference_number
            ? data.pagination.transaction_reference_number
            : "",
          perPageRows: data.pagination.perPageRows
            ? data.pagination.perPageRows
            : 10,
          currentPage: data.pagination.currentPage
            ? data.pagination.currentPage
            : 1,
          totalPages: data.pagination.totalPages
            ? data.pagination.totalPages
            : 0,
          totalItems: data.pagination.totalItems
            ? data.pagination.totalItems
            : 0,
          orderBy: data.pagination.orderBy ? data.pagination.orderBy : "desc",
          sortBy: data.pagination.sortBy ? data.pagination.sortBy : "id",
        });
        setTransactionList(data.transactions);
      }
    } catch (e) {}
    setIsLoading(false);
  };

  const handleRequestSort = (property: string) => {
    pagination.orderBy =
      pagination.sortBy == property
        ? pagination.orderBy == "asc"
          ? "desc"
          : "asc"
        : "asc";
    pagination.sortBy = property;
    fetchData();
  };

  const handleRowsPerPage = (rowCount: number) => {
    pagination.perPageRows = rowCount;
    pagination.currentPage = 1;
    fetchData();
  };

  const handleStatus = (state: number) => {
    pagination.status = state;
    pagination.currentPage = 1;
    fetchData();
  };

  const handleRejectApproveTransaction = async (
    id: number,
    typeApproveReject: number,
    reason: string = "",
    discount_amount: number,
    transaction_amount: number = 0
  ) => {
    try {
      const tempTransaction: any = TRANSACTIONS.find(
        (transaction: any) => id == transaction.id
      );
      if (!tempTransaction) return;
      const result = await approveRejectTransaction(
        id,
        typeApproveReject,
        reason,
        discount_amount,
        transaction_amount
      );
      if (result) {
        const tempTransactionList: any = TRANSACTIONS.map(
          (transaction: any) => {
            if (transaction.id == id) {
              return {
                ...transaction,
                aprove_reject_date: new Date(),
                post_status: typeApproveReject,
              };
            }
            return transaction;
          }
        );
        setTransactionList(tempTransactionList);
      }
      return result;
    } catch (e) {
      return false;
    }
  };

  const openRejectionModal = (id: number) => {
    setRejectionModalId(id);
    setShowRejectionModal(true);
  };

  const closeRejectionModal = async (reason: string) => {
    setIsTrnLoading(true);
    const data = await handleRejectApproveTransaction(
      rejectionModalId,
      PostStatus.Rejected,
      reason,
      0
    );
    if (data) setShowRejectionModal(false);
    setIsTrnLoading(false);
  };

  const openApproveModal = (
    id: number,
    id_offer: number | null,
    transaction_reference_number: string
  ) => {
    if (id_offer) {
      setCmfModalDetails({
        id,
        title: "Approve Transaction",
        message: `You are about to approve a transaction with referencer number ${transaction_reference_number}.`,
      });
      setShowCmfModal(true);
    } else {
      setApproveModalId(id);
      setShowApproveModal(true);
    }
  };

  const closeApproveModal = async (discount_amount: number) => {
    setIsTrnLoading(true);
    const findTransaction: any = TRANSACTIONS.find(
      (trn: any) => trn.id == approveModalId
    );
    const data = await handleRejectApproveTransaction(
      approveModalId,
      PostStatus.Approved,
      "",
      discount_amount,
      findTransaction?.transaction_amount
    );
    if (data) setShowApproveModal(false);
    setIsTrnLoading(false);
  };

  const handleApproveOfferTrn = async (status: boolean) => {
    setShowCmfModal(false);
    if (status) {
      const resDelete = await approveOfferTransaction(cmfModalDetails.id);
      if (resDelete) {
        const tempTransactionList: any = TRANSACTIONS.map(
          (transaction: any) => {
            if (transaction.id === cmfModalDetails.id) {
              return {
                ...transaction,
                aprove_reject_date: new Date(),
                post_status: PostStatus.Approved,
              };
            }
            return transaction;
          }
        );
        setTransactionList(tempTransactionList);
      }
    }
  };

  const handleSearch = async (e: any) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(async () => {
      if (e.target.name == "insta_uname") {
        pagination.first_name = e.target.value;
        pagination.currentPage = 1;
        fetchData();
      }
      if (e.target.name == "transaction_reference_number") {
        pagination.transaction_reference_number = e.target.value;
        pagination.currentPage = 1;
        fetchData();
      }
      if (e.target.name == "transaction_date") {
        pagination.transaction_date = e.target.value;
        pagination.currentPage = 1;
        fetchData();
      }
      if (e.target.name == "concated_offer_title") {
        pagination.offer_title = e.target.value;
        pagination.currentPage = 1;
        fetchData();
      }
    }, 500);
  };

  const handlePageChange = (page: number) => {
    pagination.currentPage = page;
    fetchData();
  };

  return (
    <React.Fragment>
      <div className="page-header">
        <span className="page-title">Transaction List</span>
      </div>
      <div>
        <Grid container spacing={2} className="headElements">
          <Grid item className="leftHead">
            <p className="selectPages">Show</p>
            <Select
              value={pagination.perPageRows}
              label="Page"
              className="dropDown"
              onChange={(e) => handleRowsPerPage(Number(e.target.value))}
            >
              {PER_PAGE_ROWS_OPTIONS.map((item: number) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
            <span
              className="margin-left-16 btn-icon"
              title="Reload"
              onClick={() => fetchData()}
            >
              <RefreshIcon fontSize="large" className="color-light-blue" />
            </span>
          </Grid>

          <Grid item className="rightHead">
            <span
              className="margin-left-8 btn-icon"
              title="Search"
              onClick={() => setShowSearch(!showSearch)}
            >
              <SearchRoundedIcon
                fontSize="large"
                className="color-light-blue"
              />
            </span>
          </Grid>
        </Grid>
        <TableContainer component={Paper} className="tableContainer">
          <Table stickyHeader className="table" aria-label="simple table">
            <SortTableColumn
              order={pagination.orderBy}
              orderBy={pagination.sortBy}
              headCells={headCells}
              handleRequestSort={handleRequestSort}
            />
            {showSearch && (
              <TableHead>
                <TableRow>
                  {headCells.map((headCell: any) =>
                    headCell.disableSearch === false ? (
                      <TableCell
                        key={headCell.id}
                        style={{
                          width: headCell.width,
                          height: 10,
                          textAlign: headCell.textAlign,
                        }}
                        className=""
                        align={"center"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                      >
                        {headCell.id !== "transaction_amount" ? (
                          <TextField
                            size={"small"}
                            // className="searchField"
                            id={headCell.id}
                            name={headCell.id}
                            disabled={headCell.disableSearch}
                            color={"info"}
                            type={headCell ? headCell.type : ""}
                            placeholder={headCell.searchLabel}
                            onChange={(e: any) => handleSearch(e)}
                          />
                        ) : (
                          <FormControl fullWidth>
                            <Select
                              size={"small"}
                              style={{ textAlign: "left" }}
                              value={pagination.status}
                              onChange={(e) =>
                                handleStatus(Number(e.target.value))
                              }
                            >
                              <MenuItem value={0}>{"All"}</MenuItem>
                              {Array.from(getPostStatusLabel.entries()).map(
                                ([key, value]) => (
                                  <MenuItem value={key}>{value}</MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                        )}
                      </TableCell>
                    ) : (
                      <TableCell
                        key={headCell.id}
                        style={{
                          width: headCell.width,
                          height: 10,
                          textAlign: headCell.textAlign,
                        }}
                        className=""
                        align={"center"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                      />
                    )
                  )}
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {isLoading ? (
                <TableCell colSpan={6}>
                  <div className="loader-container">
                    <div className="loader"></div>
                  </div>
                </TableCell>
              ) : (
                TRANSACTIONS.map((row: any, index) => (
                  <StyledTableRow
                    hover
                    key={index}
                    className="user-table-row-height"
                  >
                    <StyledTableCell className="table-cell-left-align">
                      <Grid item lg={10}>
                        <a
                          rel="noreferrer"
                          className="text-decoration-none link-text"
                          href={`${INSTAGRAM_API_ENDPOINT}/${row.insta_uname}`}
                          target="_blank"
                        >
                          {row.insta_uname}
                        </a>
                        {/* {row.first_name} */}
                      </Grid>
                    </StyledTableCell>
                    <StyledTableCell className="table-cell-left-align">
                      <a
                          rel="noreferrer"
                          className="text-decoration-none link-text"
                          href={row.ecommerce_site_address}
                          target="_blank"
                        >
                      {row.concated_offer_title}
                      </a>
                      {/* {row.offer_title
                        ? (row.offer_brand_name
                            ? row.offer_brand_name + " - "
                            : "") + row.offer_title
                        : row.brand_name} */}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell-center-align">
                      {moment(row.transaction_date).format("DD/MM/YYYY")}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell-left-align">
                      {row.transaction_reference_number}
                    </StyledTableCell>
                    <StyledTableCell className="tableCell table-cell-right-align">
                      {row.transaction_amount}
                    </StyledTableCell>
                    <StyledTableCell className="tableCell table-cell-center-align">
                      {row.end_date
                        ? moment(row.end_date).format("DD/MM/YYYY")
                        : "-"}
                    </StyledTableCell>
                    <StyledTableCell className="tableCell table-cell-center-align">
                      {row.post_expire_date
                        ? moment(row.post_expire_date).format("DD/MM/YYYY")
                        : "-"}
                      {/* {row.post_duration
                        ? moment(row.transaction_date)
                            .add(parseInt(row.post_duration) + 1, "days")
                            .format("DD/MM/YYYY")
                        : "-"} */}
                    </StyledTableCell>

                    <StyledTableCell className="table-cell-left-align">
                      <Grid
                        container
                        className="actionGrid"
                        justifyContent="start"
                      >
                        <React.Fragment>
                          {row.post_status == PostStatus.Submitted ||
                          row.post_status == PostStatus.Resubmitted ? (
                            <React.Fragment>
                              <Grid>
                                <IconButton
                                  title="Approve"
                                  onClick={() =>
                                    openApproveModal(
                                      row.id,
                                      row.id_offer,
                                      row.transaction_reference_number
                                    )
                                  }
                                >
                                  <CheckCircleOutlineOutlinedIcon color="success" />
                                </IconButton>
                              </Grid>
                              <Grid>
                                <IconButton
                                  title="Reject"
                                  onClick={() => openRejectionModal(row.id)}
                                >
                                  <CancelOutlinedIcon color="error" />
                                </IconButton>
                              </Grid>
                            </React.Fragment>
                          ) : row.post_status == PostStatus.Approved ? (
                            <IconButton title="Approved">
                              <CheckCircleIcon color="success" />
                            </IconButton>
                          ) : (
                            row.post_status == PostStatus.Rejected && (
                              <IconButton title="Rejected">
                                <CancelIcon color="error" />
                              </IconButton>
                            )
                          )}
                          {row.post_url && (
                            <IconButton
                              title="story/post"
                              onClick={() =>
                                window.open(row.post_url, "_blank")
                              }
                            >
                              <InstagramIcon />
                            </IconButton>
                          )}
                        </React.Fragment>
                      </Grid>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          currentPage={pagination.currentPage}
          perPageRows={pagination.perPageRows}
          totalItems={pagination.totalItems}
          totalPages={pagination.totalPages}
          handleOnChangePage={handlePageChange}
        />
      </div>
      {showRejectionModal && (
        <TransactionRejectionReasonModal
          closeModal={() => setShowRejectionModal(false)}
          handleReasonSubmit={closeRejectionModal}
          isTrnLoading={isTrnLoading}
        />
      )}
      {showApproveModal && (
        <TransactionApproveModal
          closeModal={() => setShowApproveModal(false)}
          handleDiscountSubmit={closeApproveModal}
          isTrnLoading={isTrnLoading}
        />
      )}
      {showCmfModal && (
        <ConfirmationModal
          closeModal={handleApproveOfferTrn}
          title={cmfModalDetails.title}
          message={cmfModalDetails.message}
        />
      )}
    </React.Fragment>
  );
}
