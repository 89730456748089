import React, { useState } from "react";
import {
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Tooltip,
  TableRow,
  Paper,
  Grid,
  Select,
  MenuItem,
  Switch,
  IconButton,
  Pagination,
  Button,
  Typography,
  Avatar,
} from "@mui/material";
import moment from "moment";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import FiberManualRecordOutlinedIcon from "@mui/icons-material/FiberManualRecordOutlined";
import StorefrontIcon from "@mui/icons-material/Storefront";
import { getComparator } from "../../../utils/sortFunction";
import useListState from "../../../hooks/useListState";
import SortTableColumn from "../../../components/common/SortTableColumn";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../components/common/TableCell";
import ViewEditBrandModal from "./ViewEditBrandModal";
import {
  headCells,
  fetchBrandList,
  deleteBrand,
  activeDeactiveAccount,
  approvedDeapprovedBrand,
} from "./brandList";
import { useParams, useLocation } from "react-router";
import useViewEditVendorModalState from "../../../hooks/vendor/useViewEditVendorModalState";
import usePaginationState from "../../../hooks/usePaginationState";
import useConfirmationModalState from "../../../hooks/useConfirmationModalState";
import ConfirmationModal from "../../../components/common/ConfirmationModal";
import RefreshIcon from "@mui/icons-material/Refresh";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TablePagination from "../../../components/common/TablePagination";

import NoImgAvailable from "./../../../assets/images/no-image.jpg";
import {
  getDecryptedText,
  getEncryptedText,
  UserType,
} from "../../../utils/sharedFunctions";
import ImageRenderer from "../../../components/core/ImageRenderer";
import { adminRoutePath, vendorRoutePath } from "../../../AppConstants";
import history from "../../../history";
import { PER_PAGE_ROWS_OPTIONS } from "../../../AppConfig";

export default function MuiTable(props: any) {
  const location = useLocation();
  const [BRAND, setBrandList] = React.useState([]);
  let params: any = useParams();
  const decryptedText: string = getDecryptedText(params.id);
  const vendorId =
    props.loginInfo.user_type == UserType.Vendor
      ? props.loginInfo.id
      : decryptedText;
  const { pagination, setPagination } = usePaginationState({
    searchFilter: "",
    vendorId: vendorId,
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const { showCmfModal, setShowCmfModal, cmfModalDetails, setCmfModalDetails } =
    useConfirmationModalState();
  const {
    showModal,
    setShowModalState,
    modalId,
    setModalIdState,
    modalFiledDisabled,
    setModalFiledDisabledState,
  } = useViewEditVendorModalState();
  let timer: ReturnType<typeof setTimeout>;

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      setBrandList([]);
      const data = await fetchBrandList(pagination);
      if (data) {
        setPagination({
          vendorId: vendorId,
          searchFilter: data.pagination.searchFilter
            ? data.pagination.searchFilter
            : "",
          perPageRows: data.pagination.perPageRows
            ? data.pagination.perPageRows
            : 10,
          currentPage: data.pagination.currentPage
            ? data.pagination.currentPage
            : 1,
          totalPages: data.pagination.totalPages
            ? data.pagination.totalPages
            : 0,
          totalItems: data.pagination.totalItems
            ? data.pagination.totalItems
            : 0,
          orderBy: data.pagination.orderBy ? data.pagination.orderBy : "desc",
          sortBy: data.pagination.sortBy ? data.pagination.sortBy : "id",
        });
        setBrandList(data.brand);
      }
    } catch (e) {}
    setIsLoading(false);
  };

  const handleRequestSort = (property: string) => {
    pagination.orderBy =
      pagination.sortBy == property
        ? pagination.orderBy == "asc"
          ? "desc"
          : "asc"
        : "asc";
    pagination.sortBy = property;
    fetchData();
  };

  const handleRowsPerPage = (rowCount: number) => {
    pagination.perPageRows = rowCount;
    pagination.currentPage = 1;
    fetchData();
  };

  const handleCloseModal = (status: boolean) => {
    setShowModalState(false);
    if (status) {
      fetchData();
    }
  };

  const handleShowModal = (id: number, disabled: boolean) => {
    setModalIdState(id);
    setModalFiledDisabledState(disabled);
    setShowModalState(true);
  };

  const handleShowDelModal = (id: number, name: string) => {
    setCmfModalDetails({
      id,
      title: "Brand Delete",
      message: `You are about to delete ${name}.`,
    });
    setShowCmfModal(true);
  };

  const handleDelCloseModal = async (status: boolean) => {
    setShowCmfModal(false);
    if (status) {
      const resDelete = await deleteBrand(cmfModalDetails.id);
      if (resDelete) fetchData();
    }
  };

  const handleActiveDeactive = async (id: number) => {
    const tempBrand: any = BRAND.find((brand: any) => id == brand.id);
    if (!tempBrand) return;
    const result = await activeDeactiveAccount(
      id,
      tempBrand.is_active == "0" ? 1 : 0
    );
    if (result) {
      const tempBrandList: any = BRAND.map((brand: any) => {
        if (brand.id == id) {
          return {
            ...brand,
            is_active: brand.is_active == "0" ? "1" : "0",
          };
        }
        return brand;
      });
      setBrandList(tempBrandList);
    }
  };

  const handleApproved = async (id: number) => {
    const tempBrand: any = BRAND.find((brand: any) => id == brand.id);
    if (!tempBrand) return;
    const result = await approvedDeapprovedBrand(id, 1);
    if (result) {
      const tempBrandList: any = BRAND.map((brand: any) => {
        if (brand.id == id) {
          return { ...brand, approved_date: new Date(), is_active: "1" };
        }
        return brand;
      });
      setBrandList(tempBrandList);
    }
  };

  const handleSearchChange = async (e: any) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(async () => {
      if (e.target.name == "searchFilter") {
        pagination.searchFilter = e.target.value;
        pagination.currentPage = 1;
        fetchData();
      }
    }, 500);
  };

  const handlePageChange = (page: number) => {
    pagination.currentPage = page;
    fetchData();
  };

  const goToBrandMerchant = (id: number) => {
    if (props.loginInfo.user_type == UserType.Vendor) {
      history.push(
        `${vendorRoutePath.brandMerchantConfiguration}/${getEncryptedText(
          id.toString()
        )}`
      );
    } else {
      history.push(
        `${adminRoutePath.brandMerchantConfiguration}/${getEncryptedText(
          id.toString()
        )}`
      );
    }
  };

  return (
    <React.Fragment>
      <div className="page-header">
        <span className="page-title">Brand List</span>
      </div>
      <div>
        <Grid container spacing={2} className="headElements">
          <Grid item className="leftHead">
            <p className="selectPages">Show</p>
            <Select
              value={pagination.perPageRows}
              label="Page"
              className="dropDown"
              onChange={(e) => handleRowsPerPage(Number(e.target.value))}
            >
              {PER_PAGE_ROWS_OPTIONS.map((item: number) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
            <span
              className="margin-left-16 btn-icon"
              title="Reload"
              onClick={() => fetchData()}
            >
              <RefreshIcon fontSize="large" className="color-light-blue" />
            </span>
            <Button
              className="btn-primary margin-left-16"
              onClick={() => handleShowModal(0, false)}
            >
              Add New Brand
            </Button>
          </Grid>
          <Grid item className="rightHead">
            <p className="selectPages">Search</p>
            <TextField
              size={"small"}
              className="searchField"
              id="outlined-basic"
              name="searchFilter"
              placeholder="Search Brands"
              onChange={(e: any) => handleSearchChange(e)}
            />
            {location.pathname.includes(adminRoutePath.vendorBrands) && (
              <Button
                className="btn-primary margin-left-16 margin-right-8"
                onClick={() => history.push(adminRoutePath.vendors)}
              >
                BACK
              </Button>
            )}
          </Grid>
        </Grid>
        <TableContainer component={Paper} className="tableContainer">
          <Table stickyHeader className="table" aria-label="simple table">
            <SortTableColumn
              order={pagination.order}
              orderBy={pagination.orderBy}
              headCells={headCells}
              handleRequestSort={handleRequestSort}
            />
            <TableBody>
              {isLoading ? (
                <TableCell colSpan={6}>
                  <div className="loader-container">
                    <div className="loader"></div>
                  </div>
                </TableCell>
              ) : (
                BRAND.map((row: any, index) => (
                  <StyledTableRow hover key={index}>
                    <StyledTableCell className="table-cell-left-align">
                      <Grid item lg={10}>
                        {row.brand_image && row.brand_image.image_path ? (
                          <ImageRenderer
                            src={row.brand_image.image_path}
                            alt="Avatar"
                            height={26}
                            width={26}
                            noLoader={true}
                            style={{ borderRadius: "50%" }}
                          />
                        ) : (
                          <img
                            src={NoImgAvailable}
                            alt="Avatar"
                            height="26"
                            width="26"
                            style={{ borderRadius: "50%" }}
                          />
                        )}
                      </Grid>
                    </StyledTableCell>
                    <StyledTableCell className="table-cell-left-align">
                      <Typography color="black" variant="subtitle2">
                        {row.brand_name}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell className="table-cell-left-align">
                      <Tooltip title={row.brand_about}>
                        <p className="ellipsis">{row.brand_about}</p>
                      </Tooltip>
                    </StyledTableCell>
                    <StyledTableCell className="table-cell-center-align">
                      {row.modified_date
                        ? moment(row.modified_date).format("DD/MM/YYYY")
                        : moment(row.created_date).format("DD/MM/YYYY")}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.is_active === "1" ? (
                        <Typography title="Active">
                          <FiberManualRecordIcon color="success" />
                        </Typography>
                      ) : (
                        <Typography title="Inactive">
                          <FiberManualRecordOutlinedIcon color="error" />
                        </Typography>
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      <Grid
                        container
                        className="actionGrid"
                        justifyContent="start"
                      >
                        <Grid>
                          <IconButton
                            title="View"
                            onClick={() => handleShowModal(row.id, true)}
                          >
                            <RemoveRedEyeIcon />
                          </IconButton>
                        </Grid>
                        {row.approved_date ? (
                          <React.Fragment>
                            <Grid>
                              <IconButton
                                title="Edit"
                                onClick={() => handleShowModal(row.id, false)}
                              >
                                <EditIcon />
                              </IconButton>
                            </Grid>
                            <Grid>
                              <IconButton
                                title="Delete"
                                onClick={() =>
                                  handleShowDelModal(row.id, row.brand_name)
                                }
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                            <Grid>
                              <Switch
                                color={
                                  row.is_active == "0" ? "error" : "success"
                                }
                                title="Active"
                                checked={row.is_active == "1"}
                                onChange={() => handleActiveDeactive(row.id)}
                              />
                            </Grid>
                          </React.Fragment>
                        ) : (
                          <span>
                            {props.loginInfo.user_type ==
                              UserType.Administrator && (
                              <Grid>
                                <IconButton
                                  title="Approve"
                                  onClick={() => handleApproved(row.id)}
                                >
                                  <CheckCircleIcon color="success" />
                                </IconButton>
                              </Grid>
                            )}
                          </span>
                        )}
                        <Grid>
                          <IconButton
                            title="Brand Merchant Configuration"
                            onClick={() => goToBrandMerchant(row.id)}
                          >
                            <StorefrontIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          currentPage={pagination.currentPage}
          perPageRows={pagination.perPageRows}
          totalItems={pagination.totalItems}
          totalPages={pagination.totalPages}
          handleOnChangePage={handlePageChange}
        />
      </div>
      {showModal && (
        <ViewEditBrandModal
          id_vendor={vendorId}
          id={modalId}
          closeModal={handleCloseModal}
          disabled={modalFiledDisabled}
        />
      )}{" "}
      {showCmfModal && (
        <ConfirmationModal
          closeModal={handleDelCloseModal}
          title={cmfModalDetails.title}
          message={cmfModalDetails.message}
        />
      )}
    </React.Fragment>
  );
}
