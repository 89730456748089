import React from 'react'
import { TCriteriaDelCmfModalDetails } from '../data/types/componentState';

const useCriteriaDelCmfModalState = (props: any = null): {
  showCriteriaDelCmfModal: boolean
  setShowCriteriaDelCmfModal: (value: boolean) => void
  criteriaDelCmfModalDetails: TCriteriaDelCmfModalDetails
  setCriteriaDelCmfModalDetails: (value: TCriteriaDelCmfModalDetails) => void
} => {
  const [showCriteriaDelCmfModal, setShowCmfModalState] = React.useState<boolean>(false);
  // const [cmfModalId, setCmfModalIdState] = React.useState<number>(0);
  const [criteriaDelCmfModalDetails, setCmfModalDetailState] = React.useState<TCriteriaDelCmfModalDetails>({
    id: 0,
    title: '',
    message: '',
    type: 0,
  });

  const setShowCriteriaDelCmfModal = (value: boolean) => {
    setShowCmfModalState(value)
  }

  const setCriteriaDelCmfModalDetails = (state: TCriteriaDelCmfModalDetails) => {
    setCmfModalDetailState({...criteriaDelCmfModalDetails, ...state});
  }

  return {
    showCriteriaDelCmfModal,
    setShowCriteriaDelCmfModal,
    criteriaDelCmfModalDetails,
    setCriteriaDelCmfModalDetails,
  }
}

export default useCriteriaDelCmfModalState;