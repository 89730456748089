import history from "../history";
import { localStorageUtils } from "../utils/localStorageUtils";
import { toast } from "react-toastify";
import { publicRoutePath } from "../AppConstants";


export const checkValidUser = (
  pathname: string,
  pathList: string[],
  userType: number
) => {
  let currentType = localStorageUtils.getUserType();
  let token = localStorageUtils.getAccessToken();
  console.log(token);
  console.log(currentType);
 
  
  let truePath = false;
  for(const path of pathList){
   
    if (pathname.includes(path)) {
      truePath = true;
    }
  }

  if(!truePath){
    history.push(publicRoutePath.pageNotFound);
  }
};
