import { toast } from "react-toastify";
import { appErrors } from "../../../AppConstants";
import { TFetchAllTransactions } from "../../../data/types/adminApi";
import {
  ADMIN_ACCOUNT_APPROVE_DISAPPROVE,
  ADMIN_FETCH_ALL_TRANSACTIONS,
  ADMIN_TRANSACTION_APPROVE_REJECT,
} from "../../../services/AppServices";
import { getEncryptedText, PostStatus } from "../../../utils/sharedFunctions";

export const fetchTransactionList = async (props: any = {}) => {
  const payload: TFetchAllTransactions = {
    // searchFilter: props.searchFilter ? props.searchFilter : "",
    first_name: props.first_name ? props.first_name : "",
    transaction_date: props.transaction_date ? props.transaction_date : "",
    offer_title: props.offer_title ? props.offer_title : "",
    transaction_reference_number: props.transaction_reference_number
      ? props.transaction_reference_number
      : "",

    perPageRows: props.perPageRows ? props.perPageRows : 10,
    currentPage: props.currentPage ? props.currentPage : 1,
    orderBy: props.orderBy ? props.orderBy : "DESC",
    sortBy: props.sortBy ? props.sortBy : "id",
    status: props.status ? props.status : 0,
  };
  try {
    const data = await ADMIN_FETCH_ALL_TRANSACTIONS(payload);
    if (data.code === 200 || data.code === "200") {
      return data.data;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
};

// export const deleteTransaction = async (id: number) => {
//   try {
//     const payload = getEncryptedText(id.toString());
//     const data = await DELETE_TRANSACTION(payload);
//     if (data.code === 200 || data.code === "200") {
//       toast.success("Transaction successfully deleted");
//       return true;
//     } else {
//       toast.error(data?.message);
//     }
//   } catch (e: any) {
//     toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
//   }
//   return false;
// }

export const approveRejectTransaction = async (
  id: number,
  isApprovedRejected: number,
  reason: string,
  discount_amount: number,
  transaction_amount: number = 0
) => {
  try {
    if (isApprovedRejected == PostStatus.Approved) {
      if (!transaction_amount || transaction_amount == 0) {
        toast.error(appErrors.UNKNOWN_ERROR_TRY_AGAIN);
        return false;
      }
      if (transaction_amount < discount_amount) {
        toast.error(
          "Cashback should not be greater than transaction amount!"
        );
        return false;
      }
    }

    const payload = { id, isApprovedRejected, reason, discount_amount };
    const data = await ADMIN_TRANSACTION_APPROVE_REJECT(payload);
    if (data.code === 200 || data.code === "200") {
      toast.success(data.message);
      return true;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
  return false;
};


export const approveOfferTransaction = async (id: number) => {
  try {
    const payload = { id, isApprovedRejected: PostStatus.Approved, reason: "", discount_amount: null };
    const data = await ADMIN_TRANSACTION_APPROVE_REJECT(payload);
    if (data.code === 200 || data.code === "200") {
      toast.success(data.message);
      return true;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
  return false;
}

export const headCells = [
  {
    // id: "first_name",
    id: "insta_uname",
    disablePadding: false,
    label: "Influencer",
    searchLabel: "Influencer",
    width: "14%",
    textAlign: "left",
    disableSearch: false,
  },
  {
    id: "concated_offer_title",
    disablePadding: false,
    label: "Brand/Offer Title",
    searchLabel: "Brand/Offer Title",
    width: "15%",
    textAlign: "left",
    disableSearch: false,
  },
  {
    id: "transaction_date",
    disablePadding: false,
    label: "Transaction Date",
    searchLabel: "Transaction Date",
    width: "14%",
    disableSearch: false,
    type: "date",
    textAlign: "center",
  },
  {
    id: "transaction_reference_number",
    disablePadding: false,
    label: "Transaction Ref#",
    searchLabel: "Reference#",
    width: "14%",
    textAlign: "left",
    disableSearch: false,
  },
  {
    id: "transaction_amount",
    disablePadding: true,
    label: "Amount",
    searchLabel: "Status",
    width: "10%",
    disableSearch: false,
    textAlign: "right",
  },
  {
    id: "end_date",
    disablePadding: false,
    label: "Offer Expiry",
    textAlign: "right",
    width: "10%",
    disableSearch: true,
  },
  {
    id: "post_expire_date",
    disablePadding: false,
    label: "Post expiry",
    textAlign: "right",
    width: "10%",
    disableSearch: true,
  },
  {
    id: "action",
    disablePadding: false,
    label: "Actions",
    textAlign: "left",
    width: "13%",
    isNotSortable: true,
    disableSearch: true,
  },
];
