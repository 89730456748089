import React from "react";
import moment from "moment";
import history from "../../../history";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import FiberManualRecordOutlinedIcon from "@mui/icons-material/FiberManualRecordOutlined";
import PaidIcon from "@mui/icons-material/Paid";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import TablePagination from "../../../components/common/TablePagination";
import ConfirmationModal from "../../../components/common/ConfirmationModal";
import usePaginationState from "../../../hooks/usePaginationState";
import useConfirmationModalState from "../../../hooks/useConfirmationModalState";
import SortTableColumn from "../../../components/common/SortTableColumn";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../components/common/TableCell";
import {
  headCells,
  fetchAllOffers,
  deleteOffer,
  activeDeactiveOffer,
} from "./offerList";
import {
  TableCell,
  TextField,
  Table,
  TableBody,
  TableContainer,
  Paper,
  Grid,
  Select,
  MenuItem,
  IconButton,
  Switch,
  Button,
} from "@mui/material";
import {
  getCurrentDate,
  getEncryptedText,
  UserType,
} from "../../../utils/sharedFunctions";
import { adminRoutePath, vendorRoutePath } from "../../../AppConstants";
import { PER_PAGE_ROWS_OPTIONS } from "../../../AppConfig";

const OfferList = (props: any) => {
  const [offerList, setOfferList] = React.useState([]);
  const { showCmfModal, setShowCmfModal, cmfModalDetails, setCmfModalDetails } =
    useConfirmationModalState();
  const { pagination, setPagination } = usePaginationState({
    searchFilter: "",
    vendorId:
      props.loginInfo.user_type == UserType.Vendor ? props.loginInfo.id : "",
  });
  const [isLoading, setIsLoading] = React.useState(false);
  let timer: ReturnType<typeof setTimeout>;

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      setOfferList([]);
      const data = await fetchAllOffers(pagination);
      if (data) {
        setPagination({
          searchFilter: data.pagination.searchFilter
            ? data.pagination.searchFilter
            : "",
          perPageRows: data.pagination.perPageRows
            ? data.pagination.perPageRows
            : 10,
          currentPage: data.pagination.currentPage
            ? data.pagination.currentPage
            : 1,
          totalPages: data.pagination.totalPages
            ? data.pagination.totalPages
            : 0,
          totalItems: data.pagination.totalItems
            ? data.pagination.totalItems
            : 0,
          orderBy: data.pagination.orderBy ? data.pagination.orderBy : "desc",
          sortBy: data.pagination.sortBy ? data.pagination.sortBy : "id",
        });
        setOfferList(data.offers);
      }
    } catch (e) {}
    setIsLoading(false);
  };

  const handlePageChange = (page: number) => {
    pagination.currentPage = page;
    fetchData();
  };

  const handleRequestSort = (property: string) => {
    pagination.orderBy =
      pagination.sortBy == property
        ? pagination.orderBy == "asc"
          ? "desc"
          : "asc"
        : "asc";
    pagination.sortBy = property;
    fetchData();
  };

  const handleRowsPerPage = (rowCount: number) => {
    pagination.perPageRows = rowCount;
    pagination.currentPage = 1;
    fetchData();
  };

  const handleSearchChange = async (e: any) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(async () => {
      if (e.target.name == "searchFilter") {
        pagination.searchFilter = e.target.value;
        pagination.currentPage = 1;
        fetchData();
      }
    }, 500);
  };

  const handleActiveDeactive = async (id: number) => {
    const tempOffer: any = offerList.find((offer: any) => id == offer.id);
    if (!tempOffer) return;
    const result = await activeDeactiveOffer(
      id,
      tempOffer.is_active == "0" ? 1 : 0
    );
    if (result) {
      const tempOfferList: any = offerList.map((offer: any) => {
        if (offer.id == id) {
          return { ...offer, is_active: offer.is_active == "0" ? "1" : "0" };
        }
        return offer;
      });
      setOfferList(tempOfferList);
    }
  };

  const handleShowDelModal = (id: number, name: string) => {
    setCmfModalDetails({
      id,
      title: "Delete Offer",
      message: `You are about to delete ${name} offer.`,
    });
    setShowCmfModal(true);
  };

  const handleDelCloseModal = async (status: boolean) => {
    setShowCmfModal(false);
    if (status) {
      const resDelete = await deleteOffer(cmfModalDetails.id);
      if (resDelete) fetchData();
    }
  };

  const handleNavigation = (offerId: number | null) => {
    if (offerId) {
      if (props.loginInfo.user_type === UserType.Administrator) {
        history.push(
          `${adminRoutePath.updateOffer}/${getEncryptedText(
            offerId.toString()
          )}`
        );
      } else {
        history.push(
          `${vendorRoutePath.updateOffer}/${getEncryptedText(
            offerId.toString()
          )}`
        );
      }
    } else {
      if (props.loginInfo.user_type === UserType.Administrator) {
        history.push(`${adminRoutePath.addOffer}`);
      } else {
        history.push(`${vendorRoutePath.addOffer}`);
      }
    }
  };

  const handleNavigationToViewOffer = (offerId: number) => {
    if (props.loginInfo.user_type === UserType.Administrator) {
      history.push(
        `${adminRoutePath.viewOffer}/${getEncryptedText(offerId.toString())}`
      );
    } else {
      history.push(
        `${vendorRoutePath.viewOffer}/${getEncryptedText(offerId.toString())}`
      );
    }
  };

  const handleNavigationToOfferTransaction = () => {
    if (props.loginInfo.user_type === UserType.Administrator) {
      history.push(
        `${adminRoutePath.offerTransactions}`
      );
    } else {
      history.push(
        `${vendorRoutePath.offerTransactions}`
      );
    }
  }

  return (
    <React.Fragment>
      <div className="page-header">
        <span className="page-title">Offer List</span>
      </div>
      <div>
        <Grid container spacing={2} className="headElements">
          <Grid item className="leftHead">
            <p className="selectPages">Show</p>
            <Select
              value={pagination.perPageRows}
              label="Page"
              className="dropDown"
              onChange={(e:any) => handleRowsPerPage(Number(e.target.value))}
            >
              {PER_PAGE_ROWS_OPTIONS.map((item: number) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
            {/* <Button className='recordButton'>Add Record</Button> */}
            <span
              className="margin-left-16 btn-icon"
              title="Reload"
              onClick={() => fetchData()}
            >
              <RefreshIcon fontSize="large" className="color-light-blue" />
            </span>
            <Button
              className="btn-primary margin-left-16"
              onClick={() => handleNavigation(null)}
            >
              Add New Offer
            </Button>
          </Grid>

          <Grid item className="rightHead">
            <p className="selectPages">Search</p>
            <TextField
              size={"small"}
              className="searchField"
              id="outlined-basic"
              name="searchFilter"
              placeholder="Search Offers"
              onChange={(e: any) => handleSearchChange(e)}
            />
          </Grid>
        </Grid>
        <TableContainer component={Paper} className="tableContainer">
          <Table stickyHeader className="table" aria-label="simple table">
            <SortTableColumn
              order={pagination.orderBy}
              orderBy={pagination.sortBy}
              headCells={headCells}
              handleRequestSort={handleRequestSort}
            />
            <TableBody>
              {isLoading ? (
                <TableCell colSpan={7}>
                  <div className="loader-container">
                    <div className="loader"></div>
                  </div>
                </TableCell>
              ) : (
                offerList.map((row: any, index) => (
                  <StyledTableRow hover key={index}>
                    <StyledTableCell className="table-cell-left-align">
                      <Grid item lg={10}>
                        {row.offer_title}
                      </Grid>
                    </StyledTableCell>
                    <StyledTableCell className="table-cell-left-align">
                      {row.vendor_name}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell-left-align">
                      {row.brand_name}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell-left-align">
                      {row.category_name}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell-center-align">
                      {moment(row.start_date).format("DD/MM/YYYY")}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell-center-align">
                      {moment(row.end_date).format("DD/MM/YYYY")}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell-left-align">
                      <Grid
                        container
                        className="actionGrid"
                        justifyContent="start"
                      >
                        <Grid>
                          <IconButton
                            title="View"
                            onClick={() => handleNavigationToViewOffer(row.id)}
                          >
                            <RemoveRedEyeIcon />
                          </IconButton>
                        </Grid>
                        {new Date(row.end_date).getTime() >
                          getCurrentDate().getTime() && (
                          <Grid>
                            <IconButton
                              title="Edit"
                              onClick={() => handleNavigation(row.id)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Grid>
                        )}
                        {new Date(row.start_date).getTime() >
                          getCurrentDate().getTime() && (
                          <Grid>
                            <IconButton
                              title="Delete"
                              onClick={() =>
                                handleShowDelModal(row.id, row.offer_title)
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        )}
                        {new Date(row.end_date).getTime() >
                          getCurrentDate().getTime() && (
                          <Grid>
                            <Switch
                              color={row.is_active == "0" ? "error" : "success"}
                              title="Active/Inactive"
                              checked={row.is_active == "1"}
                              onChange={() => handleActiveDeactive(row.id)}
                            />
                          </Grid>
                        )}
                         <Grid>
                            <IconButton
                              title="Offer Transaction"
                              onClick={() =>
                                handleNavigationToOfferTransaction()
                              }
                            >
                              <PaidIcon />
                            </IconButton>
                          </Grid>
                      </Grid>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          currentPage={pagination.currentPage}
          perPageRows={pagination.perPageRows}
          totalItems={pagination.totalItems}
          totalPages={pagination.totalPages}
          handleOnChangePage={handlePageChange}
        />
      </div>
      {showCmfModal && (
        <ConfirmationModal
          closeModal={handleDelCloseModal}
          title={cmfModalDetails.title}
          message={cmfModalDetails.message}
        />
      )}
    </React.Fragment>
  );
};

export default OfferList;
