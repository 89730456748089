import React from "react";
import {
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
  Grid,
  Select,
  MenuItem,
  IconButton,
  Button,
} from "@mui/material";
import SortTableColumn from "../../../components/common/SortTableColumn";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../components/common/TableCell";
import { headCells, fetchAllOfferTransaction } from "./OfferTransaction";
import usePaginationState from "../../../hooks/usePaginationState";
import RefreshIcon from "@mui/icons-material/Refresh";
import TablePagination from "../../../components/common/TablePagination";
import { PER_PAGE_ROWS_OPTIONS } from "../../../AppConfig";
import moment from "moment";
import {
  PostStatus,
  getPostStatusLabel,
  getPostStatusBgColorClass,
  UserType,
} from "../../../utils/sharedFunctions";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import { adminRoutePath, vendorRoutePath } from "../../../AppConstants";
import history from "../../../history";

export default function MuiTable(props: any) {
  const [offerTransactionList, setOfferTransactionList] = React.useState<any>(
    []
  );
  const { pagination, setPagination } = usePaginationState({
    searchFilter: "",
  });
  const [isLoading, setIsLoading] = React.useState(false);
  let timer: ReturnType<typeof setTimeout>;

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      setOfferTransactionList([]);
      const data = await fetchAllOfferTransaction(pagination);
      if (data) {
        setPagination({
          searchFilter: data.pagination.searchFilter
            ? data.pagination.searchFilter
            : "",
          perPageRows: data.pagination.perPageRows
            ? data.pagination.perPageRows
            : 10,
          currentPage: data.pagination.currentPage
            ? data.pagination.currentPage
            : 1,
          totalPages: data.pagination.totalPages
            ? data.pagination.totalPages
            : 0,
          totalItems: data.pagination.totalItems
            ? data.pagination.totalItems
            : 0,
          orderBy: data.pagination.orderBy ? data.pagination.orderBy : "desc",
          sortBy: data.pagination.sortBy ? data.pagination.sortBy : "id",
        });
        setOfferTransactionList(data.offer_transactions);
      }
    } catch (e) {}
    setIsLoading(false);
  };

  const handleRequestSort = (property: string) => {
    pagination.orderBy =
      pagination.sortBy === property
        ? pagination.orderBy === "asc"
          ? "desc"
          : "asc"
        : "asc";
    pagination.sortBy = property;
    fetchData();
  };

  const handleRowsPerPage = (rowCount: number) => {
    pagination.perPageRows = rowCount;
    pagination.currentPage = 1;
    fetchData();
  };

  const handleSearchChange = async (e: any) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(async () => {
      if (e.target.name === "searchFilter") {
        pagination.searchFilter = e.target.value;
        pagination.currentPage = 1;
        fetchData();
      }
    }, 500);
  };

  const handlePageChange = (page: number) => {
    pagination.currentPage = page;
    fetchData();
  };

  const goBack = () => {
    if (props.loginInfo.user_type === UserType.Vendor) {
      history.push(`${vendorRoutePath.brandOffers}`);
    } else {
      history.push(`${adminRoutePath.vendorOffers}`);
    }
  };

  return (
    <React.Fragment>
      <div className="page-header">
        <span className="page-title">Offer Transactions</span>
      </div>
      <div>
        <div className="OT-analyze-box-container">
          <div className="analyze-box">
            <h4 className="analyze-box-value">230</h4>
            <p className="analyze-box-title">CPE</p>
          </div>
          <div className="analyze-box">
            <h4 className="analyze-box-value">276</h4>
            <p className="analyze-box-title">CPR</p>
          </div>
          <div className="analyze-box">
            <h4 className="analyze-box-value">624</h4>
            <p className="analyze-box-title">Reach</p>
          </div>
        </div>

        <Grid container spacing={2} className="headElements">
          <Grid item className="leftHead">
            <p className="selectPages">Show</p>
            <Select
              value={pagination.perPageRows}
              label="Page"
              className="dropDown"
              onChange={(e) => handleRowsPerPage(Number(e.target.value))}
            >
              {PER_PAGE_ROWS_OPTIONS.map((item: number) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
            <span
              className="margin-left-16 btn-icon"
              title="Reload"
              onClick={() => fetchData()}
            >
              <RefreshIcon fontSize="large" className="color-light-blue" />
            </span>
          </Grid>
          <Grid item className="rightHead">
            <p className="selectPages">Search</p>
            <TextField
              size={"small"}
              className="searchField"
              id="outlined-basic"
              name="searchFilter"
              placeholder="Search Offer Transactions"
              onChange={(e: any) => handleSearchChange(e)}
            />
            <Button
              className="btn-primary margin-left-16 margin-right-8"
              onClick={() => goBack()}
            >
              BACK
            </Button>
          </Grid>
        </Grid>
        <TableContainer component={Paper} className="tableContainer">
          <Table stickyHeader className="table" aria-label="simple table">
            <SortTableColumn
              order={pagination.orderBy}
              orderBy={pagination.sortBy}
              headCells={headCells}
              handleRequestSort={handleRequestSort}
            />
            <TableBody>
              {isLoading ? (
                <TableCell colSpan={6}>
                  <div className="loader-container">
                    <div className="loader"></div>
                  </div>
                </TableCell>
              ) : (
                offerTransactionList.map((row: any, index: any) => (
                  <StyledTableRow
                    hover
                    key={index}
                    className="user-table-row-height"
                  >
                    <StyledTableCell className="tableCell table-cell-left-align">
                      {row.first_name}
                    </StyledTableCell>
                    <StyledTableCell>
                      {moment(row.transaction_date).format("DD/MM/YYYY")}
                    </StyledTableCell>
                    <StyledTableCell className="tableCell table-cell-right-align">
                      {row.transaction_amount.toFixed(2)}
                    </StyledTableCell>
                    <StyledTableCell>
                      <span
                        className={`text-rounded-chip ${getPostStatusBgColorClass(
                          row.post_status
                        )}`}
                      >
                        {getPostStatusLabel.get(row.post_status)}{" "}
                      </span>
                    </StyledTableCell>
                    <StyledTableCell>{row.likes}</StyledTableCell>
                    <StyledTableCell>{row.comments}</StyledTableCell>
                    <StyledTableCell>{row.reach}</StyledTableCell>
                    <StyledTableCell className="tableCell table-cell-left-align">
                      <Grid
                        container
                        className="actionGrid"
                        justifyContent="start"
                      >
                        <React.Fragment>
                          {row.post_status === PostStatus.Submitted ||
                          row.post_status === PostStatus.Resubmitted ? (
                            <React.Fragment>
                              <Grid>
                                <IconButton
                                  title="Approve"
                                  // onClick={() =>
                                  //   openApproveModal(
                                  //     row.id,
                                  //     row.id_offer,
                                  //     row.transaction_reference_number
                                  //   )
                                  // }
                                >
                                  <CheckCircleOutlineOutlinedIcon color="success" />
                                </IconButton>
                              </Grid>
                              <Grid>
                                <IconButton
                                  title="Reject"
                                  // onClick={() => openRejectionModal(row.id)}
                                >
                                  <CancelOutlinedIcon color="error" />
                                </IconButton>
                              </Grid>
                            </React.Fragment>
                          ) : row.post_status === PostStatus.Approved ? (
                            <IconButton title="Approved">
                              <CheckCircleIcon color="success" />
                            </IconButton>
                          ) : (
                            row.post_status === PostStatus.Rejected && (
                              <IconButton title="Rejected">
                                <CancelIcon color="error" />
                              </IconButton>
                            )
                          )}
                          {row.post_url && (
                            <IconButton
                              title="story/post"
                              onClick={() =>
                                window.open(row.post_url, "_blank")
                              }
                            >
                              <InstagramIcon />
                            </IconButton>
                          )}
                        </React.Fragment>
                      </Grid>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          currentPage={pagination.currentPage}
          perPageRows={pagination.perPageRows}
          totalItems={pagination.totalItems}
          totalPages={pagination.totalPages}
          handleOnChangePage={handlePageChange}
        />
      </div>
    </React.Fragment>
  );
}
