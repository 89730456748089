import { toast } from "react-toastify";
import { appErrors } from "../../../AppConstants";
import { TFetchAllVendors } from "../../../data/types/adminApi";
import { ADMIN_ACCOUNT_ACTIVE_DEACTIVE, ADMIN_ACCOUNT_APPROVE_DISAPPROVE, ADMIN_FETCH_ALL_VENDORS, DELETE_VENDOR } from "../../../services/AppServices";
import { getEncryptedText } from "../../../utils/sharedFunctions";

export const fetchVendorList = async (props: any = {}) => {
  const payload: TFetchAllVendors = {
    searchFilter: props.searchFilter ? props.searchFilter : "",
    perPageRows: props.perPageRows ? props.perPageRows : 10,
    currentPage: props.currentPage ? props.currentPage : 1,
    isActive: props.isActive ? props.isActive : "",
    orderBy: props.orderBy ? props.orderBy : "DESC",
    sortBy: props.sortBy ? props.sortBy : "id",
  };
  try {
    const data = await ADMIN_FETCH_ALL_VENDORS(payload);
    if (data.code === 200 || data.code === "200") {
      return data.data;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
};

export const deleteVendor = async (id: number) => {
  try {
   const payload = getEncryptedText(id.toString());
    const data = await DELETE_VENDOR(payload);
    if (data.code === 200 || data.code === "200") {
      toast.success("Vendor successfully deleted");
      return true;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
  return false;
}

export const activeDeactiveAccount = async (id: number, is_active: number) => {
  try {
    const payload = {id, is_active};
    const data = await ADMIN_ACCOUNT_ACTIVE_DEACTIVE(payload);
    if (data.code === 200 || data.code === "200") {
      toast.success(data.message);
      return true;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
  return false;
}

export const approvedDeapprovedAccount = async (id: number, isApproved: number) => {
  try {
    const payload = {id, isApproved};
    const data = await ADMIN_ACCOUNT_APPROVE_DISAPPROVE(payload);
    if (data.code === 200 || data.code === "200") {
      toast.success(data.message);
      return true;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
  return false;
}

export const headCells = [
  {
    id: "first_name",
    disablePadding: false,
    label: "First Name",
    width: "15%",
    textAlign: 'left',
  },
  {
    id: "last_name",
    disablePadding: false,
    label: "Last Name",
    width: "15%",
    textAlign: 'left',
  },
  {
    id: "vendor_name",
    disablePadding: false,
    label: "Vendor Name",
    width: "15%",
    textAlign: 'left',
  },
  {
    id: "primary_contact_number",
    disablePadding: true,
    label: "Contact No",
    width: "15%",
  },
  {
    id: "email_address",
    disablePadding: false,
    label: "Email Address",
    textAlign: 'left',
    width: "20%",
  },
  {
    id: "action",
    disablePadding: false,
    label: "Actions",
    textAlign: 'left',
    width: "20%",
    isNotSortable: true,
  },
];

