import * as Yup from "yup";
import { TOfferBucket, TPostCriteria } from "../../data/types/OfferState";

export const getInitialState = (
  postCriteria: TPostCriteria | null,
  bucketList: TOfferBucket[]
) => {
  let bucketId: string | number = "";
  if (postCriteria && postCriteria.id_offer_bucket) {
    const findBucket = bucketList.find(
      (bucket) => bucket.id === postCriteria.id_offer_bucket
    );
    if (
      findBucket &&
      findBucket.min_score === postCriteria.score_min_value &&
      findBucket.max_score === postCriteria.score_max_value
    ) {
      bucketId = findBucket.id;
    }
  }
  return {
    // score_min_value: postCriteria?.score_min_value || "",
    score_range: bucketId,
    post_discount: postCriteria?.post_discount ? Number(postCriteria.post_discount).toFixed(2) : "",
  };
};

export const FORM_VALIDATION = Yup.object().shape({
  // score_min_value: Yup.number()
  //   .max(1000000, "Insta score must be less than 1000000")
  //   .required("Insta score is required!"),
  score_range: Yup.number().required("Score range is required"),
  post_discount: Yup.number()
    .max(99999, "Post discount must be less than 99999")
    .required("Post discount is required"),
});
