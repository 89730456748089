import React from "react";
import { localStorageUtils } from "../../../utils/localStorageUtils";
import history from "../../../history";
import { UserType } from "../../../utils/sharedFunctions";
import { adminRoutePath, publicRoutePath, vendorRoutePath } from "../../../AppConstants";

const HomePage = () => {
  React.useEffect(() => {
    //console.log(localStorageUtils.getUserSignedInStatus());
    if (localStorageUtils.getUserSignedInStatus()) {
      
      history.push(adminRoutePath.dashboard);
    } else {
      history.push(publicRoutePath.login);
    }
  }, []);
  
  return (
    <React.Fragment>
      <div>This is home page</div>
    </React.Fragment>
  );
};

export default HomePage;
