import { useState } from "react";

const useViewEditUserModalState = (): {
  showModal: boolean;
  setShowModalState: (value: boolean) => void;
  modalId: number;
  setModalIdState: (value: number) => void;
  modalFiledDisabled: boolean;
  setModalFiledDisabledState: (value: boolean) => void;
  vieEditUserType: number;
  setViewEditUserTypeState: (value: number) => void;
} => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalId, setModalId] = useState<number>(0);
  const [modalFiledDisabled, setModalFiledDisabled] = useState<boolean>(false);
  const [vieEditUserType, setViewEditUserType] = useState<number>(0);

  const setShowModalState = (value: boolean) => {
    setShowModal(value);
  };

  const setModalIdState = (value: number) => {
    setModalId(value);
  };

  const setModalFiledDisabledState = (value: boolean) => {
    setModalFiledDisabled(value);
  };

  const setViewEditUserTypeState = (value: number) => {
    setViewEditUserType(value);
  };

  return {
    showModal,
    setShowModalState,
    modalId,
    setModalIdState,
    modalFiledDisabled,
    setModalFiledDisabledState,
    vieEditUserType,
    setViewEditUserTypeState,
  };
};

export default useViewEditUserModalState;
