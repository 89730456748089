import { TStaticPage } from "../../../data/types/adminApi";
import { ADD_STATICPAGES,FETCH_STATIC_PAGE_LIST,FETCH_BY_ID_DETAILS,UPDATE_STATIC_PAGE,DELETE_STATIC_PAGE } from "../../../services/AdminServices";
import { toast } from "react-toastify";
import { appErrors } from "../../../AppConstants";
import * as Yup from "yup";


export const INITIAL_FORM_STATE = {
    page_name: "",
    contents : ""
    
  };
  
  export const FORM_VALIDATION = Yup.object().shape({
    
    page_name: Yup.string().required("Title is required!"),
    contents: Yup.string().required("content is required!")
        
  });
// export const apiAddStaticPage = async (props: any) =>{

//     const payload: TStaticPage = {
//         id:props.id,
//         page_name: props.page_name,
//         contents: props.contents,
//     };
//     try {
//       const data = await ADD_STATICPAGES(payload);
//       console.log(data);
//       if (data.code === 200 || data.code === "200") {
      
//        return true
//       } else {
//         toast.success("Data Add successfully...");
//       }
//     } catch (e: any) {
//       toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
//     }
//     return false;
  
// }

export const fetchStaticPage = async (props: any) =>{
    
  const payload: TStaticPage = {
      id:props.id,
      page_name: props.page_name,
      contents: props.contents,
  };
  try {
    const data = await FETCH_STATIC_PAGE_LIST(payload);
    console.log(data);
    if (data.status === 200 || data.status === "200") {
      return data.data;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }


}

export const apiCallFetchEditDetails = async (id: any) => {
   
 
  try {
   
    const data = await FETCH_BY_ID_DETAILS(id);
    console.log(data);
    if (data.status === 200 || data.status === "200") {
      return data.data;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
};

export const updateStaticpage = async (props: any) => {
  const payload: TStaticPage = {
    id:props.id,
      page_name: props.page_name,
      contents: props.contents,
   
  }
  try {
    const data = await UPDATE_STATIC_PAGE(payload);
    if (data.status === 200 || data.status === "200") {
      return true;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
  return false;
};

export const deleteStaticpage = async (props: any) => {
  const payload: TStaticPage = {
    id:props.id
   
  }
  try {
    const data = await DELETE_STATIC_PAGE(payload);
    if (data.status === 200 || data.status === "200") {
      return true;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
  return false;
};

