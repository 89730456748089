import React from "react";
import ReactDOM from "react-dom";

import "./assets/sass/style.scss";
import "./assets/sass/custom.scss";

import App from "./App";
import { ToastMessageContainer } from "./components/common/ToastMessagge";

ReactDOM.render(
  <React.StrictMode>
    <ToastMessageContainer />
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
