import * as Yup from "yup";
import { toast } from "react-toastify";
import { appErrors } from "../../../AppConstants";
import history from "../../../history";
import {
  VENDOR_UPDATE_PROFILE,
  GET_PROFILE,
  ADD_IMAGE,
  DELETE_IMAGE,
} from "../../../services/AppServices";
import { localStorageUtils } from "../../../utils/localStorageUtils";
import { TImageDetails } from "../../../data/types/OfferState";
import { formatPhoneMaskNumber, ImageFileType } from "../../../utils/sharedFunctions";
import { TVendorProfile } from "../../../data/types/appUser";

export const getInitialFormState = (vendor: TVendorProfile | null) => {
  return {
    id_app_user: vendor?.id_app_user || "",
    id: vendor?.id || "",
    vendor_name: vendor?.vendor_name || "",
    first_name: vendor?.first_name || "",
    last_name: vendor?.last_name || "",
    email_address: vendor?.email_address || "",
    primary_contact_number: vendor?.primary_contact_number || "",
    emergency_contact_number: vendor?.emergency_contact_number || "",
  };
};

export const FORM_VALIDATION = Yup.object().shape({
  vendor_name: Yup.string()
    .required("Vendor name is required!")
    .max(75, "Vendor name must be 75 characters!"),
  first_name: Yup.string()
    .required("First name is required!")
    .max(30, "First name must be 30 characters!"),
  last_name: Yup.string()
    // .required("Last name is required!")
    .max(30, "Last name must be 30 characters!"),
  email_address: Yup.string()
    .required("Email address is required!")
    .email("Invalid email")
    .max(75, "Email must be 75 characters!"),
  primary_contact_number: Yup.string()
    .max(20, "Primary contact number must be 20 characters")
    .min(10, "Primary contact number must be 10 characters"),
  emergency_contact_number: Yup.string()
    .max(20, "Emergency contact number must be 20 characters")
    .min(10, "Emergency contact number must be 10 characters")
});

export const apiCallFetchVendorProfile = async () => {
  try {
    const data = await GET_PROFILE();
    if (data.code === 200 || data.code === "200") {
      return data.data;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
};

export const apiCallUpdateProfile = async (
  fieldValues: any,
  addMainUserInfoHandler: any,
  imageDetails: TImageDetails,
  setVendor: (value: TVendorProfile | null) => void,
  delImageList: number[],
  setDelImageList: (value: number[]) => void
) => {
  const payload = {
    id: fieldValues.id,
    id_app_user: fieldValues.id_app_user,
    vendor_name: fieldValues.vendor_name,
    first_name: fieldValues.first_name,
    last_name: fieldValues.last_name,
    primary_contact_number: formatPhoneMaskNumber(fieldValues.primary_contact_number),
    emergency_contact_number: formatPhoneMaskNumber(fieldValues.emergency_contact_number),
    id_image: imageDetails.id ? imageDetails.id : null,
  };
  try {
    const data = await VENDOR_UPDATE_PROFILE(payload);
    if (data.code === 200 || data.code === "200") {
      setVendor(data.data);
      localStorageUtils.setUserInfo(data.data);
      addMainUserInfoHandler(data.data);
      apiCallDeleteImages(delImageList);
      setDelImageList([]);
      toast.success(data?.message);
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
};

export const handleDropImage = async (
  acceptedFiles: any,
  vendor_id: number | null,
  imageDetails: TImageDetails,
  setImageDetails: (value: TImageDetails) => void,
  setIsImgLoading: (value: boolean) => void,
  delImageList: number[],
  setDelImageList: (value: number[]) => void
) => {
  setIsImgLoading(true);
  let formData = new FormData();
  formData.append("image", acceptedFiles[0]);
  formData.append("vendorId", vendor_id ? vendor_id.toString() : "");
  formData.append("ftype", ImageFileType.VendorImage.toString());
  try {
    const data = await ADD_IMAGE(formData);
    if (data.code === 200 || data.code === "200") {
      if (imageDetails.id) {
        setDelImageList([...delImageList, imageDetails.id]);
      }
      setImageDetails({ id: data.data.id, image_path: data.data.image_path });
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
  setIsImgLoading(false);
};

export const apiCallDeleteImages = async (delImageList: number[]) => {
  for (const id of delImageList) {
    try {
      await DELETE_IMAGE({ id });
    } catch (e: any) {
      return false;
    }
  }
  return true;
};
