import React from 'react';

const useLogin = (): {
  showPassword: boolean;
  togglePasswordVisibility: () => void;
} => {
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  };
  return {
   showPassword,
   togglePasswordVisibility
  }
}

export default useLogin;