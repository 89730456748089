import { apiEndPoints } from "../AppConstants";
import {
  UserAuthentictation,
  TVendorRegistration,
  TLogout,
  TChangePassword,
  TForgotPassword,
  TRecoverPassword,
} from "../data/types/appUser";
import { TReSendOtp, TVendorVerification } from "../data/types/VerifyOtp";
import { getEncryptedText } from "../utils/sharedFunctions";
import { httpMethods, serviceMaker } from "./ServiceWarpper";

export const APP_LOGIN = (payload: UserAuthentictation) =>
  serviceMaker(`${apiEndPoints.LOGIN}`, httpMethods.POST, {
    user_name: payload.userName,
    password: payload.password,
  });

export const VENDOR_REGISTER = (payload: TVendorRegistration) =>
  serviceMaker(`${apiEndPoints.VENDOR_REGISTER}`, httpMethods.POST, {
    ...payload,
    password: getEncryptedText(payload.password),
  });

export const VENDOR_VERIFICATION = (payload: TVendorVerification) =>
  serviceMaker(`${apiEndPoints.VERIFY_VENDOR}`, httpMethods.POST, payload);

export const RE_SEND_OTP = (payload: TReSendOtp) =>
  serviceMaker(`${apiEndPoints.RESEND_OTP}`, httpMethods.POST, payload);

export const USER_LOGOUT = (payload: TLogout) =>
  serviceMaker(`${apiEndPoints.LOGOUT}`, httpMethods.POST, payload);

export const CHANGE_PASSWORD = (payload: TChangePassword) =>
  serviceMaker(`${apiEndPoints.CHANGE_PASSWORD}`, httpMethods.POST, payload);

export const FORGOT_PASSWORD = (payload: TForgotPassword) =>
  serviceMaker(`${apiEndPoints.FORGOT_PASSWORD}`, httpMethods.POST, payload);

export const RECOVER_PASSWORD = (payload: TRecoverPassword) =>
  serviceMaker(`${apiEndPoints.RECOVER_PASSWORD}`, httpMethods.POST, payload);

export const FETCH_RESEND_OTP_WAITING_TIME = () =>
  serviceMaker(`${apiEndPoints.FETCH_RESEND_OTP_WAITING_TIME}`, httpMethods.GET);
