import React from "react";
import { Drawer, Box, Grid, Button } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Formik, Form } from "formik";
import {
  getInitialState,
  FORM_VALIDATION,
  fetchBrandById,
  addBrand,
  updateBrand,
  handleDropImage,
  apiCallDeleteImages,
} from "./ViewEditBrand";
import Textfield from "../../../components/FormsUI/Textfield";
import useImageState from "../../../hooks/useImageState";
import Dropzone from "react-dropzone";
import ImageRenderer from "../../../components/core/ImageRenderer";
import NoImgAvailable from "../../../assets/images/no-image.jpg";
import { toast } from "react-toastify";
import { appErrors } from "../../../AppConstants";
import {
  ImageFileType,
  checkIsValidImage,
} from "../../../utils/sharedFunctions";

const ViewEditBrandModal = (props: any) => {
  const [brand, setBrand] = React.useState<any>(null);
  const {
    imageDetails,
    setImageDetails,
    delImageList,
    setDelImageList,
    isImgLoading,
    setIsImgLoading,
  } = useImageState();

  React.useEffect(() => {
    if (props.id !== 0) {
      new Promise(async () => {
        const data = await fetchBrandById(props.id);
        setBrand(data);
        if (data && data.brand_image) {
          setImageDetails({
            id: data.brand_image.id,
            image_path: data.brand_image.image_path,
          });
        }
      });
    }
  }, []);

  const checkApiCall = async (fieldValues: any) => {
    if (brand) {
      const data = await updateBrand(
        {
          ...fieldValues,
          id: brand.id,
          id_app_user: brand.id_app_user,
        },
        imageDetails,
        delImageList,
        setDelImageList
      );
      if (data) props.closeModal(true);
    } else {
      const data = await addBrand(
        {
          ...fieldValues,
          id_vendor: props.id_vendor,
        },
        imageDetails,
        delImageList,
        setDelImageList
      );
      if (data) props.closeModal(true);
    }
  };

  const dropzoneSelectedImage = async (acceptedImage: any) => {
    const isValidImage = await checkIsValidImage(acceptedImage[0], ImageFileType.BrandImage);
    if (isValidImage) {
      const vendorId = props.id_vendor;
      handleDropImage(
        acceptedImage,
        vendorId,
        imageDetails,
        setImageDetails,
        setIsImgLoading,
        delImageList,
        setDelImageList
      );
    } else {
      toast.error(appErrors.PRODUCT_IMAGE_SIZE_TYPE);
    }
  };

  const handleRemoveImage = async () => {
    setIsImgLoading(true);
    if (imageDetails.id) {
      const data = await apiCallDeleteImages([
        ...delImageList,
        imageDetails.id,
      ]);
      if (data) {
        setDelImageList([]);
        setImageDetails({ id: null, image_path: "" });
        toast.success("Image successfully removed");
      } else {
        toast.error(appErrors.UNKNOWN_ERROR_TRY_AGAIN);
      }
    }
    setIsImgLoading(false);
  };

  return (
    <React.Fragment>
      <Drawer open anchor="right" className="side-modal">
        <Box className="modal-dialog-box">
          <div className="modal-header">
            <div className="modal-title">{brand?.first_name}</div>
            <div className="modal-header-action">
              <span
                className="modal-btn-close"
                onClick={() => props.closeModal(false)}
              >
                <Close />
              </span>
            </div>
          </div>
          <div className="modal-content">
            <Formik
              enableReinitialize
              initialValues={getInitialState(brand)}
              validateOnBlur={false}
              validationSchema={FORM_VALIDATION}
              onSubmit={async (fieldValues) => checkApiCall(fieldValues)}
            >
              <Form>
                <Grid container spacing={3}>
                  <Grid item className="display-flex">
                    <div className="padding-right-16">
                      {imageDetails.image_path ? (
                        isImgLoading ? (
                          <div className="offer-image-view">
                            <div className="loader-container">
                              <span className="loader"></span>
                            </div>
                          </div>
                        ) : (
                          <ImageRenderer
                            src={imageDetails.image_path}
                            height="140px"
                            width="140px"
                            style={{
                              border: "1px solid lightgrey",
                              borderRadius: "4px",
                            }}
                            title={"Offer Image"}
                            alt={"Offer"}
                          />
                        )
                      ) : (
                        <img
                          src={NoImgAvailable}
                          style={{
                            border: "0.5px solid lightgrey",
                            borderRadius: "4px",
                            height: "140px",
                            width: "140px",
                          }}
                          title={"Offer Image"}
                          alt={"Offer"}
                        />
                      )}
                    </div>
                    <div>
                      <p className="text-align-left">
                        {brand ? brand.brand_name : ""}
                      </p>
                      {!props.disabled && (
                        <Box display={{ sm: "inline-block", xs: "flex" }}>
                          <Dropzone
                            onDrop={(acceptedFiles: any) =>
                              dropzoneSelectedImage(acceptedFiles)
                            }
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <Button className="margin-right-16 btn-primary">
                                  UPLOAD/CHANGE
                                </Button>
                              </div>
                            )}
                          </Dropzone>
                        </Box>
                      )}
                      {!props.disabled && (
                        <Box
                          display={{ sm: "inline-block", xs: "flex" }}
                          pt={{ xs: 1, sm: 0 }}
                        >
                          <Button
                            className="btn-outline"
                            onClick={() => handleRemoveImage()}
                          >
                            REMOVE
                          </Button>
                        </Box>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Textfield
                      name="brand_name"
                      label="Brand Name"
                      disabled={props.disabled}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Textfield
                      name="brand_about"
                      label="Brand Description"
                      disabled={props.disabled}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Textfield
                      name="address"
                      label="Brand Address"
                      disabled={props.disabled}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Textfield
                      name="web_site_address"
                      label="Website"
                      disabled={props.disabled}
                    />
                  </Grid>

                  <Grid item>
                    {!props.disabled && (
                      <Button
                        type="submit"
                        className="btn-primary margin-right-16"
                      >
                        SUBMIT
                      </Button>
                    )}
                    <Button
                      className="btn-outline"
                      onClick={() => props.closeModal(false)}
                    >
                      CANCEL
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </Formik>
          </div>
        </Box>
      </Drawer>
    </React.Fragment>
  );
};

export default ViewEditBrandModal;
