import { toast } from "react-toastify";
import { appErrors } from "../../../AppConstants";
import { TFetchAllOffers } from "../../../data/types/OfferApi";
import {
  FETCH_ALL_OFFERS,
  DELETE_OFFER,
  ACTIVE_INACTIVE_OFFER,
} from "../../../services/AppServices";

export const fetchAllOffers = async (props: any) => {
  const payload: TFetchAllOffers = {
    vendorId: props.vendorId ? props.vendorId : "",
    searchFilter: props.searchFilter ? props.searchFilter : "",
    perPageRows: props.perPageRows ? props.perPageRows : 10,
    currentPage: props.currentPage ? props.currentPage : 1,
    isActive: props.isActive ? props.isActive : "",
    orderBy: props.orderBy ? props.orderBy : "DESC",
    sortBy: props.sortBy ? props.sortBy : "id",
  };

  try {
    const data = await FETCH_ALL_OFFERS(payload);
    if (data.code === 200 || data.code === "200") {
      return data.data;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
};

export const deleteOffer = async (id: number) => {
  try {
    const data = await DELETE_OFFER(id);
    if (data.code === 200 || data.code === "200") {
      toast.success("Offer successfully deleted");
      return true;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
  return false;
};

export const activeDeactiveOffer = async (id: number, is_active: number) => {
  try {
    const payload = { id, is_active };
    const data = await ACTIVE_INACTIVE_OFFER(payload);
    if (data.code === 200 || data.code === "200") {
      toast.success(data.message);
      return true;
    } else {
      toast.error(data?.message);
    }
  } catch (e: any) {
    toast.error(e?.data?.message || appErrors.UNKNOWN_ERROR_TRY_AGAIN);
  }
  return false;
};

export const headCells = [
  {
    id: "offer_title",
    disablePadding: false,
    label: "Offer",
    textAlign: "left",
    width: "20%",
  },
  {
    id: "vendor_name",
    disablePadding: false,
    label: "Vendor",
    textAlign: "left",
    width: "12%",
  },
  {
    id: "brand_name",
    disablePadding: false,
    label: "Brand",
    textAlign: "left",
    width: "12%",
  },
  {
    id: "category_name",
    disablePadding: false,
    label: "Category",
    textAlign: "left",
    width: "12%",
  },
  {
    id: "start_date",
    disablePadding: false,
    label: "Start Date",
    textAlign: "center",
    width: "12%",
  },
  {
    id: "end_date",
    disablePadding: false,
    label: "End Date",
    textAlign: "center",
    width: "12%",
  },
  {
    id: "action",
    disablePadding: false,
    label: "Actions",
    textAlign: "left",
    width: "20%",
    isNotSortable: true,
  },
];
