import { connect } from "react-redux";
import VendorRegisterForm from "../views/public/register/VendorRegisterForm";
import { TReduxDispatch, TReduxState } from "../store/store";

const mapStateToProps = (state: TReduxState) => ({
  loginInfo: state.loginInfo.loginInfo,
});

const mapDispatchToProps = (dispatch: TReduxDispatch) => ({});

const VendorRegisterContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(VendorRegisterForm);
export default VendorRegisterContainer;
