import { useState } from 'react';

const useViewEditVendorModalState = () : {
  showModal: boolean;
  setShowModalState: (value: boolean) => void;
  modalId: number;
  setModalIdState: (value: number) => void;
  modalFiledDisabled: boolean;
  setModalFiledDisabledState: (value: boolean) => void;
} => {
  
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalId, setModalId] = useState<number>(0);
  const [modalFiledDisabled, setModalFiledDisabled] = useState<boolean>(false);
  
  const setShowModalState = (value: boolean) => {
    setShowModal(value)
  };

  const setModalIdState = (value: number) => {
    setModalId(value)
  };

  const setModalFiledDisabledState = (value: boolean) => {
    setModalFiledDisabled(value)
  };
  
  return {
    showModal,
    setShowModalState,
    modalId,
    setModalIdState,
    modalFiledDisabled,
    setModalFiledDisabledState,
  }
}

export default useViewEditVendorModalState;