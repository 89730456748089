import { LOGIN_INFO, USER_INFO } from "../actionTypes";
import { localStorageUtils } from "../../utils/localStorageUtils";

const intialState = {
  userInfo: localStorageUtils.getUserInfo(),
  loginInfo: localStorageUtils.getUserInfo(),
};

export const userInfoReducer = (state = intialState, action: any) => {
  switch (action.type) {
    case LOGIN_INFO:
      return {
        ...state,
        loginInfo: action.data,
      };
    case USER_INFO:
      return {
        ...state,
        userInfo: action.data,
      };
    default:
      return { ...state };
  }
};
