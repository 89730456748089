import * as Yup from "yup";

export const getInitialState = (reason: string) => {
  return {
    reason: reason || "",
  };
};

export const FORM_VALIDATION = Yup.object().shape({
  reason: Yup.string()
    .required("Reason is required!")
    .min(3, "Reason must be 3 characters!")
    .max(5000, "Reason must be 5000 characters!"),
});

